import _, { isUndefined } from 'lodash';
import { initFilterForm } from '../../helpers/functions';
import { CREATE_FILTER_MODE, EMPTY_VALUE } from '../../helpers/const';

export const filterFormClear = (state, action) => {
    return {
        ...state,
        configuration: {
            ...state.configuration,
            filterForm: {
                ...state.configuration.filterForm,
                filterColumnsDropdownSelectedKey: 0,
                filterComparatorDropdown: {0: {columnKey: "", columnName: ""}},
                filterComparatorDropdownSelectedKey: 0,
                filterValue: "",
                filterDataType: "",
                mode: CREATE_FILTER_MODE
            }
        }
    }
}

export const fetch = (state, action) => {
    if (! state.configuration) { //Init filter form or preserve it
        if (action.payload.configuration.filters !== undefined) { //Import Export Log do not have filters
            const filterForm = initFilterForm(action.payload.configuration.filters);
            action.payload.configuration["filterForm"] = filterForm;
        }
    } else {
        action.payload.configuration["filterForm"] = state.configuration.filterForm; 
    }

    if (action.payload.configuration.filters !== undefined) { //Import Export Log do not have filters
        Object.keys(action.payload.configuration.filters).map(filterName => { //Init Active filters Object if there are none
            let filterObject = action.payload.configuration.filters[filterName];
            if (! filterObject['activeFilters']) {
                filterObject['activeFilters'] = {};
            }
            return true; 
        });
    }
    
    return action.payload;
}

export const ajax = (state, action) => {
    const {sendToMagoAction} = action.payload;

    if (! isUndefined(sendToMagoAction)) {
        const {question, description, rowsForExport} = sendToMagoAction;
        state = {
            ...state,
            sendToMagoAction: {
                ...state.sendToMagoAction,
                question: question,
                description: description,
                rowsForExport: rowsForExport
            }
        }
    }
    
    return state;
}

export const additionalActionSpinnerStart = (state) => {
    return {
        ...state,
        additionalAction: {
            ...state.additionalAction,
            spinner: true,
        }
    };
}

export const additionalActionSpinnerStop = (state) => {
    return {
        ...state,
        additionalAction: {
            ...state.additionalAction,
            spinner: false,
        }
    };
}

export const updateOnPage = (state, action) => {
    const {pageIndex, currentPage} = action.payload;

    return {
        ...state,
        configuration: {
            ...state.configuration,
            pagination: {
                ...state.configuration.pagination,
                onPageSelectedIndex: pageIndex,
                currentPage: currentPage,
            }
        }
    }
}

export const changePage = (state, action) => {
    const {pageNumber} = action.payload;

    return {
        ...state,
        configuration: {
            ...state.configuration,
            pagination: {
                ...state.configuration.pagination,
                currentPage: pageNumber,
            }
        }
    }
}

export const changeSortingDirection = (state, action) => {
    const {headerObject, currentPage, headerName} = action.payload;

    return {
        ...state,
        configuration: {
            ...state.configuration,
            gridColumns: {
                ...state.configuration.gridColumns,
                [headerName]: headerObject
            },
            pagination: {
                ...state.configuration.pagination,
                currentPage: currentPage
            }
        }
    };
}

export const cancelFilter = (state, action) => {
    const {fieldName, filterIndex} = action.payload;

    const newActiveFiltersObj = _.omit(state.configuration.filters[fieldName].activeFilters, filterIndex); 

    return {
        ...state,
        configuration: {
            ...state.configuration,
            filters: {
                ...state.configuration.filters,
                [fieldName]: {
                    ...state.configuration.filters[fieldName],
                    activeFilters: newActiveFiltersObj  
                }
            }
        }
    };
}

export const clearFilters = (state, action) => {
    if (state.configuration.filters !== undefined) {
        Object.keys(state.configuration.filters).forEach(fieldName => {
            state = {
                ...state,
                configuration: {
                    ...state.configuration,
                    filters: {
                        ...state.configuration.filters,
                        [fieldName]: {
                            ...state.configuration.filters[fieldName],
                            activeFilters: {}
                        }
                    }
                }
            };
        });
    }

    return state;
}

export const addFilter = (state, action) => {
    const {filterKey, filterObject} = action.payload;
    const uniqueKey = + new Date();

    return {
        ...state,
        configuration: {
            ...state.configuration,
            filters: {
                ...state.configuration.filters,
                [filterKey]: {
                    ...state.configuration.filters[filterKey],
                    activeFilters: {
                        ...state.configuration.filters[filterKey].activeFilters,
                        [uniqueKey]: filterObject
                    }
                }
            }
        }
    };
}

export const filterFormSelectColumn = (state, action) => {
    const {filterColumnsDropdownSelectedKey, filterComparatorDropdown, filterDataType} = action.payload;

    return {
        ...state,
        configuration: {
            ...state.configuration,
            filterForm: {
                ...state.configuration.filterForm,
                filterColumnsDropdownSelectedKey: filterColumnsDropdownSelectedKey,
                filterComparatorDropdown: filterComparatorDropdown,
                filterComparatorDropdownSelectedKey: 0,
                filterValue: "",
                filterDataType: filterDataType
            }
        }
    };
}

export const filterFormSelectComparator = (state, action) => {
    const {filterComparatorDropdownSelectedKey} = action.payload;

    return {
        ...state,
        configuration: {
            ...state.configuration,
            filterForm: {
                ...state.configuration.filterForm,
                filterComparatorDropdownSelectedKey: filterComparatorDropdownSelectedKey
            }
        }
    };
}

export const filterFormUpdateInput = (state, action) => {
    const {filterValue} = action.payload;
            
    return {
        ...state,
        configuration: {
            ...state.configuration,
            filterForm: {
                ...state.configuration.filterForm,
                filterValue: filterValue
            }
        }
    };
}

export const filterFormLoadForEdit = (state, action) => {
    const {
        filterColumnsDropdown, filterColumnsDropdownSelectedKey, filterComparatorDropdown, filterComparatorDropdownSelectedKey,
        filterDataType, filterValue, index, isActive, mode       
    } = action.payload;

    return {
        ...state,
        configuration: {
            ...state.configuration,
            filterForm: {
                ...state.configuration.filterForm,
                filterColumnsDropdownSelectedKey: filterColumnsDropdownSelectedKey, 
                filterComparatorDropdown: filterComparatorDropdown, 
                filterComparatorDropdownSelectedKey: filterComparatorDropdownSelectedKey,
                filterDataType: filterDataType, 
                filterValue: filterValue, 
                index: index, 
                isActive: isActive, 
                mode: mode
            }
        }
    };
}

export const toggleVisibility = (state, action) => {
    const newIsActive = ! state.configuration.filterForm.isActive;

    return {
        ...state,
        configuration: {
            ...state.configuration,
            filterForm: {
                ...state.configuration.filterForm,
                isActive: newIsActive 
            }
        }
    };
}

export const editFilterValues = (state, action) => {
    const {columnKey, index, filterValue, comparator} = action.payload;

    return {
        ...state,
        configuration: {
            ...state.configuration,
            filters: {
                ...state.configuration.filters,
                [columnKey]: {
                    ...state.configuration.filters[columnKey],
                    activeFilters: {
                        ...state.configuration.filters[columnKey].activeFilters,
                        [index]: {
                            ...state.configuration.filters[columnKey].activeFilters[index],
                            value: filterValue,
                            comparator: comparator,
                            applied: false
                        }
                    }
                }
            }
        }
    };
}

export const updateSendToMago = (state, action) => {
    const { elements, allValuesNotHidden, allValuesHidden, allValuesNotHiddenAndHidden } = action.payload;

    return {
        ...state, 
        sendToMagoAction: {
            ...state.sendToMagoAction,
            elements: elements,  
            allValuesNotHidden: allValuesNotHidden,  
            allValuesHidden: allValuesHidden,  
            allValuesNotHiddenAndHidden: allValuesNotHiddenAndHidden
        }
    };
}

export const toggleGridCheckbox = (state, action) => {
    const { value } = action.payload;
    const { checkedValues, uncheckedValues, allValues } = state.configuration.checkboxAction;

    const code = allValues[value];

    if (_.has(checkedValues, value)) {
        state = {
            ...state,
            configuration: {
                ...state.configuration,
                checkboxAction: {
                    ...state.configuration.checkboxAction,
                    checkedValues: _.omit(state.configuration.checkboxAction.checkedValues, value),
                    uncheckedValues: {
                        ...state.configuration.checkboxAction.uncheckedValues,
                        [value]: code
                    },
                }
            }
        }
    } else if (_.has(uncheckedValues, value)) {
        state = {
            ...state,
            configuration: {
                ...state.configuration,
                checkboxAction: {
                    ...state.configuration.checkboxAction,
                    uncheckedValues: _.omit(state.configuration.checkboxAction.uncheckedValues, value),
                    checkedValues: {
                        ...state.configuration.checkboxAction.checkedValues,
                        [value]: code
                    },
                }
            }
        }
    }

    return state;
}

export const checkAll = (state) => {
    const {allValues } = state.configuration.checkboxAction;

    _.forEach(allValues, (code, value) => {
        state = {
            ...state,
            configuration: {
                ...state.configuration,
                checkboxAction: {
                    ...state.configuration.checkboxAction,
                    checkedValues: {
                        ...state.configuration.checkboxAction.checkedValues,
                        [value]: code
                    },
                    uncheckedValues: {[EMPTY_VALUE]: EMPTY_VALUE}
                }
            }
        }
    }); 

    return state;
}

export const onVariantChange = (state, action) => {
    return {
        ...state,
        configuration: {
            ...state.configuration,
            checkboxAction: {
                ...state.configuration.checkboxAction,
                variant: {
                    ...state.configuration.checkboxAction.variant,
                    value: action.payload.variant
                }
            }
        }
    };
}

export const uncheckAll = (state) => {
    const { checkedValues, uncheckedValues, allValues } = state.configuration.checkboxAction;

    return {
        ...state,
        configuration: {
            ...state.configuration,
            checkboxAction: {
                ...state.configuration.checkboxAction,
                checkedValues: {[EMPTY_VALUE]: EMPTY_VALUE},
                uncheckedValues: allValues
            }
        }
    }
}

import actionTypes from "../../actions/appDocumentation/actionTypes";
import { openModal, closeModal, startSpinner, stopSpinner, fetchData } from './stateMutators';

const INITIAL_STATE = {
    isOpen: false,
    showSpinner: false,
    documentationData: {},
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.APP_DOCUMENTATION_OPEN_MODAL:
            return openModal(state);
        case actionTypes.APP_DOCUMENTATION_CLOSE_MODAL:
            return closeModal(state);
        case actionTypes.APP_DOCUMENTATION_SPINNER_START:
            return startSpinner(state);
        case actionTypes.APP_DOCUMENTATION_SPINNER_STOP:
            return stopSpinner(state);
        case actionTypes.APP_DOCUMENTATION_FETCH_DATA:
            return fetchData(state, action);
        default: 
            return state;
    }
}

export default reducer;

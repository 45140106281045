import React, { useState } from 'react';
import { Button, Popup, Grid, Icon, Dropdown, Modal, Header, ModalActions } from 'semantic-ui-react';
import { FormButtonProp } from '../../../../enum/apiResultProp/FormButtonProp';

export default ({ buttonProps, onButtonClick, size }) => {
    const [open, setOpen] = useState(false);

    const buttonName = buttonProps[FormButtonProp.ButtonName];
    const text = buttonProps[FormButtonProp.Text];
    const iconName = buttonProps[FormButtonProp.IconName];
    const popupQuestion = buttonProps[FormButtonProp.PopupQuestion];
    const popupPositiveText = buttonProps[FormButtonProp.PopupPositiveText];
    const popupNegativeText = buttonProps[FormButtonProp.PopupNegativeText];

    const onPositiveButtonClick = () => {
        setOpen(false);
        onButtonClick();
    }

    // const triggerButton1 = (
    //     <Dropdown.Item
    //         key={buttonName}
    //         icon={<Icon name={iconName} color='blue' />}
    //         text={<span style={{ color: '#3C8AD9', fontWeight: 'bold' }}>{text}</span>}
    //     />
    // );

    // const triggerButton2 = (
    //     <Button color='blue' onClick={onButtonClick} key={buttonName} >
    //         <Icon name={iconName} />
    //     </Button>
    // );

    const triggerButton = (
        <Popup content={text} trigger={
            <Button key={buttonName} onClick={() => setOpen(true)} >
                <Icon name={iconName} style={{color: '#3C8AD9'}} />
            </Button>
        } />
    );

    return (
        <Modal
            basic
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size='small'
            trigger={triggerButton}
        >
            <Header icon>
                <Icon name={'question'} />
                {popupQuestion}
            </Header>
            <ModalActions>
                <Button basic floated='left' color='red' inverted onClick={() => setOpen(false)}>
                    <Icon name='remove' /> {popupNegativeText}
                </Button>
                <Button color='green' inverted onClick={onPositiveButtonClick}>
                    <Icon name='checkmark' /> {popupPositiveText}
                </Button>
            </ModalActions>
        </Modal>
    );

    // return (
    //     <Popup
    //         wide
    //         open={open}
    //         trigger={triggerButton}
    //         onClose={() => setOpen(false)}
    //         onOpen={() => setOpen(true)}
    //         on='click'
    //     >
    //         <Grid>
    //             <Grid.Row centered>
    //                 <h2>{popupQuestion}</h2>
    //             </Grid.Row>
    //             <Grid.Row divided columns='equal'>
    //                 <Grid.Column>
    //                     <Button onClick={onPositiveButtonClick} color='blue' content={popupPositiveText} fluid />
    //                 </Grid.Column>
    //                 <Grid.Column>
    //                     <Button color='black' content={popupNegativeText} fluid onClick={() => setOpen(false)} />
    //                 </Grid.Column>
    //             </Grid.Row>
    //         </Grid>
    //     </Popup>
    // );
}
import React from 'react';
import { Popup } from 'semantic-ui-react';
import { ContentLabelProp } from '../../enum/apiResultProp/itemGroup/ContentLabelProp';
import LabelSeparator from './LabelSeparator';

export default ({ labelProps, labelSeparator, isLastLabel }) => {
    const position = labelProps[ContentLabelProp.Position];
    const text = labelProps[ContentLabelProp.Text];
    const textColor = labelProps[ContentLabelProp.TextColor];
    const popupTitle = labelProps[ContentLabelProp.PopupTitle];
    const popupText = labelProps[ContentLabelProp.PopupText];

    const renderLabelSeparator = () => {
        if (isLastLabel) {
            return null;
        }

        return <LabelSeparator labelsSeparator={labelSeparator} />
    }

    const renderLabel = () => {
        return (
            <span style={{ color: textColor, cursor: 'pointer' }} >
                {text}
            </span>
        );
    }

    const renderLabelWithPopup = () => {
        return (
            <Popup
                content={popupText}
                key={position}
                header={popupTitle}
                trigger={renderLabel()}
            />
        );
    }

    return (
        <React.Fragment key={position}>
            {renderLabelWithPopup()}
            {renderLabelSeparator()}
        </React.Fragment>
    );
}

import React from 'react';
import _ from 'lodash';
import { Form as SemanticUIForm, Table, Grid, Button, Popup, Form, Image, Icon } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';

import {validateInput, concatEvent, formulaEvent, disableEvent, ajaxUrlEvent, setOtherFiledValueOnDropdownSelectEvent} from '../../helpers/functions';
import ButtonWithConfirmation from './ButtonWithConfirmation';
import { FORM_DATA_TYPES, BUTTON_TYPES, EMPTY_VALUE } from '../../helpers/const';
import { STRINGS } from '../../helpers/strings';

class TableForm extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        let shouldUpdate = false;
        var BreakException = {};
        
        try {
            Object.keys(nextProps.fields).forEach(fieldName => {
                let oldValue = this.props.fields[fieldName].value;
                let newValue = nextProps.fields[fieldName].value;
                let oldError = this.props.fields[fieldName].error;
                let newError = nextProps.fields[fieldName].error;
                let oldErrorMessage = this.props.fields[fieldName].errorMessage;
                let newErrorMessage = nextProps.fields[fieldName].errorMessage;
                let oldWarning = this.props.fields[fieldName].warning;
                let newWarning = nextProps.fields[fieldName].warning;

                switch (this.props.fields[fieldName].dataType) {
                    case 'BUTTON_OPTIONS':
                        let oldValues = this.props.fields[fieldName].values;
                        let newValues = nextProps.fields[fieldName].values;
                        
                        if (oldValues !== newValues) {
                            shouldUpdate = true;
                        }
    
                        break;
                    case 'DROPDOWN_FILTER_VALUES':
                        if (oldValue !== newValue || oldError !== newError || oldErrorMessage !== newErrorMessage || oldWarning !== newWarning) {
                            shouldUpdate = true;
                        }
                    
                        if (nextProps.fields[fieldName].filteredOptions !== this.props.fields[fieldName].filteredOptions) {
                            shouldUpdate = true;
                        }
    
                        break;
                    case 'DROPDOWN':
                        if (oldValue !== newValue || oldError !== newError || oldErrorMessage !== newErrorMessage || oldWarning !== newWarning) {
                            shouldUpdate = true;
                        }
                    
                        if (this.props.fields[fieldName].readOnly !== nextProps.fields[fieldName].readOnly) {
                            shouldUpdate = true;
                        }
    
                        break;
                    
                    case 'DIMENSIONS':
                        shouldUpdate = true;
    
                        break;
                    default:
                        if (oldValue !== newValue || oldError !== newError || oldErrorMessage !== newErrorMessage || oldWarning !== newWarning) {
                            shouldUpdate = true;
                        }

                        if (this.props.fields[fieldName].readOnly !== nextProps.fields[fieldName].readOnly) {
                            shouldUpdate = true;
                        }
                }

                if (shouldUpdate === true) {
                    throw BreakException;
                }
            });
        } catch (e) {
            if (e !== BreakException) throw e; //Rethrow if exception is not from me
        }

        return shouldUpdate;  
    }
    
    onInputBlur = async (fieldName, fieldValue, validationRules) => {
        const [error, errorMessage] = validateInput(fieldValue, validationRules, this.props.fields, fieldName);

        const warningRules = this.props.fields[fieldName].warningRules;
        if (warningRules) {
            const [warning, warningMessage] = validateInput(fieldValue, warningRules, this.props.fields, fieldName);
            await this.props.warningUpdate(fieldName, warning, warningMessage);
        } 

        this.props.errorUpdate(fieldName, error, errorMessage);
    }

    onFormInputChangeTriggerEvent = async (event, fieldValue, fieldName) => {
        const eventType = event.type;

        if (eventType === 'CONCAT') { 
            concatEvent(event, this.props.fields, this.props.onInputChange);
        } else if (eventType === 'FORMULA') { 
            formulaEvent(event, this.props.fields, this.props.onInputChange);
        } else if (eventType === 'SET_VALUE_FROM_OPTION_PROP') { 
            setOtherFiledValueOnDropdownSelectEvent(event, this.props.fields, fieldValue, fieldName, this.onFormInputChange, this.onInputBlur);
        } else if (eventType === 'MANAGE_DISABLE_BY_CONDITION') { 
            disableEvent(event, fieldValue, fieldName, this.props.disabledUpdate);
        } else if (eventType === 'AJAX_WITH_URL_ACTION') {  
            ajaxUrlEvent(event, this.props.fields, this.props.handleAjaxWitUrlAction, this.props.rowKey, this.props.mode);
        }
    }

    onFormInputChange = async (fieldName, fieldValue, events) => {
        await this.props.onInputChange(fieldName, fieldValue);

        if (events) {
            events.forEach(async event => {
                await this.onFormInputChangeTriggerEvent(event, fieldValue, fieldName);
            });
        }
    }

    renderMessageGroup = (messages, type) => {
        if (messages.length > 0) {
            return (
                <Table.Cell colSpan={this.props.gridColumnsCount}>
                    <div className={`ui ${type} message`}>
                        <ul className="list" style={{listStyle: "none"}}>
                            {messages.map((message, index) => {
                                return (
                                    <li key={index}>
                                        <p>{message}</p>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </Table.Cell>
            );
        }
    }

    renderFields = () => {
        return Object.keys(this.props.fieldGroups).map(groupName => {
            const fieldsGroup = this.props.fieldGroups[groupName];

            let fieldsGroupErrorMessages = [];
            let fieldsGroupWarningMessages = [];

            return (
                    <React.Fragment key={groupName}>
                        <Table.Row textAlign="center">
                            {Object.keys(fieldsGroup).map((index) => {
                                const fieldName = fieldsGroup[index]; 
                                const field = this.props.fields[fieldName];
                                
                                if (field.errorMessage.length > 0) {
                                    fieldsGroupErrorMessages.push(field.errorMessage);
                                }
                                    
                                if (field.warningMessage && field.warningMessage.length > 0) {
                                    fieldsGroupWarningMessages.push(field.warningMessage);
                                }

                                const { 
                                    oldValue, value, presentationValue, fieldWidth, downloadUrl, 
                                    serverFilePath , downloadedFileName 
                                } = field;

                                switch (field.dataType) {
                                    case FORM_DATA_TYPES.SLIDERS:
                                        const {
                                            sliders, checkedSliders, checkedSlidersFullAccess, buttonOptionsFieldName, 
                                            noAccessOptionValue, fullAccessOptionValue, positiveOptions, minPositiveOptionValue
                                        } = field;
                                        if (sliders == EMPTY_VALUE) {
                                            return (
                                                <Table.Cell key={fieldName + '_' + this.props.rowKey}>
                                                    <p style={{fontWeight: 'bold'}}>-</p>
                                                </Table.Cell>
                                            );    
                                        } else {
                                            return (
                                                <Table.Cell key={fieldName + '_' + this.props.rowKey}>
                                                        {sliders.map(slider => {
                                                            const {label, routeId} = slider;
                                                            const checked = checkedSliders[routeId];

                                                            return (
                                                                <SemanticUIForm.Checkbox 
                                                                    key={routeId + '_' + Math.random()}
                                                                    slider
                                                                    label={label}
                                                                    defaultChecked={checked}
                                                                    onChange={e => this.props.onInputSliderChange({
                                                                        fieldName, actionId: routeId, checkedSlidersFullAccess, 
                                                                        buttonOptionsFieldName, noAccessOptionValue, 
                                                                        fullAccessOptionValue, positiveOptions, minPositiveOptionValue 
                                                                    })}
                                                                />
                                                            );
                                                        })}
                                                </Table.Cell>
                                            );
                                        }
                                    case FORM_DATA_TYPES.READ:
                                        const valueToShow = _.isUndefined(presentationValue) ? value : presentationValue;
                                        return (
                                            <Table.Cell 
                                                textAlign='center' 
                                                key={fieldName + '_' + this.props.rowKey} 
                                                width={fieldWidth}
                                            >
                                                <p>{valueToShow}</p>
                                            </Table.Cell>
                                        );
                                    case FORM_DATA_TYPES.READ_WITH_DOWNLOAD_URL:            
                                        return (
                                            <Table.Cell 
                                                textAlign='center' 
                                                key={fieldName + '_' + this.props.rowKey} 
                                                width={fieldWidth}
                                                selectable
                                                onClick={() => {
                                                    if (serverFilePath != EMPTY_VALUE) {
                                                        this.props.downloadFileByPath(downloadUrl, serverFilePath , downloadedFileName)
                                                    }
                                                }}
                                            >
                                                <a style={{color: 'blue'}} href='' onClick={e => {e.preventDefault()}}>{field.downloadedFileName}</a>
                                            </Table.Cell>
                                        );                    
                                    case FORM_DATA_TYPES.INPUT_FIELD_STRING:
                                        return (
                                            <Table.Cell key={fieldName + '_' + this.props.rowKey} width={field.fieldWidth}>
                                                {this.renderInputFieldString(fieldName, field, "text", field.event)}
                                            </Table.Cell>
                                        );                    
                    
                                    case FORM_DATA_TYPES.INPUT_FIELD_FILE:
                                        return (
                                            <Table.Cell key={fieldName + '_' + this.props.rowKey} width={field.fieldWidth}>
                                                {this.renderInputFieldFile(fieldName, field)}
                                            </Table.Cell>
                                        );                    
                                        
                                    case FORM_DATA_TYPES.INPUT_FIELD_FILE_NO_PREVIEW:
                                        return (
                                            <Table.Cell key={fieldName + '_' + this.props.rowKey} width={field.fieldWidth}>
                                                {this.renderInputFieldFileNoPreview(fieldName, field)}
                                            </Table.Cell>
                                        );                    
                                        
                                    case FORM_DATA_TYPES.IMAGE:
                                        return (
                                            <Table.Cell key={fieldName + '_' + this.props.rowKey} width={field.fieldWidth}>
                                                {this.renderImageCell(field)}
                                            </Table.Cell>
                                        );      
                                        
                                    case FORM_DATA_TYPES.FLAG:
                                        const color = field.value ? 'green' : 'red';   
                                        const name = field.value ? 'check' : 'close';   
                                    
                                        return ( 
                                            <Table.Cell key={fieldName + this.props.rowKey} width={field.fieldWidth}>
                                                <Icon color={color} name={name} />
                                            </Table.Cell>
                                        );
                                    
                                    case FORM_DATA_TYPES.INPUT_FIELD_NUMERIC:
                                        return (
                                            <Table.Cell key={fieldName + '_' + this.props.rowKey} width={field.fieldWidth}>
                                                {this.renderInputFieldString(fieldName, field, "number", field.event)}                    
                                            </Table.Cell>
                                        );
                    
                                    case FORM_DATA_TYPES.INPUT_FIELD_PERCENT:
                                        return (
                                            <Table.Cell key={fieldName + '_' + this.props.rowKey} width={field.fieldWidth}>
                                                {this.renderInputFieldString(fieldName, field, "number", field.event)}                    
                                            </Table.Cell>
                                        );
                    
                                    case FORM_DATA_TYPES.INPUT_FIELD_EMAIL:
                                        return (
                                            <Table.Cell key={fieldName + '_' + this.props.rowKey} width={field.fieldWidth}>
                                                {this.renderInputFieldString(fieldName, field, "mail")}                    
                                            </Table.Cell>
                                        );    
                    
                                    case FORM_DATA_TYPES.INPUT_FIELD_PASSWORD:
                                        return (    
                                            <Table.Cell width={field.fieldWidth}>
                                                {this.renderInputFieldString(fieldName, field, "password")}                    
                                            </Table.Cell>
                                        );    
                    
                                    case FORM_DATA_TYPES.CHECKBOX:
                                        return (    
                                            <Table.Cell key={fieldName + '_' + this.props.rowKey} width={field.fieldWidth}>
                                                {this.renderCheckbox(fieldName, field, field.event)}                    
                                            </Table.Cell>
                                        );

                                    case FORM_DATA_TYPES.DROPDOWN:
                                        return (    
                                            <Table.Cell key={fieldName + '_' + this.props.rowKey} width={field.fieldWidth}>
                                                {this.renderDropdown(fieldName, field, field.event, field.options)}                    
                                            </Table.Cell>
                                        );
                                    
                                    case FORM_DATA_TYPES.DROPDOWN_AUTOCOMPLETE:
                                        return (    
                                            <Table.Cell key={fieldName + '_' + this.props.rowKey} width={field.fieldWidth}>
                                                {this.renderAutocompleteDropdown(fieldName, field, field.event, field.options)}                    
                                            </Table.Cell>
                                        );
        
                                    case FORM_DATA_TYPES.DROPDOWN_FILTER_VALUES:
                                        return (    
                                            <Table.Cell key={fieldName + '_' + this.props.rowKey} width={field.fieldWidth}>
                                                {this.renderDropdown(fieldName, field, field.event, field.filteredOptions)}                    
                                            </Table.Cell>
                                        );
                    
                                    case FORM_DATA_TYPES.TEXTAREA:
                                        return (    
                                            <Table.Cell key={fieldName + '_' + this.props.rowKey} width={field.fieldWidth}>
                                                {this.renderTextArea(fieldName, field)}                    
                                            </Table.Cell>
                                        );

                                    case FORM_DATA_TYPES.DATE:
                                        return (    
                                            <Table.Cell width={field.fieldWidth} key={fieldName + '_' + this.props.rowKey}>
                                                {this.renderDateInput(fieldName, field)}                    
                                            </Table.Cell>
                                        );

                                    case FORM_DATA_TYPES.BUTTON_OPTIONS:
                                        return (
                                            <Table.Cell width={field.fieldWidth} key={fieldName + '_' + this.props.rowKey}>
                                                {this.renderButtonsOptions(field, fieldName, this.props.rowKey)}
                                            </Table.Cell>        
                                        )

                                    case FORM_DATA_TYPES.DIMENSIONS:
                                        return this.renderDimensionsField(field, fieldName, this.props.rowKey);
                                    
                                    case FORM_DATA_TYPES.SKIP:
                                        return null;
                    
                                    default:
                                        return null;
                                }
                            })}

                            { this.renderActions() }
                        </Table.Row>

                        <Table.Row> 
                            { this.renderMessageGroup(fieldsGroupErrorMessages, 'negative') }
                        </Table.Row>

                        <Table.Row> 
                            { this.renderMessageGroup(fieldsGroupWarningMessages, 'warning') }
                        </Table.Row>
                    </React.Fragment>
            );
        });
    }

    renderDimensionsField = (field, fieldName, rowKey) => {
        const {fieldWidth, dimensionsList} = field;
        
        return (
            <Table.Cell width={fieldWidth} key={fieldName + '_' + rowKey}>
                <SemanticUIForm.Group widths='equal'>
                    {_.map(dimensionsList, (dimensionObj, dimensionName) => {
                        const {readOnly, label, value} = dimensionObj;

                        return (
                            <SemanticUIForm.Input 
                                key={fieldName + '_' + rowKey + '_' + dimensionName}
                                fluid
                                disabled={readOnly} 
                                type='number'
                                label={label}  
                                value={value}
                                onChange={ e => this.props.onInputChangeDimensionField(fieldName, e.target.value, dimensionName) } 
                            />
                        );
                    })}
                </SemanticUIForm.Group>
            </Table.Cell>        
        );
    }

    renderImageCell = (field) => {
        if (field.value == EMPTY_VALUE) {
            return null;
        }

        return (
            <Image
                centered
                size='small'
                src={field.value}
            />
        );
    }

    renderImage = (field, shouldRenderImage = true) => {
        if (! shouldRenderImage) {
            return null;
        }

        return (
            <Image
                centered
                size='small'
                src={URL.createObjectURL(field.value)}
            />
        );
    }

    renderInputFieldFile = (fieldName, field) => {
        let shouldRenderImage = false;
        if (field.value !== undefined && field.value !== '') {
            shouldRenderImage = true;
        }
        
        let inputField = (
            <Grid divided='vertically' centered verticalAlign='middle'>
                <Grid.Row columns={2} centered verticalAlign='middle'>
                    <Grid.Column width={12} verticalAlign='middle' style = {{marginTop: '1em'}}>
                        <SemanticUIForm.Input 
                            type="file" 
                            fluid 
                            onChange={event => this.onFormInputChange(fieldName, event.target.files[0]) }
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        {this.renderImage(field, shouldRenderImage)}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        ); 

        return inputField; 
    }

    renderInputFieldFileNoPreview = (fieldName, field) => {
        return (
            <SemanticUIForm.Input 
                type="file" 
                fluid 
                onChange={event => this.onFormInputChange(fieldName, event.target.files[0]) }
            />
        ); 
    }
    
    renderActions = () => {
        //TODO This Action logic should be in BE. Refactor it!
        if (this.props.fields['is_system'] && this.props.fields['is_system'].value === 1) {
            return <Table.Cell width='1'></Table.Cell>;
        }

        if (_.isEmpty(this.props.actions)) {
            return null;
        }
        
        if (! this.props.actions) {
            return null;
        } 

        return (
            <Table.Cell width='1'>
                {
                    this.props.actions.map(action => {
                        switch (action) {
                            case BUTTON_TYPES.DELETE:
                                return (
                                    <Button.Group key={this.props.rowKey}>
                                        {this.renderDeleteButton()}
                                    </Button.Group>
                                );
                                
                            case BUTTON_TYPES.CANCEL_CREATE:
                                return (
                                    <Button.Group key={this.props.rowKey}>
                                        {this.renderCancelCreateButton()}
                                    </Button.Group>
                                );
                            default:
            
                                return null;
                        }
                    })
                }
            </Table.Cell>
        )
    }

    renderButtonsOptions = (field, fieldName) => {
        const options = field.options;
        const clickedValuesArr = field.values;

        const {
            positiveOptions, negativeOption, minPositiveOption, fullAccessOption,
            slidersFieldName, slidersFullAccess, slidersNoAccess
        } = field;

        return (
            <Grid>
                <Grid.Column textAlign="center">
                    <Button.Group>
                        {
                            _.map(options, (option, index) => {        
                                const optionValue = option.value;                        
                                let isLastOption = _.findLastIndex(options) === index; 
                                let separator = null;
                                if (! isLastOption) {
                                    separator = <Button.Or text='|' />;
                                }

                                const isChecked = _.indexOf(clickedValuesArr, optionValue) !== -1;

                                return (
                                    <React.Fragment key={option.key}>
                                        <Button 
                                            primary={isChecked}
                                            onClick={() => this.props.onInputButtonOptionClick({
                                                fieldName, optionValue, clickedValuesArr, positiveOptions, 
                                                negativeOption, minPositiveOption, fullAccessOption, 
                                                slidersFieldName, slidersFullAccess, slidersNoAccess
                                            })}
                                        >
                                            {option.text}
                                        </Button>
                                        {separator}        
                                    </React.Fragment>
                                );
                            })
                        }
                    </Button.Group>                    
                </Grid.Column>
            </Grid>
        );
    }

    renderDeleteButton = () => {
        return (
            <ButtonWithConfirmation 
                translations={this.props.translations}
                rowId={this.props.rowKey}
                question={STRINGS.BUTTON_DELETE_CONFIRM_QUESTION}
                buttonConfirmTitle='X'
                actionConfirm={this.props.onDeleteClick}
                onDeleteRowAjaxEvent={this.props.onDeleteRowAjaxEvent}
            />
        );
    }

    renderCancelCreateButton = () => {
        return (
            <ButtonWithConfirmation 
                translations={this.props.translations}
                rowId={this.props.rowKey}
                question={STRINGS.BUTTON_CANCEL_CONFIRM_QUESTION}
                buttonConfirmTitle='X'
                actionConfirm={this.props.onCancelCreateClick}
                onDeleteRowAjaxEvent={this.props.onDeleteRowAjaxEvent}
            />
        );
    }

    renderInputFieldString = (fieldName, field, type, event) => {
        let value = field.value;
        let placeholder = field.placeholder ? field.placeholder : undefined;
        
        return (
            <SemanticUIForm.Input
                key={fieldName} 
                fluid
                required={field.required[this.props.mode]} 
                disabled={field.readOnly[this.props.mode]} 
                type={type}
                label={field.label}  
                placeholder={placeholder} 
                value={value}
                error={field.error}
                autoComplete="new-password"
                onChange={ e => {this.onFormInputChange(fieldName, e.target.value, event )} } 
                onBlur={() => { this.onInputBlur(fieldName, field.value, field.validationRules) } }
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault(); 
                    }
                }}
            />
        );
    }

    renderCheckbox = (fieldName, field, event) => {
        const defaultChecked = field.value ? true : false;

        return (
            <SemanticUIForm.Group key={fieldName} inline style={{paddingLeft: '35%', paddingTop: '1%'}}>
                <SemanticUIForm.Checkbox 
                    slider
                    defaultChecked={defaultChecked}
                    disabled={field.readOnly[this.props.mode]}
                    required={field.required[this.props.mode]} 
                    readOnly={field.readOnly[this.props.mode]} 
                    onChange={(e) => { this.onFormInputChange(fieldName, ! field.value, event) }}
                />      
            </SemanticUIForm.Group>
        );
    }

    renderDropdown = (fieldName, field, event, options) => {
        options = _.isEmpty(options) ? [{key: '', value: '', text: ''}] : options;
        
        return (
            <React.Fragment key={fieldName}>
                <SemanticUIForm.Select  
                    onOpen={this.props.toggleDetailedTabDropdownChanged}
                    onClose={this.props.toggleDetailedTabDropdownChanged}
                    fluid
                    options={options} 
                    required={field.required[this.props.mode]} 
                    readOnly={field.readOnly[this.props.mode]} 
                    disabled={field.readOnly[this.props.mode]}
                    error={field.error}
                    label={field.label} 
                    placeholder={field.placeholder} 
                    value={field.value}
                    onChange={(e, data) => { this.onFormInputChange(fieldName, data.value, event) }}
                    onBlur={ () => { this.onInputBlur(fieldName, field.value, field.validationRules) } }
                    selectOnBlur={false}
                />
            </React.Fragment>
        )
    }

    //Caused by this dropdown behavior is different -> onChange does not trigger on blurr by default, like SemanticUIForm.Select do
    onAutocompleteDropdownChange = async (fieldName, value, validationRules, error, event) => {
        await this.onFormInputChange(fieldName, value, event);
        this.onInputBlur(fieldName, value, validationRules);
    }

    renderAutocompleteDropdown = (fieldName, field, event, options) => {
        return (
            <React.Fragment key={fieldName}>
                <SemanticUIForm.Select  
                    onOpen={this.props.toggleDetailedTabDropdownChanged}
                    onClose={this.props.toggleDetailedTabDropdownChanged}
                    fluid
                    search 
                    selection
                    options={options} 
                    required={field.required[this.props.mode]} 
                    readOnly={field.readOnly[this.props.mode]} 
                    disabled={field.readOnly[this.props.mode]}
                    error={field.error}
                    label={field.label} 
                    placeholder={field.placeholder} 
                    value={field.value}
                    onBlur={ () => { this.onInputBlur(fieldName, field.value, field.validationRules) } }
                    onChange={(e, {value}) => { this.onAutocompleteDropdownChange(fieldName, value, field.validationRules, field.error, event) }}
                    selectOnBlur={false}
                />
            </React.Fragment>
        )
    }

    renderTextArea = (fieldName, field) => {
        return (
            <React.Fragment key={fieldName}>
                <SemanticUIForm.TextArea 
                    rows={1}
                    required={field.required[this.props.mode]} 
                    readOnly={field.readOnly[this.props.mode]} 
                    error={field.error}
                    label={field.label} 
                    placeholder={field.placeholder} 
                    value={field.value}
                    onChange={(e) => { this.onFormInputChange(fieldName, e.target.value) }}
                    onBlur={ () => { this.onInputBlur(fieldName, field.value, field.validationRules) } }
                />
            </React.Fragment>
        );
    }

    renderDateInput = (fieldName, field) => {
        return (
            <React.Fragment key={fieldName} >
                <SemanticUIForm.Input 
                    fluid
                    as={DateInput}
                    duration={0}
                    closable={true}
                    required={field.required[this.props.mode]} 
                    readOnly={field.readOnly[this.props.mode]} 
                    error={field.error}
                    label={field.label}
                    dateFormat="YYYY-MM-DD"
                    placeholder={field.placeholder}
                    value={field.value === undefined ? '' : field.value}
                    iconPosition="left"
                    icon={false}
                    onChange={(event, {value}) => { this.onFormInputChange(fieldName, value) }}
                    onBlur={ () => { this.onInputBlur(fieldName, field.value, field.validationRules) } }
                />

            </React.Fragment>
        )
    }

    render = () => {
        return this.renderFields(); 
    }
}

export default TableForm;

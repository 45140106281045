import React from 'react';
import _ from 'lodash';
import { Table, Icon, Flag, Button, Popup, List, Form, Message } from 'semantic-ui-react';

import { FIELD_TYPES, ACTIONS, FORM_DATA_TYPES, NO_FILE } from '../../../helpers/const';
import { getValue } from '../../../helpers/functions';
import { STRINGS } from '../../../helpers/strings';
import ButtonWithConfirmation from '../ButtonWithConfirmation';
import ModalWithSimpleInfoTable from '../ModalWithSimpleInfoTable';
import PriceListModal from '../PriceListModal';

class TableBody extends React.Component {
    renderButtonData = (actions, fields) => {
        if (actions.length === 0) {
            return null;
        }

        const rowId = fields.id.value;
        
        return (
            <Table.Cell width={1} key={rowId}>
                <Button.Group>
                    { actions.map(action => this.renderButton(action, rowId, fields))}
                </Button.Group>
            </Table.Cell>
        );
    }

    renderButton(action, rowId, fields) {
        const type = action.type;
        const active = action.active; 
        const key = type + '_' + rowId; 

        if (active === false) {
            return null;
        }

        const {label} = action;

        switch (type) {
            case ACTIONS.DOWNLOAD:
                const {url, serverFilePath, fileName} = action;
                return (
                    <Popup
                        position='top right'
                        key={key}
                        trigger={
                            <Button 
                                icon 
                                color='teal'
                                onClick={() => this.props.downloadFileByPath(url, serverFilePath , fileName)}
                            >
                                <Icon name='download' />
                            </Button>
                        }
                        content='Download'
                    />
                );

            case ACTIONS.GENERATE_DOCUMENT:
                const { fetchPriceListUrl, label } = action;

                return (
                    <React.Fragment key={key}>    
                        <Button icon color='blue' onClick={() => this.props.openPriceListModal(fetchPriceListUrl)}>
                            <Icon name='download' /> {label}
                        </Button>
                    </React.Fragment>
                );

            case ACTIONS.DELETE:
                return (
                    <ButtonWithConfirmation 
                        translations={this.props.translations}
                        rowId={rowId}
                        key={key}
                        question={getValue(this.props.translations, STRINGS.BUTTON_CONFIRMATION_DEFAULT_QUESTION)}
                        buttonConfirmTitle={getValue(this.props.translations, STRINGS.BUTTON_DELETE)}
                        actionConfirm={this.props.actionDeleteEntity}
                    />
                );
    
            case ACTIONS.EDIT:
                return (
                    <button 
                        className="ui green button"
                        onClick={() => this.props.onEditEntityClick(rowId, fields)}  
                        key={key}
                    >
                        {getValue(this.props.translations, STRINGS.BUTTON_EDIT)}
                    </button>
                );
            
            case ACTIONS.INFO:
                const infoArr = action.info;
                
                const infoContent = (
                    <List>
                        {infoArr.map(infoItem => <List.Item key={infoItem}>{infoItem}</List.Item>)}
                    </List>
                );

                return (
                    <Popup
                        key={key}
                        on='click'
                        trigger={<Button icon='info' />}
                        content={infoContent}
                        position='bottom right'
                    />
                );
            default:
                return null;
        }
    }

    renderPriceListModal = () => {
        return <PriceListModal />;
    }

    renderFields(fields) {
        return Object.keys(fields).map(key => { 
            const fieldName = key;
            const fieldValue = fields[key].value;
            const fieldType = fields[key].type;
            const fieldClass = fields[key].class;
            const pageInfoPanels = fields[key].pageInfoPanels;
            const linkRouteName = fields[key].routeName;
            const linkRouteParams = fields[key].routeParams;

            switch (fieldType) { 
                case FORM_DATA_TYPES.READ_WITH_DOWNLOAD_URL:
                    const {downloadUrl, serverFilePath , downloadedFileName} = fields[key];
                
                    return (
                        <Table.Cell 
                            key={fieldName + '_' + fields.id.value}
                            selectable
                            onClick={() => {
                                if (serverFilePath != NO_FILE) {
                                    this.props.downloadFileByPath(downloadUrl, serverFilePath , downloadedFileName)
                                }
                            }}
                        >
                            <a style={{color: 'blue'}} href='' onClick={e => {e.preventDefault()}}>{fieldValue}</a>
                        </Table.Cell>
                    );
                case FIELD_TYPES.CHECKBOX:
                    const {disabled, checkboxValue} = fields[key];
                    const { checkedValues, uncheckedValues } = this.props;

                    let checked = false;
                    if (_.has(checkedValues, checkboxValue)) {
                        checked = true;
                    }

                    // if (checkboxValue === 2005) {
                    //     console.log('checked values', checkedValues);
                    //     console.log('unchecked values', uncheckedValues);
                    // }

                    return (
                        <Table.Cell key={fieldName + '_' + fields.id.value + Math.random(1)}>
                            <Form.Checkbox 
                                defaultChecked={checked}
                                onChange={(e) => this.props.toggleGridCheckbox(checkboxValue)}
                                fitted
                                disabled={disabled}
                                readOnly={disabled} 
                            />    
                        </Table.Cell>
                    );
                case FIELD_TYPES.DATA:
                    return this.renderDataField(fields.id.value, fieldName, fieldValue, fieldClass);
                case FIELD_TYPES.SHOW_INFO_ON_HOVER:
                    return this.renderFieldShowInfoOnHover(fields.id.value, fieldName, fieldValue, fieldClass, pageInfoPanels);
                case FIELD_TYPES.LINK:
                    return this.renderLinkField(fields.id.value, fieldName, fieldValue, fieldClass, fields);
                case FIELD_TYPES.LINK_WITH_BACK_GET_PARAMS:
                    return this.renderLinkFieldWithBackGetParams(fields.id.value, fieldName, fieldValue, linkRouteName, linkRouteParams);
                case FIELD_TYPES.FLAG:
                    const color = fieldValue ? 'green' : 'red';   
                    const name = fieldValue ? 'check' : 'close';   
                
                    return( 
                        <Table.Cell key={fieldName + '_' + fields.id.value}>
                            <Icon color={color} name={name} />
                        </Table.Cell>
                    );
                case FIELD_TYPES.COUNTRY_FLAG:
                    const countryIsoCode = fields[key].countryIsoCode;
                    return( 
                        <Table.Cell key={fieldName + '_' + fields.id.value}>
                            {
                                _.isEmpty(fieldValue) ?
                                    null:
                                    <Flag name={countryIsoCode.toLowerCase()} />
                            }
                            {fieldValue}
                        </Table.Cell>
                    );
                case FIELD_TYPES.HIDDEN:
                    return null;
                case FIELD_TYPES.SKIP:
                    return null;
                case FIELD_TYPES.ACTIONS:
                    return this.renderButtonData(fields[key].ACTIONS, fields);
                case FIELD_TYPES.LINK_MODAL_INFO:
                    const modalInfo = fields[key].modalInfo;

                    return this.renderLinkModalInfoField(fieldName, fieldValue, fields.id.value, modalInfo);
                case FIELD_TYPES.LINK_MODAL_TREE:
                    const modalTreeInfo = fields[key].modalInfo;
                    return this.renderLinkModalTree(fieldName, fieldValue, fields.id.value, modalTreeInfo);
                default:
                    return <Table.Cell key={fieldName + '_' + fields.id.value}>Unhandled field type</Table.Cell>;
            }
        });
    }

    renderLinkModalTree = (fieldName, fieldValue, id, modalInfo) => {
        return (
            <Table.Cell 
                key={fieldName + '_' + id}
                selectable 
                style={{cursor: 'pointer', fontWeight: 'bold' }} 
                onClick={() => this.props.onLinkModalTreeClick(modalInfo)}
            >
                {fieldValue}
            </Table.Cell>
        );
    }

    renderLinkModalInfoField = (fieldName, fieldValue, id, modalInfo) => {
        
        const headerLabels = modalInfo.data.configuration; 
        const tableData = modalInfo.data.data; 
        const title = modalInfo.data.title; 

        return (
            <React.Fragment key={fieldName + '_' + id}>
                <ModalWithSimpleInfoTable 
                    header={title}
                    gridHeader={headerLabels.gridColumns}
                    tableData={tableData}
                    fieldValue={fieldValue}
                />
            </React.Fragment>
        )
    }

    renderDataField = (id, fieldName, fieldValue, fieldClass) => {
        return (
            <Table.Cell key={fieldName + '_' + id} className={fieldClass === 'undefined' ? '' : fieldClass}>
                {fieldValue}
            </Table.Cell>
        );
    }

    renderFieldShowInfoOnHover = (id, fieldName, fieldValue, fieldClass, pageInfoPanels) => {
        const errorMessages = (
            <React.Fragment>
                {
                    _.map(pageInfoPanels, (infoPanel, panelKey) => {
                        const { panelColor, title, icon, bullets } = infoPanel;
                        
                        return (
                            <Message
                                size='small'
                                key={panelKey}
                                color={panelColor}
                                icon={icon}
                                header={title}
                                list={bullets}
                            />
                        );
                    })
                }
            </React.Fragment>
        );

        return (
            <Popup
                wide
                flowing
                on={'click'}
                key={fieldName + '_' + id}
                trigger={
                    <Table.Cell className={fieldClass === 'undefined' ? '' : fieldClass}>
                        {fieldValue}
                    </Table.Cell>
                }
                content={errorMessages}
            />
        );
    }

    renderLinkField = (id, fieldName, fieldValue) => {
        return (
            <Table.Cell 
                selectable 
                key={fieldName + '_' + id} 
                style={{cursor: 'pointer', fontWeight: 'bold' }} 
                onClick={() => this.props.onTableRowLinkClick(id)}
            >
                {fieldValue}
            </Table.Cell>
        );
    }

    renderLinkFieldWithBackGetParams = (id, fieldName, fieldValue, linkRouteName, linkRouteParams) => {
        return (
            <Table.Cell 
                selectable 
                key={fieldName + '_' + id} 
                style={{cursor: 'pointer', fontWeight: 'bold' }} 
                onClick={() => this.props.onTableRowLinkClickWithBackGetParams(linkRouteName, linkRouteParams)}
            >
                {fieldValue}
            </Table.Cell>
        );
    }

    render() {
        return (
            <Table.Body>
                { this.renderPriceListModal() }
                {
                    this.props.rows.map(row => {
                        const key = row.fields.id.value === undefined ? _.random(0, 10000) : row.fields.id.value;
                        
                        let negative = false;
                        let warning = false;
                        if (row.rowColor === 2) {
                            negative = true;
                        } else if (row.rowColor === 3) {
                            warning = true;
                        }

                        return (
                            <Table.Row negative={negative} warning={warning} textAlign='center' key={key}>
                                { this.renderFields(row.fields) }
                            </Table.Row>
                        );
                    })
                }
            </Table.Body>
        )
    }   
}

export default TableBody;

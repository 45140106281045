import React from 'react';
import _ from 'lodash';
import { Table } from 'semantic-ui-react';
import TableHeaderCell from '../cell/TableHeaderCell';

export default ({ tableHeaderCellsData }) => {
    return (
        <Table.Row textAlign='center'>
            {
                _.map(tableHeaderCellsData, (tableHeaderDataCellProps, headerCellKey) => {
                    return <TableHeaderCell key={headerCellKey} tableHeaderDataCellProps={tableHeaderDataCellProps} />;
                })
            }
        </Table.Row>
    );
}
import React from 'react';
import { Form } from 'semantic-ui-react';
import { FormFieldProp } from '../../../enum/apiResultProp/FormFieldProp';
import RichTextEditor from '../../../components/customComponents/RichTextEditor';


export default ({ fieldProps, onValueChange }) => {
    const value = fieldProps[FormFieldProp.Value];
    const name = fieldProps[FormFieldProp.Name];

    return (
        <Form.Input
            key={name}
            as={RichTextEditor}
            value={value}
            setValue={(value) => onValueChange({ value })}
        />
    )
}

import React from 'react';
import { Table } from 'semantic-ui-react';
import { TableHeaderDataCellProp } from '../../../../enum/apiResultProp/TableHeaderDataCellProp';

export default ({tableHeaderDataCellProps}) => {
    const columnName = tableHeaderDataCellProps[TableHeaderDataCellProp.ColumnName];
    const positionNumber = tableHeaderDataCellProps[TableHeaderDataCellProp.PositionNumber];
    const text = tableHeaderDataCellProps[TableHeaderDataCellProp.Text];
    const hasSorting = tableHeaderDataCellProps[TableHeaderDataCellProp.HasSorting];
    const sortingDirection = tableHeaderDataCellProps[TableHeaderDataCellProp.SortingDirection];

    return <Table.HeaderCell>{text}</Table.HeaderCell>;
}
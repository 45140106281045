import React from 'react';
import _ from 'lodash';
import { Item } from 'semantic-ui-react';
import { ItemProp } from '../../enum/apiResultProp/itemGroup/ItemProp';
import ItemContentLabel from './ItemContentLabel';

export default ({ props }) => {
    const position = props[ItemProp.Position];
    const title = props[ItemProp.Title];
    const titleMeta = props[ItemProp.TitleMeta];
    const contentLabels = props[ItemProp.ContentLabels];
    const labelSeparator = props[ItemProp.LabelSeparator];

    let currentLabel = 0;
    const countOfLabels = _.size(contentLabels);

    return (
        <Item key={position} style={{ paddingTop: '3px', paddingBottom: '3px' }}>
            <Item.Content>
                <Item.Header>
                    {title}
                    {/* <span style={{ fontSize: '9px', marginLeft: '3px', color: 'grey' }}>{titleMeta}</span> */}
                </Item.Header>
                <Item.Meta style={{fontSize: '11px', marginTop: '0px'}}>{titleMeta}</Item.Meta>
                <Item.Description style={{ margin: '0px' }}>
                    {_.map(contentLabels, (labelProps, labelKey) => {
                        return <ItemContentLabel key={labelKey} labelProps={labelProps} labelSeparator={labelSeparator} isLastLabel={++currentLabel === countOfLabels} />;
                    })}
                </Item.Description>
            </Item.Content>
        </Item>
    );

}

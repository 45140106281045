import React from 'react';
import { connect } from 'react-redux';
import { login, updateFormInput, checkAuth, generateValidationCodeForEmail, generateValidationCodeForUsername, loginSubmit } from '../actions/authActions';
import { Grid, Header, Icon, Form, Segment, Button, Message } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import history from '../history';
import { ROUTES } from '../helpers/routes';

class Login extends React.Component {
    componentDidMount() {
        this.props.checkAuth();
    }

    onInputChange = (formName, fieldName, fieldValue) => {
        this.props.updateFormInput(formName, fieldName, fieldValue);
    }

    onCodeGenerateClickWithEmail = (formName, buttonName, email) => {
        this.props.generateValidationCodeForEmail(formName, buttonName, email);
    }

    onCodeGenerateClickWithUsername = (formName, buttonName, username, password) => {
        this.props.generateValidationCodeForUsername(formName, buttonName, username, password);
    }

    onRegisterClick = () => {
        this.props.loginSubmit('registrationForm', 'submitForm', 'register-external-user')
    }

    onAdministratorLoginClick = () => {
        this.props.loginSubmit('loginForm', 'submitForm', 'login')
    }

    renderErrorMessage = (message) => {
        if (!message) {
            return null;
        }

        return (
            <Message error size='mini' style={{ marginTop: '0px' }}>
                {message}
            </Message>
        );
    }

    renderSuccessMessage = (message) => {
        if (!message) {
            return null;
        }

        return (
            <Message success size='mini' style={{ marginTop: '0px' }}>
                {message}
            </Message>
        );
    }

    renderForms = () => {
        return (
            <Grid centered columns={3} textAlign='center' style={{ marginTop: '0.5em' }}>
                <Grid.Row>
                    <Grid.Column centered style={{ width: '40%' }}>
                        <Segment raised>
                            <Header as='h4' style={{ color: "#3C8AD9" }} textAlign='center'>
                                <Icon name="user" /> Имам Регистрация
                            </Header>
                            {this.renderErrorMessage(this.props.auth.loginForm.message.errorMessage)}
                            {this.renderSuccessMessage(this.props.auth.loginForm.message.successMessage)}
                            <Form size='tiny'>
                                <Form.Input
                                    label='Имейл / Потребителско Име'
                                    icon='user'
                                    iconPosition='left'
                                    value={this.props.auth.loginForm.fields.username}
                                    onChange={(e) => this.onInputChange('loginForm', 'username', e.target.value)}
                                />

                                <Form.Input
                                    type='password'
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    label='Парола'
                                    value={this.props.auth.loginForm.fields.password}
                                    onChange={(e) => this.onInputChange('loginForm', 'password', e.target.value)}
                                />

                                <Button
                                    loading={this.props.auth.loginForm.buttonIsInAction.generateValidationCode}
                                    disabled={this.props.auth.loginForm.buttonIsInAction.generateValidationCode}
                                    size='tiny'
                                    style={{ backgroundColor: '#3C8AD9', color: 'white' }}
                                    onClick={() => this.onCodeGenerateClickWithUsername(
                                        'loginForm',
                                        'generateValidationCode',
                                        this.props.auth.loginForm.fields.username,
                                        this.props.auth.loginForm.fields.password
                                    )}
                                    icon=''
                                    fluid
                                    type='button'
                                >
                                    <Icon name='keyboard'></Icon>
                                    Изпрати ми верификационен код
                                </Button>

                                <Form.Input
                                    icon='keyboard'
                                    iconPosition='left'
                                    label='Въведи кода получен по имейл'
                                    value={this.props.auth.loginForm.fields.verificationCode}
                                    onChange={(e) => this.onInputChange('loginForm', 'verificationCode', e.target.value)}
                                />

                                <Button
                                    loading={this.props.auth.loginForm.buttonIsInAction.submitForm}
                                    disabled={this.props.auth.loginForm.buttonIsInAction.submitForm}
                                    style={{ backgroundColor: '#3C8AD9', color: 'white' }}
                                    fluid
                                    size='tiny'
                                    onClick={this.onAdministratorLoginClick}
                                    type='submit'
                                >
                                    Влез
                                </Button>
                            </Form>
                            <Message color='blue' size='mini'>
                                <a
                                    onClick={(e) => {
                                        e.preventDefault();
                                        history.push(ROUTES.FORGOT_PASSOWRD_ROUTE.URL);
                                    }}
                                    href='#'
                                >Забравена парола? Натисни Тук!</a>
                            </Message>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column centered style={{ width: '5%' }}>
                        <Header as='h1' style={{ color: "#3C8AD9" }} textAlign='center'>
                            Или
                        </Header>
                    </Grid.Column>
                    <Grid.Column centered style={{ width: '40%' }}>
                        <Segment raised>
                            <Header as='h4' style={{ color: "#3C8AD9" }} textAlign='center'>
                                <Icon name="user plus" /> Нова Регистрация
                            </Header>
                            {this.renderErrorMessage(this.props.auth.registrationForm.message.errorMessage)}
                            {this.renderSuccessMessage(this.props.auth.registrationForm.message.successMessage)}
                            <Form size='tiny' warning>
                                <Form.Input
                                    action={{
                                        loading: this.props.auth.registrationForm.buttonIsInAction.generateValidationCode,
                                        disabled: this.props.auth.registrationForm.buttonIsInAction.generateValidationCode,
                                        size: 'tiny',
                                        content: 'Изпрати ми верификационен код',
                                        style: { backgroundColor: "#3C8AD9", color: 'white' },
                                        onClick: () => this.onCodeGenerateClickWithEmail('registrationForm', 'generateValidationCode', this.props.auth.registrationForm.fields.email),
                                        icon: 'keyboard',
                                        type: 'button'
                                    }}
                                    icon='mail'
                                    iconPosition='left'
                                    label='Имейл'
                                    value={this.props.auth.registrationForm.fields.email}
                                    onChange={(e) => this.onInputChange('registrationForm', 'email', e.target.value)}
                                />
                                <Form.Input
                                    icon='keyboard'
                                    iconPosition='left'
                                    label='Въведи кода получен по имейл'
                                    value={this.props.auth.registrationForm.fields.verificationCode}
                                    onChange={(e) => this.onInputChange('registrationForm', 'verificationCode', e.target.value)}
                                />
                                <Form.Input
                                    type='password'
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    label='Парола'
                                    value={this.props.auth.registrationForm.fields.password}
                                    onChange={(e) => this.onInputChange('registrationForm', 'password', e.target.value)}
                                />
                                <Form.Input
                                    type='password'
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    label='Повтори паролата'
                                    value={this.props.auth.registrationForm.fields.confirmPassword}
                                    onChange={(e) => this.onInputChange('registrationForm', 'confirmPassword', e.target.value)}
                                />
                                <Form.Input
                                    as={DateInput}
                                    duration={0}
                                    closable
                                    dateFormat="YYYY-MM-DD"
                                    iconPosition="left"
                                    startMode='year'
                                    initialDate='2003'

                                    fluid
                                    label='Дата на Раждане'
                                    value={this.props.auth.registrationForm.fields.birthdate}
                                    onChange={(e, { value }) => this.onInputChange('registrationForm', 'birthdate', value)}
                                />
                                <Button
                                    loading={this.props.auth.registrationForm.buttonIsInAction.submitForm}
                                    disabled={this.props.auth.registrationForm.buttonIsInAction.submitForm}
                                    style={{ backgroundColor: "#3C8AD9", color: 'white' }}
                                    fluid
                                    size='tiny'
                                    onClick={this.onRegisterClick}
                                    type='submit'
                                >
                                    Регистрирай ме
                                </Button>
                            </Form>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    render() {
        return (
            <>
                <Header
                    style={{ marginTop: '0.5em', color: "#3C8AD9" }}
                    as='h3'
                    textAlign='center'
                    content='БЪЛГАРСКА ФЕДЕРАЦИЯ ПО МОТОЦИКЛЕТИЗЪМ'
                    subheader='Портал за управление на сертификати'
                />
                {this.renderForms()}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return { auth: state.auth };
}

export default connect(mapStateToProps, { login, updateFormInput, checkAuth, generateValidationCodeForEmail, generateValidationCodeForUsername, loginSubmit })(Login);

import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { getValue } from '../../../../helpers/functions';
import { STRINGS } from '../../../../helpers/strings';

class Actions extends React.Component {
    onSearchClick = () => {
        this.props.onPageChange(1); //Reset pagination
        this.props.onSearchClickFetchDataAction();
    }
    
    render () {
        return (
            <React.Fragment>
                <Button color='black' onClick={this.onSearchClick}>
                    <Icon name='filter' /> {getValue(this.props.translations, STRINGS.BUTTON_APPLY_FILTERS)}
                </Button>
                <Button color='red' onClick={this.props.onClearFilters}>
                    <Icon name='window close outline' /> {getValue(this.props.translations, STRINGS.BUTTON_CLEAR_FILTERS)}
                </Button>
            </React.Fragment>
        ); 
    } 
} 

export default Actions;

export const CREATE_FILTER_MODE = "CREATE_FILTER_MODE";
export const EDIT_FILTER_MODE = "EDIT_FILTER_MODE";

export const FORM_EDIT_MODE = "FORM_EDIT_MODE";
export const FORM_READ_MODE = "FORM_READ_MODE";
export const FORM_CREATE_MODE = "FORM_CREATE_MODE";

export const ACCESS_LEVEL = {
    READ: 10,
    WRITE: 20,
    DELETE: 30,
};

export const EMPTY_VALUE = -1000;
export const NO_FILE = -1000;

export const FORM_DATA_TYPES = {
    READ: "READ",
    IMAGE: "FORM_DATA_TYPE_IMAGE",
    INPUT_FIELD_FILE: "INPUT_FIELD_FILE",
    INPUT_FIELD_FILE_NO_PREVIEW: "INPUT_FIELD_FILE_NO_PREVIEW",
    INPUT_FIELD_STRING: "INPUT_FIELD_STRING",
    INPUT_FIELD_STRING_ONLY_UPPERCASE: "INPUT_FIELD_STRING_ONLY_UPPERCASE",
    INPUT_FIELD_NUMERIC: "INPUT_FIELD_NUMERIC",
    INPUT_FIELD_PERCENT: "INPUT_FIELD_PERCENT",
    INPUT_FIELD_EMAIL: "INPUT_FIELD_EMAIL",
    INPUT_FIELD_PASSWORD: "INPUT_FIELD_PASSWORD",
    INPUT_FIELD_RICH_TEXT_EDITOR: "INPUT_FIELD_RICH_TEXT_EDITOR",
    CHECKBOX: "CHECKBOX",
    DROPDOWN: "DROPDOWN",
    DROPDOWN_MULTI: "DROPDOWN_MULTI",
    DROPDOWN_FILTER_VALUES: "DROPDOWN_FILTER_VALUES",
    DROPDOWN_AUTOCOMPLETE: "DROPDOWN_AUTOCOMPLETE",
    TEXTAREA: "TEXTAREA",
    DATE: "DATE",
    DATE_TIME: "DATE_TIME",
    SKIP: "SKIP",
    BUTTON_OPTIONS: "BUTTON_OPTIONS",
    SLIDERS: "SLIDERS",
    DIMENSIONS: "DIMENSIONS",
    FLAG: "FLAG",
    READ_WITH_DOWNLOAD_URL: "READ_WITH_DOWNLOAD_URL",
    DOWNLOAD_URL_LOOKING_LIKE_BUTTON: "DOWNLOAD_URL_LOOKING_LIKE_BUTTON",
};

export const MESSAGE_ERROR = "error";
export const MESSAGE_WARNING = "warning";
export const MESSAGE_SUCCESS = "success";

export const GRID_WRITE_TYPE_MODAL = "GRID_WRITE_TYPE_MODAL";
export const GRID_WRITE_TYPE_DETAILED_PAGE = "GRID_WRITE_TYPE_DETAILED_PAGE";
export const GRID_WRITE_TYPE_INLINE = "GRID_WRITE_TYPE_INLINE";

export const FIELD_TYPES = {
    DATA: "DATA",
    HIDDEN: "HIDDEN",
    SKIP: "SKIP",
    FLAG: "FLAG",
    CHECKBOX: "CHECKBOX",
    IMAGE: "FORM_DATA_TYPE_IMAGE",
    LINK: "LINK",
    LINK_WITH_BACK_GET_PARAMS: "LINK_WITH_BACK_GET_PARAMS",
    COUNTRY_FLAG: "COUNTRY_FLAG",
    ACTIONS: "ACTIONS",
    LINK_MODAL_INFO: "LINK_MODAL_INFO",
    LINK_MODAL_TREE: "LINK_MODAL_TREE",
    SHOW_INFO_ON_HOVER: "SHOW_INFO_ON_HOVER",
}

export const BUTTON_TYPES = {
    DELETE: "DELETE",
    EDIT: "EDIT",
    DO_EDIT: "DO_EDIT",
    CANCEL_EDIT: "CANCEL_EDIT",
    CANCEL_CREATE: "CANCEL_CREATE",
    SAVE: "SAVE",
    CANCEL: "CANCEL",
}

export const ACTIONS = {
    CREATE: 'CREATE',
    EDIT: 'EDIT',
    DOWNLOAD: 'DOWNLOAD',
    BACK_WITH_GRID_FILTERS: 'BACK_WITH_GRID_FILTERS',
    INFO: 'INFO',
    DELETE: 'DELETE',
    IMPORT: 'IMPORT',
    EXPORT: 'EXPORT',
    GENERATE_DOCUMENT: 'GENERATE_DOCUMENT',
    SEND_OFFER_TREE: 'SEND_OFFER_TREE',
}

import React from 'react';
import { Form as SemanticForm } from 'semantic-ui-react';

import FieldSelector from './FieldSelector';
import ComparatorSelector from './ComparatorSelector';
import Input from './Input';
import FormActions from './FormActions';

import { getValue } from '../../../../helpers/functions';

class Form extends React.Component {
    validateFilterFormInput = (filterValue, filterDataType) => {
        let result = {isValid: true, errorMessage: ''};
        
        switch(filterDataType) {
            case "string":
                if (filterValue.length < 1) {
                    result.isValid = false;
                    result.errorMessage = 'Field is mandatory!';
                }
                break;
            case "date":
                if (isNaN(Date.parse(filterValue))) {
                    result.isValid = false;
                    result.errorMessage = 'Invalid date!';
                } else {
                    var splited = filterValue.split('-');
                    if (splited.length !== 3) {
                        result.isValid = false;
                        result.errorMessage = 'Invalid date format!';
                    } else {
                        //TODO you could validate the month and date 
                        if (splited[0].length !== 4 || splited[1].length !== 2 || splited[2].length !== 2) {
                            result.isValid = false;
                            result.errorMessage = 'Invalid date format!';
                        }
                    }
                }

                break;
            case "number":
                if (isNaN(filterValue)) {
                    result.isValid = false;
                    result.errorMessage = 'Invalid number!';
                } 

                break;
            case "dropdown":
            case "dropdown_autocomplete":
                if (filterValue === '') {
                    result.isValid = false;
                    result.errorMessage = 'Please Select Value!';
                } else if  (Array.isArray(filterValue) && filterValue.length === 0) {
                    result.isValid = false;
                    result.errorMessage = 'Please Select at least one Value!';
                }

                break;
            default:
                result.isValid = false;

                break;
        }

        result.errorMessage = getValue(this.props.translations, result.errorMessage);

        return result;
    }
    
    render () {
        const { filterForm, comparators, filters, translations } = this.props;
        const { 
            mode, filterColumnsDropdown, filterColumnsDropdownSelectedKey, filterComparatorDropdown, 
            filterComparatorDropdownSelectedKey, filterDataType, filterValue 
        } = filterForm;

        return (
            <SemanticForm error>
                <SemanticForm.Group>
                    <FieldSelector
                        fields={filterColumnsDropdown}
                        selectedFieldKey={filterColumnsDropdownSelectedKey}
                        mode={mode}
                        translations={translations}
                        comparators={comparators}
                        filterFormSelectColumn={this.props.filterFormSelectColumn}
                    />
                    
                    <ComparatorSelector 
                        fields={filterColumnsDropdown}
                        selectedFieldKey={filterColumnsDropdownSelectedKey}
                        comparators={filterComparatorDropdown}
                        selectedComparatorKey={filterComparatorDropdownSelectedKey}
                        type={filterDataType}
                        filterFormSelectComparator={this.props.filterFormSelectComparator}
                        filterFormUpdateInput={this.props.filterFormUpdateInput}
                    />

                    <Input 
                        fields={filterColumnsDropdown}
                        selectedFieldKey={filterColumnsDropdownSelectedKey}
                        type={filterDataType}
                        value={filterValue}
                        placeholder={filterColumnsDropdown[filterColumnsDropdownSelectedKey].columnPlaceholder}
                        validateFilterFormInput={this.validateFilterFormInput}
                        filterFormUpdateInput={this.props.filterFormUpdateInput}
                        filters={filters}
                        selectedComparatorName={filterComparatorDropdown[filterComparatorDropdownSelectedKey].columnKey}
                    />

                    <FormActions 
                        translations={translations}
                        mode={mode}
                        filterValue={filterValue}
                        type={filterDataType}
                        validateFilterFormInput={this.validateFilterFormInput}
                        onAddFilter={this.props.onAddFilter}
                        filterForm={filterForm}
                        onClearClick={this.props.onClearClick}
                        editFilterValues={this.props.editFilterValues}
                        filterFormClear={this.props.filterFormClear}
                    />
                </SemanticForm.Group>
            </SemanticForm>
        );
    } 
} 

export default Form;

import React from 'react';
import { DateInput } from 'semantic-ui-calendar-react';
import { Form } from 'semantic-ui-react';

class Input extends React.Component {
    getField = () => {
        const { type, value, placeholder, fields, selectedFieldKey, filters, selectedComparatorName } = this.props;
        
        let input;
        switch (type) {
            case 'string':
                input = (
                    <Form.Input 
                        type='text' 
                        placeholder={placeholder} 
                        value={value}
                        onChange={e => this.props.filterFormUpdateInput(e.target.value)}
                    />
                );

                break;
            case 'date':
                input = (
                    <DateInput
                        duration={0}
                        closable={true}
                        dateFormat='YYYY-MM-DD'
                        placeholder={placeholder}
                        value={value}
                        iconPosition='left'
                        onChange={(event, {value}) => this.props.filterFormUpdateInput(value)}
                    />
                );

                break;
            case 'number':
                input = (
                    <Form.Input 
                        type='number' 
                        placeholder={placeholder} 
                        value={value}
                        onChange={e => this.props.filterFormUpdateInput(e.target.value)}
                    />
                );

                break;
            case 'dropdown':
                const columnName = fields[selectedFieldKey].columnKey;
                const dropDownValues = filters[columnName].dropDownValues;
                
                let multiSelect = false;
                if (selectedComparatorName === 'notInList' || selectedComparatorName === 'inList') {
                    multiSelect = true;
                }

                input = (
                    <Form.Select 
                        multiple={multiSelect} 
                        selection={multiSelect}
                        options={dropDownValues} 
                        placeholder={placeholder} 
                        value={value}
                        onChange={(e, {value}) => { this.props.filterFormUpdateInput(value) }}
                        selectOnBlur={false}
                    />
                );

                break;
            case 'dropdown_autocomplete':
                const columnNameAuto = fields[selectedFieldKey].columnKey;
                const dropDownValuesAuto = filters[columnNameAuto].dropDownValues;
                
                let multiSelectAuto = false;
                if (selectedComparatorName === 'notInList' || selectedComparatorName === 'inList') {
                    multiSelectAuto = true;
                }

                input = (
                    <Form.Select 
                        search
                        multiple={multiSelectAuto} 
                        selection={multiSelectAuto}
                        options={dropDownValuesAuto} 
                        placeholder={placeholder} 
                        value={value}
                        onChange={(e, {value}) => { this.props.filterFormUpdateInput(value) }}
                        selectOnBlur={false}
                    />
                );

                break;
            default:
                input = null;
        };

        return input;
    }
    
    render () {
        const { type, value } = this.props;
        let error = ! this.props.validateFilterFormInput(value, type).isValid;

        return(
            <React.Fragment>
                <Form.Field error={error}>
                    {this.getField()}
                </Form.Field>
            </React.Fragment>
        );
    } 
} 

export default Input;

export const TableHeaderDataCellProp = Object.freeze({
    ColumnName: 'columnName',
    PositionNumber: 'positionNumber',
    Text: 'text',
    HasSorting: 'hasSorting',
    SortingDirection: 'sortingDirection',

    HasFiltering: 'hasFiltering',
    FilterConfig: 'filterConfig',
    ActiveFilters: 'activeFilters',
});
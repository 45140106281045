import React from 'react';
import _, { isUndefined } from 'lodash';
import { connect } from 'react-redux';
import history from '../../../history';
import { Header, Table, Grid, Button, Form, Divider, Icon, Label, Segment } from 'semantic-ui-react';

import { GRID_WRITE_TYPE_DETAILED_PAGE, GRID_WRITE_TYPE_MODAL, ACTIONS, EMPTY_VALUE } from '../../../helpers/const';
import { getRouteObj, getValue, prepareGetParamsObject } from '../../../helpers/functions';
import { ROUTES } from '../../../helpers/routes';
import { STRINGS } from '../../../helpers/strings';
import TableFooter from '../../customComponents/customGrid/TableFooter';
import TableHeader from '../../customComponents/customGrid/TableHeader';
import TableBody from '../../customComponents/customGrid/TableBody';
import SearchBar from '../../customComponents/customGrid/searchBar/SearchBar';
import Modal from '../../customComponents/customGrid/GridModal';
import ImportField from '../../customComponents/ImportField';
import Loader from '../../customComponents/Loader';
import RevisionModal from '../../customComponents/RevisionModal';
import SecondaryMenu from '../../customComponents/SecondaryMenu';
import CheckListModal from '../../customComponents/CheckListModal';

import { openGridModalCreate, openGridModalEdit } from '../../../actions/gridModalActions';
import { open } from '../../../actions/revisionModalTree/actions';
import { fetchPriceListDataAndOpen as openPriceList } from '../../../actions/priceList/actions';
import { updateOnPage, changePage } from '../../../actions/gridPage/paginationActions';
import { saveGridPageParams, deleteGridPageParams, resetAllGridPageParams } from '../../../actions/gridFilterParams/actions';
import {
    fetchGridData,
    fetchGridDataWithGetParams,
    createEntity,
    editEntity,
    deleteEntity,
    importEntities,
    downloadFile,
    clearState,
    exportGridData,
    confirmRevisionTree,
    updateFormAjax,
    downloadDocumentAndRefresh,
    downloadFileByPath,
    toggleGridCheckbox,
    checkAll,
    uncheckAll,
    confirmCheckboxesAndRefresh,
    onVariantChange
} from '../../../actions/gridPage/existingRecordActions';
import {
    updateSortableHeaderDirection,
    cancelFilter,
    clearFilters,
    addFilter,
    filterFormSelectColumn,
    filterFormSelectComparator,
    filterFormUpdateInput,
    filterFormClear,
    loadFilterForEdit,
    editFilterValues,
    toggleFormVisibility,
} from '../../../actions/gridPage/searchBarActions';
import { open as openChekList } from '../../../actions/checkList/actions';

class GridPage extends React.Component {
    state = { routeInfo: getRouteObj(this.props.match.path) };

    componentDidMount() {
        //This is set from history.push(); when we redirect inside the React App
        let applyBackParams = false;
        if (!_.isUndefined(this.props.location.state) && !_.isUndefined(this.props.location.state.applyBackParams)) {
            applyBackParams = this.props.location.state.applyBackParams;
        }

        const currentRouteName = this.state.routeInfo.NAME;
        let previousFilterGetParams = {};
        if (applyBackParams === true && !_.isUndefined(this.props.gridFilterParamsReducer) && !_.isUndefined(this.props.gridFilterParamsReducer[currentRouteName])) {
            previousFilterGetParams = this.props.gridFilterParamsReducer[currentRouteName].getParams;
        }

        //Build API url
        let fetchUrl = this.state.routeInfo.API_FETCH_URL;
        const routeParams = this.props.match.params;
        if (routeParams) {
            _.forEach(this.props.match.params, (paramValue, paramName) => {
                fetchUrl = fetchUrl.replace(':' + paramName, paramValue);
            });
        } else {
            fetchUrl = fetchUrl;
        }

        this.props.fetchGridData(fetchUrl, previousFilterGetParams);
    }

    componentWillUnmount = () => {
        this.props.clearState();
    }

    onEditClickModalPageType = (id, fieldValues) => {
        const { modalFormFields, modalFormFieldGroups } = this.props.grid.configuration;

        this.props.openGridModalEdit(id, fieldValues, modalFormFields, modalFormFieldGroups);
    }

    onCreateClickDetailedPageType = () => {
        history.push(this.state.routeInfo.URL_CREATE);
    }

    onEditClickDetailedPageType = (id) => {
        history.push(this.state.routeInfo.URL_EDIT + id);
    }

    onTableRowLinkClick = (id) => {
        history.push(this.state.routeInfo.URL_READ + id);
    }

    onTableRowLinkClickWithBackGetParams = (linkRouteName, linkRouteParams) => {
        //1. Get Front End Route URL by its name
        let frontEndUrl = ROUTES[linkRouteName].URL;

        //2. Replace Url Params with its corresponding value
        _.forEach(linkRouteParams, (paramValue, paramName) => {
            frontEndUrl = frontEndUrl.replace(':' + paramName, paramValue);
        });

        //3. add page getParams to the action to preserve page filters
        const getParamsObject = prepareGetParamsObject(this.props.grid.configuration);

        //4. Find Current Route Name
        const gridPageCurrentRouteName = this.state.routeInfo.NAME;

        //5. Save current Params Object in Redux
        this.props.saveGridPageParams(gridPageCurrentRouteName, getParamsObject);

        //6. Go to the Page from the link 
        history.push({ pathname: frontEndUrl });
    }

    importEntities = (file) => {
        this.props.importEntities(file, this.state.routeInfo.API_IMPORT_URL, this.state.routeInfo.API_FETCH_URL);
    }

    renderImportExportActions = () => {
        return (
            <Form>
                {this.renderSendToMagoAction()}
                {this.renderImportAction()}
            </Form>
        );
    }

    renderImportAction = () => {
        const { importAction } = this.props.grid;
        if (_.isUndefined(importAction)) {
            return null;
        }
        const { importActionData, downloadTemplateData } = importAction;

        const { importLabel, importButtonName, importUrl } = importActionData;
        const { templateButtonName, templateFileName, templateDownloadUrl } = downloadTemplateData;

        return (
            <ImportField
                importLabel={importLabel}
                importButtonName={importButtonName}
                doImport={(file) => this.props.importEntities(file, importUrl, this.state.routeInfo.API_FETCH_URL)}

                templateButtonName={templateButtonName}
                templateDownloadAction={() => this.props.downloadFile(templateDownloadUrl, templateFileName)}
            />
        );
    }

    renderExportButton = () => {
        if (this.props.grid.configuration.PAGE_ACTIONS !== false && this.props.grid.configuration.PAGE_ACTIONS.includes(ACTIONS.EXPORT)) {
            const { exportUrl, downloadExportUrl } = this.props.grid;

            return (
                <Button
                    style={{ marginBottom: '1em' }}
                    color='blue'
                    content={getValue(this.props.translations, STRINGS.BUTTON_EXPORT)}
                    onClick={() => this.props.exportGridData(exportUrl, downloadExportUrl)}
                />
            )
        }

        return null;
    }

    renderCreateLogic = () => {
        const pageActions = this.props.grid.configuration.PAGE_ACTIONS;

        if (pageActions !== false && pageActions.includes(ACTIONS.CREATE)) {
            const writeType = this.state.routeInfo.GRID_WRITE_TYPE;

            if (writeType === GRID_WRITE_TYPE_DETAILED_PAGE) {
                return (
                    <Grid style={{ marginTop: "5px", marginBottom: '1px' }}>
                        <Grid.Column textAlign="center">
                            <Button
                                color="green"
                                content={getValue(this.props.translations, STRINGS.BUTTON_CREATE)}
                                onClick={this.onCreateClickDetailedPageType}
                            />
                        </Grid.Column>
                    </Grid>
                );
            } else if (writeType === GRID_WRITE_TYPE_MODAL) {
                const { modalFormFields, modalFormFieldGroups } = this.props.grid.configuration;

                return (
                    <Grid style={{ marginTop: "5px", marginBottom: '1px' }}>
                        <Grid.Column textAlign="center">
                            <Button
                                color="green"
                                content={getValue(this.props.translations, STRINGS.BUTTON_CREATE)}
                                onClick={() => this.props.openGridModalCreate(modalFormFields, modalFormFieldGroups)}
                            />
                        </Grid.Column>
                    </Grid>
                );
            }
        } else {
            return <Divider />
        }

        return null;
    }

    getEditEntityAction = () => {
        const writeType = this.state.routeInfo.GRID_WRITE_TYPE;

        if (writeType === GRID_WRITE_TYPE_DETAILED_PAGE) {
            return this.onEditClickDetailedPageType;
        } else if (writeType === GRID_WRITE_TYPE_MODAL) {
            return this.onEditClickModalPageType;
        }

        return null;
    }

    renderTitle = () => {
        return (
            <Header as='h2' textAlign='center' style={{ marginTop: '0px' }}>
                <Header.Content>{this.props.grid.title}</Header.Content>
            </Header>
        )
    }

    updateOnPage = (pageIndex) => {
        this.props.updateOnPage(pageIndex, this.state.routeInfo.API_FETCH_URL);
    }

    changePage = (pageNumber) => {
        this.props.changePage(pageNumber, this.state.routeInfo.API_FETCH_URL);
    }

    renderTableFooter = () => {
        const { grid } = this.props;
        const { access, gridColsCount } = grid.configuration;

        if (this.props.grid.configuration.pagination === false) {
            return null;
        }

        return (
            <TableFooter
                grid={grid}
                access={access}
                gridColumnsCount={gridColsCount}
                changePage={this.changePage}
                updateOnPage={this.updateOnPage}
            />
        )
    }

    createEntity = (postData) => {
        this.props.createEntity(postData, this.state.routeInfo.API_CREATE_URL, this.state.routeInfo.API_FETCH_URL)
    }

    editEntity = (postData, id) => {
        this.props.editEntity(postData, id, this.state.routeInfo.API_EDIT_URL, this.state.routeInfo.API_FETCH_URL);
    }

    fetchGridDataWithGetParams = () => {
        this.props.fetchGridDataWithGetParams(this.state.routeInfo.API_FETCH_URL);
    }

    deleteEntity = (id) => {
        this.props.deleteEntity(id, this.state.routeInfo.API_DELETE_URL, this.state.routeInfo.API_FETCH_URL);
    }

    clearFilters = () => {
        this.props.clearFilters(this.state.routeInfo.API_FETCH_URL);
    }

    updateSortableHeaderDirection = (headerName, headerObject) => {
        this.props.updateSortableHeaderDirection(headerName, headerObject, this.state.routeInfo.API_FETCH_URL);
    }

    downloadDocumentAndRefresh = (generateFileUrl) => {
        this.props.downloadDocumentAndRefresh(generateFileUrl, this.state.routeInfo.API_FETCH_URL);
    }

    renderTable = () => {
        const { grid, translations } = this.props;
        const { configuration, data } = grid;
        const { access, checkboxAction } = configuration;

        let checkedValues = [];
        let uncheckedValues = [];
        if (checkboxAction !== undefined) {
            checkedValues = checkboxAction.checkedValues;
            uncheckedValues = checkboxAction.uncheckedValues;
        }

        return (
            <div style={{ width: '100%', overflowX: 'auto', overflowY: 'hidden' }}>
                <Table celled sortable selectable color='grey'>
                    <TableHeader
                        gridConfig={configuration}
                        onSearchButtonClick={this.onSearchClick}
                        onSortableHeaderClick={this.updateSortableHeaderDirection}
                    />

                    <TableBody
                        openPriceListModal={this.props.openPriceList}
                        translations={translations}
                        rows={_.values(data)}
                        access={access}
                        actionDeleteEntity={this.deleteEntity}
                        onTableRowLinkClick={this.onTableRowLinkClick}
                        onTableRowLinkClickWithBackGetParams={this.onTableRowLinkClickWithBackGetParams}
                        onEditEntityClick={this.getEditEntityAction()}
                        onLinkModalTreeClick={this.props.open}
                        downloadDocument={this.downloadDocumentAndRefresh}
                        downloadFileByPath={this.props.downloadFileByPath}
                        checkedValues={checkedValues}
                        uncheckedValues={uncheckedValues}
                        toggleGridCheckbox={this.props.toggleGridCheckbox}
                    />

                    {this.renderTableFooter()}
                </Table>
            </div>
        );
    }

    renderSerachBar = () => {
        const { translations, grid } = this.props;
        const { filters, filterForm } = grid.configuration;

        return (
            <SearchBar
                translations={translations}
                filters={filters}
                filterForm={filterForm}
                onClearFilters={this.clearFilters}
                onAddFilter={this.props.addFilter}
                filterFormSelectColumn={this.props.filterFormSelectColumn}
                filterFormSelectComparator={this.props.filterFormSelectComparator}
                filterFormUpdateInput={this.props.filterFormUpdateInput}
                onCancelFilter={this.props.cancelFilter}
                filterFormClear={this.props.filterFormClear}
                loadFilterForEdit={this.props.loadFilterForEdit}
                editFilterValues={this.props.editFilterValues}
                toggleFormVisibility={this.props.toggleFormVisibility}
                onSearchClickFetchDataAction={this.fetchGridDataWithGetParams}
                onPageChange={this.changePage}
            />
        );
    }

    renderModal = () => {
        return (
            <Modal
                translations={this.props.translations}
                actionCreateEntity={this.createEntity}
                actionEditEntity={this.editEntity}
            />
        );
    }

    renderRevisionTreeModal = () => {
        return (
            <RevisionModal
                translations={this.props.translations}
                actionConfirm={(postData, confirmUrl) => {
                    const routeParams = this.props.match.params;
                    let fetchUrl = this.state.routeInfo.API_FETCH_URL;

                    if (routeParams) {
                        _.forEach(this.props.match.params, (paramValue, paramName) => {
                            fetchUrl = fetchUrl.replace(':' + paramName, paramValue);
                        });
                    } else {
                        fetchUrl = fetchUrl;
                    }

                    this.props.confirmRevisionTree(postData, confirmUrl, fetchUrl)
                }}
            />
        );
    }

    renderSecondaryMenu = () => {
        const { secondaryMenu } = this.props.grid;

        if (secondaryMenu !== null && secondaryMenu !== undefined && secondaryMenu !== EMPTY_VALUE) {
            return (
                <SecondaryMenu
                    menuItems={secondaryMenu}
                />
            );
        } else {
            return null;
        }
    }

    renderAdditionalActions = () => {
        const { additionalAction } = this.props.grid;

        if (additionalAction === undefined) {
            return null;
        }

        const { url, label, spinner } = additionalAction;

        return (
            <Button
                loading={spinner}
                disabled={spinner}
                color="teal"
                content={label}
                onClick={() => this.props.updateFormAjax(url)}
                style={{ marginBottom: '1em' }}
            />
        );
    }

    renderDownloadActions = () => {
        const { downloadActions } = this.props.grid;
        if (downloadActions === undefined) {
            return null;
        }

        return (
            <>
                {
                    _.map(downloadActions, (downloadAction, index) => {
                        const { url, label, fileName } = downloadAction;

                        return (
                            <Button
                                key={index}
                                color="teal"
                                content={fileName}
                                icon='download'
                                onClick={() => this.props.downloadFile(url, fileName)}
                                style={{ marginBottom: '1em' }}
                            />
                        );
                    })
                }
            </>
        );
    }

    renderSendToMagoAction = () => {
        const { sendToMagoAction } = this.props.grid;
        if (isUndefined(sendToMagoAction)) {
            return null;
        }

        const { buttonLabel } = sendToMagoAction;

        return (
            <Button
                floated='right'
                color='green'
                onClick={() => this.props.openChekList(sendToMagoAction)}
            >
                {buttonLabel}
            </Button>
        );
    }

    onSendEmailClick = () => {
        const { checkboxAction } = this.props.grid.configuration;
        const { checkedValues, variant, url } = checkboxAction;

        const postData = new FormData();
        postData.append('ids', _.keys(checkedValues));
        postData.append('variant', variant.value);

        this.props.confirmCheckboxesAndRefresh(url, postData, this.state.routeInfo.API_FETCH_URL);
    }

    renderChecboxAction = () => {
        const { checkboxAction } = this.props.grid.configuration;
        if (isUndefined(checkboxAction)) {
            return null;
        }

        const { checkedValues, variant, buttonTitle } = checkboxAction;

        return (
            <Segment>
                <Form>
                    <Form.Select
                        floated='left'
                        options={variant.options}
                        required={variant.required}
                        readOnly={variant.readOnly}
                        disabled={variant.disabled}
                        label={variant.label}
                        placeholder={variant.placeholder}
                        value={variant.value}
                        onChange={(e, data) => { this.props.onVariantChange(data.value) }}
                        selectOnBlur={false}
                    />
                    <Button
                        floated='right'
                        color='green'
                        onClick={() => this.onSendEmailClick()}
                    >
                        {buttonTitle}
                    </Button>
                    <Button icon color='blue' onClick={this.props.checkAll}>
                        <Icon size='large' name='check square outline' />
                    </Button>
                    <Button icon onClick={this.props.uncheckAll}>
                        <Icon size='large' name='square outline' />
                    </Button>
                    {
                        _.map(checkedValues, (code, value) => {
                            let label = null;
                            if (code != EMPTY_VALUE) {
                                label = <Label color='blue' key={value} tag>{code}</Label>
                            }

                            return label;
                        })
                    }
                </Form>
            </Segment>
        )
    }

    render() {
        if (this.props.grid === EMPTY_VALUE) {
            return <Loader text={getValue(this.props.translations, STRINGS.LOADING)} />;
        }

        return (
            <React.Fragment>
                {this.renderTitle()}

                {this.renderSecondaryMenu()}

                {this.renderSerachBar()}

                {this.renderModal()}

                <CheckListModal />

                {this.renderRevisionTreeModal()}

                {this.renderCreateLogic()}

                {this.renderImportExportActions()}

                {this.renderExportButton()}

                {this.renderAdditionalActions()}

                {this.renderDownloadActions()}

                {this.renderChecboxAction()}

                {this.renderTable()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.auth.translations,
        grid: state.gridPage,
        gridFilterParamsReducer: state.gridFilterParamsReducer
    }
}

const actions = {
    //modal
    openGridModalCreate,
    openGridModalEdit,

    //pagination
    updateOnPage,
    changePage,

    //existing data 
    fetchGridData,
    fetchGridDataWithGetParams,
    createEntity,
    editEntity,
    deleteEntity,
    downloadDocumentAndRefresh,
    downloadFileByPath,
    toggleGridCheckbox,
    checkAll,
    uncheckAll,
    onVariantChange,
    confirmCheckboxesAndRefresh,

    //filter, pagination, sort
    updateSortableHeaderDirection,
    cancelFilter,
    clearFilters,
    addFilter,
    filterFormSelectColumn,
    filterFormSelectComparator,
    filterFormUpdateInput,
    filterFormClear,
    loadFilterForEdit,
    editFilterValues,
    toggleFormVisibility,

    //import, download acrions
    importEntities,
    downloadFile,
    exportGridData,

    clearState,
    confirmRevisionTree,
    open,
    updateFormAjax,

    //ChekList Modal
    openChekList,

    //Price List
    openPriceList,

    // Grid Page Params 
    saveGridPageParams,
    deleteGridPageParams,
    resetAllGridPageParams
}

export default connect(mapStateToProps, actions)(GridPage);

import React from 'react';
import _, { size } from 'lodash';
import { Table, Icon, Label, Button } from 'semantic-ui-react';
import { FilterConfigProp } from '../../../enum/dataGrid/filter/FilterConfigProp';
import GridFilterPopupForm from '../filter/GridFilterPopupForm';
import AbstractButton from '../../form/formButton/button/AbstractButton';
import { FilterProp } from '../../../enum/dataGrid/filter/FilterProp';
import { STRINGS } from '../../../helpers/strings';
import { getValue } from '../../../helpers/functions';

export default ({
    columnsCount,
    buttons, 
    filterConfig, 
    filters, 
    appliedFiltersIdsByColumn,
    onApplyFiltersClick,
    onDeleteAllFiltersClick,
    onDeleteAppliedFilterRowClick,
    onDeleteNotAppliedFilterRowClick,
    onCreateRowClick,  
    onFilterColumnFieldChange,
    onFilterComparatorFieldChange,
    onFilterValueFieldChange,
    clearAllNotAppliedEmptyFilters,
    openModalForm,
    onButtonClickMutateComponentWithPostData,
    reloadScreenWithPostDataOnButtonClick,
    translations,
}) => {
    const renderTriggerComponentForFilterPopup = (onTrigerClick, appliedFiltersCount, notAppliedFiltersCount) => {
        let appliedFilters = null;
        if (appliedFiltersCount) {
            appliedFilters = (
                <span style={{marginLeft: '0.6em'}} className='grid-blue-color'>
                    (
                        <Icon name='thumbs up' className='grid-blue-color' size='small' />
                        {appliedFiltersCount}
                    ) 
                </span>
            );
        }
        let notAppliedFilters = null;
        if (notAppliedFiltersCount) {
            notAppliedFilters = (
                <span style={{marginLeft: '0.6em', color: 'red'}}>
                    (
                        <Icon name='thumbs down' color='red' size='small' />
                        {notAppliedFiltersCount}
                    ) 
                </span>
            );

        }

        return (
            <Button 
                color='blue'
                size='tiny'
                onClick={onTrigerClick}
                style={{backgroundColor: 'white', color: "#3C8AD9", borderColor: '#3C8AD9', border: '1px solid'}}
            >
                <Icon name='filter' />
                {getValue(translations, STRINGS.FILTERS)}
                {appliedFilters}
                {notAppliedFilters}
            </Button>
        );
    }

    const renderFiltersButton = () => {
        if (!filterConfig) {
            return null;
        }

        const filterColumns = filterConfig[FilterConfigProp.Columns];
        if (size(filterColumns) === 0) {
            return null;
        }

        let appliedFiltersCount = 0;
        let notAppliedFiltersCount = 0;
        let allFilters = [];

        _.forEach(filters, (filtersArray, columnName) => {
            const appliedFiltersIdsForThisColumn = appliedFiltersIdsByColumn[columnName] ? appliedFiltersIdsByColumn[columnName] : [];
            _.forEach(filtersArray, filterProps => {
                const id = filterProps[FilterProp.Id];
                
                if (_.includes(appliedFiltersIdsForThisColumn, id)) {
                    appliedFiltersCount += 1;
                } else {
                    notAppliedFiltersCount += 1;
                }
            })
            
            allFilters = allFilters.concat(filtersArray);
        })

        return (
            <GridFilterPopupForm
                triggerComponentRenderFunction={(onTrigerClick) => renderTriggerComponentForFilterPopup(onTrigerClick, appliedFiltersCount, notAppliedFiltersCount)}
                filters={allFilters}
                appliedFiltersIdsByColumn={appliedFiltersIdsByColumn}
                onApplyFiltersClick={onApplyFiltersClick}
                onDeleteAllFiltersClick={onDeleteAllFiltersClick}
                onDeleteAppliedFilterRowClick={onDeleteAppliedFilterRowClick} 
                onDeleteNotAppliedFilterRowClick={onDeleteNotAppliedFilterRowClick} 
                onCreateRowClick={onCreateRowClick}
                onFilterColumnFieldChange={onFilterColumnFieldChange}
                onFilterComparatorFieldChange={onFilterComparatorFieldChange}
                onFilterValueFieldChange={onFilterValueFieldChange}
                clearAllNotAppliedEmptyFilters={clearAllNotAppliedEmptyFilters}
                translations={translations}
            />
        )
    }

    const filtersButton = renderFiltersButton();
    if (!filtersButton && !buttons) {
        return null;
    }

    return (
        <Table.Row textAlign='left' >
            <Table.HeaderCell colSpan={columnsCount} style={{ padding: '6px' }}>
                <div>
                    {filtersButton}

                    {_.map(buttons, (buttonsProps, buttonKey) => {
                        return (
                            <AbstractButton
                                key={buttonKey}
                                buttonProps={buttonsProps}
                                className='grid-blue-color'
                                openModalForm={openModalForm}
                                onButtonClickMutateComponentWithPostData={onButtonClickMutateComponentWithPostData}
                                reloadScreenWithPostDataOnButtonClick={reloadScreenWithPostDataOnButtonClick}
                                // onActionSubmitForm={onActionSubmitForm}
                                // onActionMutateScreenWithPostData={onActionMutateScreenWithPostData}
                                // onActionMutateScreenNoPostData={onActionMutateScreenNoPostData}
                                // onActionDownload={onActionDownload}
                                // onActionLogout={onActionLogout}
                                // onActionPostDataAndRedirect={onActionPostDataAndRedirect}

                                // onButtonClickMutateComponentNoPostData={onButtonClickMutateComponentNoPostData}
                                // getRequestAndRedirectExternalLink={getRequestAndRedirectExternalLink}
                            />
                        );
                    })}
                </div>
            </Table.HeaderCell>
        </Table.Row>
    );
}
import React from 'react';
import { Accordion, Icon, Divider } from 'semantic-ui-react';

import { getValue } from '../../../../helpers/functions';
import { STRINGS } from '../../../../helpers/strings';
import Actions from './Actions';
import FiltersGrid from './FiltersGrid';
import Form from './Form';

class SearchBar extends React.Component {
    comparatorsDictionary = {
        "equals": "=",
        "not_equals": "<>",
        "string_ci_equals": "=",
        "contains": getValue(this.props.translations, STRINGS.CONTAINS),
        "before": getValue(this.props.translations, STRINGS.BEFORE),
        "after": getValue(this.props.translations, STRINGS.AFTER),
        "bigger": ">",
        "smaller": "<",
        "biggerOrEqual": ">=",
        "smallerOrEqual": "<=",
        "inList": getValue(this.props.translations, STRINGS.IS_IN_LIST),
        "notInList": getValue(this.props.translations, STRINGS.IS_NOT_IN_LIST),
    };

    comparators = {
        "string": {
            0: {
                columnKey: "string_ci_equals",
                columnName: this.comparatorsDictionary["equals"]
            },
            1: {
                columnKey: "contains",
                columnName: this.comparatorsDictionary["contains"]
            },
            2: {
                columnKey: "not_equals",
                columnName: this.comparatorsDictionary["not_equals"]
            },
        },
        "date": {
            0: {
                columnKey: "equals",
                columnName: this.comparatorsDictionary["equals"]
            },
            1: {
                columnKey: "before",
                columnName: this.comparatorsDictionary["before"]
            },
            2: {
                columnKey: "after",
                columnName: this.comparatorsDictionary["after"]
            },
            3: {
                columnKey: "not_equals",
                columnName: this.comparatorsDictionary["not_equals"]
            },
        },
        "number": {
            0: {
                columnKey: "equals",
                columnName: this.comparatorsDictionary["equals"]
            },
            1: {
                columnKey: "bigger",
                columnName: this.comparatorsDictionary["bigger"]
            },
            2: {
                columnKey: "smaller",
                columnName: this.comparatorsDictionary["smaller"]
            },
            3: {
                columnKey: "biggerOrEqual",
                columnName: this.comparatorsDictionary["biggerOrEqual"]
            },
            4: {
                columnKey: "smallerOrEqual",
                columnName: this.comparatorsDictionary["smallerOrEqual"]
            },
            5: {
                columnKey: "not_equals",
                columnName: this.comparatorsDictionary["not_equals"]
            },
        },
        "dropdown": {
            0: {
                columnKey: "equals",
                columnName: this.comparatorsDictionary["equals"]
            },
            1: {
                columnKey: "inList",
                columnName: this.comparatorsDictionary["inList"]
            },
            2: {
                columnKey: "notInList",
                columnName: this.comparatorsDictionary["notInList"]
            },
            3: {
                columnKey: "not_equals",
                columnName: this.comparatorsDictionary["not_equals"]
            },
        },
        "dropdown_autocomplete": {
            0: {
                columnKey: "equals",
                columnName: this.comparatorsDictionary["equals"]
            },
            1: {
                columnKey: "inList",
                columnName: this.comparatorsDictionary["inList"]
            },
            2: {
                columnKey: "notInList",
                columnName: this.comparatorsDictionary["notInList"]
            },
            3: {
                columnKey: "not_equals",
                columnName: this.comparatorsDictionary["not_equals"]
            },
        },
        "": {
            0: {
                columnKey: "",
                columnName: ""
            }
        }
    };

    render() {
        if (!this.props.filters) {
            return null;
        }

        const { filterForm, toggleFormVisibility, translations, filters, filterFormSelectComparator } = this.props;
        const { isActive } = filterForm;

        return (
            <Accordion fluid styled>
                <Accordion.Title active={isActive} onClick={toggleFormVisibility}>
                    <Icon name='dropdown' /> {getValue(translations, STRINGS.FILTERS)}
                </Accordion.Title>
                <Accordion.Content active={isActive}>
                    <Form
                        translations={translations}
                        filters={filters}
                        filterForm={filterForm}
                        comparators={this.comparators}
                        filterFormSelectComparator={filterFormSelectComparator}
                        filterFormUpdateInput={this.props.filterFormUpdateInput}
                        onAddFilter={this.props.onAddFilter}
                        onClearClick={this.props.filterFormClear}
                        editFilterValues={this.props.editFilterValues}
                        filterFormClear={this.props.filterFormClear}
                        filterFormSelectColumn={this.props.filterFormSelectColumn}
                    />

                    <Divider />

                    <FiltersGrid
                        filters={filters}
                        filterForm={filterForm}
                        comparators={this.comparators}
                        comparatorsDictionary={this.comparatorsDictionary}
                        loadFilterForEdit={this.props.loadFilterForEdit}
                        onClearClick={this.props.filterFormClear}
                        onCancelFilter={this.props.onCancelFilter}
                    />

                    <Divider />

                    <Actions
                        translations={translations}
                        onPageChange={this.props.onPageChange}
                        onSearchClickFetchDataAction={this.props.onSearchClickFetchDataAction}
                        onClearFilters={this.props.onClearFilters}
                    />
                </Accordion.Content>
            </Accordion>
        );
    }
}

export default SearchBar;

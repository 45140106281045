import actionTypes from "../../actions/treePageWithSidePane/actionTypes";
import { EMPTY_VALUE } from "../../helpers/const";
import { 
    updateScreenData, updateFilterFieldValue, expandedNoAll, expandedYesAll, toggleExpandOfNodeElem, selectTreeRecord,
    toggleSpinner 
} from './stateMutators';

const INITIAL_STATE = {
    title: '',
    subTitle: '',
    spinnerIsActive: false,
    filterFields: EMPTY_VALUE,
    filterActions: EMPTY_VALUE,
    treeData: {
        elements: [],
        allNodeTreeElementIds: [],
        allNodesExpandState: {
            expandedYes: [],
            expandedNo: [],
        }
    },
    sidePaneLoadedTreeElementId: EMPTY_VALUE,
    sidePaneInfo: EMPTY_VALUE,
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.TREE_PAGE_WITH_SIDE_PANE_CLEAR:
            return INITIAL_STATE;
        case actionTypes.TREE_PAGE_WITH_SIDE_PANE_UPDATE_SCREEN_DATA:
            return updateScreenData(state, action);
        case actionTypes.TREE_PAGE_WITH_SIDE_PANE_FILTER_FIELD_UPDATE_VALUE:
            return updateFilterFieldValue(state, action);
        case actionTypes.TREE_PAGE_WITH_SIDE_PANE_EXPAND_NO_ALL:
            return expandedNoAll(state);
        case actionTypes.TREE_PAGE_WITH_SIDE_PANE_EXPAND_YES_ALL:
            return expandedYesAll(state);
        case actionTypes.TREE_PAGE_WITH_SIDE_PANE_TOGGLE_EXPAND_NODE_ELEMENT:
            return toggleExpandOfNodeElem(state, action);
        case actionTypes.TREE_PAGE_WITH_SIDE_PANE_SET_SELECTED_ELEMENT:
            return selectTreeRecord(state, action);
        case actionTypes.TREE_PAGE_WITH_SIDE_PANE_TOGGLE_SPINNER_IS_ACTIVE:
            return toggleSpinner(state, action);
        default: 
            return state;
    }
}

export default reducer;

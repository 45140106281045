import _ from 'lodash';

export const open = (state, action) => {
    const {modalInfo} = action.payload;
    const {allValues, confirmUrl, elements, title, statusUnchecked, expandYes, additionalInfoForm} = modalInfo;

    state = {
        ...state,
        isOpen: true,
        allValues: allValues,
        confirmUrl: confirmUrl,
        elements: elements,
        statusUnchecked: statusUnchecked,
        expandYes: expandYes,
        title: title,
    };

    if (! _.isUndefined(additionalInfoForm)) {
        state = {
            ...state,
            additionalInfoForm: additionalInfoForm
        };
    }

    return state;
}

export const onAdditionalInfoFormValueChange = (state, action) => {
    const {fieldName, fieldValue} = action.payload;

    return {
        ...state, 
        additionalInfoForm: {
            ...state.additionalInfoForm,
            [fieldName]: {
                ...state.additionalInfoForm[fieldName],
                value: fieldValue
            }
        } 
    };
}

export const toggleSpinner = (state, action) => {
    const showSpinnerNewVal = ! state.showSpinner;

    return {...state, showSpinner: showSpinnerNewVal };
}

export const updateError = (state, action) => {
    return {
        ...state, 
        validationMessages: action.payload
    };
}

export const close = (INITIAL_STATE) => {
    return INITIAL_STATE;
}

export const toggleExpandOfNodeElem = (state, action) => {
    const { value, allParents, allChildren, isExpand } = action.payload;

    if (isExpand) {
        state = {
            ...state,
            expandYes: state.expandYes.filter(elem => elem !== value),
            expandNo: [...state.expandNo, value],
        }
    } else {
        state = {
            ...state,
            expandNo: state.expandNo.filter(elem => elem !== value),
            expandYes: [...state.expandYes, value],
        }
    }

    return state;
}

export const changeStatusOfNodeElem = (state, action) => {
    const { value, allParents, allChildren, isDisabled, isChecked, isCheckedNegative, isUnchecked } = action.payload;
    
    //isDisabled is status only for children, so we dont have such an option here
    if (isUnchecked) {
        state = {
            ...state,
            statusUnchecked: state.statusUnchecked.filter(elem => elem !== value),
            statusChecked: [...state.statusChecked, value],
        }

        if (allChildren) {
            state = {
                ...state,
                statusUnchecked: state.statusUnchecked.filter(elem => ! allChildren.includes(elem)),
                statusChecked: state.statusChecked.filter(elem => ! allChildren.includes(elem)),
            }

            state = {
                ...state,
                statusChecked: state.statusChecked.concat(allChildren),
            }
        }
    } else if (isChecked) {
        state = {
            ...state,
            statusChecked: state.statusChecked.filter(elem => elem !== value),
            statusCheckedNegative: [...state.statusCheckedNegative, value],
        }

        if (allChildren !== undefined) {
            state = {
                ...state,
                statusUnchecked: state.statusUnchecked.filter(elem => ! allChildren.includes(elem)),
                statusChecked: state.statusChecked.filter(elem => ! allChildren.includes(elem)),
            }

            state = {
                ...state,
                statusDisabled: state.statusDisabled.concat(allChildren),
            }
        }
    } else if (isCheckedNegative) {
        state = {
            ...state,
            statusCheckedNegative: state.statusCheckedNegative.filter(elem => elem !== value),
            statusUnchecked: [...state.statusUnchecked, value],
        }

        if (allChildren !== undefined) {
            state = {
                ...state,
                statusDisabled: state.statusDisabled.filter(elem => ! allChildren.includes(elem)),
                statusUnchecked: state.statusUnchecked.filter(elem => ! allChildren.includes(elem)),
                statusChecked: state.statusChecked.filter(elem => ! allChildren.includes(elem)),
            }
    
            state = {
                ...state,
                statusUnchecked: state.statusUnchecked.concat(allChildren),
            }
        }
    }

    return state;
}

export const changeStatusOfChildElem = (state, action) => {
    const { value, allParents, allChildren, isDisabled, isChecked, isCheckedNegative, isUnchecked } = action.payload;

    //isCheckedNegative is status only for nodes, so we dont have such an option here
    if (isUnchecked) {
        //Handle clicked elem
        state = {
            ...state,
            statusUnchecked: state.statusUnchecked.filter(elem => elem !== value),
            statusChecked: [...state.statusChecked, value],
        }

        //Handle Children -> clear them from current status
        if (allChildren !== undefined) {
            state = {
                ...state,
                statusUnchecked: state.statusUnchecked.filter(elem => ! allChildren.includes(elem)),
                statusChecked: state.statusChecked.filter(elem => ! allChildren.includes(elem)),
            }
            //Handle Children -> put them in new status
            state = {
                ...state,
                statusChecked: state.statusChecked.concat(allChildren),
            }
        }

        //Handle Direct Parents
        state = {
            ...state,
            statusUnchecked: state.statusUnchecked.filter(elem => ! allParents.includes(elem)),
            statusChecked: state.statusChecked.filter(elem => ! allParents.includes(elem)),
        }
        state = {
            ...state,
            statusChecked: state.statusChecked.concat(allParents),
        }
    } else if (isChecked) {
        //Handle clicked elem
        state = {
            ...state,
            statusChecked: state.statusChecked.filter(elem => elem !== value),
            statusUnchecked: [...state.statusUnchecked, value],
        }

        //Handle Children
        if (allChildren) {
            state = {
                ...state,
                statusUnchecked: state.statusUnchecked.filter(elem => ! allChildren.includes(elem)),
                statusChecked: state.statusChecked.filter(elem => ! allChildren.includes(elem)),
            }
            state = {
                ...state,
                statusUnchecked: state.statusUnchecked.concat(allChildren),
            }
        }

        //Handle Direct Parents - ask Gianmarco and Stani do they want it 
        // state = {
        //     ...state,
        //     statusUnchecked: state.statusUnchecked.filter(elem => ! allParents.includes(elem)),
        //     statusChecked: state.statusChecked.filter(elem => ! allParents.includes(elem)),
        // }
        // state = {
        //     ...state,
        //     statusDisabled: state.statusDisabled.concat(allParents),
        // }
    } else if (isDisabled) {
        //Do nothing - it is locked, because the parent is excluded
    }

    return state
}

export const setStatusCheckedAll = (state, action) => {
    const {allValues} = state;

    state = {
        ...state,
        statusChecked: allValues,
        statusUnchecked: [],
        statusCheckedNegative: [],
        statusDisabled: [],
    }

    return state;
}

export const setStatusCheckedNegativeAll = (state, action) => {
    const {elements} = state;
    
    state = {
        ...state,
        statusUnchecked: [],
        statusChecked: [],
        statusCheckedNegative: [],
        statusDisabled: [],
    }

    _.forEach(elements, nodeElement => {
        const {value, allChildren} = nodeElement;

        state = {
            ...state,
            statusCheckedNegative: [...state.statusCheckedNegative, value],
        }

        _.forEach(allChildren, child => {
            state = {
                ...state,
                statusDisabled: [...state.statusDisabled, child],
            }
        })
    })

    return state;
}

export const setStatusUncheckedAll = (state, action) => {
    const {allValues} = state;
    
    state = {
        ...state,
        statusUnchecked: allValues,
        statusChecked: [],
        statusCheckedNegative: [],
        statusDisabled: [],
    }

    return state;
}

export const setExpandYesAll = (state, action) => {
    const {allValues} = state;
    
    state = {
        ...state,
        expandNo: [],
        expandYes: allValues
    }

    return state;
}

export const setExpandNoAll = (state, action) => {
    const {allValues} = state;

    state = {
        ...state,
        expandYes: [],
        expandNo: allValues
    }

    return state;
}
import React from 'react';
import { Header } from 'semantic-ui-react';
import { TitleProp } from '../../enum/apiResultProp/TitleProp';

export default ({ screenComponentProps, uniqueKey }) => {
    const text = screenComponentProps[TitleProp.Text];
    const textSize = screenComponentProps[TitleProp.TextSize];
    const textAlign = screenComponentProps[TitleProp.TextAlign];
    const textColor = screenComponentProps[TitleProp.TextColor];

    return (
        <Header as={textSize} textAlign={textAlign} color={textColor} className='newScreenTitle' key={uniqueKey}>
            <Header.Content>{text}</Header.Content>
        </Header>
    );
}

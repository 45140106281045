import React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import { TableDataCellProp } from '../../../../enum/apiResultProp/TableDataCellProp';

export default ({ tableDataCellProps }) => {
    const columnName = tableDataCellProps[TableDataCellProp.ColumnName];
    const posNumber = tableDataCellProps[TableDataCellProp.PositionNumber];
    const boolValue = tableDataCellProps[TableDataCellProp.BoolValue];

    if (boolValue === true) {
        return <Table.Cell textAlign='center'><Icon color='green' name={'checkmark'} /></Table.Cell>;
    } else {
        return <Table.Cell textAlign='center'><Icon color='red' name={'close'} /></Table.Cell>;
    }
}
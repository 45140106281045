import React from 'react';
import _ from 'lodash';
import { Item } from 'semantic-ui-react';
import CustomItem from './Item';
import { ItemGroupProp } from '../../enum/apiResultProp/itemGroup/ItemGroupProp';

export default ({ screenComponentProps }) => {
    const items = screenComponentProps[ItemGroupProp.Items];
    const separatedItems = screenComponentProps[ItemGroupProp.SeparatedItems];

    return (
        <Item.Group divided={separatedItems}>
            {_.map(items, (props, itemKey) => <CustomItem key={itemKey} props={props} />)}
        </Item.Group>
    );
}

import _ from 'lodash';

export const fetch = (state, action) => {
    let {
        title, modalFormConfig, actionGenerateAndDownload, actionCancel, actionSendEmail, actionReset
    } = action.payload;

    return {
        ...state,
        isOpen: true,
        showSpinner: false,
        errorMessage: '',
        title: title,
        modalFormConfig: modalFormConfig,

        actionGenerateAndDownload: actionGenerateAndDownload,
        actionCancel: actionCancel,
        actionSendEmail: actionSendEmail,
        actionReset: actionReset,
    };
}

export const toggleSpinner = (state) => {
    const oldShowSpinner = state.showSpinner;

    return {
        ...state,
        showSpinner: !oldShowSpinner,
    };
}

export const updateFileInfo = (state, action) => {
    const { fileName, serverFilePath } = action.payload;

    return {
        ...state,
        generatedFile: {
            ...state.generatedFile,
            name: fileName,
            serverPath: serverFilePath,
        }
    };
}

export const openModal = (state) => {
    return {
        ...state,
        isOpen: true,
    };
}

export const closeModal = (state) => {
    return {
        ...state,
        isOpen: false,
        errorMessage: '',
    };
}

export const enableSendEmailButton = (state) => {
    return {
        ...state,
        actionSendEmail: {
            ...state.actionSendEmail,
            disabled: false,
        }
    };
}

export const clearForm = (state) => {
    return {
        ...state,
        isOpen: false,
        showSpinner: true,
        errorMessage: '',
        title: '',
        modalFormConfig: {},
        generatedFile: {
            name: '',
            serverPath: '',
        },
        actionGenerateAndDownload: {
            visible: false,
            generateUrl: '',
            title: '',
            downloadUrl: '',
        },
        actionCancel: {
            visible: true,
            title: ''
        },
        actionSendEmail: {
            visible: false,
            title: '',
            disabled: true,
            disabledMessage: '',
            url: '',
        },
        actionReset: {
            visible: false,
            title: '',
            url: ''
        },
    };
}

export const updateErrorMessage = (state, action) => {
    const errorMessage = action.payload;

    return {
        ...state,
        errorMessage: errorMessage,
    };
}

export const updateFieldValue = (state, action) => {
    const { rowName, fieldName, fieldValue } = action.payload;

    return {
        ...state,
        modalFormConfig: {
            ...state.modalFormConfig,
            [rowName]: {
                ...state.modalFormConfig[rowName],
                [fieldName]: {
                    ...state.modalFormConfig[rowName][fieldName],
                    value: fieldValue
                }
            }
        },
    };
}

export const updateFormAjax = (state, action) => {
    const fieldsForUpdate = action.payload;

    _.forEach(fieldsForUpdate, (fields, rowName) => {
        _.forEach(fields, (fieldProps, fieldName) => {
            _.forEach(fieldProps, (propValue, propName) => {
                state = {
                    ...state,
                    modalFormConfig: {
                        ...state.modalFormConfig,
                        [rowName]: {
                            ...state.modalFormConfig[rowName],
                            [fieldName]: {
                                ...state.modalFormConfig[rowName][fieldName],
                                [propName]: propValue
                            }
                        }
                    }
                }
            })
        })
    });

    return state;
}


import ioms from '../apis/ioms';
import actionTypes from '../actions/actionTypes';

export const getAutomaticLogoutInfo = () => async (dispatch, getState) => {
    try {
        const response = await ioms.get('getLogoutTimers', { withCredentials: true });
        
        dispatch({
            type: actionTypes.GET_AUTOMATIC_LOGOUT_INFO,
            payload: response.data.result
        });
    } catch (error) {
        //TODO Show error
    }
} 

export const stayInApp = () => async (dispatch, getState) => {
    try {
        const response = await ioms.get('stayInApp', { withCredentials: true });

        dispatch({
            type: actionTypes.GET_AUTOMATIC_LOGOUT_INFO,
            payload: response.data.result
        });
    } catch (error) {
        //TODO Show error
    }
} 

export const resetAutomaticLogoutInfo = () => {
    return {
        type: actionTypes.RESET_AUTOMATIC_LOGOUT_INFO,
    };
}

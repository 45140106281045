export default {
    PRICE_LIST_FETCH_DATA: 'PRICE_LIST_FETCH_DATA',
    PRICE_LIST_TOGGLE_SPINNER: 'PRICE_LIST_TOGGLE_SPINNER',
    PRICE_LIST_MODAL_OPEN: 'PRICE_LIST_MODAL_OPEN',
    PRICE_LIST_MODAL_CLOSE: 'PRICE_LIST_MODAL_CLOSE',
    PRICE_LIST_CLEAR_FORM: 'PRICE_LIST_CLEAR_FORM',
    PRICE_LIST_UPDATE_ERROR_MESSAGE: 'PRICE_LIST_UPDATE_ERROR_MESSAGE',

    PRICE_LIST_UPDATE_FIELD_VALUE: 'PRICE_LIST_UPDATE_FIELD_VALUE',

    PRICE_LIST_ENABLE_SEND_EMAIL_BUTTON: 'PRICE_LIST_ENABLE_SEND_EMAIL_BUTTON',
    
    PRICE_LIST_UPDATE_FILE_INFO: 'PRICE_LIST_UPDATE_FILE_INFO',

    PRICE_LIST_UPDATE_FORM_AJAX: 'PRICE_LIST_UPDATE_FORM_AJAX',
};

import React from 'react';
import _, { size } from 'lodash';
import { Table, Dimmer, Loader } from 'semantic-ui-react';
import GridColumnTitles from './header/GridColumnTitles';
import GridDataRow from './data/GridDataRow';
import GridActions from './header/GridActions';
import { DataGridProp } from '../../enum/dataGrid/DataGridProp';
import GridSubtotal from './footer/GridSubtotal';
import GridPagination from './footer/GridPagination';
import GridTitle from './header/GridTitle';
import { TableDataRowProp } from '../../enum/apiResultProp/TableDataRowProp';
import { ColumnProp } from '../../enum/dataGrid/ColumnProp';

export default ({
    screenComponentProps,
    onSortClick,
    onRecordsPerPageChange,
    onPageChange,
    onApplyFiltersClick,
    onDeleteAllFiltersClick,
    onDeleteAppliedFilterRowClick,
    onDeleteNotAppliedFilterRowClick,
    onCreateRowClick,
    onFilterColumnFieldChange,
    onFilterComparatorFieldChange,
    onFilterValueFieldChange,
    clearAllNotAppliedEmptyFilters,
    selectAll,
    selectSingle,
    mutateComponentWitPostData,
    openModalForm,
    onButtonClickMutateComponentWithPostData,
    reloadScreenWithPostDataOnButtonClick,
    translations,
    onActionDownload
}) => {
    const title = screenComponentProps[DataGridProp.Title];
    let columns = screenComponentProps[DataGridProp.Columns];
    const totalRowsCount = screenComponentProps[DataGridProp.TotalRowsCount];
    const columnTitles = screenComponentProps[DataGridProp.Titles];
    const sorting = screenComponentProps[DataGridProp.Sorting];
    const filterConfig = screenComponentProps[DataGridProp.FilterConfig];
    const filters = screenComponentProps[DataGridProp.Filters];
    const appliedFiltersIdsByColumn = screenComponentProps[DataGridProp.AppliedFiltersIds];
    let data = screenComponentProps[DataGridProp.Data];
    const subtotal = screenComponentProps[DataGridProp.Subtotal];
    const pagination = screenComponentProps[DataGridProp.Pagination];
    const buttons = screenComponentProps[DataGridProp.Buttons];
    const loaderIsActive = screenComponentProps[DataGridProp.LoaderIsActive];
    const loaderMessage = screenComponentProps[DataGridProp.LoaderMessage];
    const applyFilersAction = screenComponentProps[DataGridProp.ApplyFilersAction];
    const selectable = screenComponentProps[DataGridProp.Selectable];
    const selectedRowIds = screenComponentProps[DataGridProp.SelectedRowIds];
    const unselectableRowIds = screenComponentProps[DataGridProp.UnselectableRowIds];

    columns = _.sortBy(columns, ColumnProp.Position);
    data = _.sortBy(data, TableDataRowProp.RowNumber);

    let columnsCount = size(columns);
    if (selectable) {
        columnsCount++;
    }

    let currentPageRowIds = [];
    let currentPageRecordsCount = 0;
    let currentPageSelectedRecordsCount = 0;
    const renderBody = () => {
        return (
            <Table.Body>
                {
                    _.map(data, (tableDataRowProps, rowKey) => {
                        if (selectable) {
                            const rowId = tableDataRowProps[TableDataRowProp.RowKey];

                            if (!_.includes(unselectableRowIds, rowId)) {
                                currentPageRowIds.push(rowId);
                                currentPageRecordsCount++;
                                if (_.includes(selectedRowIds, rowId)) {
                                    currentPageSelectedRecordsCount++;
                                }
                            }
                        }

                        return (
                            <GridDataRow
                                key={rowKey}
                                tableDataRowProps={tableDataRowProps}
                                columns={columns}
                                selectable={selectable}
                                selectedRowIds={selectedRowIds}
                                unselectableRowIds={unselectableRowIds}
                                selectSingle={selectSingle}
                                mutateComponentWitPostData={mutateComponentWitPostData}
                                openModalForm={openModalForm}
                                onActionDownload={onActionDownload}
                            />
                        );
                    })}
            </Table.Body>
        );
    }
    let tableBody = renderBody();

    const renderHeader = () => {
        return (
            <Table.Header>
                <GridTitle title={title} columnsCount={columnsCount} />
                <GridActions
                    columnsCount={columnsCount}
                    buttons={buttons}
                    filterConfig={filterConfig}
                    filters={filters}
                    appliedFiltersIdsByColumn={appliedFiltersIdsByColumn}
                    onApplyFiltersClick={() => onApplyFiltersClick({ ...applyFilersAction })}
                    onDeleteAllFiltersClick={() => onDeleteAllFiltersClick({ ...applyFilersAction })}
                    onDeleteAppliedFilterRowClick={(params) => onDeleteAppliedFilterRowClick({ ...params, ...applyFilersAction })}
                    onDeleteNotAppliedFilterRowClick={onDeleteNotAppliedFilterRowClick}
                    onCreateRowClick={onCreateRowClick}
                    onFilterColumnFieldChange={onFilterColumnFieldChange}
                    onFilterComparatorFieldChange={onFilterComparatorFieldChange}
                    onFilterValueFieldChange={onFilterValueFieldChange}
                    clearAllNotAppliedEmptyFilters={clearAllNotAppliedEmptyFilters}
                    onButtonClickMutateComponentWithPostData={onButtonClickMutateComponentWithPostData}
                    reloadScreenWithPostDataOnButtonClick={reloadScreenWithPostDataOnButtonClick}
                    translations={translations}
                />
                <GridColumnTitles
                    columns={columns}
                    titles={columnTitles}
                    sorting={sorting}
                    filterConfig={filterConfig}
                    filters={filters}
                    appliedFiltersIdsByColumn={appliedFiltersIdsByColumn}
                    onSortClick={(params) => onSortClick({ ...params, ...applyFilersAction })}
                    onApplyFiltersClick={(params) => onApplyFiltersClick({ ...params, ...applyFilersAction })}
                    onDeleteAllFiltersClick={(params) => onDeleteAllFiltersClick({ ...params, ...applyFilersAction })}
                    onDeleteAppliedFilterRowClick={(params) => onDeleteAppliedFilterRowClick({ ...params, ...applyFilersAction })}
                    onDeleteNotAppliedFilterRowClick={onDeleteNotAppliedFilterRowClick}
                    onCreateRowClick={onCreateRowClick}
                    onFilterColumnFieldChange={onFilterColumnFieldChange}
                    onFilterComparatorFieldChange={onFilterComparatorFieldChange}
                    onFilterValueFieldChange={onFilterValueFieldChange}
                    clearAllNotAppliedEmptyFilters={clearAllNotAppliedEmptyFilters}
                    selectable={selectable}
                    selectAll={(params) => selectAll({ ...params, currentPageRowIds })}
                    currentPageSelectedRecordsCount={currentPageSelectedRecordsCount}
                    currentPageRecordsCount={currentPageRecordsCount}
                    translations={translations}
                />
            </Table.Header>
        );
    }

    const renderFooter = () => {
        return (
            <Table.Footer>
                <GridSubtotal columns={columns} subtotalDataRow={subtotal} selectable={selectable} selectedRowIds={selectedRowIds} />
                <GridPagination
                    columnsCount={columnsCount}
                    pagination={pagination}
                    onRecordsPerPageChange={(params) => onRecordsPerPageChange({ ...params, ...applyFilersAction })}
                    onPageChange={(params) => onPageChange({ ...params, ...applyFilersAction })}
                />
            </Table.Footer>
        );
    }

    return (
        <div style={{ width: '100%', overflowX: 'auto', overflowY: 'hidden' }}>
            <Dimmer active={loaderIsActive} inverted>
                <Loader size='large'>{loaderMessage}</Loader>
            </Dimmer>
            <Table celled selectable={false} color='blue' size='small' style={{ lineHeight: '1.2em' }}>
                {renderHeader()}
                {tableBody}
                {renderFooter()}
            </Table >
        </div>
    );
}

import actionTypes from "./actionTypes";
import { fetchGridDataWithGetParams } from './existingRecordActions';

export const onlyUpdateSortableHeaderDirection = (headerName, headerObject) => {
    let currentDirection = headerObject.direction;
    
    let nextDirection = "";
    if (currentDirection === "ASC") {
        nextDirection = "DESC";
    } else if (currentDirection === "") {
        nextDirection = "ASC";
    } 
    
    headerObject.direction = nextDirection;

    return {
        type: actionTypes.UPDATE_SORTABLE_HEADER_DIRECTION,
        payload: { headerName, headerObject, currentPage: 1 }
    }
}

export const updateSortableHeaderDirection = (headerName, headerObject, fetchUrl) => async (dispatch) => {
    await dispatch(onlyUpdateSortableHeaderDirection(headerName, headerObject));

    dispatch(fetchGridDataWithGetParams(fetchUrl));
}

export const cancelFilter = (filterName, filterIndex) => {
    return {
        type: actionTypes.CANCEL_FILTER,
        payload: { fieldName: filterName, filterIndex }
    }
}

export const clearFiltersOnly = () => {
    return {
        type: actionTypes.CLEAR_FILTERS,
    }
}

export const clearFilters = (fetchUrl) => async (dispatch, getState) => {
    await dispatch(clearFiltersOnly());
    
    dispatch(fetchGridDataWithGetParams(fetchUrl));
};

export const addFilter = (filterKey, filterObject) => {
    return {
        type: actionTypes.ADD_FILTER,
        payload: { filterKey, filterObject }
    }
}

export const filterFormSelectColumn = (filterColumnsDropdownSelectedKey, filterComparatorDropdown, filterDataType) => {
    return {
        type: actionTypes.FILTER_FORM_SELECT_COLUMN,
        payload: { filterColumnsDropdownSelectedKey, filterComparatorDropdown, filterDataType}
    }
}

export const filterFormSelectComparator = (filterComparatorDropdownSelectedKey) => {
    return {
        type: actionTypes.FILTER_FORM_SELECT_COMPARATOR,
        payload: { filterComparatorDropdownSelectedKey }
    }
}

export const filterFormUpdateInput = (filterValue) => {
    return {
        type: actionTypes.FILTER_FORM_UPDATE_INPUT,
        payload: { filterValue }
    }
}

export const filterFormClear = () => {
    return {
        type: actionTypes.FILTER_FORM_CLEAR,
    }
}


export const loadFilterForEdit = (filterObject) => {
    return {
        type: actionTypes.FILTER_FORM_LOAD_FILTER_FOR_EDIT,
        payload: filterObject
    }
}

export const editFilterValues = (index, columnKey , comparator, filterValue) => {
    return {
        type: actionTypes.EDIT_FILTER_VALUES,
        payload: { index, columnKey, comparator,filterValue }
    }
}

export const toggleFormVisibility = () => {
    return {
        type: actionTypes.TOGGLE_FORM_VISIBILITY
    }
}

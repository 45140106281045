import _ from 'lodash';

export const updateScreenData = (state, action) => {
    const { title, subTitle, filterFields, filterActions, treeData, sidePaneLoadedTreeElementId, sidePaneInfo } = action.payload;

    //Reset Title
    if (! _.isUndefined(title)) {
        state = {
            ...state,
            title: title
        };
    }

    //Reset Title
    if (! _.isUndefined(sidePaneInfo)) {
        state = {
            ...state,
            sidePaneInfo: sidePaneInfo
        };
    }

    //Reset SubTitle
    if (! _.isUndefined(subTitle)) {
        state = {
            ...state,
            subTitle: subTitle
        };
    }

    //Reset Filter Fields
    if (! _.isUndefined(filterFields)) {
        _.forEach(filterFields, (fieldConfig, fieldName) => {
            let { label, value, readOnly, required, options, width, onChangeAjaxEventUrl } = fieldConfig;

            if (! _.isUndefined(label)) {
                state = {
                    ...state,
                    filterFields: {
                        ...state.filterFields,
                        [fieldName]: {
                            ...state.filterFields[fieldName],
                            label: label,
                        }
                    }
                };
            }

            if (! _.isUndefined(width)) {
                state = {
                    ...state,
                    filterFields: {
                        ...state.filterFields,
                        [fieldName]: {
                            ...state.filterFields[fieldName],
                            width: width,
                        }
                    }
                };
            }

            if (! _.isUndefined(value)) {
                state = {
                    ...state,
                    filterFields: {
                        ...state.filterFields,
                        [fieldName]: {
                            ...state.filterFields[fieldName],
                            value: value,
                        }
                    }
                };
            }

            if (! _.isUndefined(readOnly)) {
                state = {
                    ...state,
                    filterFields: {
                        ...state.filterFields,
                        [fieldName]: {
                            ...state.filterFields[fieldName],
                            readOnly: readOnly,
                        }
                    }
                };
            }

            if (! _.isUndefined(required)) {
                state = {
                    ...state,
                    filterFields: {
                        ...state.filterFields,
                        [fieldName]: {
                            ...state.filterFields[fieldName],
                            required: required,
                        }
                    }
                };
            }

            if (! _.isUndefined(options)) {
                state = {
                    ...state,
                    filterFields: {
                        ...state.filterFields,
                        [fieldName]: {
                            ...state.filterFields[fieldName],
                            options: options,
                        }
                    }
                };
            }

            if (! _.isUndefined(onChangeAjaxEventUrl)) {
                state = {
                    ...state,
                    filterFields: {
                        ...state.filterFields,
                        [fieldName]: {
                            ...state.filterFields[fieldName],
                            onChangeAjaxEventUrl: onChangeAjaxEventUrl,
                        }
                    }
                };
            }
        });
    }

    //Reset Actions
    if (! _.isUndefined(filterActions)) {
        _.forEach(filterActions, (actionConfig, actionName) => {
            let { text, visible, locked, lockedMessage, url, requiredFieldsForUnlock } = actionConfig;

            if (! _.isUndefined(text)) {
                state = {
                    ...state,
                    filterActions: {
                        ...state.filterActions,
                        [actionName]: {
                           ...state.filterActions[actionName],
                           text: text, 
                        }
                    }
                }    
            }

            if (! _.isUndefined(visible)) {
                state = {
                    ...state,
                    filterActions: {
                        ...state.filterActions,
                        [actionName]: {
                           ...state.filterActions[actionName],
                           visible: visible, 
                        }
                    }
                }    
            }

            if (! _.isUndefined(url)) {
                state = {
                    ...state,
                    filterActions: {
                        ...state.filterActions,
                        [actionName]: {
                           ...state.filterActions[actionName],
                           url: url, 
                        }
                    }
                }    
            }
            
            if (! _.isUndefined(requiredFieldsForUnlock)) {
                state = {
                    ...state,
                    filterActions: {
                        ...state.filterActions,
                        [actionName]: {
                           ...state.filterActions[actionName],
                           requiredFieldsForUnlock: requiredFieldsForUnlock, 
                        }
                    }
                }    
            }

            if (! _.isUndefined(lockedMessage)) {
                state = {
                    ...state,
                    filterActions: {
                        ...state.filterActions,
                        [actionName]: {
                           ...state.filterActions[actionName],
                           lockedMessage: lockedMessage, 
                        }
                    }
                }    
            }
        });
    }

    //Reset Tree Data
    if (! _.isUndefined(treeData)) {
        const { elements, allNodeTreeElementIds, allNodesExpandState } = treeData;

        if (! _.isUndefined(elements)) {
            state = {
                ...state,
                treeData: {
                    ...state.treeData,
                    elements: elements
                }
            }
        }

        if (! _.isUndefined(allNodeTreeElementIds)) {
            state = {
                ...state,
                treeData: {
                    ...state.treeData,
                    allNodeTreeElementIds: allNodeTreeElementIds
                }
            }
        }

        if (! _.isUndefined(allNodesExpandState)) {
            const { expandedYes, expandedNo } = allNodesExpandState; 

            if (! _.isUndefined(expandedYes)) {
                state = {
                    ...state,
                    treeData: {
                        ...state.treeData,
                        allNodesExpandState: {
                            ...state.treeData.allNodesExpandState,
                            expandedYes: expandedYes
                        }
                    }
                }
            }
            
            if (! _.isUndefined(expandedNo)) {
                state = {
                    ...state,
                    treeData: {
                        ...state.treeData,
                        allNodesExpandState: {
                            ...state.treeData.allNodesExpandState,
                            expandedNo: expandedNo
                        }
                    }
                }
            }
        }
    }

    //Reset Side Pane Selected Tree Element Id
    if (! _.isUndefined(sidePaneLoadedTreeElementId)) {
        state = {
            ...state,
            sidePaneLoadedTreeElementId: sidePaneLoadedTreeElementId
        }
    }

    return state;
}

export const updateFilterFieldValue = (state, action) => {
    const { fieldName, fieldValue } = action.payload;

    return {
        ...state,
        filterFields: {
            ...state.filterFields,
            [fieldName]: {
                ...state.filterFields[fieldName],
                value: fieldValue
            }
        }
    }
}

export const expandedYesAll = (state) => {
    const {allNodeTreeElementIds} = state.treeData;
    
    state = {
        ...state,
        treeData: {
            ...state.treeData,
            allNodesExpandState: {
                ...state.treeData.allNodesExpandState,
                expandedNo: [],
                expandedYes: allNodeTreeElementIds
            }
        }
    }

    return state;
}

export const expandedNoAll = (state) => {
    const {allNodeTreeElementIds} = state.treeData;

    state = {
        ...state,
        treeData: {
            ...state.treeData,
            allNodesExpandState: {
                ...state.treeData.allNodesExpandState,
                expandedYes: [],
                expandedNo: allNodeTreeElementIds
            }
        }
    }

    return state;
}

export const toggleExpandOfNodeElem = (state, action) => {
    const { expandedYes } = state.treeData.allNodesExpandState;
    const { treeElementId } = action.payload;
    
    const isExpand = _.includes(expandedYes, treeElementId);
    
    if (isExpand) {
        state = {
            ...state,
            treeData: {
                ...state.treeData,
                allNodesExpandState: {
                    ...state.treeData.allNodesExpandState,
                    expandedYes: state.treeData.allNodesExpandState.expandedYes.filter(elem => elem !== treeElementId),
                    expandedNo: [...state.treeData.allNodesExpandState.expandedNo, treeElementId],
                }
            }
        }
    } else {
        state = {
            ...state,
            treeData: {
                ...state.treeData,
                allNodesExpandState: {
                    ...state.treeData.allNodesExpandState,
                    expandedNo: state.treeData.allNodesExpandState.expandedNo.filter(elem => elem !== treeElementId),
                    expandedYes: [...state.treeData.allNodesExpandState.expandedYes, treeElementId],
                }
            }
        }
    }

    return state;
}

export const selectTreeRecord = (state, action) => {
    const { treeElementId, sidePaneInfo } = action.payload;

    return {
        ...state,
        sidePaneLoadedTreeElementId: treeElementId, 
        sidePaneInfo: sidePaneInfo, 
    }
}

export const toggleSpinner = (state, action) => {
    const { isActive } = action.payload;

    return {
        ...state,
        spinnerIsActive: isActive, 
    }
}

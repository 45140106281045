import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Modal, Tab, Dimmer, Loader } from 'semantic-ui-react';
import { fetch, closeModal } from '../../actions/appDocumentation/actions';
import HtmlViewer from './HtmlViewer';
import SqlViewer from './SqlViewer';

const DocumentationModal = ({ isOpen, showSpinner, documentationData, closeModal }) => {
    const buildHtmlPageTabData = ({ key, title, html }) => {
        return {
            menuItem: { key: key, content: title },
            render: () => {
                return (
                    <HtmlViewer html={html} />
                );
            },
        };
    }

    const buildSqlPageTabData = ({ key, title, sqlObject }) => {
        return {
            menuItem: { key: key, content: title },
            render: () => {
                return (
                    <React.Fragment>
                        <SqlViewer data={sqlObject} />
                    </React.Fragment>
                );
            },
        };
    }

    const buildSideMenuPage = ({ key, title, sideMenuElements }) => {
        const subPanes = [];

        _.forEach(sideMenuElements, (sideMenuObj) => {
            const { objectType, pageData } = sideMenuObj;

            let tabSubPane = null;
            if (objectType === 'HTML_PAGE') {
                tabSubPane = buildHtmlPageTabData(pageData);
            } else if (objectType === 'SQL_QUERY_PAGE') {
                tabSubPane = buildSqlPageTabData(pageData);
            }

            subPanes.push(tabSubPane);
        });

        return {
            menuItem: { key: key, content: title },
            render: () => {
                return (
                    <Tab menu={{ fluid: true, vertical: true, tabular: false }} panes={subPanes} style={{ marginTop: '10px' }} />
                );
            },
        };
    }

    const buildSecondaryMenuPage = ({ key, title, secondaryMenuElements }) => {
        let tabSubPane;
        const subPanesArr = [];

        _.forEach(secondaryMenuElements, (secondaryMenuObj) => {
            const { pageData, objectType } = secondaryMenuObj;

            if (objectType === 'HTML_PAGE') {
                tabSubPane = buildHtmlPageTabData(pageData);
                subPanesArr.push(tabSubPane);
            } else if (objectType === 'SIDE_MENU_PAGE') {
                tabSubPane = buildSideMenuPage(secondaryMenuObj);
                subPanesArr.push(tabSubPane);
            } else {
                tabSubPane = buildHtmlPageTabData({
                    key: Math.random(1), title: 'Not registered type', html: '<h1>Not registered type!</h1>'
                })
                subPanesArr.push(tabSubPane);
            }
        });

        return {
            menuItem: { key: key, content: title },
            render: () => {
                return (
                    <Tab menu={{ fluid: true, vertical: false, tabular: false }} panes={subPanesArr} style={{ marginTop: '10px' }} />
                );
            },
        };
    }

    const getPanes = () => {
        let objectType, tabPane;
        let panesArr = [];

        const { mainMenuElements } = documentationData;

        _.forEach(mainMenuElements, (mainMenuElement) => {
            objectType = mainMenuElement.objectType;

            if (objectType === 'HTML_PAGE') {
                const { pageData } = mainMenuElement;
                tabPane = buildHtmlPageTabData(pageData);

                panesArr.push(tabPane);
            } else if (objectType === 'SQL_QUERY_PAGE') {
                const { pageData } = mainMenuElement;
                tabPane = buildSqlPageTabData(pageData);

                panesArr.push(tabPane);
            } else if (objectType === 'SIDE_MENU_PAGE') {
                tabPane = buildSideMenuPage(mainMenuElement);
                panesArr.push(tabPane);
            } else if (objectType === 'SECONDARY_MENU_PAGE') {
                tabPane = buildSecondaryMenuPage(mainMenuElement);
                panesArr.push(tabPane);
            } else {
                tabPane = buildHtmlPageTabData({
                    key: Math.random(1), title: 'Not registered type', html: '<h1>Not registered type!</h1>'
                })
            }
        });

        return panesArr;
    }

    return (
        <Modal
            closeIcon
            size='fullscreen'
            open={isOpen}
            onClose={closeModal}
            centered={false}
        >
            <Modal.Content scrolling style={{ maxHeight: '83vh' }}>
                <Dimmer active={showSpinner} inverted>
                    <Loader inverted>Loading</Loader>
                </Dimmer>
                
                <Tab panes={getPanes()} />
            </Modal.Content>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        isOpen: state.appDocumentation.isOpen,
        showSpinner: state.appDocumentation.showSpinner,
        documentationData: state.appDocumentation.documentationData,
    };
}

export default connect(mapStateToProps, { fetch, closeModal })(DocumentationModal);

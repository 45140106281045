export default {
    OPEN_LIST: 'OPEN_LIST',
    CLOSE_LIST: 'CLOSE_LIST',
    TOGGLE_SHOW_HIDDEN_ELEMENTS: 'TOGGLE_SHOW_HIDDEN_ELEMENTS',

    TOGGLE_STATUS_LIST: 'TOGGLE_STATUS_LIST',
    
    STATUS_CHECKED_ALL_LIST: 'STATUS_CHECKED_ALL_LIST',
    STATUS_UNCHECKED_ALL_LIST: 'STATUS_UNCHECKED_ALL_LIST',
    
    TOGGLE_SPINNER_LIST: 'TOGGLE_SPINNER_LIST',
    UPDATE_ERROR_LIST: 'UPDATE_ERROR_LIST',
};

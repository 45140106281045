import React from 'react';
import _ from 'lodash';
import history from '../../../history';
import { Form as SemanticForm } from 'semantic-ui-react';
import { FormFieldProp } from '../../../enum/apiResultProp/FormFieldProp';

import { ROUTES } from '../../../helpers/routes';

export default ({ fieldProps }) => {
    const value = fieldProps[FormFieldProp.Value];
    const showLabel = fieldProps[FormFieldProp.ShowLabel];
    const label = fieldProps[FormFieldProp.Label];
    const openInNewTab = fieldProps[FormFieldProp.OpenInNewTab];
    const routeName = fieldProps[FormFieldProp.FrontEndRoute];
    const routeParams = fieldProps[FormFieldProp.FrontEndParams];

    const onButtonClick = () => {
        let nextRoute = ROUTES.HOME_SCREEN_ROUTE.URL;
        if (ROUTES[routeName]) {
            nextRoute = ROUTES[routeName].URL;

            if (routeParams) {
                _.forEach(routeParams, (paramValue, paramName) => {
                    nextRoute = nextRoute.replace(':' + paramName, paramValue);
                });
            }
        }

        if (openInNewTab) {
            if (process.env.REACT_APP_HISTORY_BASENAME && process.env.REACT_APP_HISTORY_BASENAME !== '/') {
                if (process.env.REACT_APP_HISTORY_BASENAME[0] === '/') {
                    nextRoute = process.env.REACT_APP_HISTORY_BASENAME + nextRoute;
                } else {
                    nextRoute = '/' + process.env.REACT_APP_HISTORY_BASENAME + nextRoute;
                }
            }

            window.open(nextRoute, '_blank');
        } else {
            history.push(nextRoute);
        }
    }

    let textFieldProps = {
        type: 'text',
        required: false,
        readOnly: true,
        disabled: false,
        value: value,
        action: {
            icon: 'linkify',
            color: 'teal',
            onClick: onButtonClick
        },
    };
    if (showLabel) {
        textFieldProps = { ...textFieldProps, label: label };
    }

    return <SemanticForm.Input {...textFieldProps} />;
}

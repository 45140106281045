import React from 'react';
import _ from 'lodash';
import TableHeaderRow from '../tableHeader/row/TableHeaderRow';
import TableDataRow from '../tableData/row/TableDataRow';
import { Table } from 'semantic-ui-react';
import { TableProp } from '../../../enum/apiResultProp/TableProp';

export default ({ screenComponentProps }) => {
    const tableHeader = screenComponentProps[TableProp.TableHeader];
    const tableData = screenComponentProps[TableProp.TableData];

    return (
        <Table celled sortable selectable color='grey'>

            <Table.Header>
                <TableHeaderRow tableHeaderCellsData={tableHeader} />
            </Table.Header>

            <Table.Body>
                {_.map(tableData, (tableDataRowProps, rowKey) => {
                    return (
                        <TableDataRow key={rowKey} tableDataRowProps={tableDataRowProps} />
                    );
                })}
            </Table.Body>
        </Table>
    );
}

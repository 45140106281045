import React from 'react';
import { Form, Button } from 'semantic-ui-react';

import { CREATE_FILTER_MODE, EDIT_FILTER_MODE } from '../../../../helpers/const';
import { STRINGS } from '../../../../helpers/strings';
import { getValue } from '../../../../helpers/functions';

class FormActions extends React.Component {
    onAddFilterClick = () => {
        const {
            filterColumnsDropdown, filterColumnsDropdownSelectedKey, filterComparatorDropdown, 
            filterComparatorDropdownSelectedKey, filterValue
        } = this.props.filterForm;

        this.props.onAddFilter(
            filterColumnsDropdown[filterColumnsDropdownSelectedKey].columnKey,
            {
                comparator: filterComparatorDropdown[filterComparatorDropdownSelectedKey].columnKey,
                value: filterValue,
                applied: false
            }
        );

        this.props.onClearClick();
    } 

    onSaveClick = () => {
        const {
            index, filterComparatorDropdown, filterComparatorDropdownSelectedKey, 
            filterValue, filterColumnsDropdown, filterColumnsDropdownSelectedKey
        } = this.props.filterForm;

        const comparator = filterComparatorDropdown[filterComparatorDropdownSelectedKey].columnKey;
        const columnKey = filterColumnsDropdown[filterColumnsDropdownSelectedKey].columnKey;
        
        this.props.editFilterValues(index, columnKey, comparator, filterValue);
        this.props.onClearClick();
    }

    renderFilterFormActions = () => {
        const {mode, filterValue, type, translations} = this.props;
        
        let btnAdd, btnClear, btnEdit, btnDelete;
        let disabled = ! this.props.validateFilterFormInput(filterValue, type).isValid;
        
        if (mode === CREATE_FILTER_MODE) {
            btnAdd = (
                <Form.Field>
                    <Button 
                        color='black' 
                        disabled={disabled} 
                        onClick={this.onAddFilterClick}
                    >
                        {getValue(translations, STRINGS.BUTTON_ADD)}
                    </Button>
                </Form.Field>
            );

            btnClear = (
                <Form.Field>
                    <Button 
                        color='red' 
                        onClick={this.props.onClearClick}
                    >
                        {getValue(translations, STRINGS.BUTTON_CLEAR)}
                    </Button>
                </Form.Field>
            );
        } else if (mode === EDIT_FILTER_MODE) {
            btnEdit = (
                <Form.Field>
                    <Button 
                        color='black' 
                        disabled={disabled} 
                        onClick={this.onSaveClick}
                    >
                        {getValue(translations, STRINGS.BUTTON_SAVE)}
                    </Button>
                </Form.Field>
            );

            btnDelete = (
                <Form.Field>
                    <Button 
                        color='red' 
                        onClick={this.props.filterFormClear}
                    >
                        {getValue(translations, STRINGS.BUTTON_CANCEL)}
                    </Button>
                </Form.Field>
            );
        }
        
        return (
            <React.Fragment>
                { btnAdd }
                { btnClear }
                { btnEdit }
                { btnDelete }
            </React.Fragment>
        );
    }
    
    render () {
        return (
            <React.Fragment>
                {this.renderFilterFormActions()}
            </React.Fragment>
        );
    } 
} 

export default FormActions;

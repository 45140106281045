import React, { createRef } from 'react';
import _ from 'lodash';
import { Grid, Ref, Rail, Sticky, Segment, Item, Popup, Label, ItemDescription } from 'semantic-ui-react';
import Tree from './Tree';
import ButtonsForm from '../buttonsForm/ButtonsForm';
import { TreeWithSidePanelProp } from '../../enum/apiResultProp/TreeWithSidePanelProp';
import { SidePanelType } from '../../enum/SidePanelType';
import ItemGroup from '../itemGroup/ItemGroup';

export default ({
    screenComponentProps,
    uniqueKey,
    expandAll,
    collapseAll,
    expand,
    collapse,
    onElementSelect,
    mutateWithPostData,
    changeValue
}) => {
    const contextRef = createRef();

    const renderSidePanelComponent = () => {
        const sidePanelType = screenComponentProps[TreeWithSidePanelProp.SidePanelType];

        let sidePanelComponent = null;
        if (sidePanelType === SidePanelType.ButtonOptionsForm) {
            sidePanelComponent = (
                <ButtonsForm
                    screenComponentProps={screenComponentProps}
                    changeValue={changeValue}
                    mutateWithPostData={mutateWithPostData}
                />
            );
        } else if (sidePanelType === SidePanelType.DetailedData) {
            sidePanelComponent = <ItemGroup screenComponentProps={screenComponentProps} />
        }

        return sidePanelComponent;
    }

    const renderTree = () => {
        return (
            <Tree
                screenComponentProps={screenComponentProps}
                uniqueKey={uniqueKey}
                expandAll={expandAll}
                collapseAll={collapseAll}
                expand={expand}
                collapse={collapse}
                onElementSelect={onElementSelect}
                mutateWithPostData={mutateWithPostData}
            />
        );
    }

    const items = {
        item_1: {
            title: 'Read',
            titleMeta: ' - 3 roles, 11 users',
            contentLabels: {
                label_1: {
                    text: 'Admin',
                    textColor: 'blue',
                    popupTitle: 'Admin Role - 3 users',
                    popupContent: 'Petar Ivanov, Georgi Toshkov, Tosho Ivanov'
                },
                label_2: {
                    text: 'Purchasing',
                    textColor: 'green',
                    popupTitle: 'Purchasing Role - 2 users',
                    popupContent: 'Petar Ivanov, Georgi Toshkov'
                },
                label_3: {
                    text: 'Adm_Mng',
                    textColor: 'orange',
                    popupTitle: 'Adm_Mng Role - 1 user',
                    popupContent: 'Petar Ivanov'
                },
                label_4: {
                    text: 'Operation_Mng',
                    textColor: 'teal',
                    popupTitle: 'Operation_Mng Role - 1 user',
                    popupContent: 'Petar Ivanov'
                },
                label_5: {
                    text: 'CEO',
                    textColor: 'purple',
                    popupTitle: 'CEO Role - 3 users',
                    popupContent: 'Gianmarco Santucci'
                },
            },
            labelsSeparator: ','
        },
        item_2: {
            title: 'Create',
            titleMeta: ' - 3 roles, 11 users',
            contentLabels: {
                label_1: {
                    text: 'Admin',
                    textColor: 'blue',
                    popupTitle: 'Admin Role - 3 users',
                    popupContent: 'Petar Ivanov, Georgi Toshkov, Tosho Ivanov'
                },
                label_2: {
                    text: 'Purchasing',
                    textColor: 'green',
                    popupTitle: 'Purchasing Role - 2 users',
                    popupContent: 'Petar Ivanov, Georgi Toshkov'
                },
                label_3: {
                    text: 'Adm_Mng',
                    textColor: 'orange',
                    popupTitle: 'Adm_Mng Role - 1 user',
                    popupContent: 'Petar Ivanov'
                },
                label_4: {
                    text: 'Operation_Mng',
                    textColor: 'teal',
                    popupTitle: 'Operation_Mng Role - 1 user',
                    popupContent: 'Petar Ivanov'
                },
                label_5: {
                    text: 'CEO',
                    textColor: 'purple',
                    popupTitle: 'CEO Role - 3 users',
                    popupContent: 'Gianmarco Santucci'
                },
            },
            labelsSeparator: ','
        },
        item_3: {
            title: 'Edit',
            titleMeta: ' - 3 roles, 11 users',
            contentLabels: {
                label_1: {
                    text: 'Admin',
                    textColor: 'blue',
                    popupTitle: 'Admin Role - 3 users',
                    popupContent: 'Petar Ivanov, Georgi Toshkov, Tosho Ivanov'
                },
                label_2: {
                    text: 'Purchasing',
                    textColor: 'green',
                    popupTitle: 'Purchasing Role - 2 users',
                    popupContent: 'Petar Ivanov, Georgi Toshkov'
                },
                label_3: {
                    text: 'Adm_Mng',
                    textColor: 'orange',
                    popupTitle: 'Adm_Mng Role - 1 user',
                    popupContent: 'Petar Ivanov'
                },
                label_4: {
                    text: 'Operation_Mng',
                    textColor: 'teal',
                    popupTitle: 'Operation_Mng Role - 1 user',
                    popupContent: 'Petar Ivanov'
                },
                label_5: {
                    text: 'CEO',
                    textColor: 'purple',
                    popupTitle: 'CEO Role - 3 users',
                    popupContent: 'Gianmarco Santucci'
                },
            },
            labelsSeparator: ','
        },
        item_4: {
            title: 'Delete',
            titleMeta: ' - 3 roles, 11 users',
            contentLabels: {
                label_1: {
                    text: 'Admin',
                    textColor: 'blue',
                    popupTitle: 'Admin Role - 3 users',
                    popupContent: 'Petar Ivanov, Georgi Toshkov, Tosho Ivanov'
                },
                label_2: {
                    text: 'Purchasing',
                    textColor: 'green',
                    popupTitle: 'Purchasing Role - 2 users',
                    popupContent: 'Petar Ivanov, Georgi Toshkov'
                },
                label_3: {
                    text: 'Adm_Mng',
                    textColor: 'orange',
                    popupTitle: 'Adm_Mng Role - 1 user',
                    popupContent: 'Petar Ivanov'
                },
                label_4: {
                    text: 'Operation_Mng',
                    textColor: 'teal',
                    popupTitle: 'Operation_Mng Role - 1 user',
                    popupContent: 'Petar Ivanov'
                },
                label_5: {
                    text: 'CEO',
                    textColor: 'purple',
                    popupTitle: 'CEO Role - 3 users',
                    popupContent: 'Gianmarco Santucci'
                },
            },
            labelsSeparator: ','
        },
        item_5: {
            title: 'Check',
            titleMeta: ' - 3 roles, 11 users',
            contentLabels: {
                label_1: {
                    text: 'Admin',
                    textColor: 'blue',
                    popupTitle: 'Admin Role - 3 users',
                    popupContent: 'Petar Ivanov, Georgi Toshkov, Tosho Ivanov'
                },
                label_2: {
                    text: 'Purchasing',
                    textColor: 'green',
                    popupTitle: 'Purchasing Role - 2 users',
                    popupContent: 'Petar Ivanov, Georgi Toshkov'
                },
                label_3: {
                    text: 'Adm_Mng',
                    textColor: 'orange',
                    popupTitle: 'Adm_Mng Role - 1 user',
                    popupContent: 'Petar Ivanov'
                },
                label_4: {
                    text: 'Operation_Mng',
                    textColor: 'teal',
                    popupTitle: 'Operation_Mng Role - 1 user',
                    popupContent: 'Petar Ivanov'
                },
                label_5: {
                    text: 'CEO',
                    textColor: 'purple',
                    popupTitle: 'CEO Role - 3 users',
                    popupContent: 'Gianmarco Santucci'
                },
            },
            labelsSeparator: ','
        },
        item_6: {
            title: 'Approve',
            titleMeta: ' - 3 roles, 11 users',
            contentLabels: {
                label_1: {
                    text: 'Admin',
                    textColor: 'blue',
                    popupTitle: 'Admin Role - 3 users',
                    popupContent: 'Petar Ivanov, Georgi Toshkov, Tosho Ivanov'
                },
                label_2: {
                    text: 'Purchasing',
                    textColor: 'green',
                    popupTitle: 'Purchasing Role - 2 users',
                    popupContent: 'Petar Ivanov, Georgi Toshkov'
                },
                label_3: {
                    text: 'Adm_Mng',
                    textColor: 'orange',
                    popupTitle: 'Adm_Mng Role - 1 user',
                    popupContent: 'Petar Ivanov'
                },
                label_4: {
                    text: 'Operation_Mng',
                    textColor: 'teal',
                    popupTitle: 'Operation_Mng Role - 1 user',
                    popupContent: 'Petar Ivanov'
                },
                label_5: {
                    text: 'CEO',
                    textColor: 'purple',
                    popupTitle: 'CEO Role - 3 users',
                    popupContent: 'Gianmarco Santucci'
                },
            },
            labelsSeparator: ','
        },
        item_7: {
            title: 'Temp Approve',
            titleMeta: ' - 3 roles, 11 users',
            contentLabels: {
                label_1: {
                    text: 'Admin',
                    textColor: 'blue',
                    popupTitle: 'Admin Role - 3 users',
                    popupContent: 'Petar Ivanov, Georgi Toshkov, Tosho Ivanov'
                },
                label_2: {
                    text: 'Purchasing',
                    textColor: 'green',
                    popupTitle: 'Purchasing Role - 2 users',
                    popupContent: 'Petar Ivanov, Georgi Toshkov'
                },
                label_3: {
                    text: 'Adm_Mng',
                    textColor: 'orange',
                    popupTitle: 'Adm_Mng Role - 1 user',
                    popupContent: 'Petar Ivanov'
                },
                label_4: {
                    text: 'Operation_Mng',
                    textColor: 'teal',
                    popupTitle: 'Operation_Mng Role - 1 user',
                    popupContent: 'Petar Ivanov'
                },
                label_5: {
                    text: 'CEO',
                    textColor: 'purple',
                    popupTitle: 'CEO Role - 3 users',
                    popupContent: 'Gianmarco Santucci'
                },
            },
            labelsSeparator: ','
        },
        item_8: {
            title: 'Temp Disapprove',
            titleMeta: ' - 3 roles, 11 users',
            contentLabels: {
                label_1: {
                    text: 'Admin',
                    textColor: 'blue',
                    popupTitle: 'Admin Role - 3 users',
                    popupContent: 'Petar Ivanov, Georgi Toshkov, Tosho Ivanov'
                },
                label_2: {
                    text: 'Purchasing',
                    textColor: 'green',
                    popupTitle: 'Purchasing Role - 2 users',
                    popupContent: 'Petar Ivanov, Georgi Toshkov'
                },
                label_3: {
                    text: 'Adm_Mng',
                    textColor: 'orange',
                    popupTitle: 'Adm_Mng Role - 1 user',
                    popupContent: 'Petar Ivanov'
                },
                label_4: {
                    text: 'Operation_Mng',
                    textColor: 'teal',
                    popupTitle: 'Operation_Mng Role - 1 user',
                    popupContent: 'Petar Ivanov'
                },
                label_5: {
                    text: 'CEO',
                    textColor: 'purple',
                    popupTitle: 'CEO Role - 3 users',
                    popupContent: 'Gianmarco Santucci'
                },
            },
            labelsSeparator: ','
        }
    };

    const renderNewSticky = () => {
        return (
            <Item.Group divided>
                {_.map(items, (itemData, itemKey) => {
                    const { title, titleMeta, contentLabels, labelsSeparator } = itemData;

                    const countOfLabels = _.size(contentLabels);
                    let currentLabel = 0;

                    return (
                        <Item key={itemKey} style={{ paddingTop: '3px', paddingBottom: '3px' }}>
                            <Item.Content>
                                <Item.Header>
                                    {title}
                                    <span style={{ fontSize: '9px', marginLeft: '3px', color: 'grey' }}>{titleMeta}</span>
                                </Item.Header>
                                <Item.Description style={{ margin: '0px' }}>
                                    {_.map(contentLabels, (labelData, labelKey) => {
                                        const { text, popupTitle, popupContent, textColor } = labelData;
                                        currentLabel++;

                                        return (
                                            <React.Fragment key={labelKey}>
                                                <Popup
                                                    inverted
                                                    color={textColor}
                                                    content={popupContent}
                                                    key={labelKey}
                                                    header={popupTitle}
                                                    trigger={<span style={{ color: textColor, cursor: 'pointer' }}>{text}</span>}
                                                />
                                                {
                                                    currentLabel !== countOfLabels 
                                                        ? <span style={{ marginRight: '4px' }}>{labelsSeparator}</span>
                                                        : null
                                                }
                                            </React.Fragment>
                                        );
                                    })}
                                </Item.Description>
                            </Item.Content>
                        </Item>
                    );
                })}
            </Item.Group>
        );
    }

    return (
        <React.Fragment key={uniqueKey}>
            <Grid>
                <Grid.Column width={10}>
                    <Ref innerRef={contextRef}>
                        <Segment basic>
                            {renderTree()}

                            <Rail position='right' size='small'>
                                <Sticky context={contextRef} offset={20}>
                                    {renderSidePanelComponent()}
                                    {/* {renderNewSticky()} */}
                                </Sticky>
                            </Rail>
                        </Segment>
                    </Ref>
                </Grid.Column>
            </Grid>
        </React.Fragment>
    );
}

import React from 'react';
import _ from 'lodash';
import { Form as SemanticForm, Button, Icon } from 'semantic-ui-react';
import { FormFieldProp } from '../../../enum/apiResultProp/FormFieldProp';

export default ({ fieldProps, actionDownload }) => {
    const value = fieldProps[FormFieldProp.Value];
    const showLabel = fieldProps[FormFieldProp.ShowLabel];
    const label = fieldProps[FormFieldProp.Label];
    const fileDownloadEvent = fieldProps[FormFieldProp.FileDownloadEvent];

    let textFieldProps = {
        type: 'text',
        action: true,
        readOnly: true,
        error: false,
        value: value,
    };
    if (showLabel) {
        textFieldProps = { ...textFieldProps, label: label };
    }

    const onClick = () => {
        if (fileDownloadEvent) {
            actionDownload(fileDownloadEvent.action);
        }
    }

    const isButtonDisabled = () => {
        return _.isNull(fileDownloadEvent);
    }

    return (
        <SemanticForm.Input {...textFieldProps} action>
            <input />
            <Button disabled={isButtonDisabled()} icon color='green' onClick={onClick}>
                <Icon name='download' />
            </Button>
        </SemanticForm.Input>
    );
}

import actionTypes from "./actionTypes";
import { addMessage } from '../messageActions';
import { MESSAGE_SUCCESS } from '../../helpers/const';
import ioms from "../../apis/ioms";
import { updateSendToMagoAction } from '../gridPage/existingRecordActions';

export const submit = (submitUrl, postData) => async (dispatch, getState) => {
    try {
        await dispatch(toggleSpinner());

        const response = await ioms.post(submitUrl, postData, { withCredentials: true });
        const { success, message, sendToMagoAction } = response.data.result;

        if (success) {
            await dispatch(toggleSpinner());
            await dispatch(addMessage(MESSAGE_SUCCESS, {content: message, visible: true}));
            await dispatch(updateSendToMagoAction(sendToMagoAction));
            await dispatch(close());
        } else {
            await dispatch(toggleSpinner());
            await dispatch(updateError([message]));
        }
    } catch (error) {
        await dispatch(toggleSpinner());
        await dispatch(updateError([error.message]));
    }
}

export const open = (data) => {
    return {
        type: actionTypes.OPEN_LIST,
        payload: data
    };
};

export const close = () => {
    return {
        type: actionTypes.CLOSE_LIST,
    };
};

export const toggleStatus = (data) => {
    return {
        type: actionTypes.TOGGLE_STATUS_LIST,
        payload: data
    };
};

export const toggleShowHiddenElements = (data) => {
    return {
        type: actionTypes.TOGGLE_SHOW_HIDDEN_ELEMENTS,
        payload: data
    };
};

export const setStatusCheckedAll = () => {
    return {
        type: actionTypes.STATUS_CHECKED_ALL_LIST,
    };
};

export const setStatusUncheckedAll = () => {
    return {
        type: actionTypes.STATUS_UNCHECKED_ALL_LIST,
    };
};

export const toggleSpinner = () => {
    return {
        type: actionTypes.TOGGLE_SPINNER_LIST,
    }
}

export const updateError = (errors) => {
    return {
        type: actionTypes.UPDATE_ERROR_LIST,
        payload: errors
    }
}

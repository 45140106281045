import React from 'react';
import { Form as SemanticForm } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { FormFieldProp } from '../../../enum/apiResultProp/FormFieldProp';

export default ({ fieldProps, onValueChange, performDataValidation, onInputBlur }) => {
    const value = fieldProps[FormFieldProp.Value];
    const placeholder = fieldProps[FormFieldProp.Placeholder];
    const showLabel = fieldProps[FormFieldProp.ShowLabel];
    const label = fieldProps[FormFieldProp.Label];
    const hasError = fieldProps[FormFieldProp.HasError];
    const showMandatorySign = fieldProps[FormFieldProp.ShowMandatorySign];
    const isReadOnly = fieldProps[FormFieldProp.IsReadOnly];

    let dateProps = {
        style: { minWidth: '10em' },
        fluid: true,
        as: DateInput,
        duration: 0,
        closable: true,
        dateFormat: "YYYY-MM-DD",
        iconPosition: "left",
        icon: false,

        required: showMandatorySign,
        readOnly: isReadOnly,
        disabled: isReadOnly,
        error: hasError,
        placeholder: placeholder,
        value: value,
        onChange: async (e, { value }) => {
            await onValueChange({ value: value });
            performDataValidation({ value: value });
        },
        onBlur: () => onInputBlur({ value: value })
    };
    if (showLabel) {
        dateProps = { ...dateProps, label: label };
    }

    return (
        <SemanticForm.Input
            {...dateProps}
        />
    );
}

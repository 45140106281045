export default {
    FETCH_GRID_DATA: "FETCH_GRID_DATA",
    UPDATE_ON_PAGE: "UPDATE_ON_PAGE",
    CHANGE_PAGE: "CHANGE_PAGE",
    UPDATE_SORTABLE_HEADER_DIRECTION: "UPDATE_SORTABLE_HEADER_DIRECTION",

    CANCEL_FILTER: "CANCEL_FILTER",
    CLEAR_FILTERS: "CLEAR_FILTERS",
    ADD_FILTER: "ADD_FILTER",

    FILTER_FORM_SELECT_COLUMN: "FILTER_FORM_SELECT_COLUMN",
    FILTER_FORM_SELECT_COMPARATOR: "FILTER_FORM_SELECT_COMPARATOR",
    FILTER_FORM_UPDATE_INPUT: "FILTER_FORM_UPDATE_INPUT",
    FILTER_FORM_LOAD_FILTER_FOR_EDIT: "FILTER_FORM_LOAD_FILTER_FOR_EDIT",
    FILTER_FORM_CLEAR: "FILTER_FORM_CLEAR",
    EDIT_FILTER_VALUES: "EDIT_FILTER_VALUES",
    
    TOGGLE_FORM_VISIBILITY: "TOGGLE_FORM_VISIBILITY",
    
    TOGGLE_GRID_CHECKBOX: "TOGGLE_GRID_CHECKBOX",
    CHECK_ALL: "CHECK_ALL",
    UNCHECK_ALL: "UNCHECK_ALL",
    ON_VARIANT_CHANGE: "ON_VARIANT_CHANGE",
    
    CLEAR_STATE: "CLEAR_STATE",

    EXPORT_GRID_DATA: "EXPORT_GRID_DATA",
    AJAX_ACTION: "AJAX_ACTION",
    ADDITIONAL_ACTION_SPINNER_START: "ADDITIONAL_ACTION_SPINNER_START",
    ADDITIONAL_ACTION_SPINNER_STOP: "ADDITIONAL_ACTION_SPINNER_STOP",

    UPDATE_SEND_TO_MAGO_ACTION: "UPDATE_SEND_TO_MAGO_ACTION",
};
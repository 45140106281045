import React, { useRef } from 'react';
import { Form as SemanticForm } from 'semantic-ui-react';
import { FormFieldProp } from '../../../enum/apiResultProp/FormFieldProp';
import StandardButton from '../formButton/button/StandardButton';

export default ({ fieldProps, onValueChange, performDataValidation }) => {
    const fileInputRef = useRef(null);

    const value = fieldProps[FormFieldProp.Value];
    const placeholder = fieldProps[FormFieldProp.Placeholder];
    const showLabel = fieldProps[FormFieldProp.ShowLabel];
    const label = fieldProps[FormFieldProp.Label];
    const hasError = fieldProps[FormFieldProp.HasError];
    const showMandatorySign = fieldProps[FormFieldProp.ShowMandatorySign];
    const isReadOnly = fieldProps[FormFieldProp.IsReadOnly];
    const buttonProps = fieldProps[FormFieldProp.Button];

    //TMP Logic - after all places are OK in BE - delete this IF
    if (!buttonProps) {
        return null;
    }

    const renderHiddenFileField = () => {
        return (
            <input
                type='file'
                readOnly={isReadOnly}
                disabled={isReadOnly}
                onChange={async (e) => {
                    const file = e.target.files[0];
                    await onValueChange({ value: file });
                    performDataValidation({ value: file });
                }}
                style={{ display: 'none' }}
                ref={fileInputRef}
                id="actual-btn"
            />
        );
    }

    const openFileSelectionDialog = () => {
        fileInputRef.current.click();
    }

    const renderVisualFileField = () => {
        const visualFileFieldButton = <StandardButton onButtonClick={openFileSelectionDialog} buttonProps={buttonProps} />;
        let visualFileFieldProps = {
            disabled: isReadOnly,
            readOnly: true,
            value: value ? value.name : placeholder,
            actionPosition: 'left',
            action: visualFileFieldButton,
            error: hasError,
            onClick: openFileSelectionDialog
        };
        if (showLabel) {
            visualFileFieldProps = { ...visualFileFieldProps, ...{label: label, required: showMandatorySign} };
        }

        return <SemanticForm.Input {...visualFileFieldProps} />;
    }

    const field = (
        <SemanticForm.Field>
            {renderHiddenFileField()}
            {renderVisualFileField()}
        </SemanticForm.Field>
    );

    return field;
}

import React from 'react';
import _, { upperCase } from 'lodash';
import { Form as SemanticUIForm, Popup, Icon, Button, Message } from 'semantic-ui-react';
import { DateInput, DateTimeInput } from 'semantic-ui-calendar-react';

import { validateInput, concatEvent, ajaxUrlEvent, setOtherFiledValueOnDropdownSelectEvent, formulaEvent, getValue } from '../../helpers/functions';
import ButtonWithConfirmation from './ButtonWithConfirmation';
import { FORM_DATA_TYPES, BUTTON_TYPES } from '../../helpers/const';
import { STRINGS } from '../../helpers/strings';
import RichTextEditor from './RichTextEditor';

class Form extends React.Component {
    onInputBlur = (fieldName, fieldValue, validationRules) => {
        const [error, errorMessage] = validateInput(fieldValue, validationRules, this.props.fields);

        this.props.errorUpdate(fieldName, error, errorMessage);
    }

    onFormInputChangeTriggerEvent = async (event, fieldValue, fieldName) => {
        const eventType = event.type;

        if (eventType === 'CONCAT') {
            concatEvent(event, this.props.fields, this.props.onInputChange);
        } else if (eventType === 'SET_VALUE_FROM_OPTION_PROP') {
            setOtherFiledValueOnDropdownSelectEvent(event, this.props.fields, fieldValue, fieldName, this.onFormInputChange, this.onInputBlur);
        } else if (eventType === 'AJAX_WITH_URL_ACTION') {
            ajaxUrlEvent(event, this.props.fields, this.props.handleAjaxWitUrlAction, null, null, fieldName);
        } else if (eventType === 'FORMULA') {
            formulaEvent(event, this.props.fields, this.props.onInputChange);
        }
    }

    onFormInputChange = async (fieldName, fieldValue, events) => {
        await this.props.onInputChange(fieldName, fieldValue);

        if (events) {
            events.forEach(event => {
                this.onFormInputChangeTriggerEvent(event, fieldValue, fieldName);
            });
        }
    }

    renderErrorMessageGroup = (errorMessages) => {
        if (errorMessages.length > 0) {
            return (
                <div className="ui negative message" style={{ marginBottom: '10px' }}>
                    <ul className="list">
                        {errorMessages.map((message, index) => {
                            return <li key={index}>{message}</li>;
                        })}
                    </ul>
                </div>
            );
        }
    }

    renderWarningMessageGroup = (warningMessages, warningMessageHeader) => {
        if (warningMessages.length > 0) {
            return (
                <Message warning>
                    <Message.Header>{warningMessageHeader}</Message.Header>
                    <Message.List>
                        {warningMessages.map((message, index) => {
                            return <Message.Item key={index}>{message}</Message.Item>;
                        })}
                    </Message.List>
                </Message>
            );
        }
    }

    renderFields = () => {
        const { fieldGroups, fields } = this.props;

        return _.map(fieldGroups, (groupOfFields, groupName) => {
            let fieldsGroupErrorMessages = [];
            let fieldsGroupWarningMessages = [];
            let warningMessageTitle = '';

            return (
                <React.Fragment key={groupName}>
                    <SemanticUIForm.Group widths='equal'>
                        {
                            _.map(groupOfFields, fieldName => {
                                const field = fields[fieldName];
                                const { value } = field;

                                const { errorMessage, warningMessages, warningTitle } = field;
                                if (warningMessageTitle == '' && warningTitle != undefined) {
                                    warningMessageTitle = warningTitle;
                                }

                                if (errorMessage.length > 0) {
                                    fieldsGroupErrorMessages.push(field.errorMessage);
                                }

                                if (!_.isUndefined(warningMessages) && warningMessages.length > 0) {
                                    _.merge(fieldsGroupWarningMessages, warningMessages);
                                }

                                switch (field.dataType) {
                                    case FORM_DATA_TYPES.DOWNLOAD_URL_LOOKING_LIKE_BUTTON:
                                        return (
                                            <Button
                                                key={fieldName}
                                                positive
                                                icon
                                                labelPosition='left'
                                                onClick={() => this.props.downloadFileByPath(
                                                    field.downloadUrl, field.serverFilePath, field.downloadedFileName
                                                )}
                                            >
                                                <Icon name='download' />
                                                {field.value}
                                            </Button>
                                        );

                                    case FORM_DATA_TYPES.INPUT_FIELD_FILE:
                                        return this.renderInputFieldFile(fieldName, field);

                                    case FORM_DATA_TYPES.INPUT_FIELD_RICH_TEXT_EDITOR:
                                        return (
                                            <SemanticUIForm.Input
                                                key={groupName + fieldName}
                                                as={RichTextEditor}
                                                value={value}
                                                setValue={(value) => { this.onFormInputChange(fieldName, value) }}
                                            />
                                        );

                                    case FORM_DATA_TYPES.INPUT_FIELD_STRING:
                                        return this.renderInputFieldString(fieldName, field, "text", field.event);

                                    case FORM_DATA_TYPES.INPUT_FIELD_STRING_ONLY_UPPERCASE:
                                        return this.renderInputFieldString(fieldName, field, "text", field.event, true);

                                    case FORM_DATA_TYPES.INPUT_FIELD_NUMERIC:
                                        return this.renderInputFieldString(fieldName, field, "number", field.event);

                                    case FORM_DATA_TYPES.INPUT_FIELD_EMAIL:
                                        return this.renderInputFieldString(fieldName, field, "mail", field.event);

                                    case FORM_DATA_TYPES.INPUT_FIELD_PASSWORD:
                                        return this.renderInputFieldString(fieldName, field, "password", field.event);

                                    case FORM_DATA_TYPES.CHECKBOX:
                                        return this.renderCheckbox(fieldName, field, field.event);

                                    case FORM_DATA_TYPES.DROPDOWN:
                                        return this.renderDropdown(fieldName, field, field.event, field.options);

                                    case FORM_DATA_TYPES.DROPDOWN_MULTI:
                                        return this.renderDropdownMulti(fieldName, field, field.event, field.options);

                                    case FORM_DATA_TYPES.DROPDOWN_AUTOCOMPLETE:
                                        return this.renderAutocompleteDropdown(fieldName, field, field.event, field.options);

                                    case FORM_DATA_TYPES.DROPDOWN_FILTER_VALUES:
                                        return this.renderDropdown(fieldName, field, field.event, field.filteredOptions);

                                    case FORM_DATA_TYPES.TEXTAREA:
                                        return this.renderTextArea(fieldName, field);

                                    case FORM_DATA_TYPES.DATE:
                                        return this.renderDateInput(fieldName, field);

                                    case FORM_DATA_TYPES.DATE_TIME:
                                        return this.renderDateTimeInput(fieldName, field);

                                    case FORM_DATA_TYPES.READ:
                                        return (
                                            <React.Fragment key={fieldName}>
                                                <h2 style={{ marginTop: '2%' }}>=</h2>
                                            </React.Fragment>
                                        );

                                    case FORM_DATA_TYPES.SKIP:
                                        return null;

                                    default:
                                        return this.renderInputFieldString(fieldName, field, "text");
                                }
                            })
                        }

                        {this.renderActions()}
                    </SemanticUIForm.Group>

                    {this.renderErrorMessageGroup(fieldsGroupErrorMessages)}
                    {this.renderWarningMessageGroup(fieldsGroupWarningMessages, warningMessageTitle)}

                </React.Fragment>
            );
        });
    }

    renderInputFieldFile = (fieldName, field) => {
        return (
            <React.Fragment key={fieldName}>
                <SemanticUIForm.Input
                    type="file"
                    required={field.required[this.props.mode]}
                    disabled={field.readOnly[this.props.mode]}
                    label={field.label}
                    onChange={e => {
                        this.onFormInputChange(fieldName, e.target.files[0]);

                        this.onInputBlur(fieldName, e.target.files[0], field.validationRules, field.error);
                    }}
                />
            </React.Fragment>
        );
    }

    renderActions = () => {
        if (!this.props.actions) {
            return null;
        }

        return this.props.actions.map(action => {
            switch (action) {
                case BUTTON_TYPES.DELETE:

                    return this.renderDeleteButton();
                case BUTTON_TYPES.CANCEL_CREATE:

                    return this.renderCancelCreateButton();
                default:

                    return null;
            }
        })
    }

    renderDeleteButton = () => {
        return (
            <React.Fragment key={this.props.rowKey}>
                <ButtonWithConfirmation
                    translations={this.props.translations}
                    rowId={this.props.rowKey}
                    question={getValue(this.props.translations, STRINGS.BUTTON_DELETE_CONFIRM_QUESTION)}
                    buttonConfirmTitle='X'
                    actionConfirm={this.props.onDeleteClick}
                />
            </React.Fragment>
        )
    }

    renderCancelCreateButton = () => {
        return (
            <React.Fragment key={this.props.rowKey}>
                <ButtonWithConfirmation
                    translations={this.props.translations}
                    rowId={this.props.rowKey}
                    question={getValue(this.props.translations, STRINGS.BUTTON_CANCEL_CONFIRM_QUESTION)}
                    buttonConfirmTitle='X'
                    actionConfirm={this.props.onCancelCreateClick}
                />
            </React.Fragment>
        )
    }

    renderInputFieldString = (fieldName, field, type, event, makeInputUppercase = false) => {
        let value = field.value;
        let placeholder = field.placeholder;

        let result = '';
        if (field.oldValue !== undefined) {
            result = (
                <React.Fragment key={fieldName}>
                    <Popup
                        trigger={
                            <SemanticUIForm.Input
                                type={type}
                                label={field.label}
                                className={"changedValue"}
                                value={value}
                                icon={<Icon name='exclamation triangle' inverted color="red" circular />}
                            />
                        }
                        content={getValue(this.props.translations, "Old Value: ") + field.oldValue}
                        size='small'
                    />
                </React.Fragment>
            );
        } else {
            result = (
                <React.Fragment key={fieldName}>
                    <SemanticUIForm.Input
                        required={field.required[this.props.mode]}
                        disabled={field.readOnly[this.props.mode]}
                        type={type}
                        label={field.label}
                        placeholder={placeholder}
                        value={value}
                        error={field.error}
                        autoComplete="new-password"
                        onChange={e => { this.onFormInputChange(fieldName, e.target.value, event) }}
                        onBlur={
                            async () => {
                                let newValue = field.value;
                                if (makeInputUppercase && field.value) {
                                    newValue = field.value.toUpperCase();
                                    await this.onFormInputChange(fieldName, newValue, event);
                                }

                                this.onInputBlur(fieldName, newValue, field.validationRules, field.error);
                            }
                        }
                    />
                </React.Fragment>
            );
        }

        return result;
    }

    renderCheckbox = (fieldName, field, event) => {
        const defaultChecked = field.value ? true : false;

        return (
            <SemanticUIForm.Group key={fieldName} inline style={{ paddingTop: '2em' }}>
                <SemanticUIForm.Checkbox
                    slider
                    label={field.label}

                    defaultChecked={defaultChecked}
                    disabled={field.readOnly[this.props.mode]}
                    required={field.required[this.props.mode]}
                    readOnly={field.readOnly[this.props.mode]}
                    onChange={(e) => { this.onFormInputChange(fieldName, !field.value, event) }}
                />
            </SemanticUIForm.Group>
        );
    }

    renderDropdownMulti = (fieldName, field, event, options) => {
        options = _.isEmpty(options) ? [{ key: '', value: '', text: '' }] : options;

        return (
            <React.Fragment key={fieldName}>
                <SemanticUIForm.Select
                    multiple selection
                    options={options}
                    required={field.required[this.props.mode]}
                    readOnly={field.readOnly[this.props.mode]}
                    disabled={field.readOnly[this.props.mode]}
                    error={field.error}
                    label={field.label}
                    placeholder={field.placeholder}
                    value={field.value}
                    onChange={(e, data) => { this.onFormInputChange(fieldName, data.value, event) }}
                    onBlur={() => { this.onInputBlur(fieldName, field.value, field.validationRules, field.error) }}
                    selectOnBlur={false}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                        }
                    }}
                />
            </React.Fragment>
        )
    }

    onChangeDropdown = (name, value, event, validationRules, error) => {
        this.onFormInputChange(name, value, event);
        this.onInputBlur(name, value, validationRules, error);
    }

    renderDropdown = (fieldName, field, event, options) => {
        options = _.isEmpty(options) ? [{ key: '', value: '', text: '' }] : options;
        const value = field.value ? field.value : field.defaultValue;

        return (
            <React.Fragment key={fieldName}>
                <SemanticUIForm.Select
                    options={options}
                    required={field.required[this.props.mode]}
                    readOnly={field.readOnly[this.props.mode]}
                    disabled={field.readOnly[this.props.mode]}
                    error={field.error}
                    label={field.label}
                    placeholder={field.placeholder}
                    value={value}
                    onChange={(e, data) => { this.onChangeDropdown(fieldName, data.value, event, field.validationRules, field.error) }}
                    selectOnBlur={false}
                />
            </React.Fragment>
        )
    }

    renderAutocompleteDropdown = (fieldName, field, event, options) => {
        return (
            <React.Fragment key={fieldName}>
                <SemanticUIForm.Select
                    search
                    selection
                    options={options}
                    required={field.required[this.props.mode]}
                    readOnly={field.readOnly[this.props.mode]}
                    disabled={field.readOnly[this.props.mode]}
                    error={field.error}
                    label={field.label}
                    placeholder={field.placeholder}
                    value={field.value}
                    onChange={(e, { value }) => { this.onChangeDropdown(fieldName, value, event, field.validationRules, field.error) }}
                    selectOnBlur={false}
                />
            </React.Fragment>
        )
    }

    renderTextArea = (fieldName, field) => {
        return (
            <React.Fragment key={fieldName}>
                <SemanticUIForm.TextArea
                    required={field.required[this.props.mode]}
                    readOnly={field.readOnly[this.props.mode]}
                    error={field.error}
                    label={field.label}
                    rows={1}
                    placeholder={field.placeholder}
                    value={field.value}
                    onChange={(e) => { this.onFormInputChange(fieldName, e.target.value) }}
                    onBlur={() => { this.onInputBlur(fieldName, field.value, field.validationRules, field.error) }}
                />
            </React.Fragment>
        );
    }

    renderDateInput = (fieldName, field) => {
        const dateValue = field.value ? field.value : field.defaultValue;

        return (
            <React.Fragment key={fieldName} >
                <SemanticUIForm.Input
                    as={DateInput}
                    duration={0}
                    closable={true}
                    required={field.required[this.props.mode]}
                    readOnly={field.readOnly[this.props.mode]}
                    error={field.error}
                    label={field.label}
                    dateFormat="YYYY-MM-DD"
                    placeholder={field.placeholder}
                    value={dateValue}
                    iconPosition="left"
                    icon={false}
                    onChange={(event, { value }) => { this.onFormInputChange(fieldName, value) }}
                    onBlur={() => { this.onInputBlur(fieldName, field.value, field.validationRules, field.error) }}
                />

            </React.Fragment>
        )
    }

    renderDateTimeInput = (fieldName, field) => {
        const dateValue = field.value ? field.value : field.defaultValue;

        return (
            <React.Fragment key={fieldName} >
                <SemanticUIForm.Input
                    as={DateTimeInput}
                    duration={0}
                    closable={true}
                    required={field.required[this.props.mode]}
                    readOnly={field.readOnly[this.props.mode]}
                    error={field.error}
                    label={field.label}
                    dateFormat="YYYY-MM-DD"
                    placeholder={field.placeholder}
                    value={dateValue}
                    iconPosition="left"
                    icon={false}
                    onChange={(event, { value }) => { this.onFormInputChange(fieldName, value) }}
                    onBlur={() => { this.onInputBlur(fieldName, field.value, field.validationRules, field.error) }}
                />

            </React.Fragment>
        );
    }

    render = () => {
        return (
            <SemanticUIForm loading={false} error warning>
                {this.renderFields()}
            </SemanticUIForm>
        );
    }
}

export default Form;

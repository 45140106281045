export const DataValidationRuleType = Object.freeze({
    Required: 'REQUIRED',

    // numeruc rules
    NumericMinValue: 'MIN_VALUE',
    NumericMaxValue: 'MAX_VALUE',
    NumericMinOrEqualValue: 'MIN_EQUAL_VALUE',
    NumericMaxOrEqualValue: 'MAX_EQUAL_VALUE',
    FloatMaxPrecision: 'FLOAT_MAX_PRECISION',
    OnlyIntegerValue: 'INT_VALUE',

    // string rules
    StringMinLength: 'MIN_LENGTH',
    StringMaxLength: 'MAX_LENGTH',
    StringNotAllowedSymbolsRegex: 'REGEX',

});

import _ from "lodash";
import DOMPurify from 'dompurify';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import ioms from "../../apis/ioms";
import newScreenActionTypes from "./newScreenActionTypes";
import { ROUTES } from '../../helpers/routes';
import { addMessage } from '../messageActions';
import { MESSAGE_ERROR, MESSAGE_SUCCESS } from '../../helpers/const';
import history from '../../history';
import fileSaver from '../../fileSaver';
import { ScreenComponentProp } from "../../enum/apiResultProp/ScreenComponentProp";
import { TableFormDataRowProp } from "../../enum/apiResultProp/TableFormDataRowProp";
import { TableFormCellProp } from "../../enum/apiResultProp/TableFormCellProp";
import { ScreenComponentType } from "../../enum/ScreenComponentType";
import { TableFormCellType } from "../../enum/TableFormCellType";
import { FormProp } from "../../enum/apiResultProp/FormProp";
import { FormFieldProp } from "../../enum/apiResultProp/FormFieldProp";
import { DataType } from "../../enum/DataType";
import { ScreenProp } from "../../enum/apiResultProp/ScreenProp";
import { TableFormProp } from "../../enum/apiResultProp/TableFormProp";
import { TreeProp } from "../../enum/apiResultProp/TreeProp";
import { ButtonOptionsFormProp } from "../../enum/apiResultProp/buttonOptionsForm/ButtonOptionsFormProp";
import { ButtonOptionsFormFieldGroupProp } from "../../enum/apiResultProp/buttonOptionsForm/ButtonOptionsFormFieldGroupProp";
import { ButtonOptionsFormFieldProp } from "../../enum/apiResultProp/buttonOptionsForm/ButtonOptionsFormFieldProp";
import { ModalFormProp } from "../../enum/apiResultProp/ModalFormProp";
import { buildDataGridPost } from "./dataGridActions";

export const fetchScreenData = (backEndUrl, postFormData) => async (dispatch) => {
    try {
        const response = await ioms.post(backEndUrl, postFormData, { withCredentials: true });
        const responseData = response.data;

        const { success, result, messages, sessionExpired, closedUserLog, permission, shouldChangePassword, shouldAcceptTermsAndConditions } = responseData;

        if (success) {
            dispatch({
                type: newScreenActionTypes.NEW_SCREEN_FETCH,
                payload: result
            });
        } else {
            let route;
            let shouldAddErrorMessage = true;
            if (sessionExpired) {
                route = ROUTES.LOGIN_ROUTE.URL;
            } else if (closedUserLog) {
                route = ROUTES.LOGIN_ROUTE.URL;
            } else if (permission) {
                route = ROUTES.HOME_SCREEN_ROUTE.URL;
            } else if (shouldChangePassword) {
                route = ROUTES.EDIT_PASSWORD_ROUTE.URL;
            } else if (shouldAcceptTermsAndConditions) {
                route = ROUTES.TERMS_AND_CONDITIONS_DECISION_SCREEN.URL;
                shouldAddErrorMessage = false;
            }

            if (shouldAddErrorMessage) {
                dispatch(addMessage(MESSAGE_ERROR, { content: messages[0].text, visible: true }));
            }

            history.push(route);
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
};

export const onFieldChangeMutateComponentWithPostData = ({ backEndUrl, collectDataFromComponentName }) => async (dispatch, getState) => {
    const screenPostData = buildScreenPost(getState().screenData[ScreenProp.Components], [collectDataFromComponentName]);

    try {
        const response = await ioms.post(backEndUrl, screenPostData, { withCredentials: true });
        if (response.data.success) {
            dispatch({
                type: newScreenActionTypes.NEW_SCREEN_ACTION_MUTATE_COMPONENT,
                payload: response.data.result
            })
        } else {
            if (response.data.messages) {
                let message = response.data.messages[0].text;
                await dispatch(addMessage(MESSAGE_ERROR, { content: message, visible: true }));
            }
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
}

export const onFieldChangeMutateScreenWithPostData = ({ backEndUrl }) => async (dispatch, getState) => {
    const screenPostData = buildScreenPost(getState().screenData[ScreenProp.Components]);

    try {
        const response = await ioms.post(backEndUrl, screenPostData, { withCredentials: true });
        if (response.data.success) {
            dispatch({
                type: newScreenActionTypes.NEW_SCREEN_ACTION_MUTATE_SCREEN,
                payload: response.data.result
            })
        } else {
            if (response.data.messages) {
                let message = response.data.messages[0].text;
                await dispatch(addMessage(MESSAGE_ERROR, { content: message, visible: true }));
            }
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
}

export const onFieldChangeMutateScreenNoPostData = ({ backEndUrl }) => async (dispatch) => {
    try {
        const response = await ioms.get(backEndUrl, { withCredentials: true });
        if (response.data.success) {
            dispatch({
                type: newScreenActionTypes.NEW_SCREEN_ACTION_MUTATE_SCREEN,
                payload: response.data.result
            })
        } else {
            if (response.data.messages) {
                let message = response.data.messages[0].text;
                await dispatch(addMessage(MESSAGE_ERROR, { content: message, visible: true }));
            }
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
}

export const onButtonClickMutateScreenWithPostData = ({ backEndUrl, buttonName, disableOnActionRequest, enableOnActionResponse, componentName, componentType }) => async (dispatch, getState) => {
    const screenPostData = buildScreenPost(getState().screenData[ScreenProp.Components]);

    dispatch(startButtonSpinner({ buttonName, componentName, componentType }));
    if (disableOnActionRequest) {
        dispatch(disableButton({ buttonName, componentName, componentType }));
    }

    try {
        const response = await ioms.post(backEndUrl, screenPostData, { withCredentials: true });
        if (response.data.success) {
            dispatch({
                type: newScreenActionTypes.NEW_SCREEN_ACTION_MUTATE_SCREEN,
                payload: response.data.result
            })
        } else {
            if (response.data.messages) {
                let message = response.data.messages[0].text;
                await dispatch(addMessage(MESSAGE_ERROR, { content: message, visible: true }));
            }
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }

    if (enableOnActionResponse) {
        dispatch(enableButton({ buttonName, componentName, componentType }));
    }
    dispatch(stopButtonSpinner({ buttonName, componentName, componentType }));
}

export const onButtonClickMutateComponentWithPostData = ({ backEndUrl, buttonName, disableOnActionRequest, enableOnActionResponse, componentName, componentType, collectDataFromComponentName }) => async (dispatch, getState) => {
    const screenPostData = buildScreenPost(getState().screenData[ScreenProp.Components], [collectDataFromComponentName]);

    dispatch(startButtonSpinner({ buttonName, componentName, componentType }));
    if (disableOnActionRequest) {
        dispatch(disableButton({ buttonName, componentName, componentType }));
    }

    try {
        const response = await ioms.post(backEndUrl, screenPostData, { withCredentials: true });
        if (response.data.success) {
            await dispatch({
                type: newScreenActionTypes.NEW_SCREEN_ACTION_MUTATE_COMPONENT,
                payload: response.data.result
            })

            const { shouldDownloadFile, downloadFileName, downloadUrl } = response.data.result;
            if (shouldDownloadFile) {
                const { data } = await ioms.get(downloadUrl, { withCredentials: true, responseType: 'blob' });
                fileSaver(data, downloadFileName);
            }
        } else {
            if (response.data.messages) {
                let message = response.data.messages[0].text;
                await dispatch(addMessage(MESSAGE_ERROR, { content: message, visible: true }));
            }
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }

    if (enableOnActionResponse) {
        dispatch(enableButton({ buttonName, componentName, componentType }));
    }
    dispatch(stopButtonSpinner({ buttonName, componentName, componentType }));
}

export const downloadFile = ({ backEndUrl, fileNameOnDownload }) => async (dispatch, getState) => {
    const { data } = await ioms.get(backEndUrl, { withCredentials: true, responseType: 'blob' });
    fileSaver(data, fileNameOnDownload);
}

export const onButtonClickMutateComponentNoPostData = ({ backEndUrl, buttonName, disableOnActionRequest, enableOnActionResponse, componentName, componentType }) => async (dispatch) => {
    if (disableOnActionRequest) {
        dispatch(startButtonSpinner({ buttonName, componentName, componentType }));
    }

    try {
        const response = await ioms.get(backEndUrl, { withCredentials: true });
        if (response.data.success) {
            dispatch({
                type: newScreenActionTypes.NEW_SCREEN_ACTION_MUTATE_COMPONENT,
                payload: response.data.result
            })
        } else {
            if (response.data.messages) {
                let message = response.data.messages[0].text;
                await dispatch(addMessage(MESSAGE_ERROR, { content: message, visible: true }));
            }
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }

    if (enableOnActionResponse) {
        dispatch(stopButtonSpinner({ buttonName, componentName, componentType }));
    }
}

export const onButtonClickMutateScreenNoPostData = ({ backEndUrl, buttonName, disableOnActionRequest, enableOnActionResponse, componentName, componentType }) => async (dispatch) => {
    if (disableOnActionRequest) {
        dispatch(startButtonSpinner({ buttonName, componentName, componentType }));
    }

    try {
        const response = await ioms.get(backEndUrl, { withCredentials: true });
        if (response.data.success) {
            dispatch({
                type: newScreenActionTypes.NEW_SCREEN_ACTION_MUTATE_SCREEN,
                payload: response.data.result
            })
        } else {
            if (response.data.messages) {
                let message = response.data.messages[0].text;
                await dispatch(addMessage(MESSAGE_ERROR, { content: message, visible: true }));
            }
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }

    if (enableOnActionResponse) {
        dispatch(stopButtonSpinner({ buttonName, componentName, componentType }));
    }
}

export const postDataAndRedirect = ({ backEndUrl, buttonName, disableOnActionRequest, enableOnActionResponse, makeBackEndCallOnlyIfDataIsValid, componentName, componentType }) => async (dispatch, getState) => {
    const screenPostData = buildScreenPost(getState().screenData[ScreenProp.Components]);

    dispatch(startButtonSpinner({ buttonName, componentName, componentType }));
    if (disableOnActionRequest) {
        dispatch(disableButton({ buttonName, componentName, componentType }));
    }

    try {
        const response = await ioms.post(backEndUrl, screenPostData, { withCredentials: true });

        if (response.data.success) {
            const { routeName, routeParams, successMessage } = response.data.result.redirect;

            await dispatch(addMessage(MESSAGE_SUCCESS, { content: successMessage, visible: true }));
            if (_.has(ROUTES, routeName)) {
                let nextRoute = ROUTES[routeName].URL;

                if (routeParams) {
                    _.forEach(routeParams, (paramValue, paramName) => {
                        nextRoute = nextRoute.replace(':' + paramName, paramValue);
                    });
                }

                history.push(nextRoute);
            } else {
                await dispatch(addMessage(MESSAGE_ERROR, { content: 'Invalid Redirect Route! Call to administrator', visible: true }));
            }
        } else {
            if (response.data.messages) {
                let message = response.data.messages[0].text;
                await dispatch(addMessage(MESSAGE_ERROR, { content: message, visible: true }));
            }
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }

    if (enableOnActionResponse) {
        dispatch(enableButton({ buttonName, componentName, componentType }));
    }
    dispatch(stopButtonSpinner({ buttonName, componentName, componentType }));
}

export const getRequestAndRedirectExternalLink = ({ backEndUrl, buttonName, disableOnActionRequest, enableOnActionResponse, componentName, componentType }) => async (dispatch, getState) => {
    dispatch(startButtonSpinner({ buttonName, componentName, componentType }));
    if (disableOnActionRequest) {
        dispatch(disableButton({ buttonName, componentName, componentType }));
    }

    try {
        const response = await ioms.get(backEndUrl, { withCredentials: true });

        if (response.data.success) {
            const { result, message, externalUrl } = response.data.result;

            if (result) {
                // dispatch(enableButton({ buttonName, componentName, componentType }));
                dispatch(stopButtonSpinner({ buttonName, componentName, componentType }));

                window.location.href = externalUrl;
            } else {
                await dispatch(addMessage(MESSAGE_ERROR, { content: message, visible: true }));
            }
        } else {
            if (response.data.messages) {
                let message = response.data.messages[0].text;
                await dispatch(addMessage(MESSAGE_ERROR, { content: message, visible: true }));
            }
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }

    if (enableOnActionResponse) {
        dispatch(enableButton({ buttonName, componentName, componentType }));
    }
    dispatch(stopButtonSpinner({ buttonName, componentName, componentType }));
}

export const submitForm = ({ backEndUrl, buttonName, disableOnActionRequest, enableOnActionResponse, componentName, componentType }) => async (dispatch, getState) => {
    const screenPostData = buildScreenPost(getState().screenData[ScreenProp.Components]);

    if (disableOnActionRequest) {
        dispatch(startButtonSpinner({ buttonName, componentName, componentType }));
        dispatch(disableAllComponentButtons({ componentName, componentType, skipButtonNames: [buttonName] }));
    }

    try {
        const response = await ioms.post(backEndUrl, screenPostData, { withCredentials: true });
        const { routeName, routeParams, message, success } = response.data.result;
        if (success) {
            let nextRoute = ROUTES.HOME_SCREEN_ROUTE.URL;
            if (ROUTES[routeName]) {
                nextRoute = ROUTES[routeName].URL;

                if (routeParams) {
                    _.forEach(routeParams, (paramValue, paramName) => {
                        nextRoute = nextRoute.replace(':' + paramName, paramValue);
                    });
                }
            }

            history.push(nextRoute);
            await dispatch(addMessage(MESSAGE_SUCCESS, { content: message, visible: true }));
        } else {
            await dispatch(addMessage(MESSAGE_ERROR, { content: message, visible: true }));
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }

    if (enableOnActionResponse) {
        dispatch(stopButtonSpinner({ buttonName, componentName, componentType }));
        dispatch(enableAllComponentButtons({ componentName, componentType, skipButtonNames: [buttonName] }));
    }
}

export const actionDownload = ({ backEndUrl, fileNameOnDownload }) => async (dispatch) => {
    try {
        const { data } = await ioms.get(backEndUrl, { withCredentials: true, responseType: 'blob' });
        fileSaver(data, fileNameOnDownload);
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
}

export const formFieldValueChange = ({ componentName, rowKey, fieldName, propsChanged, componentType }) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_FORM_FIELD_VALUE_CHANGE,
        payload: { componentName, rowKey, fieldName, propsChanged, componentType }
    }
}

export const formFieldErrorChange = ({ componentName, rowKey, fieldName, hasError, errorMessage, componentType }) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_FORM_FIELD_ERROR_CHANGE,
        payload: { componentName, rowKey, fieldName, hasError, errorMessage, componentType }
    }
}

export const openModalForm = ({ modalFormComponentName }) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_MODAL_FORM_OPEN,
        payload: { modalFormComponentName }
    }
}

export const clear = () => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_CLEAR,
    }
}

export const startSpinner = () => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_START_SPINNER,
    }
}

export const stopSpinner = () => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_STOP_SPINNER,
    }
}

export const startButtonSpinner = ({ buttonName, componentName, componentType }) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_START_BUTTON_SPINNER,
        payload: { buttonName, componentName, componentType }
    }
}

export const stopButtonSpinner = ({ buttonName, componentName, componentType }) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_STOP_BUTTON_SPINNER,
        payload: { buttonName, componentName, componentType }
    }
}

export const infoPanelHide = ({ componentName }) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_INFO_PANEL_HIDE,
        payload: { componentName }
    }
}

export const enableButton = ({ buttonName, componentName, componentType }) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_ENABLE_BUTTON,
        payload: { buttonName, componentName, componentType }
    }
}

export const disableButton = ({ buttonName, componentName, componentType }) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_DISABLE_BUTTON,
        payload: { buttonName, componentName, componentType }
    }
}

const disableAllComponentButtons = ({ componentName, componentType, skipButtonNames }) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_DISABLE_ALL_COMPONENT_BUTTONS,
        payload: { componentName, componentType, skipButtonNames }
    }
}

const enableAllComponentButtons = ({ componentName, componentType, skipButtonNames }) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_ENABLE_ALL_COMPONENT_BUTTONS,
        payload: { componentName, componentType, skipButtonNames }
    }
}

export const tableFormFieldValueChange = ({ componentName, rowKey, cellKey, propsChanged, componentType }) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_TABLE_FORM_FIELD_VALUE_CHANGE,
        payload: { componentName, rowKey, cellKey, propsChanged, componentType }
    }
}

export const tableFormFieldErrorChange = ({ componentName, rowKey, cellKey, hasError, errorMessage, componentType }) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_TABLE_FORM_FIELD_ERROR_CHANGE,
        payload: { componentName, rowKey, cellKey, hasError, errorMessage, componentType }
    }
}

const buildScreenPost = (components, componentNamesToGet = []) => {
    const formData = new FormData();
    const formDataDebugAssoc = {};

    //If filter is not passed
    if (!_.isArray(componentNamesToGet) || componentNamesToGet.length === 0) {
        componentNamesToGet = _.keys(components);
    }

    _.forEach(components, (screenComponentProps, componentName) => {
        if (_.includes(componentNamesToGet, componentName)) {
            const componentType = screenComponentProps[ScreenComponentProp.ScreenComponentType];

            if (componentType === ScreenComponentType.TreeWithSidePanel) {
                //Tree
                const selectedElementUniqueId = screenComponentProps[TreeProp.SelectedElementUniqueId];
                const formDataKey = `${componentName}[selectedElementUniqueId]`;
                formData.append(formDataKey, selectedElementUniqueId);
                formDataDebugAssoc[formDataKey] = selectedElementUniqueId;

                //Button Form
                const fieldGroups = screenComponentProps[ButtonOptionsFormProp.FieldGroups];
                if (fieldGroups) {
                    formData.append(`${componentName}[buttonFormIsSet]`, 1);

                    _.forEach(fieldGroups, group => {
                        const fields = group[ButtonOptionsFormFieldGroupProp.ButtonOptionFields];
                        _.forEach(fields, field => {
                            const fieldName = field[ButtonOptionsFormFieldProp.Name];
                            const fieldValue = field[ButtonOptionsFormFieldProp.Value];
                            const fieldDataType = field[ButtonOptionsFormFieldProp.DataType];

                            const formDataKey = `${componentName}[${fieldName}]`;
                            const fileKey = null;
                            addFieldValueToPostData(formData, formDataDebugAssoc, formDataKey, fileKey, fieldDataType, fieldValue);
                        })
                    });
                } else {
                    formData.append(`${componentName}[buttonFormIsSet]`, 0);
                }
            } else if (componentType === ScreenComponentType.Tree) {
                const selectedElementUniqueId = screenComponentProps[TreeProp.SelectedElementUniqueId];
                const formDataKey = `${componentName}[selectedElementUniqueId]`;
                formData.append(formDataKey, selectedElementUniqueId);
                formDataDebugAssoc[formDataKey] = selectedElementUniqueId;
            } else if (componentType === ScreenComponentType.Form) {
                const formRows = screenComponentProps[FormProp.Fields];

                _.forEach(formRows, rowFields => {
                    _.forEach(rowFields, (fieldProps, fieldName) => {
                        const fieldValue = fieldProps[FormFieldProp.Value];
                        const fieldDataType = fieldProps[FormFieldProp.DataType];

                        const formDataKey = `${componentName}[${fieldName}]`;
                        const fileKey = `${componentName}[${fieldName}]`;
                        addFieldValueToPostData(formData, formDataDebugAssoc, formDataKey, fileKey, fieldDataType, fieldValue);
                    });
                })
            } else if (componentType === ScreenComponentType.ModalForm) {
                const formRows = screenComponentProps[ModalFormProp.Fields];

                _.forEach(formRows, rowFields => {
                    _.forEach(rowFields, (fieldProps, fieldName) => {
                        const fieldValue = fieldProps[FormFieldProp.Value];
                        const fieldDataType = fieldProps[FormFieldProp.DataType];

                        const formDataKey = `${componentName}[${fieldName}]`;
                        const fileKey = `${componentName}[${fieldName}]`;
                        addFieldValueToPostData(formData, formDataDebugAssoc, formDataKey, fileKey, fieldDataType, fieldValue);
                    });
                })
            } else if (componentType === ScreenComponentType.TableForm) {
                const dataRows = screenComponentProps[TableFormProp.TableFormDataRows];
                _.forEach(dataRows, (dataRow, rowKey) => {
                    const cells = dataRow[TableFormDataRowProp.Cells];
                    const mode = dataRow[TableFormDataRowProp.Mode];
                    const uniqueRowId = dataRow[TableFormDataRowProp.UniqueRowId];
                    const rowNumber = dataRow[TableFormDataRowProp.RowNumber];

                    const tableFormDataModeKey = `${componentName}[${rowKey}][${TableFormDataRowProp.Mode}]`;
                    formData.append(tableFormDataModeKey, mode);
                    formDataDebugAssoc[tableFormDataModeKey] = mode;

                    const tableFormUniqueRowIdKey = `${componentName}[${rowKey}][${TableFormDataRowProp.UniqueRowId}]`;
                    formData.append(tableFormUniqueRowIdKey, uniqueRowId);
                    formDataDebugAssoc[tableFormUniqueRowIdKey] = uniqueRowId;

                    const tableFormRowNumberKey = `${componentName}[${rowKey}][${TableFormDataRowProp.RowNumber}]`;
                    formData.append(tableFormRowNumberKey, rowNumber);
                    formDataDebugAssoc[tableFormRowNumberKey] = rowNumber;

                    _.forEach(cells, cell => {
                        const cellType = cell[TableFormCellProp.CellType];
                        const textCellName = cell[TableFormCellProp.ColumnName];

                        if (cellType === TableFormCellType.InputField) {
                            const cellFieldProps = cell[TableFormCellProp.FieldProps];
                            const cellFieldName = cellFieldProps[FormFieldProp.Name];
                            const cellFieldValue = cellFieldProps[FormFieldProp.Value];
                            const cellFieldDataType = cellFieldProps[FormFieldProp.DataType];

                            const tableFormDataFieldKey = `${componentName}[${rowKey}][${'data'}][${cellFieldName}]`;
                            const tableFormDataFileKey = `${componentName}[${rowKey}][${cellFieldName}]`;
                            addFieldValueToPostData(formData, formDataDebugAssoc, tableFormDataFieldKey, tableFormDataFileKey, cellFieldDataType, cellFieldValue);
                        } else if (cellType === TableFormCellType.Text) {
                            const textCellValue = cell[TableFormCellProp.Text];

                            formData.append(`${componentName}[${rowKey}][${'data'}][${textCellName}]`, textCellValue);
                            formDataDebugAssoc[textCellName] = textCellValue;
                        } else if (cellType === TableFormCellType.BoolFlag) {
                            const boolFlag = cell[TableFormCellProp.Flag];

                            formData.append(`${componentName}[${rowKey}][${'data'}][${textCellName}]`, boolFlag);
                            formDataDebugAssoc[textCellName] = boolFlag;
                        } else if (cellType === TableFormCellType.DownloadLink) {
                            const linkText = cell[TableFormCellProp.Text];

                            formData.append(`${componentName}[${rowKey}][${'data'}][${textCellName}]`, linkText);
                            formDataDebugAssoc[textCellName] = linkText;
                        }
                    })
                });
            } else if (componentType === ScreenComponentType.DataGrid) {
                buildDataGridPost({ formData, dataGridProps: screenComponentProps });
            }
        }
    });

    return formData;
}

export const reloadScreenWithPostDataOnButtonClick = ({ backEndUrl, buttonName, componentName, componentType, collectDataFromComponentName, showMessages = false }) => async (dispatch, getState) => {
    let screenPostData;
    if (collectDataFromComponentName) {
        screenPostData = buildScreenPost(getState().screenData[ScreenProp.Components], [collectDataFromComponentName]);
    } else {
        screenPostData = buildScreenPost(getState().screenData[ScreenProp.Components]);
    }

    dispatch(startButtonSpinner({ buttonName, componentName, componentType }));

    try {
        const response = await ioms.post(backEndUrl, screenPostData, { withCredentials: true });
        if (response.data.success) {
            dispatch({
                type: newScreenActionTypes.NEW_SCREEN_ACTION_RELOAD,
                payload: response.data.result
            });

            const { shouldDownloadFile, downloadFileName, downloadUrl } = response.data.result;
            if (shouldDownloadFile) {
                const { data } = await ioms.get(downloadUrl, { withCredentials: true, responseType: 'blob' });
                fileSaver(data, downloadFileName);
            }

            if (showMessages) {
                _.forEach(response.data.messages, message => {
                    dispatch(addMessage(MESSAGE_SUCCESS, { content: message.text, visible: true }));
                })
            }
        } else {
            if (response.data.messages) {
                let message = response.data.messages[0].text;
                await dispatch(addMessage(MESSAGE_ERROR, { content: message, visible: true }));
            }
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }

    dispatch(stopButtonSpinner({ buttonName, componentName, componentType }));
}

const addFieldValueToPostData = (formData, formDataDebugAssoc, formDataKey, fileKey, fieldDataType, fieldValue) => {
    switch (fieldDataType) {
        case DataType.Html:
            let html = '';
            if (typeof (fieldValue) === 'string') {
                html = fieldValue;
            } else {
                html = draftToHtml(convertToRaw(fieldValue.getCurrentContent()));
            }
            fieldValue = DOMPurify.sanitize(html);

            formData.append(formDataKey, fieldValue);
            formDataDebugAssoc[formDataKey] = fieldValue;

            break;
        case DataType.File:
            if (fieldValue instanceof Blob) {
                const file = fieldValue;
                const fileName = fieldValue.name;

                formDataDebugAssoc[formDataKey] = fileName;

                formData.append(formDataKey, fileName);
                formData.append(fileKey, file, fileName)
            } else {
                formDataDebugAssoc[formDataKey] = null;

                formData.append(formDataKey, null);
            }

            break;
        case DataType.Int:
            fieldValue = _.isInteger(fieldValue) ? parseInt(fieldValue) : fieldValue;
            formData.append(formDataKey, fieldValue);

            formDataDebugAssoc[formDataKey] = fieldValue;

            break;
        case DataType.String:
            fieldValue = fieldValue ? String(fieldValue) : null;
            formData.append(formDataKey, fieldValue);

            formDataDebugAssoc[formDataKey] = fieldValue;

            break;
        case DataType.Date:
            fieldValue = fieldValue ? String(fieldValue) : null;
            formData.append(formDataKey, fieldValue);

            formDataDebugAssoc[formDataKey] = fieldValue;

            break;
        case DataType.Float:
            if (!_.isNaN(fieldValue) && !_.isNaN(parseFloat(fieldValue))) {
                fieldValue = parseFloat(fieldValue);
            } else {
                fieldValue = null;
            }
            formData.append(formDataKey, fieldValue);

            formDataDebugAssoc[formDataKey] = fieldValue;

            break;
        case DataType.Bool:
            formData.append(formDataKey, fieldValue);
            formDataDebugAssoc[formDataKey] = fieldValue;

            break;
        case DataType.Array:
            formData.append(formDataKey, JSON.stringify(fieldValue));
            formDataDebugAssoc[formDataKey] = JSON.stringify(fieldValue);

            break;
    }
}

export const treeExpandElement = (parapms) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_TREE_EXPAND_ELEMENT,
        payload: parapms
    }
}

export const treeCollapseElement = (parapms) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_TREE_COLLAPSE_ELEMENT,
        payload: parapms
    }
}

export const treeExpandAllElements = (parapms) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_TREE_EXPAND_ALL_ELEMENTS,
        payload: parapms
    }
}

export const treeCollapseAllElements = (parapms) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_TREE_COLLAPSE_ALL_ELEMENTS,
        payload: parapms
    }
}

export const treeSelectElement = (parapms) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_TREE_SELECT_ELEMENT,
        payload: parapms
    }
}

export const buttonFormChangeValue = (parapms) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_BUTTON_FORM_CHANGE_VALUE,
        payload: parapms
    }
}

export const treeOnElementClickMutateScreenWithPostData = (backEndUrl) => async (dispatch, getState) => {
    const screenPostData = buildScreenPost(getState().screenData[ScreenProp.Components]);

    try {
        const response = await ioms.post(backEndUrl, screenPostData, { withCredentials: true });
        if (response.data.success) {
            dispatch({
                type: newScreenActionTypes.NEW_SCREEN_ACTION_MUTATE_SCREEN,
                payload: response.data.result
            })
        } else {
            if (response.data.messages) {
                let message = response.data.messages[0].text;
                await dispatch(addMessage(MESSAGE_ERROR, { content: message, visible: true }));
            }
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
}

import actionTypes from "../../actions/actionTypes";
import _ from 'lodash';

//every messageArray contains objects {content: 'messageText', visible: true}
const INITIAL_AUTH_STATE = {
    error: [],
    warning: [],
    success: []
}

const messageReducer = (state = INITIAL_AUTH_STATE, action) => {
    switch (action.type) {
        case actionTypes.ADD_MESSAGE:
            state[action.payload.type].push(action.payload.message);

            return {...state};
        case actionTypes.DELETE_MESSAGE:
            state[action.payload.type][action.payload.index].visible = false;

            return {...state};
        case actionTypes.CLEAR_MESSAGES:
            _.forEach(state, (collection, collectionName) => {
                _.forEach(collection, (messageObject, index) => {
                    state[collectionName][index].visible = false;
                })
            })

            return INITIAL_AUTH_STATE;
        default:
            return state;
    }
}

export default messageReducer;

import React from 'react';
import _ from 'lodash';
import { FieldType } from '../../../enum/FieldType';
import DateField from './DateField';
import DropdownMultiselect from './DropdownMultiselectField';
import DropdownSearchable from './DropdownSearchableField';
import DropdownStandard from './DropdownStandardField';
import TextField from './TextField';
import TextAreaField from './TextAreaField';
import NumericField from './NumericField';
import FileField from './FileField';
import { FormFieldProp } from '../../../enum/apiResultProp/FormFieldProp';
import { EventProp } from '../../../enum/apiResultProp/EventProp';
import { ActionProp } from '../../../enum/apiResultProp/ActionProp';
import { ActionType } from '../../../enum/ActionType';
import { EventType } from '../../../enum/EventType';
import { performFieldDataValidation } from '../../../newHelpers/formDataValidation';
import BoolSliderField from './BoolSliderField';
import DownloadField from './DownloadField';
import HtmlViewerField from './HtmlViewerField';
import PasswordField from './PasswordField';
import TextWithInternalLinkField from './TextWithInternalLinkField';
import ModalValueSelectorField from './ModalValueSelectorField';
import RichTextEditorField from './RichTextEditorField';
import ImagePreviewField from './ImagePreviewField';

export default ({
    rowKey,
    fieldName,
    fieldProps,
    onChange,
    updateFieldErrorAction,
    onActionMutateScreenWithPostData,
    onActionMutateScreenNoPostData,
    onFieldChangeMutateComponentWithPostData,
    onFieldChangeMutateComponentNoPostData,
    actionDownload
}) => {
    const onInputChange = async (propsChanged) => {
        //1. Set new field value in the reducer store
        await onChange({ propsChanged, fieldName, rowKey });

        //2. Handle BE Events if value has changed
        if (_.has(propsChanged, 'value')) {
            handleOnInputChangeEvents();
        }
    }

    const onInputBlur = async ({ value }) => {
        await performDataValidation({ value });
        handleOnInputBlurEvents();
    }

    const performDataValidation = async ({ value }) => {
        performFieldDataValidation({
            fieldProps: { ...fieldProps, value: value },
            updateFieldErrorAction: (errorParams) => updateFieldErrorAction({ ...errorParams, fieldName, rowKey })
        });
    }

    const handleOnInputChangeEvents = () => {
        const events = fieldProps[FormFieldProp.Events];

        if (!events) {
            return true;
        }

        _.forEach(events, (backEndEvent) => {
            const eventType = backEndEvent[EventProp.EventType];

            switch (eventType) {
                case EventType.AjaxPostEvent:
                case EventType.OnInputChange:
                    const action = backEndEvent[EventProp.Action];
                    const actionType = action[ActionProp.ActionType];

                    if (actionType === ActionType.MutateScreenWithPostDataAction) {
                        const backEndUrl = action[ActionProp.BackEndUrl];

                        onActionMutateScreenWithPostData({ backEndUrl });
                    } else if (actionType === ActionType.MutateScreenNoPostDataAction) {
                        const backEndUrl = action[ActionProp.BackEndUrl];

                        onActionMutateScreenNoPostData({ backEndUrl });
                    } else if (actionType === ActionType.MutateComponentNoPostDataAction) {
                        const backEndUrl = action[ActionProp.BackEndUrl];

                        onFieldChangeMutateComponentNoPostData({ backEndUrl });
                    } else if (actionType === ActionType.MutateComponentWithPostDataAction) {
                        const backEndUrl = action[ActionProp.BackEndUrl];
                        const collectDataFromComponentName = action[ActionProp.CollectDataFromComponentName];

                        onFieldChangeMutateComponentWithPostData({ backEndUrl, collectDataFromComponentName });
                    }

                    break;
                default:
                    break;
            }
        });
    }

    const handleOnInputBlurEvents = () => {
        const events = fieldProps[FormFieldProp.Events];
        if (!events) {
            return true;
        }

        _.forEach(events, (backEndEvent) => {
            const eventType = backEndEvent[EventProp.EventType];

            switch (eventType) {
                case EventType.OnInputBlur:
                    const action = backEndEvent[EventProp.Action];
                    const actionType = action[ActionProp.ActionType];

                    if (actionType === ActionType.MutateScreenWithPostDataAction) {
                        const backEndUrl = action[ActionProp.BackEndUrl];

                        onActionMutateScreenWithPostData({ backEndUrl });
                    } else if (actionType === ActionType.MutateScreenNoPostDataAction) {
                        const backEndUrl = action[ActionProp.BackEndUrl];

                        onActionMutateScreenNoPostData({ backEndUrl });
                    }

                    break;
                default:
                    break;
            }
        });
    }

    let field = null;

    let props = { fieldProps: fieldProps, onValueChange: onInputChange, performDataValidation: performDataValidation, onInputBlur: onInputBlur };

    const fieldType = fieldProps[FormFieldProp.FieldType];
    if (fieldType === FieldType.Date) {
        field = <DateField {...props} />
    } else if (fieldType === FieldType.DropdownMultiselect) {
        field = <DropdownMultiselect {...props} />
    } else if (fieldType === FieldType.DropdownSearchable) {
        field = <DropdownSearchable {...props} />
    } else if (fieldType === FieldType.Dropdown) {
        field = <DropdownStandard {...props} />
    } else if (fieldType === FieldType.Text) {
        field = <TextField {...props} />
    } else if (fieldType === FieldType.Password) {
        field = <PasswordField {...props} />
    } else if (fieldType === FieldType.TextArea) {
        field = <TextAreaField {...props} />
    } else if (fieldType === FieldType.Numeric) {
        field = <NumericField {...props} />
    } else if (fieldType === FieldType.File) {
        field = <FileField {...props} />
    } else if (fieldType === FieldType.BoolSlider) {
        field = <BoolSliderField {...props} />
    } else if (fieldType === FieldType.TextWithInternalLink) {
        field = <TextWithInternalLinkField {...props} />
    } else if (fieldType === FieldType.ModalValueSelector) {
        field = <ModalValueSelectorField {...props} />
    } else if (fieldType === FieldType.DownloadFile) {
        props = { ...props, actionDownload };
        field = <DownloadField {...props} />
    } else if (fieldType === FieldType.HtmlViewer) {
        field = <HtmlViewerField {...props} />;
    } else if (fieldType === FieldType.RichTextEditor) {
        field = <RichTextEditorField {...props} />;
    } else if (fieldType === FieldType.ImagePreviewField) {
        field = <ImagePreviewField {...props} />;
    } else {
        field = <p>Field Type not registered - {fieldType}</p>;
    }

    return field;
}

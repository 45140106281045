export const FlowStepProp = Object.freeze({
    Name: 'name',
    Title: 'title',
    StepNumber: 'stepNumber',
    RowNumber: 'rowNumber',
    ColNumber: 'colNumber',
    TitleColor: 'titleColor',
    Description: 'description',
    IsHighlighted: 'isHighlighted',
    StepNumberLabel: 'stepNumberLabel',
    StatusLabel: 'statusLabel',
    OnClickAction: 'onClickAction',
});
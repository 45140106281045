export const TreeElementProp = Object.freeze({
    OrderNumber: 'orderNumber',
    Elements: 'elements',
    UniqueId: 'uniqueId',
    Text: 'text',
    IconName: 'iconName',
    IconColor: 'iconColor',
    OnClickGetEvent: 'onClickGetEvent',
    ElementType: 'elementType',
});

import React from 'react';
import _ from 'lodash';
import { Divider, Header } from 'semantic-ui-react';
import { ButtonOptionsFormFieldGroupProp } from '../../enum/apiResultProp/buttonOptionsForm/ButtonOptionsFormFieldGroupProp';
import ButtonOptionField from './ButtonOptionField';
import { ButtonOptionsFormFieldProp } from '../../enum/apiResultProp/buttonOptionsForm/ButtonOptionsFormFieldProp';

export default ({ props, changeValue, mutateWithPostData }) => {
    const title = props[ButtonOptionsFormFieldGroupProp.Title];
    const groupName = props[ButtonOptionsFormFieldGroupProp.GroupName];
    const buttonOptionFields = props[ButtonOptionsFormFieldGroupProp.ButtonOptionFields];
    const positionNumber = props[ButtonOptionsFormFieldGroupProp.PositionNumber];

    const renderGroupTitle = () => {
        if (!title) {
            return null;
        }

        return <Header size='small'>{title}</Header>;
    }

    const renderButtonFormFields = () => {
        if (!buttonOptionFields) {
            return null;
        }

        return (
            <>
                {_.map(buttonOptionFields, (fieldProps, fieldKey) => {
                    return (
                        <ButtonOptionField
                            props={fieldProps}
                            changeValue={(params) => changeValue({ ...params, fieldKey })}
                            key={fieldProps[ButtonOptionsFormFieldProp.Name]}
                            mutateWithPostData={mutateWithPostData}
                        />
                    );
                })}
            </>
        );
    }

    return (
        <React.Fragment key={groupName}>
            {renderGroupTitle()}
            {renderButtonFormFields()}
        </React.Fragment>
    );
}

import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import { Accordion as SemanticAccordion, Icon, Ref } from 'semantic-ui-react';

const Accordion = ({ isActiveInitialValue, title, renderContent, someDropdownToggledItsStateInRelatedTabForms }) => {
    const [active, setActive] = useState(isActiveInitialValue);
    const contentRef = useRef();

    useEffect(() => {
        if (contentRef.current.scrollHeight > contentRef.current.clientHeight) {
            contentRef.current.style.height = contentRef.current.scrollHeight + 14 + 'px';
        } else {
            let childrenClientHeightSum = 0;
            _.forEach(contentRef.current.children, (value, key) => {
                childrenClientHeightSum += value.clientHeight;
            });
            
            contentRef.current.style.height = childrenClientHeightSum + 38 + 'px';
        } 
    });

    return (
        <SemanticAccordion fluid styled>
            <SemanticAccordion.Title active={active} onClick={() => setActive(!active)}>
                <Icon name='dropdown' /> {title}
            </SemanticAccordion.Title>
            <Ref innerRef={contentRef}>
                <SemanticAccordion.Content active={active} style={{overflow: 'auto'}}>
                    {renderContent()}
                </SemanticAccordion.Content>
            </Ref>
        </SemanticAccordion>
    );
}

export default Accordion;

import actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
    gridColumns: [],
    data: [],  
    subtotal: undefined,
};

const dashboard = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.FETCH_DASHBOARD_DATA:
            return {...action.payload};
        case actionTypes.REFRESH_DASHBOARD_DATA:
            return {...action.payload};
        default: 
            return state;
    }
}

export default dashboard;

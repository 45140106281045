import React from 'react';
import _ from 'lodash';
import AbstractButton from '../button/AbstractButton';
import { FormButtonProp } from '../../../../enum/apiResultProp/FormButtonProp';
import { ButtonsProp } from '../../../../enum/apiResultProp/ButtonsProp';

export default ({
    screenComponentProps,
    onActionSubmitForm,
    onActionMutateScreenWithPostData,
    onActionMutateScreenNoPostData,
    reloadScreenWithPostDataOnButtonClick,
    onActionPostDataAndRedirect,
    onActionDownload,
    onActionLogout,
    uniqueKey,
    openModalForm,
    onButtonClickMutateComponentWithPostData,
    onButtonClickMutateComponentNoPostData,
    getRequestAndRedirectExternalLink
}) => {
    const formButtons = screenComponentProps[ButtonsProp.Buttons];

    const renderFormButtons = () => {
        if (!formButtons) {
            return null;
        }

        _.sortBy(formButtons, button => button[FormButtonProp.Position]);
        return (
            <>
                {_.map(formButtons, (buttonProps, key) => {
                    return (
                        <AbstractButton
                            key={key}
                            buttonProps={buttonProps}
                            onActionSubmitForm={onActionSubmitForm}
                            onActionMutateScreenWithPostData={onActionMutateScreenWithPostData}
                            onActionMutateScreenNoPostData={onActionMutateScreenNoPostData}
                            reloadScreenWithPostDataOnButtonClick={reloadScreenWithPostDataOnButtonClick}
                            onActionDownload={onActionDownload}
                            onActionLogout={onActionLogout}
                            onActionPostDataAndRedirect={onActionPostDataAndRedirect}
                            openModalForm={openModalForm}

                            onButtonClickMutateComponentWithPostData={onButtonClickMutateComponentWithPostData}
                            onButtonClickMutateComponentNoPostData={onButtonClickMutateComponentNoPostData}
                            getRequestAndRedirectExternalLink={getRequestAndRedirectExternalLink}
                        />
                    );
                })}
            </>
        );
    }

    return (
        <React.Fragment key={uniqueKey}>
            {renderFormButtons()}
        </React.Fragment>
    );
}

import _ from "lodash";
import actionTypes from "./actionTypes";
import ioms from "../../apis/ioms";
import history from '../../history';
import fileSaver from '../../fileSaver';
import { ROUTES } from '../../helpers/routes';
import { MESSAGE_SUCCESS, MESSAGE_ERROR, FORM_EDIT_MODE, FORM_CREATE_MODE, MESSAGE_WARNING } from '../../helpers/const';
import { addMessage } from '../messageActions';
import { refresh } from '../notificationBar/actions';

export const clear = () => { return { type: actionTypes.CLEAR_DATA }; };

export const toggleSpinner = () => { return { type: actionTypes.TOGGLE_SPINNER_C_E_P }; };

export const fetch = (fetchUrl) => async (dispatch) => {
    try {
        const response = await ioms.get(fetchUrl, { withCredentials: true });

        if (response.data.success) {
            dispatch({
                type: actionTypes.FETCH,
                payload: response.data.result
            });
        } else {
            const { sessionExpired, closedUserLog, permission, shouldChangePassword, shouldAcceptTermsAndConditions } = response.data;
            
            let route;
            let shouldAddErrorMessage = true;
            if (sessionExpired) {
                route = ROUTES.LOGIN_ROUTE.URL;
            } else if (closedUserLog) {
                route = ROUTES.LOGIN_ROUTE.URL;
            } else if (permission) {
                route = ROUTES.HOME_SCREEN_ROUTE.URL;
            } else if (shouldChangePassword) {
                route = ROUTES.EDIT_PASSWORD_ROUTE.URL;
            } else if (shouldAcceptTermsAndConditions) {
                route = ROUTES.TERMS_AND_CONDITIONS_DECISION_SCREEN.URL;
                shouldAddErrorMessage = false;
            }
            
            if (shouldAddErrorMessage) {
                dispatch(addMessage(MESSAGE_ERROR, { content: response.data.messages[0].text, visible: true }));
            }

            history.push(route);
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
}

export const updateInput = (name, value) => {
    return {
        type: actionTypes.UPDATE_INPUT,
        payload: { name, value }
    };
}

export const updateError = (name, hasError, errorMsg) => {
    return {
        type: actionTypes.UPDATE_ERROR,
        payload: { name, hasError, errorMsg }
    };
}

export const submit = (submitUrl, postData, nextRoute, mode) => async (dispatch, getState) => {
    try {
        await dispatch(toggleSpinner());

        const response = await ioms.post(submitUrl, postData, { withCredentials: true });

        if (response.data.result.success) {
            const { message, id, warningMessage } = response.data.result;

            await dispatch(toggleSpinner());
            await dispatch(addMessage(MESSAGE_SUCCESS, { content: message, visible: true }));
            await dispatch(refresh());

            if (warningMessage) {
                await dispatch(addMessage(MESSAGE_WARNING, { content: warningMessage, visible: true }));
            }

            if (mode === FORM_CREATE_MODE) {
                nextRoute = nextRoute.replace(':id', id);
            }

            history.push(nextRoute);
        } else {
            await dispatch(toggleSpinner());
            await dispatch(addMessage(MESSAGE_ERROR, { content: response.data.result.message, visible: true }));
        }
    } catch (error) {
        await dispatch(toggleSpinner());
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
}

export const updateAllErrors = (errors) => {
    return {
        type: actionTypes.UPDATE_ALL_ERRORS,
        payload: { errors }
    };
}

export const updateRelatedDataInputOfDimensionField = (relatedDataName, mode, rowKey, field, value, dimensionName) => {
    return {
        type: actionTypes.UPDATE_RELATED_DATA_INPUT_DIMENSION_FIELD,
        payload: { relatedDataName, mode, rowKey, field, value, dimensionName }
    };
}

export const updateRelatedDataInput = (relatedDataName, mode, rowKey, field, value) => {
    return {
        type: actionTypes.UPDATE_RELATED_DATA_INPUT,
        payload: { relatedDataName, mode, rowKey, field, value }
    };
}

export const updateRelatedDataInputButtonOptions = (parameters) => {
    return {
        type: actionTypes.UPDATE_RELATED_DATA_INPUT_BUTTON_OPTIONS_FIELD,
        payload: parameters
    };
}

export const updateRelatedDataInputSliders = (params) => {
    return {
        type: actionTypes.UPDATE_RELATED_DATA_INPUT_SLIDERS,
        payload: params
    };
}

export const updateRelatedDataError = (relatedDataName, mode, rowKey, field, error, errorMessage) => {
    return {
        type: actionTypes.UPDATE_RELATED_DATA_ERROR,
        payload: { relatedDataName, mode, rowKey, field, error, errorMessage }
    };
}

export const updateRelatedDataErrorOfDimensionField = (relatedDataName, mode, rowKey, field, error, errorMessage, dimensionName) => {
    return {
        type: actionTypes.UPDATE_RELATED_DATA_ERROR_DIMENSION_FIELD,
        payload: { relatedDataName, mode, rowKey, field, error, errorMessage, dimensionName }
    };
}

export const addRelatedDataRow = (relatedDataName) => {
    return {
        type: actionTypes.ADD_RELATED_DATA_ROW,
        payload: { relatedDataName }
    };
}

export const removeRelatedDataRow = (relatedDataName, mode, rowKey) => {
    return {
        type: actionTypes.REMOVE_RELATED_DATA_ROW,
        payload: { relatedDataName, mode, rowKey }
    };
}

export const updateFormAjax = (url, postData, relatedDataName, mode, rowKey) => async (dispatch, getState) => {
    const response = await ioms.post(url, postData, { withCredentials: true });

    const { success, result, messages } = response.data;

    if (success) {
        const {
            revisionBomTreeData, headerData, relatedData, relatedDataRow, forRevision, relatedDataForAddOrDelete,
            emptyRowForUpdate, relatedDataRowsForAdd, relatedDataRowKeysForDelete
        } = result;

        dispatch({
            type: actionTypes.UPDATE_FORM_AJAX,
            payload: {
                revisionBomTreeData, headerData, relatedDataName, mode, rowKey, relatedData,
                forRevision, relatedDataRow, relatedDataForAddOrDelete, emptyRowForUpdate,
                relatedDataRowsForAdd, relatedDataRowKeysForDelete
            }
        });
    } else {
        if (!_.isUndefined(messages)) {
            dispatch(addMessage(MESSAGE_ERROR, { content: messages[0].text, visible: true }));
        }
    }
};

export const updateFormOnStepActionAjax = (url, postData, homeRoute) => async (dispatch, getState) => {
    const response = await ioms.post(url, postData, { withCredentials: true });

    const { result } = response.data;

    if (result) {
        const { success, message, warningMessages } = result;

        if (warningMessages) {
            _.forEach(warningMessages, warningMessage => dispatch(addMessage(MESSAGE_WARNING, { content: warningMessage, visible: true })));
        }

        if (success) {
            await dispatch(refresh());
            if (result.redirectToHome) {
                history.push(homeRoute);
            } else {
                dispatch({
                    type: actionTypes.UPDATE_ON_STEP_CLICK_AJAX,
                    payload: result
                });
            }
        } else {
            dispatch(addMessage(MESSAGE_ERROR, { content: message, visible: true }));
        }
    }
};

export const revisionTreeHandleExpand = (expanded) => {
    return {
        type: actionTypes.REVISION_TREE_HANDLE_EXPAND,
        payload: expanded
    }
}

export const revisionTreeHandleChecked = (checked) => {
    return {
        type: actionTypes.REVISION_TREE_HANDLE_CHECK,
        payload: checked
    }
}

export const revisionDescriptionUpdateInput = (value) => {
    return {
        type: actionTypes.REVISION_DESCRIPTION_UPDATE_INPUT,
        payload: value
    }
}

export const lock = (lockUrl, postData) => async (dispatch) => {
    const response = await ioms.post(lockUrl, postData, { withCredentials: true });

    if (response.data.success) {
        dispatch({
            type: actionTypes.HANDLE_LOCK,
            payload: response.data.result
        });
    } else {
        if (response.data.messages !== undefined) {
            dispatch(addMessage(MESSAGE_ERROR, { content: response.data.messages[0].text, visible: true }));
        }
    }
};

export const unlock = (unlockUrl, postData) => async (dispatch) => {
    const response = await ioms.post(unlockUrl, postData, { withCredentials: true });

    if (response.data.success) {
        dispatch({
            type: actionTypes.HANDLE_LOCK,
            payload: response.data.result
        });
    }
};

export const updateRelatedDataFieldDisabled = (relatedDataName, mode, rowKey, fieldName, disabled) => {
    let collection;
    if (mode === FORM_EDIT_MODE) {
        collection = 'rowsForEdit';
    } else {
        collection = 'rowsForCreate';
    }

    return {
        type: actionTypes.UPDATE_RELATED_DATA_FIELD_DISABLED,
        payload: { relatedDataName, mode, rowKey, fieldName, disabled, collection }
    };
}

export const downloadInstructions = (downloadUrl, downloadedFileName) => async (dispatch) => {
    const { data } = await ioms.get(downloadUrl, { withCredentials: true, responseType: 'blob' });

    await fileSaver(data, downloadedFileName);
};

export const importEntities = (file, importUrl, nextRoute) => async (dispatch) => {
    try {
        const response = await ioms.post(importUrl, file, { withCredentials: true });

        if (response.data.result.success) {
            await dispatch(addMessage(MESSAGE_SUCCESS, { content: response.data.result.message, visible: true }));

            history.push(nextRoute);
        } else {
            dispatch(addMessage(MESSAGE_ERROR, { content: response.data.result.message, visible: true }));
        }
    } catch (error) {
        dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
}

import _, { isUndefined } from "lodash";
import actionTypes from "./actionTypes";
import ioms from "../../apis/ioms";
import { prepareGetParamsObject } from '../../helpers/functions';
import { MESSAGE_SUCCESS, MESSAGE_ERROR, MESSAGE_WARNING } from '../../helpers/const';
import { ROUTES } from '../../helpers/routes';

import fileSaver from '../../fileSaver';
import history from '../../history';
import { clearFiltersOnly } from './searchBarActions';
import { closeGridModal, updateGridModalError, toggleSpinner } from '../gridModalActions';
import { close, toggleSpinner as toggleSpinnerModalTree, updateError } from '../revisionModalTree/actions';
import { addMessage } from '../messageActions';
import { refresh, fetch } from '../notificationBar/actions';

export const confirmRevisionTree = (postData, confirmRevisionTreeUrl, fetchUrl) => async (dispatch, getState) => {
    try {
        await dispatch(toggleSpinnerModalTree());

        const response = await ioms.post(confirmRevisionTreeUrl, postData, { withCredentials: true });

        const { success, message, warningMessages, userShouldChangePassword } = response.data.result;

        if (success) {
            await dispatch(close());

            await dispatch(addMessage(MESSAGE_SUCCESS, { content: message, visible: true }));

            await dispatch(refresh());

            if (!_.isUndefined(warningMessages) && warningMessages) {
                _.forEach(warningMessages, async warningMessage => await dispatch(addMessage(MESSAGE_WARNING, { content: warningMessage, visible: true })))
            }

            dispatch(fetchGridData(fetchUrl));
        } else {
            await dispatch(toggleSpinnerModalTree());

            dispatch(updateError([message]));
        }
    } catch (error) {
        await dispatch(toggleSpinnerModalTree());

        // dispatch(updateError([error.message]));
    }
}

export const exportGridData = (generateExportFileUrl, downloadUrl) => async (dispatch) => {
    try {
        const response = await ioms.get(generateExportFileUrl, { withCredentials: true });
        const { fileName, filePath, message } = response.data.result;

        const { data } = await ioms.post(downloadUrl, { filePath }, { withCredentials: true, responseType: 'blob' });
        await fileSaver(data, fileName);

        dispatch(addMessage(MESSAGE_SUCCESS, { content: message, visible: true }));
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
};

export const fetchGridData = (fetchUrl, getParamsObject = {}) => async (dispatch) => {
    try {
        const response = await ioms.get(fetchUrl, { params: getParamsObject, withCredentials: true });

        const { success, result, messages, sessionExpired, closedUserLog, permission, shouldChangePassword, shouldAcceptTermsAndConditions } = response.data;

        if (success) {
            dispatch({
                type: actionTypes.FETCH_GRID_DATA,
                payload: result.data
            });
        } else {
            let route;
            let shouldAddErrorMessage = true;
            if (sessionExpired) {
                route = ROUTES.LOGIN_ROUTE.URL;
            } else if (closedUserLog) {
                route = ROUTES.LOGIN_ROUTE.URL;
            } else if (permission) {
                route = ROUTES.HOME_SCREEN_ROUTE.URL;
            } else if (shouldChangePassword) {
                route = ROUTES.EDIT_PASSWORD_ROUTE.URL;
            } else if (shouldAcceptTermsAndConditions) {
                route = ROUTES.TERMS_AND_CONDITIONS_DECISION_SCREEN.URL;
                shouldAddErrorMessage = false;
            }
            
            if (shouldAddErrorMessage) {
                dispatch(addMessage(MESSAGE_ERROR, { content: messages[0].text, visible: true }));
            }

            history.push(route);
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
};

export const fetchGridDataWithGetParams = (fetchUrl) => async (dispatch, getState) => {
    const getParamsObject = prepareGetParamsObject(getState().gridPage.configuration);

    const response = await ioms.get(fetchUrl, { params: getParamsObject, withCredentials: true });

    dispatch({
        type: actionTypes.FETCH_GRID_DATA,
        payload: response.data.result.data
    });
};

export const createEntity = (postData, createUrl, fetchUrl) => async (dispatch, getState) => {
    try {
        await dispatch(toggleSpinner());

        const response = await ioms.post(createUrl, postData, { withCredentials: true });

        if (response.data.result.success) {
            await dispatch(clearFiltersOnly());

            await dispatch(closeGridModal());

            await dispatch(addMessage(MESSAGE_SUCCESS, { content: response.data.result.message, visible: true }));

            dispatch(fetchGridData(fetchUrl));
        } else {
            await dispatch(toggleSpinner());

            dispatch(updateGridModalError([response.data.result.message]));
        }
    } catch (error) {
        await dispatch(toggleSpinner());

        dispatch(updateGridModalError([error.message]));
    }
}

export const editEntity = (postData, id, editUrl, fetchUrl) => async (dispatch, getState) => {
    try {
        await dispatch(toggleSpinner());

        const editUrlWithId = editUrl.replace(/:id/, id);
        const response = await ioms.post(editUrlWithId, postData, { withCredentials: true });

        if (response.data.result.success) {
            //TODO
            // await dispatch(clearFiltersOnly());

            await dispatch(closeGridModal());

            await dispatch(addMessage(MESSAGE_SUCCESS, { content: response.data.result.message, visible: true }));

            await dispatch(refresh());

            //TODO
            // dispatch(fetchGridData(fetchUrl));
            dispatch(fetchGridDataWithGetParams(fetchUrl));
        } else {
            await dispatch(toggleSpinner());

            dispatch(updateGridModalError([response.data.result.message]));
        }
    } catch (error) {
        await dispatch(toggleSpinner());

        dispatch(updateGridModalError([error.message]));
    }
}

export const deleteEntity = (id, deleteUrl, fetchUrl) => async (dispatch, getState) => {
    try {
        await dispatch(toggleSpinner());

        const deleteUrlWithId = deleteUrl.replace(/:id/, id);
        const response = await ioms.get(deleteUrlWithId, { withCredentials: true });

        if (response.data.result.success) {
            await dispatch(addMessage(MESSAGE_SUCCESS, { content: response.data.result.message, visible: true }));

            dispatch(fetchGridDataWithGetParams(fetchUrl)); //TODO Do I need this call
        } else {
            dispatch(addMessage(MESSAGE_ERROR, { content: response.data.result.message, visible: true }));
        }
    } catch (error) {
        dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
}

export const importEntities = (file, importUrl, fetchUrl) => async (dispatch) => {
    try {
        const response = await ioms.post(importUrl, file, { withCredentials: true });

        if (response.data.result.success) {
            await dispatch(clearFiltersOnly());

            await dispatch(addMessage(MESSAGE_SUCCESS, { content: response.data.result.message, visible: true }));

            dispatch(fetchGridData(fetchUrl));
        } else {
            dispatch(addMessage(MESSAGE_ERROR, { content: response.data.result.message, visible: true }));
        }
    } catch (error) {
        dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
}

export const downloadFile = (downloadUrl, downloadedFileName) => async (dispatch) => {
    const { data } = await ioms.get(downloadUrl, { withCredentials: true, responseType: 'blob' });

    await fileSaver(data, downloadedFileName);
};

export const downloadFileByPath = (downloadUrl, serverFilePath, downloadedFileName) => async (dispatch) => {
    const { data } = await ioms.post(downloadUrl, { serverFilePath }, { withCredentials: true, responseType: 'blob' });

    fileSaver(data, downloadedFileName);
};

export const onVariantChange = (variant) => {
    return {
        type: actionTypes.ON_VARIANT_CHANGE,
        payload: { variant }
    };
}

export const clearState = () => {
    return {
        type: actionTypes.CLEAR_STATE,
    };
}

export const updateFormAjax = (url, postData) => async (dispatch) => {
    await dispatch({
        type: actionTypes.ADDITIONAL_ACTION_SPINNER_START,
    });

    const response = await ioms.post(url, postData, { withCredentials: true });

    const { success, result } = response.data;

    if (success) {
        const { res, message } = result;

        if (res) {
            await dispatch(addMessage(MESSAGE_SUCCESS, { content: response.data.result.message, visible: true }));

            dispatch({
                type: actionTypes.AJAX_ACTION,
                payload: result
            });
        } else {
            dispatch(addMessage(MESSAGE_ERROR, { content: message, visible: true }));
        }
    }

    await dispatch({
        type: actionTypes.ADDITIONAL_ACTION_SPINNER_STOP,
    });
};

export const updateSendToMagoAction = (sendToMagoAction) => {
    return {
        type: actionTypes.UPDATE_SEND_TO_MAGO_ACTION,
        payload: sendToMagoAction
    }
}

export const downloadDocumentAndRefresh = (generateFileUrl, fetchUrl) => async (dispatch, getState) => {
    try {
        const response = await ioms.get(generateFileUrl, { withCredentials: true });

        const { serverFilePath, fileName, downloadUrl, success, message } = response.data.result;

        if (success) {
            await dispatch(downloadFileByPath(downloadUrl, serverFilePath, fileName));

            await dispatch(addMessage(MESSAGE_SUCCESS, { content: message, visible: true }));

            await dispatch(refresh());

            dispatch(fetchGridData(fetchUrl));
        } else {
            dispatch(updateGridModalError([message]));
        }
    } catch (error) {
        dispatch(updateGridModalError([error.message]));
    }
}

export const toggleGridCheckbox = (value) => {
    return {
        type: actionTypes.TOGGLE_GRID_CHECKBOX,
        payload: { value }
    };
}

export const checkAll = () => {
    return {
        type: actionTypes.CHECK_ALL,
    };
}

export const uncheckAll = () => {
    return {
        type: actionTypes.UNCHECK_ALL,
    };
}

export const confirmCheckboxesAndRefresh = (url, postParams, fetchUrl) => async (dispatch, getState) => {
    try {
        const response = await ioms.post(url, postParams, { withCredentials: true });

        const { success, message, warningMessages } = response.data.result;

        if (success) {
            await dispatch(addMessage(MESSAGE_SUCCESS, { content: message, visible: true }));

            await dispatch(refresh());

            if (!_.isUndefined(warningMessages) && warningMessages) {
                _.forEach(warningMessages, async (warningMessage) => {
                    await dispatch(addMessage(MESSAGE_WARNING, { content: warningMessage, visible: true }));
                });
            }

            dispatch(fetchGridData(fetchUrl));
        } else {
            dispatch(addMessage(MESSAGE_ERROR, { content: message, visible: true }));
        }
    } catch (error) {
        dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
}

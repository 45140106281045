import React from 'react';
import DOMPurify from 'dompurify';
import { Segment } from 'semantic-ui-react';
import { FormFieldProp } from '../../../enum/apiResultProp/FormFieldProp';

export default ({ fieldProps }) => {
    const value = fieldProps[FormFieldProp.Value];
    const name = fieldProps[FormFieldProp.Name];
    const withBorders = fieldProps[FormFieldProp.WithBorders];

    return (
        <Segment
            basic={!withBorders}
            key={name}
            style={{ width: '99.1%', margin: 'auto', marginTop: '4px' }}
            size={'massive'}
        >
            <div
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }}
                style={{ all: 'initial' }}
            />
        </Segment>
    );
}

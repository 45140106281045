import React from 'react';
import _, { size } from 'lodash';
import { Table, Checkbox, Icon } from 'semantic-ui-react';
import GridColumnTitleCell from './GridColumnTitleCell';
import { ColumnProp } from '../../../enum/dataGrid/ColumnProp';
import { FilterConfigProp } from '../../../enum/dataGrid/filter/FilterConfigProp';

export default ({
    selectable,
    columns,
    titles,
    sorting,
    filterConfig,
    filters,
    appliedFiltersIdsByColumn,
    onSortClick,
    onApplyFiltersClick,
    onDeleteAllFiltersClick,
    onDeleteAppliedFilterRowClick,
    onDeleteNotAppliedFilterRowClick,
    onCreateRowClick,
    onFilterColumnFieldChange,
    onFilterComparatorFieldChange,
    onFilterValueFieldChange,
    clearAllNotAppliedEmptyFilters,
    selectAll,
    currentPageRecordsCount,
    currentPageSelectedRecordsCount,
    translations,
}) => {
    const allColumnFiledOptions = filterConfig ? filterConfig[FilterConfigProp.ColumnFieldOptions] : null;

    const renderSelectableHeaderCell = () => {
        if (!selectable) {
            return null;
        }

        let iconName = '';
        let selectAllRecordsFromCurrentPage = false;
        if (currentPageSelectedRecordsCount === 0) {
            iconName = 'square outline';
            selectAllRecordsFromCurrentPage = true;
        } else if (currentPageSelectedRecordsCount === currentPageRecordsCount) {
            iconName = 'check square';
        } else {
            iconName = 'minus square';
        }

        return (
            <Table.HeaderCell textAlign='center'>
                <Icon name={iconName} size='large' style={{ color: '#3C8AD9', cursor: 'pointer' }} onClick={() => selectAll({ selectAllRecordsFromCurrentPage })} />
            </Table.HeaderCell>
        )
    }

    return (
        <Table.Row textAlign='left'>
            {renderSelectableHeaderCell()}
            {
                _.map(columns, (columnProps) => {
                    const isHidden = columnProps[ColumnProp.Hidden];
                    const columnName = columnProps[ColumnProp.Name];
                    if (isHidden) {
                        return null;
                    }

                    const columnTitle = titles[columnName];
                    const columnSortingDirection = sorting[columnName];
                    const columnFilterConfig = filterConfig ? filterConfig[FilterConfigProp.Columns][columnName] : null;
                    const columnFilters = filters ? filters[columnName] : [];

                    return <GridColumnTitleCell
                        key={columnName}
                        columnName={columnName}
                        title={columnTitle}
                        sortingDirection={columnSortingDirection}
                        filterConfig={columnFilterConfig}
                        filters={columnFilters}
                        appliedFiltersIdsByColumn={appliedFiltersIdsByColumn}
                        allColumnFiledOptions={allColumnFiledOptions}
                        onSortClick={onSortClick}
                        onApplyFiltersClick={onApplyFiltersClick}
                        onDeleteAllFiltersClick={onDeleteAllFiltersClick}
                        onDeleteAppliedFilterRowClick={onDeleteAppliedFilterRowClick}
                        onDeleteNotAppliedFilterRowClick={onDeleteNotAppliedFilterRowClick}
                        onCreateRowClick={onCreateRowClick}
                        onFilterColumnFieldChange={onFilterColumnFieldChange}
                        onFilterComparatorFieldChange={onFilterComparatorFieldChange}
                        onFilterValueFieldChange={onFilterValueFieldChange}
                        clearAllNotAppliedEmptyFilters={clearAllNotAppliedEmptyFilters}
                        translations={translations}
                    />;
                })
            }
        </Table.Row>
    );
}

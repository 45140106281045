import React from 'react';
import _ from 'lodash';
import { Form } from 'semantic-ui-react';
import FormRow from './FormRow';
import { FormFieldProp } from '../../../enum/apiResultProp/FormFieldProp';
import { FormProp } from '../../../enum/apiResultProp/FormProp';

export default ({
    screenComponentProps, onFormValueChange, updateFieldErrorAction, onActionMutateScreenWithPostData, onActionMutateScreenNoPostData, className,
    uniqueKey, onFieldChangeMutateComponentWithPostData, actionDownload
}) => {
    const formFields = screenComponentProps[FormProp.Fields];

    const getFormErrors = () => {
        let formHasError = false;
        let errorMessagesByRowKey = {};

        _.forEach(formFields, (rowFields, rowKey) => {
            errorMessagesByRowKey[rowKey] = [];

            _.forEach(rowFields, (fieldProps, fieldName) => {
                const fieldHasError = fieldProps[FormFieldProp.HasError];
                const fieldErrorMessage = fieldProps[FormFieldProp.ErrorMessage];

                if (fieldHasError) {
                    formHasError = true;

                    errorMessagesByRowKey[rowKey].push(fieldErrorMessage);
                }
            });
        });

        return { formHasError, errorMessagesByRowKey };
    }

    const { formHasError, errorMessagesByRowKey } = getFormErrors();

    return (
        <Form className={className} error={formHasError} key={uniqueKey}>
            {
                _.map(formFields, (rowFields, rowKey) => {
                    return (
                        <FormRow
                            key={rowKey}
                            rowKey={rowKey}
                            rowFields={rowFields}
                            actionDownload={actionDownload}
                            onFormValueChange={onFormValueChange}
                            updateFieldErrorAction={updateFieldErrorAction}
                            onActionMutateScreenWithPostData={onActionMutateScreenWithPostData}
                            onActionMutateScreenNoPostData={onActionMutateScreenNoPostData}
                            rowErrorMessages={errorMessagesByRowKey[rowKey]}
                            onFieldChangeMutateComponentWithPostData={onFieldChangeMutateComponentWithPostData}
                        />
                    );
                })
            }
        </ Form >
    );
}

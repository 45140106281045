import React, { useState } from 'react';
import _ from 'lodash';
import TableHeaderRow from '../tableFormHeader/row/TableHeaderRow';
import TableFormDataRow from '../tableFormData/row/TableFormDataRow';
import TableFormButtons from '../tableFormFooter/TableFormButtons';
import { Table, Accordion, Icon, Form, Divider } from 'semantic-ui-react';
import { TableFormProp } from '../../../enum/apiResultProp/TableFormProp';
import { TableFormExpand } from '../../../enum/TableFormExpand';
import { TableFormDataRowProp } from '../../../enum/apiResultProp/TableFormDataRowProp';
import { TableFormCellProp } from '../../../enum/apiResultProp/TableFormCellProp';
import { TableFormCellType } from '../../../enum/TableFormCellType';
import { FormFieldProp } from '../../../enum/apiResultProp/FormFieldProp';

export default ({
    screenComponentProps, onButtonClickMutateScreenWithPostData, onInputFieldChange, updateInputFieldErrorAction,
    onActionDownload, uniqueKey, onFieldChangeMutateScreenWithPostData, onFieldChangeMutateComponentWithPostData,
    openModalForm
}) => {
    const tableExpand = screenComponentProps[TableFormProp.TableFormExpand];
    const tableHeader = screenComponentProps[TableFormProp.TableFormHeader];
    const tableData = screenComponentProps[TableFormProp.TableFormDataRows];
    const tableTitle = screenComponentProps[TableFormProp.TableFormTitle];

    const [expand, setExpand] = useState(tableExpand === TableFormExpand.Yes);

    const columnsCount = Object.keys(tableHeader).length;

    const renderErrorMessagesTableRow = (tableDataRowProps) => {
        const type = 'error';

        //Find Messages
        const messages = {};
        const cells = tableDataRowProps[TableFormDataRowProp.Cells];
        _.forEach(cells, (cellProps, cellKey) => {
            const cellType = cellProps[TableFormCellProp.CellType];
            if (cellType === TableFormCellType.InputField) {
                const fieldProps = cellProps[TableFormCellProp.FieldProps];
                const hasError = fieldProps[FormFieldProp.HasError];
                const errorMessge = fieldProps[FormFieldProp.ErrorMessage];

                if (hasError) {
                    messages[cellKey] = errorMessge;
                }
            }
        })

        if (Object.keys(messages).length === 0) {
            return null;
        }

        return (
            <Table.Row>
                <Table.Cell colSpan={columnsCount}>
                    <div className={`ui ${type} message`}>
                        <ul className="list" style={{ listStyle: "none" }}>
                            {
                                _.map(messages, (message, key) => {
                                    return (
                                        <li key={key}>
                                            <p>{message}</p>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </Table.Cell>
            </Table.Row>
        );
    }

    const renderContent = () => {
        return (
            <Form warning error>
                <Table size='small' className='tableForm' celled={false}>
                    <Table.Header>
                        <TableHeaderRow tableHeaderCellsData={tableHeader} />
                    </Table.Header>

                    <Table.Body>
                        {_.map(tableData, (tableDataRowProps, rowKey) => {
                            return (
                                <React.Fragment key={rowKey}>
                                    <TableFormDataRow
                                        key={rowKey}
                                        rowKey={rowKey}
                                        tableDataRowProps={tableDataRowProps}
                                        onInputFieldChange={onInputFieldChange}
                                        updateInputFieldErrorAction={updateInputFieldErrorAction}
                                        onButtonClickMutateScreenWithPostData={onButtonClickMutateScreenWithPostData}
                                        onFieldChangeMutateScreenWithPostData={onFieldChangeMutateScreenWithPostData}
                                        onActionDownload={onActionDownload}
                                        onFieldChangeMutateComponentWithPostData={onFieldChangeMutateComponentWithPostData}
                                        openModalForm={openModalForm}
                                    />

                                    {renderErrorMessagesTableRow(tableDataRowProps)}
                                </React.Fragment>
                            );
                        })}
                    </Table.Body>

                    <Table.Footer>
                        <TableFormButtons
                            screenComponentProps={screenComponentProps}
                            onActionMutateScreenWithPostData={onButtonClickMutateScreenWithPostData}
                            openModalForm={openModalForm}
                            colsCount={columnsCount}
                        />
                    </Table.Footer>
                </Table>
            </Form>
        );
    }

    return (
        <React.Fragment key={uniqueKey}>
            <Accordion styled fluid>
                <Accordion.Title active={expand} onClick={() => setExpand(!expand)} >
                    <Icon name='dropdown' /> {tableTitle}
                </Accordion.Title>
                <Accordion.Content active={expand} style={{ overflow: 'auto' }}>
                    {renderContent()}
                </Accordion.Content>
            </Accordion>
            <Divider hidden style={{ marginTop: '3px', marginBottom: '3px' }} />
        </React.Fragment>
    );
}

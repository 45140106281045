import React from 'react';
import _ from 'lodash';
import { Table, Dropdown, Icon, Button, ButtonGroup } from 'semantic-ui-react';
import { TableDataCellProp } from '../../../../enum/apiResultProp/TableDataCellProp';
import AbstractButton from '../../../form/formButton/button/AbstractButton';

export default ({ tableDataCellProps, mutateComponentWitPostData, openModalForm }) => {
    const actions = tableDataCellProps[TableDataCellProp.Actions];

    if (!actions) {
        return (
            <Table.Cell textAlign='center' style={{ width: '1em' }}> - </Table.Cell>
        );
    }

    return (
        <Table.Cell textAlign='center' style={{ width: '1em', paddingBottom: '0.1em', paddingTop: '0.1em' }}>
            <ButtonGroup icon basic size='tiny'>
                {_.map(actions, (action, actionKey) => {
                    return (
                        <AbstractButton
                            key={actionKey}
                            buttonProps={action}
                            onButtonClickMutateComponentWithPostData={mutateComponentWitPostData}
                            openModalForm={openModalForm}
                            // onActionSubmitForm={onActionSubmitForm}
                            // onActionMutateScreenWithPostData={onActionMutateScreenWithPostData}
                            // onActionMutateScreenNoPostData={onActionMutateScreenNoPostData}
                            // reloadScreenWithPostDataOnButtonClick={reloadScreenWithPostDataOnButtonClick}
                            // onActionDownload={onActionDownload}
                            // onActionLogout={onActionLogout}
                            // onActionPostDataAndRedirect={onActionPostDataAndRedirect}

                            // onButtonClickMutateComponentNoPostData={onButtonClickMutateComponentNoPostData}
                            // getRequestAndRedirectExternalLink={getRequestAndRedirectExternalLink}
                        />
                    )
                })}
            </ButtonGroup>
        </Table.Cell>
    );
}

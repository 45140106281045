import React from 'react';
import _ from 'lodash';
import { Form, Modal, ModalHeader, ModalContent, ModalActions, Header, Label, FormField, Input, Button, Grid, GridColumn } from 'semantic-ui-react';
import { FormFieldProp } from '../../../enum/apiResultProp/FormFieldProp';

export default ({ fieldProps, onValueChange }) => {
    const value = fieldProps[FormFieldProp.Value];
    const showLabel = fieldProps[FormFieldProp.ShowLabel];
    const label = fieldProps[FormFieldProp.Label];
    const hasError = fieldProps[FormFieldProp.HasError];
    const showMandatorySign = fieldProps[FormFieldProp.ShowMandatorySign];
    const isReadOnly = fieldProps[FormFieldProp.IsReadOnly];

    const options = fieldProps[FormFieldProp.Options];
    const legendOptions = fieldProps[FormFieldProp.LegendOptions];
    const modalIsOpen = fieldProps[FormFieldProp.ModalIsOpen];
    const filterValue = fieldProps[FormFieldProp.FilterValue];
    const tmpValue = fieldProps[FormFieldProp.TmpValue];
    const labelColorAvailable = fieldProps[FormFieldProp.LabelColorAvailable];
    const labelColorNotAvailable = fieldProps[FormFieldProp.LabelColorNotAvailable];
    const labelColorSelected = fieldProps[FormFieldProp.LabelColorSelected];
    const labelSize = fieldProps[FormFieldProp.LabelSize];

    const renderLegend = () => {
        return (
            <div style={{ float: 'left' }}>
                {
                    _.map(legendOptions, legendOption => {
                        return (
                            <Label
                                key={legendOption.text}
                                circular
                                size={legendOption.labelSize}
                                color={legendOption.labelColor}
                            >
                                {legendOption.text}
                            </Label>
                        )
                    })
                }
            </div>
        );
    }

    const onFilterValueChange = (event) => {
        onValueChange({ filterValue: event.target.value });
    }

    const onOptionClick = (disabled, optionValue) => {
        if (!disabled) {
            onValueChange({ tmpValue: optionValue });
        }
    }

    const onConfirm = () => {
        onValueChange({ modalIsOpen: false, value: tmpValue });
    }

    const onCancel = () => {
        onValueChange({ modalIsOpen: false, tmpValue: value });
    }

    const openModal = () => {
        onValueChange({ modalIsOpen: true, tmpValue: value });
    }

    const renderOptionsGrid = () => {
        return (
            <Grid columns={16} padded>
                {_.map(options, option => {
                    if (!option.text.includes(filterValue)) {
                        return null;
                    } else {
                        const { disabled, key, value } = option;

                        let labelColor;
                        if (value === tmpValue) {
                            labelColor = labelColorSelected;
                        } else if (!disabled) {
                            labelColor = labelColorAvailable;
                        } else {
                            labelColor = labelColorNotAvailable;
                        }

                        return (
                            <GridColumn key={key}>
                                <Label
                                    size={labelSize}
                                    circular
                                    as='a'
                                    color={labelColor}
                                    onClick={() => onOptionClick(disabled, value)}
                                >
                                    {value}
                                </Label>
                            </GridColumn>
                        );
                    }

                })}
            </Grid>
        );
    }

    const renderModal = () => {
        return (
            <Modal
                open={modalIsOpen}
                onClose={() => console.log('Should i do sth here???')}
            >
                <ModalHeader>
                    <Header as={'h2'} style={{ textAlign: 'center' }}>Моля изберете своя Състезателен Номер</Header>
                    <Header as={'h5'} style={{ textAlign: 'center' }}>
                        Текущ избор:
                        <Label
                            size='big'
                            circular
                            as={'a'}
                            color={'green'}
                        >
                            {tmpValue}
                        </Label>
                    </Header>
                    <Form>
                        <FormField >
                            <label>Филтър</label>
                            <Input
                                width={5}
                                placeholder='Напишете номерът, който търсите или част от него'
                                value={filterValue}
                                onChange={onFilterValueChange}
                            />
                        </FormField>
                    </Form>
                </ModalHeader>
                <ModalContent scrolling>
                    {renderOptionsGrid()}
                </ModalContent>

                <ModalActions>
                    {renderLegend()}

                    <Button negative onClick={onCancel}>
                        Отказ
                    </Button>
                    <Button positive onClick={onConfirm}>
                        Потвърди
                    </Button>
                </ModalActions>
            </Modal>
        )
    }

    let textFieldProps = {
        type: 'text',
        required: showMandatorySign,
        readOnly: true,
        disabled: isReadOnly,
        error: hasError,
        value: value,
        action: { icon: 'pencil', color: 'teal', onClick: openModal },
    };
    if (showLabel) {
        textFieldProps = { ...textFieldProps, label: label };
    }

    return (
        <>
            {renderModal()}
            <Form.Input {...textFieldProps} />
        </>
    )
}

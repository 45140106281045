export default {
    NEW_SCREEN_FETCH: 'NEW_SCREEN_FETCH',
    NEW_SCREEN_CLEAR: 'NEW_SCREEN_CLEAR',
    NEW_SCREEN_START_BUTTON_SPINNER: 'NEW_SCREEN_START_BUTTON_SPINNER',
    NEW_SCREEN_STOP_BUTTON_SPINNER: 'NEW_SCREEN_STOP_BUTTON_SPINNER',

    NEW_SCREEN_DISABLE_ALL_COMPONENT_BUTTONS: 'NEW_SCREEN_DISABLE_ALL_COMPONENT_BUTTONS',
    NEW_SCREEN_ENABLE_ALL_COMPONENT_BUTTONS: 'NEW_SCREEN_ENABLE_ALL_COMPONENT_BUTTONS',
    NEW_SCREEN_START_SPINNER: 'NEW_SCREEN_START_SPINNER',
    NEW_SCREEN_STOP_SPINNER: 'NEW_SCREEN_STOP_SPINNER',
    NEW_SCREEN_ENABLE_BUTTON: 'NEW_SCREEN_ENABLE_BUTTON',
    NEW_SCREEN_DISABLE_BUTTON: 'NEW_SCREEN_ENABLE_BUTTON',
    NEW_SCREEN_FORM_FIELD_VALUE_CHANGE: 'NEW_SCREEN_FORM_VALUE_CHANGE',
    NEW_SCREEN_FORM_FIELD_ERROR_CHANGE: 'NEW_SCREEN_FORM_FIELD_ERROR_CHANGE',
    NEW_SCREEN_ACTION_SUBMIT: 'NEW_SCREEN_ACTION_SUBMIT',
    NEW_SCREEN_ACTION_MUTATE_SCREEN: 'NEW_SCREEN_ACTION_MUTATE_SCREEN',
    NEW_SCREEN_ACTION_MUTATE_COMPONENT: 'NEW_SCREEN_ACTION_MUTATE_COMPONENT',
    NEW_SCREEN_ACTION_RELOAD: 'NEW_SCREEN_ACTION_RELOAD',

    NEW_SCREEN_TABLE_FORM_FIELD_VALUE_CHANGE: 'NEW_SCREEN_TABLE_FORM_FIELD_VALUE_CHANGE',
    NEW_SCREEN_TABLE_FORM_FIELD_ERROR_CHANGE: 'NEW_SCREEN_TABLE_FORM_FIELD_ERROR_CHANGE',

    NEW_SCREEN_TREE_EXPAND_ELEMENT: 'NEW_SCREEN_TREE_EXPAND_ELEMENT',
    NEW_SCREEN_TREE_EXPAND_ALL_ELEMENTS: 'NEW_SCREEN_TREE_EXPAND_ALL_ELEMENTS',
    NEW_SCREEN_TREE_COLLAPSE_ELEMENT: 'NEW_SCREEN_TREE_COLLAPSE_ELEMENT',
    NEW_SCREEN_TREE_COLLAPSE_ALL_ELEMENTS: 'NEW_SCREEN_TREE_COLLAPSE_ALL_ELEMENTS',
    NEW_SCREEN_TREE_SELECT_ELEMENT: 'NEW_SCREEN_TREE_SELECT_ELEMENT',

    NEW_SCREEN_BUTTON_FORM_CHANGE_VALUE: 'NEW_SCREEN_BUTTON_FORM_CHANGE_VALUE',

    NEW_SCREEN_INFO_PANEL_HIDE: 'NEW_SCREEN_INFO_PANEL_HIDE',

    NEW_SCREEN_MODAL_FORM_OPEN: 'NEW_SCREEN_MODAL_FORM_OPEN',

    NEW_SCREEN_DATA_GRID_START_SPINNER: 'NEW_SCREEN_DATA_GRID_START_SPINNER',
    NEW_SCREEN_DATA_GRID_STOP_SPINNER: 'NEW_SCREEN_DATA_GRID_STOP_SPINNER',
    NEW_SCREEN_DATA_GRID_CREATE_FILTER: 'NEW_SCREEN_DATA_GRID_CREATE_FILTER',
    NEW_SCREEN_DATA_GRID_DELETE_NOT_APPLIED_FILTER: 'NEW_SCREEN_DATA_GRID_DELETE_NOT_APPLIED_FILTER',
    NEW_SCREEN_DATA_GRID_ON_FILTER_VALUE_CHANGE: 'NEW_SCREEN_DATA_GRID_ON_FILTER_VALUE_CHANGE',
    NEW_SCREEN_DATA_GRID_ON_FILTER_COMPARATOR_CHANGE: 'NEW_SCREEN_DATA_GRID_ON_FILTER_COMPARATOR_CHANGE',
    NEW_SCREEN_DATA_GRID_ON_FILTER_COLUMN_CHANGE: 'NEW_SCREEN_DATA_GRID_ON_FILTER_COLUMN_CHANGE',
    NEW_SCREEN_DATA_GRID_CLEAR_ALL_NOT_APPLIED_EMPTY_FILTERS: 'NEW_SCREEN_DATA_GRID_CLEAR_ALL_NOT_APPLIED_EMPTY_FILTERS',
    NEW_SCREEN_DATA_GRID_SELECT_SINGLE_CLICK: 'NEW_SCREEN_DATA_GRID_SELECT_SINGLE_CLICK',
    NEW_SCREEN_DATA_GRID_SELECT_ALL_CLICK: 'NEW_SCREEN_DATA_GRID_SELECT_ALL_CLICK',
};

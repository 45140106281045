import actionTypes from "../../actions/newScreen/newScreenActionTypes";
import {
    startSpinner,
    stopSpinner,
    fetchScreenData,
    formFieldValueChange,
    formFieldErrorChange,
    actionMutateScreen,
    actionReloadScreen,
    handleButtonSpinner,
    handleButtonDisable,
    handleAllComponentButtonsActiveness,
    tableFormFieldValueChange,
    tableFormFieldErrorChange,
    treeExpandElement,
    treeCollpaseElement,
    treeExpandAllElements,
    treeCollpaseAllElements,
    treeSelectElement,
    buttonFormChangeValue,
    infoPanelHide,
    modalFormOpen,
    actionMutateComponent,
    dataGridMutateSpinner,
    dataGridCreateFilter,
    dataGridDeleteNotAppliedFilter,
    dataGridFilterValueChange,
    dataGridFilterComparatorChange,
    dataGridFilterColumnChange,
    clearAllNotAppliedEmptyFilters,
    selectAll,
    selectSingle,
} from './stateMutators';
import { ScreenProp } from '../../enum/apiResultProp/ScreenProp'

// All New Screen Properties are listed in ScreenProp enum 
const DEFAULT_STATE = {
    [ScreenProp.Components]: {},
    [ScreenProp.ShowSpinner]: true,
};

const newScreenReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case actionTypes.NEW_SCREEN_DATA_GRID_SELECT_ALL_CLICK:
            return selectAll(state, action);
        case actionTypes.NEW_SCREEN_DATA_GRID_SELECT_SINGLE_CLICK:
            return selectSingle(state, action);
        case actionTypes.NEW_SCREEN_DATA_GRID_CLEAR_ALL_NOT_APPLIED_EMPTY_FILTERS:
            return clearAllNotAppliedEmptyFilters(state, action);
        case actionTypes.NEW_SCREEN_DATA_GRID_ON_FILTER_COLUMN_CHANGE:
            return dataGridFilterColumnChange(state, action);
        case actionTypes.NEW_SCREEN_DATA_GRID_ON_FILTER_COMPARATOR_CHANGE:
            return dataGridFilterComparatorChange(state, action);
        case actionTypes.NEW_SCREEN_DATA_GRID_ON_FILTER_VALUE_CHANGE:
            return dataGridFilterValueChange(state, action);
        case actionTypes.NEW_SCREEN_DATA_GRID_DELETE_NOT_APPLIED_FILTER:
            return dataGridDeleteNotAppliedFilter(state, action);
        case actionTypes.NEW_SCREEN_DATA_GRID_CREATE_FILTER:
            return dataGridCreateFilter(state, action);
        case actionTypes.NEW_SCREEN_DATA_GRID_START_SPINNER:
            return dataGridMutateSpinner(state, action, true);
        case actionTypes.NEW_SCREEN_DATA_GRID_STOP_SPINNER:
            return dataGridMutateSpinner(state, action, false);
        case actionTypes.NEW_SCREEN_FETCH:
            return fetchScreenData(state, action);
        case actionTypes.NEW_SCREEN_FORM_FIELD_VALUE_CHANGE:
            return formFieldValueChange(state, action);
        case actionTypes.NEW_SCREEN_TABLE_FORM_FIELD_VALUE_CHANGE:
            return tableFormFieldValueChange(state, action);
        case actionTypes.NEW_SCREEN_TABLE_FORM_FIELD_ERROR_CHANGE:
            return tableFormFieldErrorChange(state, action);
        case actionTypes.NEW_SCREEN_FORM_FIELD_ERROR_CHANGE:
            return formFieldErrorChange(state, action);
        case actionTypes.NEW_SCREEN_ACTION_MUTATE_SCREEN:
            return actionMutateScreen(state, action);
        case actionTypes.NEW_SCREEN_ACTION_MUTATE_COMPONENT:
            return actionMutateComponent(state, action);
        case actionTypes.NEW_SCREEN_START_SPINNER:
            return startSpinner(state);
        case actionTypes.NEW_SCREEN_STOP_SPINNER:
            return stopSpinner(state);
        case actionTypes.NEW_SCREEN_START_BUTTON_SPINNER:
            return handleButtonSpinner(state, action, true);
        case actionTypes.NEW_SCREEN_STOP_BUTTON_SPINNER:
            return handleButtonSpinner(state, action, false);
        case actionTypes.NEW_SCREEN_ENABLE_BUTTON:
            return handleButtonDisable(state, action, false);
        case actionTypes.NEW_SCREEN_DISABLE_BUTTON:
            return handleButtonDisable(state, action, true);
        case actionTypes.NEW_SCREEN_DISABLE_ALL_COMPONENT_BUTTONS:
            return handleAllComponentButtonsActiveness(state, action, true, false);
        case actionTypes.NEW_SCREEN_ENABLE_ALL_COMPONENT_BUTTONS:
            return handleAllComponentButtonsActiveness(state, action, false, false);
        case actionTypes.NEW_SCREEN_ACTION_RELOAD:
            return actionReloadScreen(state, action);

        case actionTypes.NEW_SCREEN_TREE_EXPAND_ELEMENT:
            return treeExpandElement(state, action);
        case actionTypes.NEW_SCREEN_TREE_COLLAPSE_ELEMENT:
            return treeCollpaseElement(state, action);
        case actionTypes.NEW_SCREEN_TREE_EXPAND_ALL_ELEMENTS:
            return treeExpandAllElements(state, action);
        case actionTypes.NEW_SCREEN_TREE_COLLAPSE_ALL_ELEMENTS:
            return treeCollpaseAllElements(state, action);
        case actionTypes.NEW_SCREEN_TREE_SELECT_ELEMENT:
            return treeSelectElement(state, action);

        case actionTypes.NEW_SCREEN_BUTTON_FORM_CHANGE_VALUE:
            return buttonFormChangeValue(state, action);

        case actionTypes.NEW_SCREEN_INFO_PANEL_HIDE:
            return infoPanelHide(state, action);
        case actionTypes.NEW_SCREEN_MODAL_FORM_OPEN:
            return modalFormOpen(state, action);

        case actionTypes.NEW_SCREEN_CLEAR:
            return DEFAULT_STATE;
        default:
            return state;
    }
}

export default newScreenReducer;

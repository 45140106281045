import React, { useState } from 'react';
import { Icon, Popup, Form, Button, Grid } from 'semantic-ui-react';
import _ from 'lodash';
import { FilterProp } from '../../../enum/dataGrid/filter/FilterProp';
import { ColumnFieldProp } from '../../../enum/dataGrid/filter/ColumnFieldProp';
import { ComparatorFieldProp } from '../../../enum/dataGrid/filter/ComparatorFieldProp';
import { ValueFieldProp } from '../../../enum/dataGrid/filter/ValueFieldProp';
import { FieldType } from '../../../enum/FieldType';
import { STRINGS } from '../../../helpers/strings';
import { getValue } from '../../../helpers/functions';

import { DateInput } from 'semantic-ui-calendar-react';

export default ({
    filters,
    triggerComponentRenderFunction,
    appliedFiltersIdsByColumn,
    onApplyFiltersClick,
    onDeleteAllFiltersClick,
    onDeleteAppliedFilterRowClick,
    onDeleteNotAppliedFilterRowClick,
    onCreateRowClick,
    loadOnColumnName,
    onFilterColumnFieldChange,
    onFilterComparatorFieldChange,
    onFilterValueFieldChange,
    clearAllNotAppliedEmptyFilters,
    translations
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const onOpen = () => {
        onCreateRowClick({ forColumn: loadOnColumnName });
        setIsOpen(true);
    }

    const onClose = (event) => {
        const target = event.target;
        const element = document.getElementsByClassName('Site-content')[0];
        const elementIsOutsideSiteContent = target !== element && !element.contains(target);
        if (!elementIsOutsideSiteContent) {
            setIsOpen(false);
            clearAllNotAppliedEmptyFilters();
        }
    }

    const renderValueField = (valueFieldProps, filterIsApplied, id, columnName) => {
        const value = valueFieldProps[ValueFieldProp.Value];
        const dataType = valueFieldProps[ValueFieldProp.DataType];
        const fieldType = valueFieldProps[ValueFieldProp.FieldType];
        const options = valueFieldProps[ValueFieldProp.Options];

        let valueField = 'Unknown type';
        if (fieldType === FieldType.Numeric) {
            valueField = (
                <Form.Input
                    name={'value_' + id}
                    type='number'
                    fluid
                    icon='search'
                    disabled={filterIsApplied}
                    readOnly={filterIsApplied}
                    value={value}
                    onChange={(e) => onFilterValueFieldChange({ columnName, id, value: e.target.value })}
                    autoFocus
                />
            );
        } else if (fieldType === FieldType.Text) {
            valueField = (
                <Form.Input
                    name={'value_' + id}
                    type='text'
                    fluid
                    icon='search'
                    disabled={filterIsApplied}
                    readOnly={filterIsApplied}
                    value={value}
                    onChange={(e) => onFilterValueFieldChange({ columnName, id, value: e.target.value })}
                    autoFocus
                />
            );
        } else if (fieldType === FieldType.Dropdown) {
            let dropdownProps = {
                selectOnBlur: false,
                options: options,
                required: false,
                readOnly: filterIsApplied,
                disabled: filterIsApplied,
                error: false,
                value: value,
                autoFocus: true,
                onChange: async (e, { value }) => {
                    onFilterValueFieldChange({ columnName, id, value })
                },
                onKeyPress: e => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                    }
                },
            };
        
            return <Form.Select  {...dropdownProps} />;
        } else if (fieldType === FieldType.DropdownSearchable) {
            let dropdownProps = {
                search: true,
                selection: true,
                selectOnBlur: false,
                options: options,
                required: false,
                readOnly: filterIsApplied,
                disabled: filterIsApplied,
                error: false,
                value: value,
                autoFocus: true,
                onChange: async (e, { value }) => {
                    onFilterValueFieldChange({ columnName, id, value })
                },
                onKeyPress: e => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                    }
                },
            };
        
            return <Form.Select  {...dropdownProps} />;
        } else if (fieldType === FieldType.DropdownMultiselect) {
            let dropdownProps = {
                multiple: true,
                selection: true,
                selectOnBlur: false,
                options: options,
                required: false,
                readOnly: filterIsApplied,
                disabled: filterIsApplied,
                error: false,
                value: value,
                autoFocus: true,
                onChange: async (e, { value }) => {
                    onFilterValueFieldChange({ columnName, id, value })
                },
                onKeyPress: e => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                    }
                },
            };
        
            return <Form.Select  {...dropdownProps} />;
        } else if (fieldType === FieldType.Date) {
            let dateProps = {
                name: 'value_' + id,
                style: { minWidth: '10em' },
                fluid: true,
                as: DateInput,
                duration: 0,
                closable: true,
                dateFormat: "YYYY-MM-DD",
                iconPosition: "left",
                icon: false,

                readOnly: false,
                disabled: false,
                error: false,
                value: value,

                onChange: (e, { value }) => onFilterValueFieldChange({ columnName, id, value: value }),
            };

            valueField = <Form.Input {...dateProps} autoFocus />;
        }

        return valueField;
    }

    const renderExistingFilters = () => {
        if (_.countBy(filters) < 1) {
            return null;
        }

        return (
            <>
                <Grid.Row style={{ marginBottom: '-20px' }}>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column style={{ color: 'grey', paddingRight: '0.3em', paddingLeft: '0.5em' }}>{getValue(translations, STRINGS.COLUMN)}</Grid.Column>
                    <Grid.Column style={{ color: 'grey', paddingRight: '0.3em', paddingLeft: '0.5em' }}>{getValue(translations, STRINGS.OPERATOR)}</Grid.Column>
                    <Grid.Column style={{ color: 'grey', paddingRight: '0.3em', paddingLeft: '0.3em' }}>{getValue(translations, STRINGS.VALUE)}</Grid.Column>
                    <Grid.Column width={2} style={{ color: 'grey', paddingRight: '2.6em', paddingLeft: '0.1em' }}>{getValue(translations, STRINGS.APPLIED)}</Grid.Column>
                </Grid.Row>

                {_.map(filters, (filter, index) => {
                    const id = filter[FilterProp.Id];
                    const columnField = filter[FilterProp.ColumnField];
                    const comparatorField = filter[FilterProp.ComparatorField];
                    const valueField = filter[FilterProp.ValueField];

                    const columnName = columnField.value;
                    const appliedFiltersIdsForCurrentColumn = appliedFiltersIdsByColumn[columnName];
                    const isApplied = _.includes(appliedFiltersIdsForCurrentColumn, id);

                    const columnFiledOptions = columnField[ColumnFieldProp.Options];
                    const columnFiledValue = columnField[ColumnFieldProp.Value];

                    const comparatorFieldOptions = _.values(comparatorField[ComparatorFieldProp.Options]);
                    const comparatorFieldValue = comparatorField[ComparatorFieldProp.Value];

                    let appliedIconColor = 'red';
                    let appliedIconName = 'ban';
                    if (isApplied) {
                        appliedIconName = 'check circle outline';
                        appliedIconColor = 'blue';
                    }

                    let columnSelectorDisabled = false;
                    if (isApplied || loadOnColumnName) {
                        columnSelectorDisabled = true;
                    }

                    return (
                        <Grid.Row style={{ marginBottom: '-20px' }} key={index}>
                            <Grid.Column width={1} textAlign='right' verticalAlign='middle' style={{ paddingRight: '0.5em' }}>
                                <Button icon basic size='tiny' onClick={() => {
                                    const deleteParams = { columnName, id };
                                    if (isApplied) {
                                        onDeleteAppliedFilterRowClick(deleteParams);
                                    } else {
                                        onDeleteNotAppliedFilterRowClick(deleteParams);
                                    }
                                }}>
                                    <Icon name='cancel' color='red' size='small' />
                                </Button>
                            </Grid.Column>
                            <Grid.Column style={{ paddingRight: '0.3em', paddingLeft: '0.5em' }}>
                                <Form size='mini' name={'column_' + id}>
                                    <Form.Select
                                        name={'column_' + id}
                                        fluid
                                        options={columnFiledOptions}
                                        value={columnFiledValue}
                                        disabled={columnSelectorDisabled}
                                        onChange={(e, data) => onFilterColumnFieldChange({ oldColumnName: columnName, id, newColumnName: data.value })}
                                    />
                                </Form>
                            </Grid.Column>
                            <Grid.Column style={{ paddingRight: '0.3em', paddingLeft: '0.3em' }}>
                                <Form size='mini' name={'comparator_' + id}>
                                    <Form.Select
                                        name={'comparator_' + id}
                                        fluid
                                        options={comparatorFieldOptions}
                                        value={comparatorFieldValue}
                                        disabled={isApplied}
                                        onChange={(e, data) => onFilterComparatorFieldChange({ columnName, id, value: data.value })}
                                    />
                                </Form>
                            </Grid.Column>
                            <Grid.Column style={{ paddingRight: '0.3em', paddingLeft: '0.3em' }}>
                                <Form size='mini' name={'value_' + id}>
                                    {renderValueField(valueField, isApplied, id, columnName)}
                                </Form>
                            </Grid.Column>
                            <Grid.Column textAlign='center' verticalAlign='middle' width={2} style={{ color: 'grey', paddingLeft: '1.3em' }}>
                                <Icon name={appliedIconName} color={appliedIconColor} />
                            </Grid.Column>
                        </Grid.Row>
                    );
                })}
            </>
        )
    }

    return (
        <Popup
            trigger={triggerComponentRenderFunction(onOpen)}
            flowing
            on='click'
            style={{ width: '53em' }}
            onClose={onClose}
            open={isOpen}
        >
            <Grid columns='equal'>
                {renderExistingFilters()}

                <Grid.Row style={{ marginBottom: '-0.8em' }}>
                    <Grid.Column>
                        <Button basic icon size='tiny' onClick={() => onCreateRowClick({ forColumn: loadOnColumnName })}>
                            <Icon name='plus' className='grid-blue-color' size='small' />
                        </Button>
                    </Grid.Column>
                    <Grid.Column textAlign='right' style={{ marginRight: '-1.8em' }}>
                        <Button basic icon size='tiny' onClick={() => {
                            onApplyFiltersClick();
                            setIsOpen(false);
                        }} >
                            <Icon name='check' className='grid-blue-color' />
                            {getValue(translations, STRINGS.APPLY_FILTERS)}
                        </Button>
                        <Button basic icon size='tiny' onClick={() => {
                            onDeleteAllFiltersClick({ forColumn: loadOnColumnName });
                            setIsOpen(false);
                        }} >
                            <Icon name='cancel' color='red' />
                            {getValue(translations, STRINGS.DELETE_FILTERS)}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Popup>
    );
}

import React from 'react';
import _ from 'lodash';
import { Table } from 'semantic-ui-react';
import { TableDataRowProp } from '../../../../enum/apiResultProp/TableDataRowProp';
import AbstractDataCell from '../cell/AbstractDataCell';
import { TableDataRowColor } from '../../../../enum/TableDataRowColor';

export default ({ tableDataRowProps }) => {
    const cells = tableDataRowProps[TableDataRowProp.Cells];
    const color = tableDataRowProps[TableDataRowProp.Color];

    let warning = false;
    let positive = false;
    let negative = false;

    if (color === TableDataRowColor.Orange) {
        warning = true;
    } else if (color === TableDataRowColor.Red) {
        negative = true;
    } else if (color === TableDataRowColor.Green) {
        positive = true;
    }

    return (
        <Table.Row warning={warning} positive={positive} negative={negative}>
            {
                _.map(cells, (tableDataCellProps, cellKey) => {
                    return <AbstractDataCell key={cellKey} tableDataCellProps={tableDataCellProps} />
                })
            }
        </Table.Row>
    );
}
import React from 'react';
import _ from 'lodash';
import { Label, Icon, Container, Button, Divider, Header, Image } from 'semantic-ui-react';
import { EMPTY_VALUE } from '../../helpers/const';

export default (props) => {
    const renderCode = () => {
        const sortedCodes = _.orderBy(props.codes, ['position'], ['asc']);
                
        return (
            <Button.Group>
                { _.map(sortedCodes, (code, idx) => renderCodeElement(code, idx)) }
            </Button.Group>        
        );
    }

    const renderImage = (imageUrl) => {
        if (imageUrl == EMPTY_VALUE) {
            return null;
        }

        return (
            <Button.Content hidden>
                <Image src={imageUrl} size='small' centered style={{marginTop: '-3em'}}/>
            </Button.Content>
        );
    }

    const renderCodeElement = ({color, description, example, imageUrl}, idx) => {
        const animated = imageUrl != EMPTY_VALUE ? 'fade' : false;
        
        return (
            <Button key={idx} basic color={color} animated={animated}>
                <Button.Content visible>
                    <Header as='h4' style={{marginBottom: '0px'}}>{description}</Header>
                    <Label pointing prompt color={color}>{example}</Label>
                </Button.Content>
                { renderImage(imageUrl) }
            </Button>
        );
    }
    
    const renderTitle = () => {
        return (
            <Divider horizontal>
                <Header as='h4'>
                    <Icon name='code' /> {props.title}
                </Header>
            </Divider>
        );
    }

    return (
        <Container textAlign='center' fluid>
            {renderTitle()}
            {renderCode()}
        </Container>
    );
}

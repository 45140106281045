import React from 'react';
import { Image, Form } from 'semantic-ui-react';
import { FormFieldProp } from '../../../enum/apiResultProp/FormFieldProp';

export default ({ fieldProps }) => {
    const value = fieldProps[FormFieldProp.Value];
    const imageSize = fieldProps[FormFieldProp.ImageSize];
    const imageRounded = fieldProps[FormFieldProp.ImageRounded];
    const imageCircular = fieldProps[FormFieldProp.ImageCircular];
    const imageFloated = fieldProps[FormFieldProp.ImageFloated];

    let imageProps = {
        type: 'imagePreview',
        src: value,
        size: imageSize,
        rounded: imageRounded,
        circular: imageCircular,
        floated: imageFloated
    };

    return (
        <Form.Field>
            <Image
                {...imageProps}
            />
        </Form.Field>
    );
}
import _ from 'lodash';

export const setTreeExpanded = (state, action) => {
    const { detailName, expanded } = action.payload;

    return {
        ...state,
        relatedData: {
            ...state.relatedData,
            [detailName]: {
                ...state.relatedData[detailName],
                expanded: expanded
            }
        }
    }
}

export const fetch = (state, action) => {
    const {
        PAGE_ACTIONS, fieldGroups, fields, relatedData, title, downloadFileName, downloadUrl,
        deleteUrl, additionalActions, GENERATE_DOCUMENT, SEND_OFFER_TREE, message,
        pageInfoPanels, backActionWithGridFilter
    } = action.payload;

    state = {
        ...state,
        showSpinner: false,
        title: title,
        PAGE_ACTIONS: PAGE_ACTIONS,
        fieldGroups: fieldGroups,
        fields: fields,
        relatedData: relatedData,
        deleteUrl: deleteUrl,
        downloadUrl: downloadUrl,
        downloadFileName: downloadFileName,
        backActionWithGridFilter: backActionWithGridFilter,
        GENERATE_DOCUMENT: GENERATE_DOCUMENT,
        SEND_OFFER_TREE: SEND_OFFER_TREE,
        pageInfoPanels: pageInfoPanels,
    }

    if (message !== undefined) {
        state = { ...state, message: message };
    }

    if (additionalActions !== undefined && additionalActions !== false && !_.isEmpty(additionalActions)) {
        state = {
            ...state,
            additionalActions: additionalActions
        }
    } else {
        state = {
            ...state,
            additionalActions: false
        }
    }

    return state;
}

export const toggleSpinner = (state, action) => {
    const newVal = !state.showSpinner;

    return { ...state, showSpinner: newVal };
}


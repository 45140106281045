import React from 'react';
import _, { keys } from 'lodash';
import { Header, Grid, Button } from 'semantic-ui-react';
import { ButtonOptionsFormFieldProp } from '../../enum/apiResultProp/buttonOptionsForm/ButtonOptionsFormFieldProp';
import ButtonOption from './ButtonOption';
import { ButtonOptionsFormFieldOptionProp } from '../../enum/apiResultProp/buttonOptionsForm/ButtonOptionsFormFieldOptionProp';

export default ({ props, changeValue, mutateWithPostData }) => {
    const name = props[ButtonOptionsFormFieldProp.Name];
    const positionRow = props[ButtonOptionsFormFieldProp.PositionRow];
    const label = props[ButtonOptionsFormFieldProp.Label];
    const dataType = props[ButtonOptionsFormFieldProp.DataType];
    const value = props[ButtonOptionsFormFieldProp.Value];
    const options = props[ButtonOptionsFormFieldProp.Options];

    const renderButtonOr = (currentOptionOrderNumber) => {
        const countOfAllOptions = keys(options).length;
        if (currentOptionOrderNumber === countOfAllOptions) {
            return null;
        }

        return <Button.Or text='|' size='tiny' />;
    }

    const renderOptions = () => {
        if (!options) {
            return null;
        }

        let currentOrderNumber = 0;

        return (
            <Grid.Column width={3}>
                <Button.Group>
                    {_.map(options, optionProps => {
                        currentOrderNumber++;

                        return (
                            <React.Fragment key={optionProps[ButtonOptionsFormFieldOptionProp.Key]}>
                                <ButtonOption
                                    props={optionProps}
                                    checkIsActiveOption={checkIsActiveOption}
                                    changeValue={changeValue}
                                    mutateWithPostData={mutateWithPostData}
                                />
                                {renderButtonOr(currentOrderNumber)}                            
                            </React.Fragment>
                        );
                    })}
                </Button.Group>
            </Grid.Column>
        );
    }

    const checkIsActiveOption = (optionValue) => {
        return optionValue === value;
    }

    const getHeaderColor = () => {
        let color = 'black';

        _.forEach(options, option => {
            const optionValue = option[ButtonOptionsFormFieldOptionProp.Value];
            const optionSelectedColor = option[ButtonOptionsFormFieldOptionProp.SelectedColor];

            if (optionValue === value) {
                color = optionSelectedColor;
                return;
            }
        });

        return color;
    }

    return (
        <Grid.Row style={{ paddingTop: '3px', paddingBottom: '3px' }}>
            <Grid.Column width={13}>
                <Header as='h5' style={{ paddingTop: '7%' }} color={getHeaderColor()} >
                    {label}
                </Header>
            </Grid.Column>
            {renderOptions()}
        </Grid.Row>
    );
}

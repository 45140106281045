import actionTypes from "../actions/staticData/staticDataActionTypes";

const staticDataReducer = (state = [], action) => {
    switch (action.type) {
        case actionTypes.FETCH_GRID_DATA_STATIC_DATA:
            return {...action.payload};
        default: 
            return state;
    }
}

export default staticDataReducer;

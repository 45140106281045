import _ from 'lodash';
import actionTypes from '../../actions/notificationBar/actionTypes';
import { 
    refresh, fetch, toggleVisibility, setIsInCreateEditPage, closePromptModal, openPromptModal, updateReadUnreadNotification, updateReadUnreadButtonLoading
} from './stateMutators';

const INITIAL_STATE = {
    visible: false,
    refresh: false,
    countTotal: 0,
    countUnread: 0,
    notificationsList: {},

    isInCreateEditPage: false,
    onLinkClickPromptModal: {
        visible: false,
        linkRouteName: '',
        linkRouteUrl: '',
        linkName: ''
    } 
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.CLEAR_NOTIFICATIONS_STATE:
            return INITIAL_STATE;
        case actionTypes.SET_IS_IN_CREATE_EDIT_PAGE:
            return setIsInCreateEditPage(state, action);
        case actionTypes.CLOSE_PROMPT_MODAL:
            return closePromptModal(state, action);
        case actionTypes.OPEN_PROMPT_MODAL:
            return openPromptModal(state, action);
        case actionTypes.REFRESH_NOTIFICATIONS:
            // return refresh(state, action);
            return fetch(state, action);
        case actionTypes.FETCH_NOTIFICATIONS:
            return fetch(state, action);
        case actionTypes.TOGGLE_VISIBILITY:
            return toggleVisibility(state, action);
        case actionTypes.UPDATE_READ_UNREAD_BUTTON_LOADING:
            return updateReadUnreadButtonLoading(state, action);
        case actionTypes.UPDATE_NOTIFICATION_READ_UNREAD:
            return updateReadUnreadNotification(state, action);
        default: 
            return state;
    }
}

export default reducer;

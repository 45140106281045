import React from 'react';
import { Dropdown, Icon, Button, Popup } from 'semantic-ui-react';
import { FormButtonProp } from '../../../../enum/apiResultProp/FormButtonProp';

export default ({ buttonProps, onButtonClick, size }) => {
    const buttonName = buttonProps[FormButtonProp.ButtonName];
    const text = buttonProps[FormButtonProp.Text];
    const iconName = buttonProps[FormButtonProp.IconName];
    const showSpinner = buttonProps[FormButtonProp.ShowSpinner];

    // return (
    //     <Button color='blue' onClick={onButtonClick} key={buttonName} >
    //         <Icon name={iconName} />
    //     </Button>
    // );

    return (
        <Popup content={text} trigger={
            <Button onClick={onButtonClick} key={buttonName} >
                <Icon name={iconName} style={{color: '#3C8AD9'}} />
            </Button>
        } />
    );

    // return (
    //     <Dropdown.Item
    //         key={buttonName}
    //         icon={<Icon name={iconName} color='blue' />}
    //         text={<span style={{ color: '#3C8AD9', fontWeight: 'bold' }}>{text}</span>}
    //         onClick={onButtonClick}
    //     />
    // );
}
import React, { useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import DOMPurify from 'dompurify';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import '../../resources/style/RichTextEditor.css';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const RichTextEditor = ({ value, setValue }) => {
    const initEditorStateFromHtml = (html) => {
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
 
        return editorState;
    }

    const getPurifiedHtml = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }

    const getCurrentHtmlContent = () => {
        return draftToHtml(convertToRaw(value.getCurrentContent()));
    }

    return (
        <Editor
            editorState={value}
            toolbarClassName='rich-text-editor-toolbar'
            wrapperClassName='rich-text-editor-wrapper'
            editorClassName='rich-text-editor-textarea'
            onEditorStateChange={setValue}
        />
    );
}

export default RichTextEditor;

import ioms from '../apis/ioms';
import actionTypes from '../actions/actionTypes';
import history from '../history';
import { STRINGS } from '../helpers/strings';
import { ROUTES } from '../helpers/routes';
import { clearNotificationBar } from './notificationBar/actions';
import { addMessage, clearMessages } from './messageActions';
import { MESSAGE_SUCCESS, MESSAGE_ERROR, MESSAGE_WARNING } from '../helpers/const';
import _ from 'lodash';

export const login = (credentials) => async (dispatch, getState) => {
    try {
        const response = await ioms.post('ajaxLogin', { credentials, strings: STRINGS }, { withCredentials: true });

        const { success, result } = response.data;

        if (success) {
            await dispatch(clearMessages());

            await dispatch({
                type: actionTypes.LOGIN,
                payload: result
            });

            const { isLoggedIn, mustChangePassLogic } = getState().auth;
            if (isLoggedIn) {
                const { mustChangePassword, warningMessages } = mustChangePassLogic;
                if (mustChangePassword) {
                    if (warningMessages) {
                        _.forEach(warningMessages, async (warningMessage) => {
                            await dispatch(addMessage(MESSAGE_WARNING, { content: warningMessage, visible: true }));
                        });
                    }

                    history.push(ROUTES.EDIT_PASSWORD_ROUTE.URL);
                } else {
                    history.push(ROUTES.HOME_SCREEN_ROUTE.URL);
                }
            }
        } else {
            let messages = [];
            response.data.messages.map(message => messages.push(message.text))

            dispatch({
                type: actionTypes.UPDATE_LOGIN_VALIDATION_MESSAGES,
                payload: messages
            });
        }
    } catch (error) {
        dispatch({
            type: actionTypes.UPDATE_LOGIN_VALIDATION_MESSAGES,
            payload: [error.message]
        });
    }
}

export const editPassword = (data) => async (dispatch, getState) => {
    try {
        const response = await ioms.post('do/edit/password', data, { withCredentials: true });

        if (response.data.success) {
            await dispatch(clearEditPasswordFormInput());

            await dispatch(addMessage(MESSAGE_SUCCESS, { content: response.data.messages[0].text, visible: true }));

            history.push(ROUTES.HOME_SCREEN_ROUTE.URL);
        } else {
            let messages = [];
            response.data.messages.map(message => messages.push(message.text))

            dispatch({
                type: actionTypes.UPDATE_LOGIN_VALIDATION_MESSAGES,
                payload: messages
            });
        }
    } catch (error) {
        dispatch({
            type: actionTypes.UPDATE_LOGIN_VALIDATION_MESSAGES,
            payload: [error.message]
        });
    }
}

export const automaticLogout = () => async (dispatch, getState) => {
    const response = await ioms.get('automaticLogout', { withCredentials: true });

    await dispatch(clearNotificationBar());

    dispatch({
        type: actionTypes.LOGOUT,
        payload: response.data.result
    });

    history.push('/login');
}

export const clearSession = () => async (dispatch, getState) => {
    const response = await ioms.get('clearSession', { withCredentials: true });

    await dispatch(clearNotificationBar());

    dispatch({
        type: actionTypes.LOGOUT,
        payload: response.data.result
    });

    history.push('/login');
}

export const updateFormInput = (formName, fieldName, fieldValue) => {
    return {
        type: actionTypes.UPDATE_LOGIN_FORM_INPUT,
        payload: { formName, fieldName, fieldValue }
    };
}

export const updateEditPasswordFormInput = (fieldName, fieldValue) => {
    return {
        type: actionTypes.UPDATE_EDIT_PASSWORD_FORM_INPUT,
        payload: { fieldName: fieldName, fieldValue: fieldValue }
    };
}

export const clearEditPasswordFormInput = () => {
    return {
        type: actionTypes.CLEAR_EDIT_PASSWORD_FORM_INPUT,
    };
}

export const updateLoginFormValidationMessages = (messages) => {
    return {
        type: actionTypes.UPDATE_LOGIN_VALIDATION_MESSAGES,
        payload: messages
    };
}

export const generateValidationCodeForEmail = (formName, buttonName, email) => async (dispatch, getState) => {
    dispatch({
        type: actionTypes.NEW_UPDATE_FORM_BUTTON_IN_ACTION,
        payload: { formName: formName, buttonName: buttonName, isInAction: true }
    });

    try {
        const response = await ioms.post('generate-validation-code/for-registration', { email: email }, { withCredentials: true });
        const { result, message } = response.data.result;

        if (result) {
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_SUCCESS_MESSAGE,
                payload: { message: message, formName: formName }
            });
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_ERROR_MESSAGE,
                payload: { message: '', formName: formName }
            });
        } else {
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_ERROR_MESSAGE,
                payload: { message: message, formName: formName }
            });
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_SUCCESS_MESSAGE,
                payload: { message: '', formName: formName }
            });
        }
    } catch (error) {
        dispatch({
            type: actionTypes.NEW_UPDATE_FORM_ERROR_MESSAGE,
            payload: { message: error.message, formName: formName }
        });
        dispatch({
            type: actionTypes.NEW_UPDATE_FORM_SUCCESS_MESSAGE,
            payload: { message: '', formName: formName }
        });
    }

    dispatch({
        type: actionTypes.NEW_UPDATE_FORM_BUTTON_IN_ACTION,
        payload: { formName: formName, buttonName: buttonName, isInAction: false }
    });

}

export const generateValidationCodeForUsername = (formName, buttonName, username, password) => async (dispatch, getState) => {
    dispatch({
        type: actionTypes.NEW_UPDATE_FORM_BUTTON_IN_ACTION,
        payload: { formName: formName, buttonName: buttonName, isInAction: true }
    });

    try {
        const response = await ioms.post('generate-validation-code/for-login', { username: username, password: password }, { withCredentials: true });
        const { result, message } = response.data.result;

        if (result) {
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_SUCCESS_MESSAGE,
                payload: { message: message, formName: formName }
            });
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_ERROR_MESSAGE,
                payload: { message: '', formName: formName }
            });
        } else {
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_ERROR_MESSAGE,
                payload: { message: message, formName: formName }
            });
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_SUCCESS_MESSAGE,
                payload: { message: '', formName: formName }
            });
        }
    } catch (error) {
        dispatch({
            type: actionTypes.NEW_UPDATE_FORM_ERROR_MESSAGE,
            payload: { message: error.message, formName: formName }
        });
        dispatch({
            type: actionTypes.NEW_UPDATE_FORM_SUCCESS_MESSAGE,
            payload: { message: '', formName: formName }
        });
    }

    dispatch({
        type: actionTypes.NEW_UPDATE_FORM_BUTTON_IN_ACTION,
        payload: { formName: formName, buttonName: buttonName, isInAction: false }
    });
}

export const generateValidationCodeForPasswordForgot = (formName, buttonName, username) => async (dispatch, getState) => {
    dispatch({
        type: actionTypes.NEW_UPDATE_FORM_BUTTON_IN_ACTION,
        payload: { formName: formName, buttonName: buttonName, isInAction: true }
    });

    try {
        const response = await ioms.post('generate-validation-code/for-password-forgot', { username: username }, { withCredentials: true });
        const { result, message } = response.data.result;

        if (result) {
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_SUCCESS_MESSAGE,
                payload: { message: message, formName: formName }
            });
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_ERROR_MESSAGE,
                payload: { message: '', formName: formName }
            });
        } else {
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_ERROR_MESSAGE,
                payload: { message: message, formName: formName }
            });
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_SUCCESS_MESSAGE,
                payload: { message: '', formName: formName }
            });
        }
    } catch (error) {
        dispatch({
            type: actionTypes.NEW_UPDATE_FORM_ERROR_MESSAGE,
            payload: { message: error.message, formName: formName }
        });
        dispatch({
            type: actionTypes.NEW_UPDATE_FORM_SUCCESS_MESSAGE,
            payload: { message: '', formName: formName }
        });
    }

    dispatch({
        type: actionTypes.NEW_UPDATE_FORM_BUTTON_IN_ACTION,
        payload: { formName: formName, buttonName: buttonName, isInAction: false }
    });
}

export const generateValidationCodeForLoggedUserEmail = (formName, buttonName) => async (dispatch, getState) => {
    dispatch({
        type: actionTypes.NEW_UPDATE_FORM_BUTTON_IN_ACTION,
        payload: { formName: formName, buttonName: buttonName, isInAction: true }
    });

    try {
        const response = await ioms.post('generate-validation-code/for-email-change/old-email', {}, { withCredentials: true });
        const { result, message } = response.data.result;

        if (result) {
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_SUCCESS_MESSAGE,
                payload: { message: message, formName: formName }
            });
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_ERROR_MESSAGE,
                payload: { message: '', formName: formName }
            });
        } else {
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_ERROR_MESSAGE,
                payload: { message: message, formName: formName }
            });
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_SUCCESS_MESSAGE,
                payload: { message: '', formName: formName }
            });
        }
    } catch (error) {
        dispatch({
            type: actionTypes.NEW_UPDATE_FORM_ERROR_MESSAGE,
            payload: { message: error.message, formName: formName }
        });
        dispatch({
            type: actionTypes.NEW_UPDATE_FORM_SUCCESS_MESSAGE,
            payload: { message: '', formName: formName }
        });
    }

    dispatch({
        type: actionTypes.NEW_UPDATE_FORM_BUTTON_IN_ACTION,
        payload: { formName: formName, buttonName: buttonName, isInAction: false }
    });
}

export const generateValidationCodeForEmailChangeNewEmail = (formName, buttonName, newEmail) => async (dispatch, getState) => {
    dispatch({
        type: actionTypes.NEW_UPDATE_FORM_BUTTON_IN_ACTION,
        payload: { formName: formName, buttonName: buttonName, isInAction: true }
    });

    try {
        const response = await ioms.post('generate-validation-code/for-email-change/new-email', { newEmail: newEmail }, { withCredentials: true });
        const { result, message } = response.data.result;

        if (result) {
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_SUCCESS_MESSAGE,
                payload: { message: message, formName: formName }
            });
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_ERROR_MESSAGE,
                payload: { message: '', formName: formName }
            });
        } else {
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_ERROR_MESSAGE,
                payload: { message: message, formName: formName }
            });
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_SUCCESS_MESSAGE,
                payload: { message: '', formName: formName }
            });
        }
    } catch (error) {
        dispatch({
            type: actionTypes.NEW_UPDATE_FORM_ERROR_MESSAGE,
            payload: { message: error.message, formName: formName }
        });
        dispatch({
            type: actionTypes.NEW_UPDATE_FORM_SUCCESS_MESSAGE,
            payload: { message: '', formName: formName }
        });
    }

    dispatch({
        type: actionTypes.NEW_UPDATE_FORM_BUTTON_IN_ACTION,
        payload: { formName: formName, buttonName: buttonName, isInAction: false }
    });
}

export const loginSubmit = (formName, buttonName, backEndUri) => async (dispatch, getState) => {
    dispatch({
        type: actionTypes.NEW_UPDATE_FORM_BUTTON_IN_ACTION,
        payload: { formName: formName, buttonName: buttonName, isInAction: true }
    });

    try {
        const postData = {
            strings: STRINGS,
            inputForm: getState().auth[formName].fields
        };

        const response = await ioms.post(backEndUri, postData, { withCredentials: true });
        const { result, message } = response.data.result;

        if (result) {
            await dispatch(clearMessages());

            const { username, email, mainMenu, translations, settings } = response.data.result;
            dispatch({
                type: actionTypes.NEW_ON_LOGIN_SUCCESS,
                payload: { username, email, mainMenu, translations, settings }
            });

            history.push(ROUTES.HOME_SCREEN_ROUTE.URL);
        } else {
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_ERROR_MESSAGE,
                payload: { message: message, formName: formName }
            });
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_SUCCESS_MESSAGE,
                payload: { message: '', formName: formName }
            });
        }
    } catch (error) {
        dispatch({
            type: actionTypes.NEW_UPDATE_FORM_ERROR_MESSAGE,
            payload: { message: error.message, formName: formName }
        });
        dispatch({
            type: actionTypes.NEW_UPDATE_FORM_SUCCESS_MESSAGE,
            payload: { message: '', formName: formName }
        });
    }

    dispatch({
        type: actionTypes.NEW_UPDATE_FORM_BUTTON_IN_ACTION,
        payload: { formName: formName, buttonName: buttonName, isInAction: false }
    });
}

export const changeEmailSubmit = (formName, buttonName, backEndUri, showSuccessMessage = false) => async (dispatch, getState) => {
    dispatch({
        type: actionTypes.NEW_UPDATE_FORM_BUTTON_IN_ACTION,
        payload: { formName: formName, buttonName: buttonName, isInAction: true }
    });

    try {
        const postData = {
            strings: STRINGS,
            inputForm: getState().auth[formName].fields
        };

        const response = await ioms.post(backEndUri, postData, { withCredentials: true });
        const { result, message } = response.data.result;

        if (result) {
            const { username, email, mainMenu, translations, settings, message } = response.data.result;
            dispatch({
                type: actionTypes.NEW_ON_LOGIN_SUCCESS,
                payload: { username, email, mainMenu, translations, settings }
            });

            history.push(ROUTES.HOME_SCREEN_ROUTE.URL);

            if (showSuccessMessage) {
                await dispatch(addMessage(MESSAGE_SUCCESS, { content: message, visible: true }));
            }
        } else {
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_ERROR_MESSAGE,
                payload: { message: message, formName: formName }
            });
            dispatch({
                type: actionTypes.NEW_UPDATE_FORM_SUCCESS_MESSAGE,
                payload: { message: '', formName: formName }
            });
        }
    } catch (error) {
        dispatch({
            type: actionTypes.NEW_UPDATE_FORM_ERROR_MESSAGE,
            payload: { message: error.message, formName: formName }
        });
        dispatch({
            type: actionTypes.NEW_UPDATE_FORM_SUCCESS_MESSAGE,
            payload: { message: '', formName: formName }
        });
    }

    dispatch({
        type: actionTypes.NEW_UPDATE_FORM_BUTTON_IN_ACTION,
        payload: { formName: formName, buttonName: buttonName, isInAction: false }
    });
}

export const checkAuth = () => async (dispatch, getState) => {
    const response = await ioms.post('ajaxIsLoggedIn', { strings: STRINGS }, { withCredentials: true });

    const { success } = response.data;

    if (success) {
        const { result } = response.data.result;
        if (result) {
            const { username, email, mainMenu, translations, settings } = response.data.result;

            // dispatch(clearMessages());

            dispatch({
                type: actionTypes.NEW_ON_LOGIN_SUCCESS,
                payload: { username, email, mainMenu, translations, settings }
            });
        } else {
            history.push(ROUTES.LOGIN_ROUTE.URL);
        }
    } else {
        let route;

        const { sessionExpired, closedUserLog, permission, shouldChangePassword, shouldAcceptTermsAndConditions } = response.data;
        if (sessionExpired) {
            route = ROUTES.LOGIN_ROUTE.URL;
        } else if (closedUserLog) {
            route = ROUTES.LOGIN_ROUTE.URL;
        } else if (permission) {
            route = ROUTES.HOME_SCREEN_ROUTE.URL;
        } else if (shouldChangePassword) {
            route = ROUTES.EDIT_PASSWORD_ROUTE.URL;
        } else if (shouldAcceptTermsAndConditions) {
            route = ROUTES.TERMS_AND_CONDITIONS_DECISION_SCREEN.URL;
        }

        history.push(route);
    }
}

export const logout = () => async (dispatch, getState) => {
    const response = await ioms.get('logout', { withCredentials: true });

    await dispatch(clearNotificationBar());

    dispatch({
        type: actionTypes.LOGOUT,
    });

    history.push('/login');
}

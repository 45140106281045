import React from 'react';
import { connect } from 'react-redux';
import { Message, Transition } from 'semantic-ui-react';

import { addMessage, deleteMessage } from '../actions/messageActions';
import { MESSAGE_ERROR, MESSAGE_SUCCESS, MESSAGE_WARNING } from '../helpers/const';

class AppMessage extends React.Component {
    onMessageDismiss = (index, type) => {
        this.props.deleteMessage(index, type);
    }
    
    renderErrorMessages = () => {
        return this.props.messages.error.map((messageObject, index) => {
            if (messageObject.visible) {
                return (
                    <Message 
                        negative
                        key={index}
                        header={messageObject.header}
                        content={messageObject.content}
                        onDismiss={() => this.onMessageDismiss(index, MESSAGE_ERROR)}
                        size='small'
                    />
                );
            }

            return null;
        })
    }
    
    renderWarningMessages = () => {
        return this.props.messages.warning.map((messageObject, index) => {
            if (messageObject.visible) {
                // setTimeout(() => this.onMessageDismiss(index, MESSAGE_WARNING), this.props.settings.positiveMessageLiveMilliseconds);
                
                return (
                    <Message 
                        warning
                        key={index}
                        header={messageObject.header}
                        content={messageObject.content}
                        onDismiss={() => this.onMessageDismiss(index, MESSAGE_WARNING)}
                        size='small'
                    />
                );
            }

            return null;
        })
    }
    
    renderSuccessMessages = () => {
        return this.props.messages.success.map((messageObject, index) => {
            if (messageObject.visible) {
                setTimeout(() => this.onMessageDismiss(index, MESSAGE_SUCCESS), this.props.settings.positiveMessageLiveMilliseconds);
                
                return (
                    <Message 
                        positive
                        key={index}
                        header={messageObject.header}
                        content={messageObject.content}
                        onDismiss={() => this.onMessageDismiss(index, MESSAGE_SUCCESS)}
                        size='small'
                    />
                );
            }
            
            return null;
        })
    }
    
    render() {
        if (this.props.isLoggedIn !== true) {
            return null;
        }

        return (
            <Transition.Group duration={500}>
                {this.renderErrorMessages()}
                {this.renderWarningMessages()}
                {this.renderSuccessMessages()}
            
            </Transition.Group>
        )
    }
}

const mapStateToProps = (state) => {
    return { 
        messages: state.message,
        isLoggedIn: state.auth.isLoggedIn,
        settings: state.auth.settings, 
    }
}

export default connect(mapStateToProps, { addMessage, deleteMessage })(AppMessage);


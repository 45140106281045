import React from 'react';
import _ from 'lodash';
import { List } from 'semantic-ui-react';
import { TreeElementProp } from '../../enum/apiResultProp/TreeElementProp';
import TreeElement from './TreeElement';

export default ({ subTreeElements, checkIsSelected, checkIsExpanded, expand, collapse, onElementSelect, mutateWithPostData }) => {
    let orderdByNumber = _.sortBy(subTreeElements, o => o.orderNumber);

    return (
        <List.List>
            {_.map(orderdByNumber, (treeElementProps) => {
                const uniqueKey = treeElementProps[TreeElementProp.UniqueId];

                return (
                    <TreeElement
                        key={uniqueKey}
                        treeElementProps={treeElementProps}
                        checkIsSelected={checkIsSelected}
                        checkIsExpanded={checkIsExpanded}
                        onElementSelect={onElementSelect}
                        mutateWithPostData={mutateWithPostData}
                        expand={expand}
                        collapse={collapse}
                    />
                );
            })}
        </List.List>
    );
}

import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { Menu } from 'semantic-ui-react';
import { ROUTES } from '../../helpers/routes';

export default (props) => {
    const {menuItems} = props; 
    
    return (
        <Menu pointing secondary>
            {
                _.map(menuItems, (menuItem, menuItemKey) => {
                    const {clientRouteName, active, label} = menuItem;
                    
                    return (
                        <Menu.Item 
                            key={menuItemKey}
                            active={active}
                            as={Link}
                            name={ROUTES[clientRouteName].NAME} 
                            to={ROUTES[clientRouteName].URL}
                        >
                            {label}
                        </Menu.Item>
                    );
                })
            }
        </Menu>
    );
}

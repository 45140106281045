import React from 'react';
import _, { size } from 'lodash';
import { Table, Header } from 'semantic-ui-react';

export default ({ title, columnsCount }) => {
    if (! title) {
        return null;
    }

    return (
        <Table.Row>
            <Table.HeaderCell colSpan={columnsCount} style={{ padding: '6px' }} textAlign='center'>
                <Header style={{ fontSize: '1.7em' }} className='grid-blue-color' >{title}</Header>
            </Table.HeaderCell>
        </Table.Row>
    );
}
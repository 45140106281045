import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { Table, Popup, Tab } from 'semantic-ui-react';
import { getValue } from '../../../helpers/functions';

const TableCellWithContextMenu = ({ value, oldValue, appHelpInfo, uniqueKey, setOpenedContextMenuElementUniqueKey, isOpen, translations }) => {
    const contextRef = useRef();
    const [open, setOpen] = useState(false);

    const createContextFromEvent = (e) => {
        const left = e.clientX
        const top = e.clientY
        const right = left + 1
        const bottom = top + 1

        return {
            getBoundingClientRect: () => ({
                left,
                top,
                right,
                bottom,

                height: 0,
                width: 0,
            }),
        }
    }

    let tableCell;
    if (oldValue !== undefined) {
        tableCell = (
            <React.Fragment key={uniqueKey + '_cell'}>
                <Popup
                    position='top center'
                    trigger={
                        <Table.Cell 
                            warning
                            key={uniqueKey + '_cell'}
                            onContextMenu={(e) => {
                                e.preventDefault()

                                contextRef.current = createContextFromEvent(e);
                                setOpenedContextMenuElementUniqueKey(uniqueKey);
                            }} 
                        >
                            {value}
                        </Table.Cell>
                    }
                    content={getValue(translations, "Old Value: ") + oldValue}
                    size='small'
                />
            </React.Fragment>
        );
    } else {
        tableCell = (
            <Table.Cell
                key={uniqueKey + '_cell'}
                onContextMenu={(e) => {
                    e.preventDefault()

                    contextRef.current = createContextFromEvent(e);
                    setOpenedContextMenuElementUniqueKey(uniqueKey);
                }}
            >
                {value}
            </Table.Cell>
        );
    }

    return (
        <>
            {tableCell}

            <Popup
                key={uniqueKey + '_popup'}
                context={contextRef}
                onClose={() => setOpenedContextMenuElementUniqueKey(null)}
                open={isOpen}
                content={
                    <Table celled inverted>
                        <Table.Body>
                            {_.map(appHelpInfo, (infoRowObject, infoRowKey) => {
                                const { infoRowLabel, infoRowValue, infoRowType, infoRowIsVisible } = infoRowObject;

                                if (!infoRowIsVisible) {
                                    return null;
                                }

                                return (
                                    <Table.Row key={infoRowKey}>
                                        <Table.Cell style={{ fontWeight: 'bold' }}>
                                            {infoRowLabel}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {infoRowValue}
                                        </Table.Cell>
                                    </ Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                }
                basic
                flowing
                inverted
            />
        </>
    );
}

export default TableCellWithContextMenu;
export const ActionType = Object.freeze({
    SubmitForm: 'submitForm',
    MutateScreenNoPostDataAction: 'mutateScreenNoPostDataAction',
    ReloadScreenWithPostDataAction: 'reloadScreenWithPostDataAction',
    DownloadFileAction: 'downloadFileAction',
    MutateScreenWithPostDataAction: 'mutateScreenWithPostDataAction',
    RedirectFrontEnd: 'redirectFrontEnd',
    PostDataAndRedirectFrontEnd: 'postDataAndRedirectFrontEnd',
    OpenModalFormAction: 'openModalFormAction',

    MutateComponentWithPostDataAction: 'mutateComponentWithPostDataAction',
    MutateComponentNoPostDataAction: 'mutateComponentNoPostDataAction',
    ExternalRedirect: 'externalRedirect',
    LogoutAction: 'logoutAction',
});

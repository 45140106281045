import actionTypes from "../../actions/readPage/actionTypes";
import { setTreeExpanded, fetch, toggleSpinner } from './stateMutators';

const INITIAL_STATE = {
    title: "",
    showSpinner: true,
    message: { header: '', content: '' },
    fields: {},
    fieldGroups: {},
    PAGE_ACTIONS: [],
    relatedData: {},
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.CLEAR_DETAIL_READ_DATA:
            return INITIAL_STATE;
        case actionTypes.SET_TREE_EXPANDED_READ:
            return setTreeExpanded(state, action);
        case actionTypes.FETCH_DETAIL_READ_DATA:
            return fetch(state, action);
        case actionTypes.TOGGLE_SPINNER_DETAIL_READ_DATA:
            return toggleSpinner(state, action);
        default:
            return state;
    }
}

export default reducer;

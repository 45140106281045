import actionTypes from "../../actions/priceList/actionTypes";
import { 
    fetch, toggleSpinner, closeModal, openModal, updateErrorMessage, clearForm, updateFieldValue, enableSendEmailButton,
    updateFileInfo, updateFormAjax
} from './stateMutators';

const INITIAL_STATE = {
    isOpen: false,
    showSpinner: false,
    errorMessage: '',
    title: '',

    modalFormConfig: {},

    generatedFile: {
        name: '', //fileName
        serverPath: '', //serverFilePath
    },

    actionGenerateAndDownload: { //confirmButton
        visible: false,
        generateUrl: '', //submitUrl
        title: '', //confirmButtonTitle
        downloadUrl: '',
    },
    actionCancel: {
        visible: true,
        title: ''
    },
    actionSendEmail: {
        visible: false,
        title: '',
        disabled: true,
        disabledMessage: '',
        url: '',
    },
    actionReset: {
        visible: false,
        title: '',
        url: ''
    }
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.PRICE_LIST_UPDATE_FORM_AJAX:
            return updateFormAjax(state, action);
        case actionTypes.PRICE_LIST_FETCH_DATA:
            return fetch(state, action);
        case actionTypes.PRICE_LIST_TOGGLE_SPINNER:
            return toggleSpinner(state, action);
        case actionTypes.PRICE_LIST_MODAL_CLOSE:
            return closeModal(state, action);
        case actionTypes.PRICE_LIST_MODAL_OPEN:
            return openModal(state, action);
        case actionTypes.PRICE_LIST_UPDATE_FIELD_VALUE:
            return updateFieldValue(state, action);
        case actionTypes.PRICE_LIST_UPDATE_ERROR_MESSAGE:
            return updateErrorMessage(state, action);
        case actionTypes.PRICE_LIST_CLEAR_FORM:
            return clearForm(state);
        case actionTypes.PRICE_LIST_ENABLE_SEND_EMAIL_BUTTON:
            return enableSendEmailButton(state);
        case actionTypes.PRICE_LIST_UPDATE_FILE_INFO:
            return updateFileInfo(state, action);
        default: 
            return state;
    }
}

export default reducer;

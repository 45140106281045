import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Message, Header, Divider, Form, Button } from 'semantic-ui-react';

import { getRouteObj } from '../../helpers/functions';
import { fetchGridData, execAjax, updateInputField, execAjaxValidation } from '../../actions/sysAdminPanel/existingRecordActions';
import ButtonWithConfirmation from '../customComponents/ButtonWithConfirmation';

class SysAdminPanel extends React.Component {
    state = { routeInfo: getRouteObj(this.props.match.path) };
    
    componentDidMount = () => {
        let fetchUrl = this.state.routeInfo.API_FETCH_URL; 
        this.props.fetchGridData(fetchUrl);
    }

    onInputFieldValueChange = (actionName, value) => {
        this.props.updateInputField(actionName, value);
    }

    renderTitle = () => {
        return (
            <Header as='h2' textAlign='center'>
                <Header.Content>{this.props.data.title}</Header.Content>
            </Header>
        );
    };

    renderMessageBullets = (bullets) => {
        if (_.isUndefined(bullets)) {
            return null;
        }

        return (
            <Message.List>
                {_.map(bullets, (bullet, index) => <Message.Item key={index}>{bullet}</Message.Item>)}
            </Message.List>
        );
    }

    renderMainActionButton = (actionUrl, buttonText, isActive, onButtonClickPopupQuestion, actionName, color, mainActionLoading, postData = {}) => {
        if (isActive) {
            return (
                <ButtonWithConfirmation 
                    translations={this.props.translations}
                    rowId={actionName}
                    question={onButtonClickPopupQuestion}
                    buttonConfirmTitle={buttonText}
                    actionConfirm={() => this.props.execAjax(actionUrl, actionName, postData)}
                    floated={false}
                    buttonColor={color}
                    loading={mainActionLoading}
                    buttonDisabled={mainActionLoading}
                />
            );
        } else {
            return (
                <Button color={color} disabled>
                    {buttonText}
                </Button>
            );
        }
    }

    renderActionWithDescriptionMessage = (action, actionName, color) => {
        const {mainAction, information} = action;
        
        const { actionUrl, buttonText, isActive, onButtonClickPopupQuestion, mainActionLoading } = mainAction;
        const { description, bullets } = information;

        return (
            <React.Fragment key={actionName}>
                <Divider horizontal color={color}>
                    <Header color={color} as='h4'>
                        {buttonText}
                    </Header>
                </Divider>
                <Message>
                    <Message.Header>{description}</Message.Header>
                    {this.renderMessageBullets(bullets)}        
                </Message>

                {this.renderMainActionButton(actionUrl, buttonText, isActive, onButtonClickPopupQuestion, actionName, color, mainActionLoading)}
            </React.Fragment>
        );
    }
    
    renderActionWithDescriptionMessageAndValidationButton = (action, actionName, color) => {
        const {mainAction, information, validateIsExecutableAction} = action;
        
        const { actionUrl, buttonText, isActive, onButtonClickPopupQuestion, mainActionLoading } = mainAction;
        const { description, bullets } = information;
        const { validateUrl, validateButtonText, isExecutableActionLoading } = validateIsExecutableAction;

        return (
            <React.Fragment key={actionName}>
                <Divider horizontal color={color}>
                    <Header color={color} as='h4'>
                        {buttonText}
                    </Header>
                </Divider>
                <Message>
                    <Message.Header>{description}</Message.Header>
                    {this.renderMessageBullets(bullets)}        
                </Message>

                <Button 
                    loading={isExecutableActionLoading} 
                    color={'green'} 
                    disabled={isExecutableActionLoading}
                    onClick={() => this.props.execAjaxValidation(validateUrl, actionName)}
                >
                    {validateButtonText}
                </Button>
                {this.renderMainActionButton(actionUrl, buttonText, isActive, onButtonClickPopupQuestion, actionName, color, mainActionLoading)}
            </React.Fragment>
        );
    }

    sendAjaxWithPostParams = (actionUrl, actionName, value) => {
        const postData = new FormData();
        postData.append('inputField', value);

        this.props.execAjax(actionUrl, actionName, postData)
    }

    renderActionWithInputFieldAndDescriptionMessage = (action, actionName, color) => {
        const {mainAction, information} = action;
        
        const { actionUrl, buttonText, isActive, onButtonClickPopupQuestion, mainActionLoading } = mainAction;
        const { description, bullets } = information;

        const postData = new FormData();
        postData.append('inputField', action.inputField.value);

        return (
            <React.Fragment key={actionName}>
                <Divider horizontal color={color}>
                    <Header color={color} as='h4'>
                        {buttonText}
                    </Header>
                </Divider>
                <Message>
                    <Message.Header>{description}</Message.Header>
                    {this.renderMessageBullets(bullets)}        
                </Message>

                <Form>
                    <Form.Group>
                        {this.renderMainActionButton(actionUrl, buttonText, isActive, onButtonClickPopupQuestion, actionName, color, mainActionLoading, postData)}
                        <Form.Input
                            placeholder={action.inputField.placeholder}
                            value={action.inputField.value}
                            onChange={(event, data) => this.onInputFieldValueChange(actionName, data.value)}
                            rows={1}
                            width={15}
                        />
                    </Form.Group>
                </Form>

                
            </React.Fragment>
        );
    }

    renderActions = () => {
        if (_.isUndefined(this.props.data.actions)) {
            return null;
        }

        const colors = ['brown', 'blue'];
        let index = 0;

        const actions = this.props.data.actions;
        return _.map(actions, (action, actionName) => {
            const {type} = action;

            let currentColor = colors[index++];
            if (index >= colors.length) {
                index = 0;
            }

            if (type == 'ACTION_WITH_DESCRIPTION_MESSAGE') {
                return this.renderActionWithDescriptionMessage(action, actionName, currentColor);
            } else if (type == 'ACTION_WITH_INPUT_FIELD_AND_DESCRIPTION_MESSAGE') {
                return this.renderActionWithInputFieldAndDescriptionMessage(action, actionName, currentColor);
            } else if (type == 'ACTION_WITH_UNLOCK_AJAX') {
                return this.renderActionWithDescriptionMessageAndValidationButton(action, actionName, currentColor);
            } else {
                return <p>Invalid Type</p>;
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                { this.renderTitle() }
                { this.renderActions() }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return { data: state.sysAdminPanel, translations: state.auth.translations };
}

export default connect(mapStateToProps, {fetchGridData, execAjax, updateInputField, execAjaxValidation})(SysAdminPanel);

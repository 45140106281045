export const DataGridProp = Object.freeze({
    Title: 'title',
    Columns: 'columns',
    TotalRowsCount: 'totalRowsCount',
    Titles: 'titles',
    Sorting: 'sorting',
    FilterConfig: 'filterConfig',
    Filters: 'filters',
    AppliedFiltersIds: 'appliedFiltersIds',
    Data: 'data',
    Subtotal: 'subtotal',
    Pagination: 'pagination',
    Buttons: 'buttons',
    LoaderIsActive: 'loaderIsActive',
    LoaderMessage: 'loaderMessage',
    ApplyFilersAction: 'applyFilersAction',
    Selectable: 'selectable',
    SelectedRowIds: 'selectedRowIds',
    UnselectableRowIds: 'unselectableRowIds',
});

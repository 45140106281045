export const saveParams = (state, action) => {
    const { gridRouteName, formData } = action.payload;
    
    return {
        ...state,
        [gridRouteName]: formData
    };
}

export const deleteParams = (state, action) => {
    const { gridRouteName } = action.payload;
    
    return {
        ...state,
        [gridRouteName]:  null
    };
}

export const reset = (defaultState) => {
    return defaultState;
}

import _ from "lodash";
import actionTypes from "./actionTypes";
import ioms from '../../apis/ioms';
import { MESSAGE_ERROR, MESSAGE_SUCCESS } from '../../helpers/const';
import { ROUTES } from '../../helpers/routes';
import { downloadFileByPath } from '../gridPage/existingRecordActions';

import history from '../../history';
import { addMessage } from '../messageActions';

export const fetchGridData = (fetchUrl) => async (dispatch) => {
    try {
        const response = await ioms.get(fetchUrl, { withCredentials: true });
        if (response.data.success) {
            dispatch({
                type: actionTypes.SYS_ADMIN_PANEL_FETCH_DATA,
                payload: response.data.result
            });
        } else {
            const { sessionExpired, closedUserLog, permission, shouldChangePassword, shouldAcceptTermsAndConditions } = response.data;
            
            let route;
            let shouldAddErrorMessage = true;
            if (sessionExpired) {
                route = ROUTES.LOGIN_ROUTE.URL;
            } else if (closedUserLog) {
                route = ROUTES.LOGIN_ROUTE.URL;
            } else if (permission) {
                route = ROUTES.HOME_SCREEN_ROUTE.URL;
            } else if (shouldChangePassword) {
                route = ROUTES.EDIT_PASSWORD_ROUTE.URL;
            } else if (shouldAcceptTermsAndConditions) {
                route = ROUTES.TERMS_AND_CONDITIONS_DECISION_SCREEN.URL;
                shouldAddErrorMessage = false;
            }
            
            if (shouldAddErrorMessage) {
                dispatch(addMessage(MESSAGE_ERROR, { content: response.data.messages[0].text, visible: true }));
            }

            history.push(route);
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
};

export const execAjax = (url, actionName, params = {}) => async (dispatch) => {
    dispatch(toggleMainActionLoading(actionName, true));

    try {
        const response = await ioms.post(url, params, { withCredentials: true });
        const { success, message, hasFiles, files, downloadUrl } = response.data.result.result;

        if (success) {
            dispatch(addMessage(MESSAGE_SUCCESS, { content: message, visible: true }));

            if (hasFiles) {
                _.forEach(files, async file => await dispatch(downloadFileByPath(downloadUrl, file.filePath, file.fileName)))
            }
        } else {
            dispatch(addMessage(MESSAGE_ERROR, { content: message, visible: true }));
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }

    dispatch(toggleMainActionLoading(actionName, false));
};

export const execAjaxValidation = (url, actionName) => async (dispatch) => {
    dispatch(toggleIsExecutableActionLoading(actionName, true));

    try {
        const response = await ioms.get(url, { withCredentials: true });
        const { success, message } = response.data.result.result;

        if (success) {
            dispatch(enableMainAction(actionName));
            dispatch(addMessage(MESSAGE_SUCCESS, { content: message, visible: true }));
        } else {
            dispatch(addMessage(MESSAGE_ERROR, { content: message, visible: true }));
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }

    dispatch(toggleIsExecutableActionLoading(actionName, false));
};

export const enableMainAction = (actionName) => {
    return {
        type: actionTypes.SYS_ADMIN_ENABLE_MAIN_ACTION,
        payload: { actionName }
    }
}

export const toggleMainActionLoading = (actionName, loading) => {
    return {
        type: actionTypes.SYS_ADMIN_TOGGLE_MAIN_ACTION_LOADING,
        payload: { actionName, loading }
    }
}

export const toggleIsExecutableActionLoading = (actionName, loading) => {
    return {
        type: actionTypes.SYS_ADMIN_TOGGLE_IS_EXECUTABLE_ACTION_LOADING,
        payload: { actionName, loading }
    }
}

export const updateInputField = (actionName, value) => {
    return {
        type: actionTypes.SYS_ADMIN_PANEL_UPDATE_INPUT_FIELD,
        payload: { actionName, value }
    }
}



import _ from 'lodash';

export const open = (state, action) => {
    let {
        confirmUrl, title, elements, statusUnchecked, statusDisabled, allValuesHidden, 
        allValuesNotHidden, allValuesNotHiddenAndHidden, showHiddenElementsLogic
    } = action.payload;

    if (_.isUndefined(showHiddenElementsLogic)) {
        showHiddenElementsLogic = true;
    }

    if (_.isUndefined(elements)) {
        elements = [];
    }

    if (_.isUndefined(statusUnchecked)) {
        statusUnchecked = [];
    }

    if (_.isUndefined(statusDisabled)) {
        statusDisabled = [];
    }

    if (_.isUndefined(allValuesHidden)) {
        allValuesHidden = [];
    }

    if (_.isUndefined(allValuesNotHidden)) {
        allValuesNotHidden = [];
    }

    if (_.isUndefined(allValuesNotHiddenAndHidden)) {
        allValuesNotHiddenAndHidden = [];
    }

    return {
        ...state,
        isOpen: true,
        showHiddenElementsLogic: showHiddenElementsLogic,
        title: title,
        confirmUrl: confirmUrl,
        statusUnchecked: statusUnchecked,
        statusDisabled: statusDisabled,
        elements: elements,
        allValuesHidden: allValuesHidden,
        allValuesNotHidden: allValuesNotHidden,
        allValuesNotHiddenAndHidden: allValuesNotHiddenAndHidden,
    };
}

export const close = (INITIAL_STATE) => {
    return INITIAL_STATE;
}

export const toggleSpinner = (state, action) => {
    const showSpinnerNewVal = ! state.showSpinner;

    return {...state, showSpinner: showSpinnerNewVal };
}

export const toggleShowHiddenElements = (state, action) => {
    const showHiddenElementsNewValue = ! state.showHiddenElements;

    return {...state, showHiddenElements: showHiddenElementsNewValue };
}

export const toggleStatus = (state, action) => {
    const { value, isChecked, isUnchecked } = action.payload;
    if (isUnchecked) {
        state = {
            ...state,
            statusUnchecked: state.statusUnchecked.filter(elem => elem !== value),
            statusChecked: [...state.statusChecked, value],
        }
    } else if (isChecked) {
        state = {
            ...state,
            statusChecked: state.statusChecked.filter(elem => elem !== value),
            statusUnchecked: [...state.statusUnchecked, value],
        }
    }

    return state;
}

export const setStatusCheckedAll = (state, action) => {
    const {allValuesNotHidden} = state;

    state = {
        ...state,
        statusChecked: allValuesNotHidden,
        statusUnchecked: [],
    }

    return state;
}

export const setStatusUncheckedAll = (state, action) => {
    const {allValuesNotHidden} = state;
    
    state = {
        ...state,
        statusUnchecked: allValuesNotHidden,
        statusChecked: [],
    }

    return state;
}

export const updateError = (state, action) => {
    return {
        ...state, 
        validationMessages: action.payload  
    };
}


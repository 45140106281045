import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import { Modal, Button, List, Dimmer, Loader, TransitionablePortal, Transition, Message, Icon, Checkbox, Segment } from 'semantic-ui-react';

import { 
    open, close, toggleStatus, setStatusCheckedAll, setStatusUncheckedAll, toggleShowHiddenElements, submit  
} from '../../actions/checkList/actions';
import {getValue} from '../../helpers/functions';
import { STRINGS } from '../../helpers/strings';

class CheckListModal extends React.Component {
    renderSingleElement = (elem) => {
        const {statusUnchecked, statusChecked, statusDisabled, showHiddenElements} = this.props.data;
        const {label, value, hideFromExport} = elem;
        
        const statusUncheckedIcon = 'square outline';
        const statusUncheckedIconColor = 'black';
        const statusCheckedIcon = 'check square outline';
        const statusCheckedIconColor = 'blue';
        const statusDisabledIcon = 'cancel';
        const statusDisabledIconColor = 'black';

        let statusIcon = '';
        let statusIconColor = '';
        let isUnchecked = false;
        let isChecked = false;
        let isDisabled = false;

        if (statusUnchecked.includes(value)) {
            statusIcon = statusUncheckedIcon;
            statusIconColor = statusUncheckedIconColor;
            isUnchecked = true;
        } else if (statusChecked.includes(value)) {
            statusIcon = statusCheckedIcon;
            statusIconColor = statusCheckedIconColor;
            isChecked = true;
        } else if (statusDisabled.includes(value)) {
            statusIcon = statusDisabledIcon;
            statusIconColor = statusDisabledIconColor;
            isDisabled = true;
        }

        const paramsForChangeStatus = {value, isChecked, isUnchecked};
        
        let visible = true;
        if (showHiddenElements === false && hideFromExport === 1) {
            visible = false;
        }

        return (
            <Transition key={value} visible={visible} animation='slide down' duration={300} unmountOnHide>
                <List.Item key={value}>
                    <List.Icon 
                        name={statusIcon}
                        color={statusIconColor}
                        onClick={() => {
                            if (isDisabled === false) {
                                this.props.toggleStatus(paramsForChangeStatus);
                            }
                        }}
                        size='large'
                    />
                    <List.Content style={{paddingTop: '0.25em'}}>
                        <List.Header style={{color: statusIconColor}}>{label}</List.Header>
                    </List.Content>
                </List.Item>
            </Transition>
        );
    }

    renderCheckList = () => {
        const {elements} = this.props.data;
        if (_.isEmpty(elements)) {
            return null;
        }

        return (
            <List>
                { _.map(elements, elem => this.renderSingleElement(elem, true)) }
            </List>
        );
    }

    renderErrorMessages = () => {
        if (this.props.data.validationMessages.length > 0) {
            return(
                <Message
                    error
                    header='Error'
                    list={this.props.data.validationMessages}
                />
            );
        }
    }

    renderActions = () => {
        const { showHiddenElementsLogic } = this.props.data;

        let showHiddenCheckbox = ( 
            <Segment compact floated='right'>
                <Checkbox toggle label='Show Hidden' onClick={this.props.toggleShowHiddenElements} />
            </Segment>
        );
        if (showHiddenElementsLogic === false) {
            showHiddenCheckbox = null;
        }

        return (
            <Modal.Content>
                {showHiddenCheckbox}
                <Button icon floated='left' color='blue' onClick={this.props.setStatusCheckedAll}>
                    <Icon size='large' name='check square outline' />
                </Button>
                <Button icon floated='left' onClick={this.props.setStatusUncheckedAll}>
                    <Icon size='large' name='square outline' />
                </Button>
                
            </Modal.Content>
        );
    }

    render = () => {
        const {isOpen, title, confirmUrl, showSpinner, statusChecked} = this.props.data; 

        const postData = {statusChecked};

        return (
            <TransitionablePortal 
                onClose={this.props.close} 
                open={isOpen} 
                transition={{animation: 'slide down', duration: 400}}
            >
                <Modal closeOnEscape={false} open={true} size='large'>
                    <Dimmer active={showSpinner} inverted>
                        <Loader inverted size='huge'>Loading</Loader>
                    </Dimmer>
                    <Modal.Header>
                        {title}
                    </Modal.Header>
                    {this.renderActions()}
                    <Modal.Content scrolling>
                        {this.renderErrorMessages()}
                        {this.renderCheckList()}        
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='black' onClick={this.props.close}>
                            {getValue(this.props.translations, STRINGS.BUTTON_CANCEL)}
                        </Button>
                        <Button
                            content={getValue(this.props.translations, STRINGS.BUTTON_CONFIRM)}
                            onClick={() => this.props.submit(confirmUrl, postData)}
                            positive
                        />
                    </Modal.Actions>
                </Modal>
            </TransitionablePortal>
        );
    }
}

const mapStateToProps = (state) => {
    return { 
        translations: state.auth.translations,
        data: state.checkList, 
    };
}

const actions = {open, close, toggleStatus, setStatusCheckedAll, setStatusUncheckedAll, toggleShowHiddenElements, submit};

export default connect(mapStateToProps, actions)(CheckListModal);

import React from 'react';
import _ from 'lodash';
import { Icon, List } from 'semantic-ui-react';
import { TreeProp } from '../../enum/apiResultProp/TreeProp';
import { TreeElementProp } from '../../enum/apiResultProp/TreeElementProp';
import TreeElement from './TreeElement';

export default ({ 
    screenComponentProps, 
    uniqueKey,
    expandAll, 
    collapseAll,
    expand,
    collapse,
    onElementSelect,
    mutateWithPostData
}) => {
    const treeElements = screenComponentProps[TreeProp.Elements];
    const selectedElementUniqueId = screenComponentProps[TreeProp.SelectedElementUniqueId];
    const expandedElements = screenComponentProps[TreeProp.ExpandedElements];

    const checkIsSelected = (elementUniqueId) => {
        return elementUniqueId === selectedElementUniqueId;
    }

    const checkIsExpanded = (elementUniqueId) => {
        return _.includes(expandedElements, elementUniqueId);
    }

    let orderdByNumber = _.sortBy(treeElements, o => o.orderNumber);

    return (
        <React.Fragment key={uniqueKey}>
            <Icon
                name='plus square outline'
                size='large'
                color='blue'
                link
                onClick={expandAll}
            />
            <Icon
                name='minus square outline'
                size='large'
                color='red'
                link
                onClick={collapseAll}
            />
            
            <List size='big' style={{paddingLeft: '5px'}}>
                {_.map(orderdByNumber, treeElementProps => {
                    const uniqueKey = treeElementProps[TreeElementProp.UniqueId];

                    return ( 
                        <TreeElement 
                            key={uniqueKey} 
                            treeElementProps={treeElementProps}
                            checkIsSelected={checkIsSelected}
                            checkIsExpanded={checkIsExpanded}
                            expand={expand}
                            collapse={collapse}
                            onElementSelect={onElementSelect} 
                            mutateWithPostData={mutateWithPostData} 
                        />
                    );
                })}
            </List>
        </React.Fragment>
    );
}

import _ from 'lodash';
import {EMPTY_VALUE} from '../../helpers/const';

export const refresh = (state, action) => {
    const { forDelete, forAdd, countTotal, countUnreadToAdd } = action.payload;

    const currentCountUnread = state.countUnread;
    const newCountUnread = currentCountUnread + countUnreadToAdd;

    if (forDelete !== EMPTY_VALUE) {
        state = {
            ...state,
            notificationsList: _.omit(state.notificationsList, forDelete)
        };
    }

    if (forAdd !== EMPTY_VALUE) {
        _.forEach(forAdd, (notification, id) => {
            state = {
                ...state,
                notificationsList: {
                    ...state.notificationsList,
                    [id]: notification
                }
            }                    
        });
    }

    state = { ...state, countTotal: countTotal, countUnread: newCountUnread };

    return state;
}

export const fetch = (state, action) => {
    const {notificationsList, countTotal, countUnread} = action.payload;

    if (notificationsList) {
        state = {
            ...state,
            notificationsList: {}
        };
        
        _.forEach(notificationsList, (notification, id) => {
            state = {
                ...state,
                notificationsList: {
                    ...state.notificationsList,
                    [id]: notification
                },
            };                    
        });
    }

    state = {
        ...state,
        countTotal: countTotal,
        countUnread: countUnread,
        refresh: true,
    }
    
    return state;
}

export const toggleVisibility = (state, action) => {
    const newVisibilityStatus = ! state.visible;
        
    return {
        ...state,
        visible: newVisibilityStatus
    };
}

export const openPromptModal = (state, action) => {
    const { linkRouteName, linkRouteUrl, linkName } = action.payload;
        
    return {
        ...state,
        onLinkClickPromptModal: {
            ...state.onLinkClickPromptModal,
            visible: true,
            linkRouteName: linkRouteName,
            linkRouteUrl: linkRouteUrl,
            linkName: linkName,
        }
    };
}

export const closePromptModal = (state, action) => {
    return {
        ...state,
        onLinkClickPromptModal: {
            ...state.onLinkClickPromptModal,
            visible: false,
            linkRouteName: '',
            linkRouteUrl: '',
            linkName: '',
        }
    };
}

export const setIsInCreateEditPage = (state, action) => {
    const {isInCreateEditPage} = action.payload;
        
    return {
        ...state,
        isInCreateEditPage: isInCreateEditPage
    };
}

export const updateReadUnreadButtonLoading = (state, action) =>
{
    const { notificationId, loading } = action.payload;

    return {
        ...state,
        notificationsList: {
            ...state.notificationsList,
            [notificationId]: {
                ...state.notificationsList[notificationId],
                readUnreadAction: {
                    ...state.notificationsList[notificationId].readUnreadAction,
                    readUnreadActionLoading: loading
                },
            } 
        }
    };
}

export const updateReadUnreadNotification = (state, action) => {
    const { notificationId, readUnreadAction, readUnreadIcon } = action.payload;
    const { readUnreadActionUrl, readUnreadActionLabel, readUnreadActionColor, readUnreadActionIsRead } = readUnreadAction;    
    const { iconLabel, iconColor, iconName } = readUnreadIcon;

    let currentCountUnread = state.countUnread;
    let newCountUnread;
    if (readUnreadActionIsRead) {
        newCountUnread = currentCountUnread - 1;
    } else {
        newCountUnread = currentCountUnread + 1;
    }

    return {
        ...state,
        countUnread: newCountUnread,
        notificationsList: {
            ...state.notificationsList,
            [notificationId]: {
                ...state.notificationsList[notificationId],
                readUnreadAction: {
                    ...state.notificationsList[notificationId].readUnreadAction,
                    readUnreadActionUrl: readUnreadActionUrl,
                    readUnreadActionLabel: readUnreadActionLabel,
                    readUnreadActionColor: readUnreadActionColor,
                    readUnreadActionIsRead: readUnreadActionIsRead,
                },
                readUnreadIcon: {
                    ...state.notificationsList[notificationId].readUnreadIcon,
                    iconLabel: iconLabel, 
                    iconColor: iconColor, 
                    iconName: iconName
                }
            } 
        }
    };
}


import React from 'react';
import _ from 'lodash';
import { List, Transition } from 'semantic-ui-react';
import { TreeElementProp } from '../../enum/apiResultProp/TreeElementProp';
import { TreeElementType } from '../../enum/TreeElementType';
import SubTree from './SubTree';

export default ({
    treeElementProps,
    checkIsSelected,
    checkIsExpanded,
    expand,
    collapse,
    onElementSelect,
    mutateWithPostData
}) => {
    const orderNumber = treeElementProps[TreeElementProp.OrderNumber];
    const elements = treeElementProps[TreeElementProp.Elements];
    const treeElementUniqueId = treeElementProps[TreeElementProp.UniqueId];
    const text = treeElementProps[TreeElementProp.Text];
    const iconName = treeElementProps[TreeElementProp.IconName];
    const iconColor = treeElementProps[TreeElementProp.IconColor];
    const onClickGetEvent = treeElementProps[TreeElementProp.OnClickGetEvent];
    const elementType = treeElementProps[TreeElementProp.ElementType];

    const isExpanded = checkIsExpanded(treeElementUniqueId);

    const onElementClick = async () => {
        if (! checkIsSelected(treeElementUniqueId)) {
            await onElementSelect({ treeElementUniqueId });

            const { action } = onClickGetEvent;
            if (action) {
                mutateWithPostData(action.backEndUrl);
            }
        }
    }

    let treeFunctionalityIcon = null;
    if (elementType === TreeElementType.Branch) {
        if (isExpanded) {
            treeFunctionalityIcon = <List.Icon name='minus square outline' color='red' link onClick={() => collapse({ treeElementUniqueId })} />;
        } else {
            treeFunctionalityIcon = <List.Icon name='plus square outline' color='blue' link onClick={() => expand({ treeElementUniqueId })} />;
        }
    }

    const listHeaderProps = {};
    if (onClickGetEvent) {
        listHeaderProps.as = 'a';
        listHeaderProps.onClick = onElementClick;
    }
    if (checkIsSelected(treeElementUniqueId)) {
        listHeaderProps.style = { background: '#f0f0f0', padding: '2px', borderRadius: '6%' };
    }

    const renderSubtreeRecursive = () => {
        if (elementType === TreeElementType.Leaf || !isExpanded || !elements) {
            return null;
        }

        return (
            <SubTree
                subTreeElements={elements}
                checkIsSelected={checkIsSelected}
                checkIsExpanded={checkIsExpanded}
                expand={expand}
                collapse={collapse}
                onElementSelect={onElementSelect}
                mutateWithPostData={mutateWithPostData}
            />
        );
    }

    return (
        <Transition key={treeElementUniqueId} visible={true} animation='slide down' duration={300} unmountOnHide>
            <List.Item key={treeElementUniqueId}>
                {treeFunctionalityIcon}
                <List.Content>
                    <List.Header
                        content={text}
                        {...listHeaderProps}
                    />
                    {renderSubtreeRecursive()}
                </List.Content>
            </List.Item>
        </Transition>
    );
}

import React from 'react';
import { Table } from 'semantic-ui-react';
import { TableDataCellProp } from '../../../../enum/apiResultProp/TableDataCellProp';

export default ({ tableDataCellProps }) => {
    const columnName = tableDataCellProps[TableDataCellProp.ColumnName];
    const posNumber = tableDataCellProps[TableDataCellProp.PositionNumber];
    const text = tableDataCellProps[TableDataCellProp.Text];

    return <Table.Cell>{text}</Table.Cell>;
}
import React from 'react';
import { connect } from 'react-redux';
import {
    login, updateFormInput, generateValidationCodeForLoggedUserEmail, changeEmailSubmit
} from '../actions/authActions';
import { Grid, Header, Icon, Form, Segment, Button, Message } from 'semantic-ui-react';

class ChangePassword extends React.Component {
    onInputChange = (formName, fieldName, fieldValue) => {
        this.props.updateFormInput(formName, fieldName, fieldValue);
    }

    onCodeGenerateClick = (formName, buttonName, email) => {
        this.props.generateValidationCodeForLoggedUserEmail(formName, buttonName, email);
    }

    onSubmitClick = () => {
        this.props.changeEmailSubmit('changePasswordForm', 'submitForm', 'change-password', true);
    }

    renderErrorMessage = (message) => {
        if (!message) {
            return null;
        }

        return (
            <Message error size='mini' style={{ marginTop: '0px' }}>
                {message}
            </Message>
        );
    }

    renderSuccessMessage = (message) => {
        if (!message) {
            return null;
        }

        return (
            <Message success size='mini' style={{ marginTop: '0px' }}>
                {message}
            </Message>
        );
    }

    renderForm = () => {
        return (
            <Grid centered columns={1} textAlign='center' style={{ marginTop: '0.5em' }}>
                <Grid.Row>
                    <Grid.Column centered style={{ width: '40%' }}>
                        <Segment raised>
                            <Header as='h4' style={{color: "#3C8AD9"}} textAlign='center'>
                                <Icon name="key" /> Промяна на Парола
                            </Header>
                            {this.renderErrorMessage(this.props.auth.changePasswordForm.message.errorMessage)}
                            {this.renderSuccessMessage(this.props.auth.changePasswordForm.message.successMessage)}
                            <Form size='tiny' warning>
                                <Form.Input
                                    action={{
                                        loading: this.props.auth.changePasswordForm.buttonIsInAction.generateValidationCode,
                                        disabled: this.props.auth.changePasswordForm.buttonIsInAction.generateValidationCode,
                                        size: 'tiny',
                                        content: 'Изпрати ми верификационен код',
                                        style: {backgroundColor: "#3C8AD9", color: 'white'},
                                        onClick: () => this.onCodeGenerateClick('changePasswordForm', 'generateValidationCode', this.props.auth.email),
                                        icon: 'keyboard'
                                    }}
                                    icon='mail'
                                    iconPosition='left'
                                    label='Имейл'
                                    readOnly={true}
                                    value={this.props.auth.email}
                                />
                                <Form.Input
                                    icon='keyboard'
                                    iconPosition='left'
                                    label='Въведи кода получен по имейл'
                                    value={this.props.auth.changePasswordForm.fields.verificationCode}
                                    onChange={(e) => this.onInputChange('changePasswordForm', 'verificationCode', e.target.value)}
                                />
                                <Form.Input
                                    type='password'
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    label='Стара Парола'
                                    value={this.props.auth.changePasswordForm.fields.oldPassword}
                                    onChange={(e) => this.onInputChange('changePasswordForm', 'oldPassword', e.target.value)}
                                />
                                <Form.Input
                                    type='password'
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    label='Нова Парола'
                                    value={this.props.auth.changePasswordForm.fields.newPassword}
                                    onChange={(e) => this.onInputChange('changePasswordForm', 'newPassword', e.target.value)}
                                />
                                <Form.Input
                                    type='password'
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    label='Потвърди Нова Парола'
                                    value={this.props.auth.changePasswordForm.fields.newPasswordConfirm}
                                    onChange={(e) => this.onInputChange('changePasswordForm', 'newPasswordConfirm', e.target.value)}
                                />
                                <Button
                                    loading={this.props.auth.changePasswordForm.buttonIsInAction.submitForm}
                                    disabled={this.props.auth.changePasswordForm.buttonIsInAction.submitForm}
                                    color='teal'
                                    fluid
                                    size='tiny'
                                    onClick={this.onSubmitClick}
                                    style={{backgroundColor: "#3C8AD9", color: 'white'}}
                                >
                                    Промени Парола
                                </Button>
                            </Form>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    render() {
        return (
            <>
                {this.renderForm()}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return { auth: state.auth };
}

const actions = { login, updateFormInput, generateValidationCodeForLoggedUserEmail, changeEmailSubmit }
export default connect(mapStateToProps, actions)(ChangePassword);

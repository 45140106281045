import React from 'react';
import _ from 'lodash';
import { Form } from 'semantic-ui-react';

class ComparatorSelector extends React.Component {
    onSelect = (value) => {
        const { comparators, type } = this.props;

        if (type === 'dropdown' || type === 'dropdown_autocomplete') { //Init filter value, when type is 
            switch (value) {
                case 'inList':
                case 'notInList':
                    this.props.filterFormUpdateInput([]);
                    
                    break;
                case 'equals':
                    this.props.filterFormUpdateInput("");

                    break;
                default:
                    break;
            }
        }

        let selectedComparatorKey = _.findKey(comparators, comparator => comparator.columnKey === value);
        this.props.filterFormSelectComparator(selectedComparatorKey);
    }
    
    render = () => {
        const { comparators, selectedComparatorKey, fields, selectedFieldKey } = this.props;
        
        const value = comparators[selectedComparatorKey].columnKey;
        const disabled = fields[selectedFieldKey].columnKey === "";
        
        let options = [];
        _.forEach(comparators, comparator => options.push({ key: comparator.columnKey, value: comparator.columnKey, text: comparator.columnName }));

        return (
            <Form.Field>
                <Form.Select
                    selection 
                    options={options}
                    disabled={disabled}
                    value={value}
                    onChange={(e, {value}) => {this.onSelect(value)}}
                />
            </Form.Field>
        );
    } 
} 

export default ComparatorSelector;

import React from 'react';
import { PopupContentType } from '../../enum/PopupContentType';
import { Popup } from 'semantic-ui-react';
import { PopupProp } from '../../enum/apiResultProp/PopupProp';
import { TextProp } from '../../enum/apiResultProp/TextProp';
import { PopupShowOnType } from '../../enum/PopupShowOnType';
import Table from '../table/table/Table';

export default ({ popupProps, triggerComponent }) => {
    const showOn = popupProps[PopupProp.ShowOn];
    const contentType = popupProps[PopupProp.ContentType];

    let additionalProps = {};
    if (showOn === PopupShowOnType.OnClick) {
        additionalProps.on = 'click';
    } else if (showOn === PopupShowOnType.OnHover) {
        additionalProps.on = 'hover';
    } else if (showOn === PopupShowOnType.OnFieldFocus) {
        additionalProps.on = 'focus';
    }

    let popup = <Popup content='Unregistered Popup Content Type' trigger={triggerComponent} />
    if (contentType === PopupContentType.Text) {
        const textProps = popupProps[PopupProp.TextProps];
        const text = textProps[TextProp.Text];

        popup = <Popup content={text} trigger={triggerComponent} {...additionalProps} />
    } else if (contentType === PopupContentType.Table) {
        const tableProps = popupProps[PopupProp.TableProps];

        const tableComponent = (
            <Table
                key={1}
                uniqueKey={1}
                screenComponentProps={tableProps}
            />
        );

        popup = <Popup content={tableComponent} trigger={triggerComponent} {...additionalProps} />
    }

    return popup;
}

export const FormFieldProp = Object.freeze({
    Value: 'value',
    Placeholder: 'placeholder',
    PositionRow: 'positionRow',
    PositionColumn: 'positionColumn',
    FieldType: 'fieldType',
    Name: 'name',
    ShowLabel: 'showLabel',
    Label: 'label',
    ErrorMessage: 'errorMessage',
    HasError: 'hasError',
    DataType: 'dataType',
    ShowMandatorySign: 'showMandatorySign',
    IsReadOnly: 'isReadOnly',
    Options: 'options',
    DataValidation: 'dataValidation',
    Events: 'events',
    FileDownloadEvent: 'fileDownloadEvent',

    OpenInNewTab: 'openInNewTab',
    FrontEndRoute: 'frontEndRoute',
    FrontEndParams: 'frontEndParams',

    ModalIsOpen: 'modalIsOpen',
    LegendOptions: 'legendOptions',
    FilterValue: 'filterValue',
    TmpValue: 'tmpValue',
    LabelColorAvailable: 'labelColorAvailable',
    LabelColorNotAvailable: 'labelColorNotAvailable',
    LabelColorSelected: 'labelColorSelected',
    LabelSize: 'labelSize',
    WithBorders: 'withBorders',

    ImageSize: 'imageSize',
    ImageRounded: 'imageRounded',
    ImageCircular: 'imageCircular',
    ImageFloated: 'imageFloated',

    Button: 'button',
});
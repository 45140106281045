import React from 'react';
import _, { size } from 'lodash';
import { Table, Dropdown, Pagination, Icon, ButtonGroup, Button } from 'semantic-ui-react';
import { DataGridPaginationProp } from '../../../enum/dataGrid/DataGridPaginationProp';

export default ({ columnsCount, pagination, onRecordsPerPageChange, onPageChange }) => {
    if (! pagination) {
        return null;
    }

    const activePage = pagination[DataGridPaginationProp.ActivePage];
    const totalPages = pagination[DataGridPaginationProp.TotalPages];
    const recordsPerPage = pagination[DataGridPaginationProp.RecordsPerPage]; 
    const recordsPerPageOptions = pagination[DataGridPaginationProp.RecordsPerPageOptions];

    const renderPagination = () => {
        return (
            <Pagination 
                style={{float: 'right', fontSize: '0.8em', color: '#3C8AD9'} }
                activePage={activePage}
                totalPages={totalPages} 
                onPageChange={(e, data) => onPageChange({newActivePage: data.activePage})}

                ellipsisItem={{ content: <Icon name='ellipsis horizontal' style={{ color: '#3C8AD9' }} />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' style={{ color: '#3C8AD9' }} />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' style={{ color: '#3C8AD9' }} />, icon: true }}
                prevItem={{ content: <Icon name='angle left' style={{ color: '#3C8AD9' }} />, icon: true }}
                nextItem={{ content: <Icon name='angle right' style={{ color: '#3C8AD9' }} />, icon: true }}
            />
        );
    }

    const renderPerPageCountSelector = () => {
        return (
            <Dropdown
                className='grid-blue-color'
                compact
                selection
                options={recordsPerPageOptions}
                value={recordsPerPage}
                style={{width: '5em'}}
                onChange={(e, {value}) => onRecordsPerPageChange({recordsPerPage: value})}
                upward
            />
        )
    }

    return (
        <Table.Row>
            <Table.HeaderCell colSpan={columnsCount} style={{padding: '0.4em'}}>
                {renderPagination()}
                {renderPerPageCountSelector()}
            </Table.HeaderCell>
        </Table.Row>
    );
}
import React from 'react';
import _ from 'lodash';
import { Button } from 'semantic-ui-react';
import { ButtonOptionsFormFieldOptionProp } from '../../enum/apiResultProp/buttonOptionsForm/ButtonOptionsFormFieldOptionProp';
import { ActionType } from '../../enum/ActionType';
import { EventProp } from '../../enum/apiResultProp/EventProp';
import { ActionProp } from '../../enum/apiResultProp/ActionProp';

export default ({ props, changeValue, checkIsActiveOption, mutateWithPostData }) => {
    const key = props[ButtonOptionsFormFieldOptionProp.Key];
    const value = props[ButtonOptionsFormFieldOptionProp.Value];
    const text = props[ButtonOptionsFormFieldOptionProp.Text];
    const position = props[ButtonOptionsFormFieldOptionProp.Position];
    const selectedColor = props[ButtonOptionsFormFieldOptionProp.SelectedColor];
    const notSelectedColor = props[ButtonOptionsFormFieldOptionProp.NotSelectedColor];
    const onClickEvent = props[ButtonOptionsFormFieldOptionProp.OnClickEvent];

    let color = null;
    if (checkIsActiveOption(value)) {
        color = selectedColor;
    } else {
        color = notSelectedColor;
    }

    const onClick = async() => {
        if (checkIsActiveOption(value)) {
            return null;
        }

        await changeValue({value});

        if (onClickEvent) {
            const action = onClickEvent[EventProp.Action];
            const backEndUrl = action[ActionProp.BackEndUrl];

            mutateWithPostData(backEndUrl);
        }
    }

    return (
        <Button 
            color={color}
            onClick={onClick}
        >
            {text}
        </Button>
    );
}

import React from 'react';
import _ from 'lodash';
import { Header, Grid } from 'semantic-ui-react';
import { ButtonOptionsFormProp } from '../../enum/apiResultProp/buttonOptionsForm/ButtonOptionsFormProp';
import ButtonFormFieldGroup from './ButtonFormFieldGroup';
import { ButtonOptionsFormFieldGroupProp } from '../../enum/apiResultProp/buttonOptionsForm/ButtonOptionsFormFieldGroupProp';

export default ({ screenComponentProps, changeValue, mutateWithPostData }) => {
    const title = screenComponentProps[ButtonOptionsFormProp.Title];
    const fieldGroups = screenComponentProps[ButtonOptionsFormProp.FieldGroups];

    const renderTitle = () => {
        if (!title) {
            return null;
        }

        return (
            <Header as='h2' style={{ paddingTop: '7%', paddingBottom: '13px' }} textAlign='center'>
                {title}
            </Header>
        );
    }

    const renderGroups = () => {
        if (!fieldGroups) {
            return null;
        }

        const keys = _.keys(fieldGroups);
        const sortedKeys = _.sortBy(keys);

        return (
            <Grid >
                {_.map(sortedKeys, fieldGroupKey => {
                    const fieldGroupProps = fieldGroups[fieldGroupKey];
                    return (
                        <ButtonFormFieldGroup
                            props={fieldGroupProps}
                            key={fieldGroupProps[ButtonOptionsFormFieldGroupProp.GroupName]}
                            changeValue={(params) => changeValue({ ...params, fieldGroupKey })}
                            mutateWithPostData={mutateWithPostData}
                        />
                    );
                })}
            </Grid>
        );
    }

    return (
        <React.Fragment>
            {renderTitle()}
            {renderGroups()}
        </React.Fragment>
    );
}

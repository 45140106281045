import actionTypes from './actionTypes';

export const addMessage = (type, message) => {
    return {
        type: actionTypes.ADD_MESSAGE,
        payload: { type: type, message: message }
    };
}

export const deleteMessage = (index, type) => {
    return {
        type: actionTypes.DELETE_MESSAGE,
        payload: { type: type, index: index }
    };
}   

export const clearMessages = () => {
    return {
        type: actionTypes.CLEAR_MESSAGES,
    };
}   

import React from 'react';
import _ from 'lodash';
import { Grid, Transition, Label, Icon } from 'semantic-ui-react';
import { EDIT_FILTER_MODE } from '../../../../helpers/const';

class FiltersGrid extends React.Component {
    shouldComponentUpdate = () => {
        return true;
    }

    onCancelFilterClick = (filterKey, filterIndex) => {
        const {mode, index, filterColumnsDropdown, filterColumnsDropdownSelectedKey} = this.props.filterForm;
        
        if (mode === EDIT_FILTER_MODE && index === filterIndex && filterColumnsDropdown[filterColumnsDropdownSelectedKey].columnKey === filterKey) {
            this.props.onClearClick();
        }
        
        this.props.onCancelFilter(filterKey, filterIndex);
    }

    onCreatedFilterClick(columnKey, index, comparator, value, dataType) {
        const {filterColumnsDropdown} = this.props.filterForm;
        const comparatorDropdown = this.props.comparators[dataType];
        let filterColumnsDropdownSelectedKey;
        let filterComparatorDropdownSelectedKey;

        Object.keys(filterColumnsDropdown).forEach(columnIndex => {
            if (filterColumnsDropdown[columnIndex].columnKey === columnKey) {
                filterColumnsDropdownSelectedKey = columnIndex;
            }
        });

        Object.keys(comparatorDropdown).forEach(comparatorIndex => {
            if (comparatorDropdown[comparatorIndex].columnKey === comparator) {
                filterComparatorDropdownSelectedKey = comparatorIndex;
            }
        });

        this.props.loadFilterForEdit({
            filterColumnsDropdown: filterColumnsDropdown,
            filterColumnsDropdownSelectedKey: filterColumnsDropdownSelectedKey,
            filterComparatorDropdown: comparatorDropdown,
            filterComparatorDropdownSelectedKey: filterComparatorDropdownSelectedKey,
            filterValue: value,
            filterDataType: dataType,
            mode: EDIT_FILTER_MODE,
            index: index,
            isActive: true
        });
    }

    buildFilterValueText = (type, value, columnConfig) => {
        let result = '';

        switch (type) {
            case 'dropdown': 
            case 'dropdown_autocomplete': 
                const multipleValues = Array.isArray(value);
                let selevtedValues = [];
                
                if (multipleValues) {
                    selevtedValues = [...value];
                } else {
                    selevtedValues.push(value);
                }

                let filterValues = _.map(columnConfig.dropDownValues, function(option) {
                    if (_.includes(selevtedValues, option.value)) {
                        return option.text;
                    }
                });
                filterValues = _.without(filterValues, undefined)

                if (multipleValues) {
                    result = "(" + _.join(filterValues, ', ') + ")"
                } else {
                    result = filterValues[0];
                }

                break;
            default:
                result = value;

                break;
        }

        return result;
    }
    
    renderFilterLabels = () => {
        const { filters, comparatorsDictionary } = this.props;
        
        return _.map(filters, (fiter, key) => {
            const {activeFilters, name, type} = fiter;

            return _.map(activeFilters, (activeFilter, index) => {
                const {value, comparator, applied} = activeFilter;

                const color = applied ? 'grey' : 'black'; 
                const iconName = applied ? 'thumbs up outline' : 'thumbs down outline'; 
                const filterValueText = this.buildFilterValueText(type, value, filters[key]);

                return (
                    <Grid.Column computer={3} key={key + '_' + index}>
                        <Label 
                            size='large' 
                            as='a'
                            color={color}
                            onClick={() => this.onCreatedFilterClick(key, index, comparator, value, type)}
                        >
                            <Icon name={iconName} />
                            {`${name} ${comparatorsDictionary[comparator]} ${filterValueText}`}
                            <Icon 
                                name='delete'
                                onClick={e => {
                                    this.onCancelFilterClick(key, index);
                                    e.stopPropagation();
                                }}
                            />
                        </Label>
                    </Grid.Column>
                );
            });
        });
    }

    render () {
        return (
            <Transition.Group as={Grid} duration={500}>
                {this.renderFilterLabels()}
            </Transition.Group>
        )
    } 
} 

export default FiltersGrid;

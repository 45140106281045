import React from 'react';
import { Segment, Dimmer, Loader as SemanticLoader } from 'semantic-ui-react';

export default (props) => {
    return (
        <Segment placeholder basic >
            <Dimmer active inverted>
                <SemanticLoader size='large'>{props.text}</SemanticLoader>
            </Dimmer>
        </Segment>
    );
}

import actionTypes from "./actionTypes";
import { fetchGridDataWithGetParams } from './existingRecordActions';

export const onlyUpdateOnPage = (pageIndex) => {
    return {
        type: actionTypes.UPDATE_ON_PAGE,
        payload: { pageIndex: pageIndex, currentPage: 1 }
    }
}

export const updateOnPage = (pageIndex, fetchUrl) => async (dispatch) => {
    await dispatch(onlyUpdateOnPage(pageIndex));

    dispatch(fetchGridDataWithGetParams(fetchUrl));
}

export const onlyChangePage = (pageNumber) => {
    return {
        type: actionTypes.CHANGE_PAGE,
        payload: { pageNumber: pageNumber }
    }
}

export const changePage = (pageNumber, fetchUrl) => async (dispatch) => {
    await dispatch(onlyChangePage(pageNumber));

    dispatch(fetchGridDataWithGetParams(fetchUrl));
}



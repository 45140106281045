import actionTypes from "./actionTypes";

export const open = (modalInfo) => {
    return {
        type: actionTypes.OPEN,
        payload: {modalInfo}
    };
};

export const close = () => {
    return {
        type: actionTypes.CLOSE,
    };
};

export const toggleStatusOfNodeElem = (data) => {
    return {
        type: actionTypes.TOGGLE_STATUS_NODE,
        payload: data
    };
};

export const toggleStatusOfChildElem = (data) => {
    return {
        type: actionTypes.TOGGLE_STATUS_CHILD,
        payload: data
    };
};

export const toggleExpandOfNodeElem = (data) => {
    return {
        type: actionTypes.TOGGLE_EXPAND_NODE,
        payload: data
    };
};

export const toggleExpandOfChildElem = (data) => {
    return {
        type: actionTypes.TOGGLE_EXPAND_CHILD,
        payload: data
    };
};

export const setStatusCheckedAll = () => {
    return {
        type: actionTypes.STATUS_CHECKED_ALL,
    };
};

export const setStatusCheckedNegativeAll = () => {
    return {
        type: actionTypes.STATUS_CHECKED_NEGATIVE_ALL,
    };
};

export const setStatusUncheckedAll = () => {
    return {
        type: actionTypes.STATUS_UNCHECKED_ALL,
    };
};

export const setExpandYesAll = () => {
    return {
        type: actionTypes.EXPAND_YES_ALL,
    };
};

export const setExpandNoAll = () => {
    return {
        type: actionTypes.EXPAND_NO_ALL,
    };
};

export const toggleSpinner = () => {
    return {
        type: actionTypes.TOGGLE_SPINNER_MODAL_TREE,
    }
}

export const updateError = (errors) => {
    return {
        type: actionTypes.UPDATE_ERROR_MODAL_TREE,
        payload: errors
    }
}

export const onAdditionalInfoFormValueChange = (fieldName, fieldValue) => {
    return {
        type: actionTypes.ADDITIONAL_INFO_FORM_VALUE_CHANGE,
        payload: {fieldName, fieldValue}
    }
}

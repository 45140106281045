import actionTypes from "../../actions/createEditPage/actionTypes";
import { EMPTY_VALUE } from '../../helpers/const';

import {
    handleLock, revisionTreeHandleExpand, revisionTreeHandleCheck, updateFormAjax, updateAllErrors, updateRelatedDataError,
    addRelatedDataRow, removeRelatedDataRow, fetch, updateInput, updateError, toggleSpinner, updateRelatedDataInput,
    updateRelatedDataFieldDisabled, revisionDescriptionUpdateInput, updateRelatedDataInputSliders, updateRelatedDataInputDimensionField,
    updateRelatedDataErrorDimensionField, updateOnStepAction, updateRelatedDataInputButtonOptions
} from './stateMutators';

const INITIAL_STATE = {
    showSpinner: true,
    mode: EMPTY_VALUE,
    invalidFieldsNumber: 0,
    validationMessages: [],
    fields: {},
    fieldGroups: {},
    relatedData: {},
    title: '',
    message: '',
    hasImportFilesLogic: false,
    hideActions: false,
    stepsConfiguration: EMPTY_VALUE,
    lockLogic: {
        hasLockLogic: false,
        isLocked: false,
        requiredFieldsForUnlock: [],
        postParamsFields: [],
        postParamsRelatedData: [],
        urlLock: '',
        urlUnlock: '',

        lockButtonMessageWhenDisabled: '',
        saveButtonDisabled: false,
        saveButtonMessageWhenDisabled: '',
    },
    revisionLogic: {
        revisionBomTreeData: EMPTY_VALUE,
        revisionDescription: '',
        forRevision: false,
        newRevisionDescription: '',
    },
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.REVISION_DESCRIPTION_UPDATE_INPUT:
            return revisionDescriptionUpdateInput(state, action);
        case actionTypes.UPDATE_RELATED_DATA_FIELD_DISABLED:
            return updateRelatedDataFieldDisabled(state, action);
        case actionTypes.UPDATE_ON_STEP_CLICK_AJAX:
            return updateOnStepAction(state, action);
        case actionTypes.HANDLE_LOCK:
            return handleLock(state, action);
        case actionTypes.REVISION_TREE_HANDLE_EXPAND:
            return revisionTreeHandleExpand(state, action);
        case actionTypes.REVISION_TREE_HANDLE_CHECK:
            return revisionTreeHandleCheck(state, action);
        case actionTypes.UPDATE_FORM_AJAX:
            return updateFormAjax(state, action);
        case actionTypes.UPDATE_ALL_ERRORS:
            return updateAllErrors(state, action);
        case actionTypes.UPDATE_RELATED_DATA_ERROR:
            return updateRelatedDataError(state, action);
        case actionTypes.ADD_RELATED_DATA_ROW:
            return addRelatedDataRow(state, action);
        case actionTypes.REMOVE_RELATED_DATA_ROW:
            return removeRelatedDataRow(state, action);
        case actionTypes.FETCH:
            return fetch(state, action);
        case actionTypes.CLEAR_DATA:
            return INITIAL_STATE;
        case actionTypes.UPDATE_INPUT:
            return updateInput(state, action);
        case actionTypes.UPDATE_ERROR:
            return updateError(state, action);
        case actionTypes.TOGGLE_SPINNER_C_E_P:
            return toggleSpinner(state, action);
        case actionTypes.UPDATE_RELATED_DATA_INPUT:
            return updateRelatedDataInput(state, action);
        case actionTypes.UPDATE_RELATED_DATA_INPUT_BUTTON_OPTIONS_FIELD:
            return updateRelatedDataInputButtonOptions(state, action);
        case actionTypes.UPDATE_RELATED_DATA_INPUT_SLIDERS:
            return updateRelatedDataInputSliders(state, action);
        case actionTypes.UPDATE_RELATED_DATA_INPUT_DIMENSION_FIELD:
            return updateRelatedDataInputDimensionField(state, action);
        case actionTypes.UPDATE_RELATED_DATA_ERROR_DIMENSION_FIELD:
            return updateRelatedDataErrorDimensionField(state, action);
        default:
            return state;
    }
}

export default reducer;

export default {
    NEW_UPDATE_FORM_SUCCESS_MESSAGE: 'NEW_UPDATE_FORM_SUCCESS_MESSAGE', 
    NEW_UPDATE_FORM_ERROR_MESSAGE: 'NEW_UPDATE_FORM_ERROR_MESSAGE', 
    NEW_UPDATE_FORM_BUTTON_IN_ACTION: 'NEW_UPDATE_FORM_BUTTON_IN_ACTION', 
    NEW_ON_LOGIN_SUCCESS: 'NEW_ON_LOGIN_SUCCESS', 

    //Login Action Types
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    EDIT_PASSWORD: 'EDIT_PASSWORD',
    UPDATE_LOGIN_FORM_INPUT: 'UPDATE_LOGIN_FORM_INPUT',
    UPDATE_EDIT_PASSWORD_FORM_INPUT: 'UPDATE_EDIT_PASSWORD_FORM_INPUT',
    CLEAR_EDIT_PASSWORD_FORM_INPUT: 'CLEAR_EDIT_PASSWORD_FORM_INPUT',
    UPDATE_LOGIN_VALIDATION_MESSAGES: 'UPDATE_LOGIN_VALIDATION_MESSAGES',
    CHECK_AUTH: 'CHECK_AUTH',
    UPDATE_OFFER_FOR_REVISION: 'UPDATE_OFFER_FOR_REVISION',

    //Grid Modal Action Types
    UPDATE_GRID_FORM_INPUT: 'UPDATE_GRID_FORM_INPUT', 
    UPDATE_GRID_FORM_ERROR: 'UPDATE_GRID_FORM_ERROR', 
    UPDATE_GRID_MODAL_ERROR: 'UPDATE_GRID_MODAL_ERROR', 
    UPDATE_ALL_GRID_FORM_ERRORS: 'UPDATE_ALL_GRID_FORM_ERRORS', 
    CLOSE_GRID_MODAL: 'CLOSE_GRID_MODAL', 
    OPEN_GRID_MODAL_CREATE: 'OPEN_GRID_MODAL_CREATE', 
    OPEN_GRID_MODAL_EDIT: 'OPEN_GRID_MODAL_EDIT', 
    TOGGLE_SPINNER: 'TOGGLE_SPINNER',
    UPDATE_FILED_OBJECT_AJAX: 'UPDATE_FILED_OBJECT_AJAX',
    SET_BOM_TREE_EXPANDED_MODAL: 'SET_BOM_TREE_EXPANDED_MODAL',
    SET_BOM_TREE_CHECKED_MODAL: 'SET_BOM_TREE_CHECKED_MODAL',

    //Errors Action Types
    ADD_MESSAGE: 'ADD_MESSAGE', 
    DELETE_MESSAGE: 'DELETE_MESSAGE', 
    CLEAR_MESSAGES: 'CLEAR_MESSAGES', 

    //Automatic Logout
    GET_AUTOMATIC_LOGOUT_INFO: 'GET_AUTOMATIC_LOGOUT_INFO',
    RESET_AUTOMATIC_LOGOUT_INFO: 'RESET_AUTOMATIC_LOGOUT_INFO',

    //Dashboard
    FETCH_DASHBOARD_DATA: 'FETCH_DASHBOARD_DATA', 
    REFRESH_DASHBOARD_DATA: 'REFRESH_DASHBOARD_DATA', 
};
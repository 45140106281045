import React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import { DataGridSorting } from '../../../enum/dataGrid/DataGridSorting';
import _, { size } from 'lodash';
import GridFilterPopupForm from '../filter/GridFilterPopupForm';

export default ({
    columnName,
    title,
    sortingDirection,
    filterConfig,
    filters,
    appliedFiltersIdsByColumn,
    allColumnFiledOptions,
    onSortClick,
    onApplyFiltersClick,
    onDeleteAllFiltersClick,
    onDeleteAppliedFilterRowClick,
    onDeleteNotAppliedFilterRowClick,
    onCreateRowClick,
    onFilterColumnFieldChange,
    onFilterComparatorFieldChange,
    onFilterValueFieldChange,
    clearAllNotAppliedEmptyFilters,
    translations,
}) => {
    const renderSorting = () => {
        if (sortingDirection === DataGridSorting.NoSort) {
            return null;
        }

        let iconName = 'question';
        let iconColor = 'black';
        let iconSize = '0.75em';
        if (sortingDirection === DataGridSorting.Neutral) {
            iconName = 'sort';
        } else if (sortingDirection === DataGridSorting.Asc) {
            iconName = 'arrow up';
            iconColor = 'red';
            iconSize = '1em'
        } else if (sortingDirection === DataGridSorting.Desc) {
            iconName = 'arrow down';
            iconColor = 'red';
            iconSize = '1em'
        }

        return (
            <Icon 
                name={iconName} 
                color={iconColor} 
                style={{ fontSize: iconSize, marginLeft: '1px', cursor: 'pointer' }} 
                onClick={() => onSortClick({ columnName })} 
            />
        );
    }

    const renderTriggerComponent = (onTrigerClick) => {
        let iconColor = 'black';
        let iconSize = 'small';
        if (size(filters) > 0) {
            iconColor = 'red';
            iconSize = 'small';
        }


        return (
            <Icon 
                name='filter' 
                size={iconSize} 
                color={iconColor} 
                style={{ marginLeft: '1px', cursor: 'pointer', }} 
                onClick={onTrigerClick} 
            />
        );
    }

    const renderFilterIconPopup = () => {
        if (!filterConfig) {
            return null;
        }

        return (
            <GridFilterPopupForm
                triggerComponentRenderFunction={renderTriggerComponent}
                filters={filters}
                appliedFiltersIdsByColumn={appliedFiltersIdsByColumn}
                onApplyFiltersClick={onApplyFiltersClick}
                onDeleteAllFiltersClick={onDeleteAllFiltersClick}
                onDeleteAppliedFilterRowClick={onDeleteAppliedFilterRowClick}
                onDeleteNotAppliedFilterRowClick={onDeleteNotAppliedFilterRowClick}
                onCreateRowClick={onCreateRowClick}
                loadOnColumnName={columnName}
                onFilterColumnFieldChange={onFilterColumnFieldChange}
                onFilterComparatorFieldChange={onFilterComparatorFieldChange}
                onFilterValueFieldChange={onFilterValueFieldChange}
                clearAllNotAppliedEmptyFilters={clearAllNotAppliedEmptyFilters}
                translations={translations}
            />
        )
    }

    return (
        <Table.HeaderCell style={{whiteSpace: 'nowrap'}}>
            {title}
            {renderSorting()}
            {renderFilterIconPopup()}
        </Table.HeaderCell>
    );

    // Old version
    return (
        <Table.HeaderCell>
            <span>
                <span style={{ float: 'left' }}>
                    {title}
                    {renderSorting()}
                </span>
                <span style={{ float: 'right' }}>
                    {renderFilterIconPopup()}
                </span>
            </span>
        </Table.HeaderCell>
    );
}

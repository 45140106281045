import actionTypes from "../actions/sysAdminPanel/actionTypes";

const sysAdminPanelReducer = (state = [], action) => {
    switch (action.type) {
        case actionTypes.SYS_ADMIN_PANEL_FETCH_DATA:
            return {...action.payload};
        case actionTypes.SYS_ADMIN_ENABLE_MAIN_ACTION:
            return {
                ...state,
                actions: {
                    ...state.actions,
                    [action.payload.actionName]: {
                        ...state.actions[action.payload.actionName],
                        mainAction: {
                            ...state.actions[action.payload.actionName].mainAction,
                            isActive: true
                        }
                    }
                }
            };
        case actionTypes.SYS_ADMIN_PANEL_UPDATE_INPUT_FIELD:
            const {actionName, value} = action.payload;
        
            return {
                ...state,
                actions: {
                    ...state.actions,
                    [actionName]: {
                        ...state.actions[actionName],
                        inputField: {
                            ...state.actions[actionName].inputField,
                            value: value
                        }
                    }
                }
            };

        
        //TODO
        case actionTypes.SYS_ADMIN_TOGGLE_MAIN_ACTION_LOADING:
            return {
                ...state,
                actions: {
                    ...state.actions,
                    [action.payload.actionName]: {
                        ...state.actions[action.payload.actionName],
                        mainAction: {
                            ...state.actions[action.payload.actionName].mainAction,
                            mainActionLoading: action.payload.loading
                        }
                    }
                }
            };
        //TODO
        case actionTypes.SYS_ADMIN_TOGGLE_IS_EXECUTABLE_ACTION_LOADING:
            return {
                ...state,
                actions: {
                    ...state.actions,
                    [action.payload.actionName]: {
                        ...state.actions[action.payload.actionName],
                        validateIsExecutableAction: {
                            ...state.actions[action.payload.actionName].validateIsExecutableAction,
                            isExecutableActionLoading: action.payload.loading
                        }
                    }
                }
            };
        
        default: 
            return state;
    }
}

export default sysAdminPanelReducer;

export const ModalFormProp = Object.freeze({
    Fields: 'fields',
    Buttons: 'buttons',
    VerticalAlignCenter: 'verticalAlignCenter',
    Size: 'size',
    IsOpen: 'isOpen',
    Title: 'title',
    ModalMessageIsHidden: 'modalMessageIsHidden',
    ModalMessageText: 'modalMessageText',
    ModalMessageStyle: 'modalMessageStyle',
});

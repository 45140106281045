import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Divider, Dimmer, Loader, Header, Grid, Form, Popup, Icon, Message, Button, Label } from 'semantic-ui-react';

import history from '../../../history';
import { STRINGS } from '../../../helpers/strings';
import { ROUTES } from '../../../helpers/routes';
import { ACTIONS, EMPTY_VALUE } from '../../../helpers/const';
import { getRouteObj, getValue } from '../../../helpers/functions';
import Accordion from '../../customComponents/Accordion';
import StandardDetailsGridRead from './StandardDetailsGridRead';
import ButtonWithConfirmation from '../../customComponents/ButtonWithConfirmation';
import CodeVisualizer from '../../customComponents/CodeVisualizer';
import {
    fetchHeaderFormReadMode, clearForm, downloadPdf, setCheckboxTreeExpanded, deleteEntity, submitAdditionalAction,
    generateAndDownload, confirmRevisionTree
} from '../../../actions/readPage/readActions';
import { downloadFileByPath } from '../../../actions/gridPage/existingRecordActions';
import { fetchPriceListDataAndOpen } from '../../../actions/priceList/actions';
import { Link } from 'react-router-dom';
import { open } from '../../../actions/revisionModalTree/actions';
import PriceListModal from '../../customComponents/PriceListModal';
import RevisionModal from '../../customComponents/RevisionModal';
import HtmlViewer from '../../customComponents/HtmlViewer';


class ReadPage extends React.Component {
    state = { routeInfo: getRouteObj(this.props.match.path) };

    componentDidMount() {
        this.props.fetchHeaderFormReadMode(this.state.routeInfo.API_FETCH_URL, this.props.match.params.id);
    };

    componentWillUnmount = () => {
        this.props.clearForm();
    }

    //This hadles the change in the ID when we are in Read mode -> that way we rerender with the new information
    componentDidUpdate = (prevProps) => {
        const previousId = prevProps.match.params.id;
        const newId = this.props.match.params.id;

        if (previousId != newId) {
            let fetchUrl = this.state.routeInfo.API_FETCH_URL;
            fetchUrl = fetchUrl.replace(':id', newId);

            this.props.fetchHeaderFormReadMode(fetchUrl);
        }
    }

    renderTitle = () => {
        return (
            <Header as='h2' textAlign='center' style={{ marginTop: '0px' }}>
                <Header.Content>{this.props.data.title}</Header.Content>
            </Header>
        );
    }

    onEditClick = () => {
        history.push(this.state.routeInfo.URL_EDIT + this.props.match.params.id);
    }

    onBackClick = () => {
        history.push(this.state.routeInfo.URL_PARENT);
    }

    onBackWithGridPageParamsClick = (routeName, routeParams) => {
        let backUrl = ROUTES[routeName].URL;

        _.forEach(routeParams, (paramValue, paramName) => {
            backUrl = backUrl.replace(':' + paramName, paramValue);
        });

        history.push({ pathname: backUrl, state: { applyBackParams: true } });
    }

    onDeleteEntityConfirm = () => {
        this.props.deleteEntity(this.props.data.deleteUrl, this.state.routeInfo.URL_PARENT);
    }

    downloadPdf = () => {
        this.props.downloadPdf(this.props.data.downloadUrl, this.props.data.downloadFileName);
    }

    renderDownloadButton = () => {
        if (this.props.data.PAGE_ACTIONS !== false && this.props.data.PAGE_ACTIONS.includes(ACTIONS.DOWNLOAD)) {
            return (
                <button
                    className="ui blue button"
                    onClick={this.downloadPdf}
                >
                    <i className="download icon"></i>PDF
                </button>
            )
        }
    }

    renderBackButtonWithGridPageParams = () => {
        if (this.props.data.PAGE_ACTIONS !== false && this.props.data.PAGE_ACTIONS.includes(ACTIONS.BACK_WITH_GRID_FILTERS)) {
            const { backActionWithGridFilter } = this.props.data;

            const { routeName, routeParams, label } = backActionWithGridFilter;

            return (
                <button
                    className="ui teal button"
                    onClick={() => this.onBackWithGridPageParamsClick(routeName, routeParams)}
                >
                    <i className="window arrow alternate circle left outline icon"></i>{label}
                </button>
            );
        }
    }

    renderPriceListAction = () => {
        if (this.props.data.GENERATE_DOCUMENT !== undefined) {
            const { fetchPriceListUrl, label } = this.props.data.GENERATE_DOCUMENT;

            return (
                <React.Fragment>
                    <PriceListModal />

                    <Button icon color='blue' onClick={() => this.props.fetchPriceListDataAndOpen(fetchPriceListUrl)}>
                        <Icon name='download' /> {label}
                    </Button>
                </React.Fragment>

            );
        }
    }

    renderPrintFormTreeModalAndAction = () => {
        if (this.props.data.SEND_OFFER_TREE !== undefined) {
            const { modalInfo, label, disabled, disabledText } = this.props.data.SEND_OFFER_TREE;

            let button = null;
            if (disabled) {
                button = (
                    <Popup
                        content={disabledText}
                        trigger={
                            <Button icon color='blue' style={{ opacity: 0.45 }} onClick={e => e.preventDefault()}>
                                {label}
                            </Button>
                        }
                    />
                );
            } else {
                button = (
                    <Button icon color='blue' onClick={() => this.props.open(modalInfo)}>
                        {label}
                    </Button>
                );
            }

            return (
                <React.Fragment>
                    <RevisionModal
                        translations={this.props.translations}
                        actionConfirm={(postData, confirmUrl) => {
                            const entityId = this.props.match.params.id;
                            let apiFetchUrl = this.state.routeInfo.API_FETCH_URL;

                            this.props.confirmRevisionTree(postData, confirmUrl, apiFetchUrl, entityId);
                        }}
                    />

                    {button}
                </React.Fragment>
            );
        }
    }

    renderAdditionalActionsButtons = () => {
        const { additionalActions } = this.props.data;
        if (additionalActions === undefined || additionalActions === false) {
            return null;
        }
        // const readRoute = this.state.routeInfo.URL.replace(':id', this.props.match.params.id);
        const nextRoute = this.state.routeInfo.URL_PARENT;

        return additionalActions.map((({ url, label }) => {
            return (
                <button
                    key={label}
                    className="ui brown button"
                    onClick={() => {
                        const entityId = this.props.match.params.id;
                        let apiFetchUrl = this.state.routeInfo.API_FETCH_URL;

                        this.props.submitAdditionalAction(url, apiFetchUrl, entityId);
                    }}
                >
                    {label}
                </button>
            );
        }));
    }

    renderEditButton = () => {
        if (this.props.data.PAGE_ACTIONS !== false && this.props.data.PAGE_ACTIONS.includes(ACTIONS.EDIT)) {
            return (
                <button
                    className="ui green button"
                    onClick={this.onEditClick}
                >
                    <i className="save icon"></i>{getValue(this.props.translations, STRINGS.BUTTON_EDIT)}
                </button>
            );
        }

        return null;
    }

    renderDeleteButton = () => {
        if (this.props.data.PAGE_ACTIONS !== false && this.props.data.PAGE_ACTIONS.includes(ACTIONS.DELETE)) {
            return (
                <Grid.Column floated='right' width={5}>
                    <ButtonWithConfirmation
                        translations={this.props.translations}
                        rowId={1}
                        key={'delete_1'}
                        question={getValue(this.props.translations, STRINGS.BUTTON_CONFIRMATION_DEFAULT_QUESTION)}
                        buttonConfirmTitle={getValue(this.props.translations, STRINGS.BUTTON_DELETE)}
                        actionConfirm={this.onDeleteEntityConfirm}
                    />
                </Grid.Column>
            );
        }

        return null;
    }

    renderButtonsArea = () => {
        return (
            <React.Fragment>
                <Grid>
                    <Grid.Column floated='left' width={10}>
                        {this.renderEditButton()}

                        {this.renderBackButtonWithGridPageParams()}

                        {this.renderDownloadButton()}

                        {this.renderPriceListAction()}

                        {this.renderPrintFormTreeModalAndAction()}

                        {this.renderAdditionalActionsButtons()}
                    </Grid.Column>
                    {this.renderDeleteButton()}
                </Grid>
            </React.Fragment>
        );
    }

    renderDimmer = () => {
        return (
            <Dimmer active={this.props.data.showSpinner} inverted>
                <Loader inverted />
            </Dimmer>
        );
    }

    renderReadForm = () => {
        const fields = this.props.data.fields;

        return (
            <Form>
                {Object.keys(this.props.data.fieldGroups).map(groupName => {
                    return (
                        <Form.Group widths='equal' key={groupName}>
                            {Object.keys(this.props.data.fieldGroups[groupName]).map(index => {
                                const fieldName = this.props.data.fieldGroups[groupName][index];
                                const field = fields[fieldName];

                                const { label, value, oldValue, linkInfo, externalLink, isHtml } = field;

                                let formField = null;
                                if (oldValue !== undefined) {
                                    formField = (
                                        <React.Fragment key={fieldName + '_' + index}>
                                            <Popup
                                                trigger={
                                                    <Form.Input
                                                        label={label}
                                                        className={"changedValue"}
                                                        value={value}
                                                        icon={<Icon name='exclamation triangle' inverted color="red" circular />}
                                                    />
                                                }
                                                content={getValue(this.props.translations, "Old Value: ") + oldValue}
                                                size='small'
                                            />
                                        </React.Fragment>
                                    );
                                } else {
                                    if (isHtml === true) {
                                        formField = (
                                            <HtmlViewer
                                                key={fieldName + '_' + index}
                                                html={value}
                                            />
                                        );
                                    } else {
                                        let formFieldLabel = null;
                                        let formFieldStyle = {};

                                        if (!_.isUndefined(linkInfo)) {
                                            const { routeName, routeParams } = linkInfo;

                                            const routeInfo = ROUTES[routeName];
                                            let routeUrl = routeInfo.URL;
                                            if (routeParams) {
                                                _.forEach(routeParams, (paramValue, paramName) => {
                                                    routeUrl = routeUrl.replace(':' + paramName, paramValue);
                                                });
                                            }

                                            formFieldLabel = (
                                                <Link
                                                    to={routeUrl}
                                                >
                                                    <Icon name='external alternate' />
                                                    {label}
                                                </Link>
                                            );

                                            formFieldStyle = { marginTop: '0.29em' };
                                        } else {
                                            formFieldLabel = label;
                                        }

                                        let action = null;
                                        if (!_.isUndefined(externalLink)) {
                                            action = {
                                                color: 'teal',
                                                labelPosition: 'left',
                                                icon: 'globe',
                                                content: 'Go',
                                                onClick: () => { window.open('https://' + externalLink, '_blank'); }
                                            };
                                        }

                                        formField = (
                                            <Form.Input
                                                key={fieldName + '_' + index}
                                                readOnly={true}
                                                label={formFieldLabel}
                                                value={value}
                                                style={formFieldStyle}

                                                action={action}
                                            />
                                        );
                                    }
                                }

                                return formField;
                            })}
                        </Form.Group>
                    );
                })}
            </Form>
        )
    }

    renderDetailTabGrid = ({ fields, headerLabels, filedKeysForCreate, filedKeysForDelete, fieldKeysWithWarning }) => {
        return (
            <StandardDetailsGridRead
                fields={fields}
                headerLabels={headerLabels}
                translations={this.props.translations}
                filedKeysForCreate={filedKeysForCreate}
                filedKeysForDelete={filedKeysForDelete}
                fieldKeysWithWarning={fieldKeysWithWarning}
                downloadFileByPath={this.props.downloadFileByPath}
            />
        );
    }

    renderContent = (type, detailName, data) => {
        let res;

        if (type === 'DETAIL_TAB_GRID') {
            res = this.renderDetailTabGrid(data);
        } else if (type === 'DETAIL_TAB_CODE_VISUALIZER') {
            let { title, codes, subTitle } = data;

            res = (
                <React.Fragment key={detailName}>
                    <CodeVisualizer codes={codes} title={subTitle} />
                </React.Fragment>
            );
        } else {
            res = <p>Unknown Type</p>;
        }

        return res;
    }

    renderRelatedData = () => {
        const { relatedData } = this.props.data;
        if (_.isEmpty(relatedData)) {
            return null;
        }

        return (
            <React.Fragment>
                {
                    _.map(relatedData, (detailsData, detailName) => {
                        if (detailsData === EMPTY_VALUE) {
                            return null;
                        }

                        const { type, title } = detailsData;

                        return (
                            <React.Fragment key={detailName}>
                                <Divider />
                                <Accordion
                                    isActiveInitialValue={this.props.settings.detailExpanded}

                                    style={{ marginTop: '25px' }}
                                    title={title}
                                    renderContent={() => this.renderContent(type, detailName, detailsData)}
                                />
                            </React.Fragment>
                        );
                    })
                }
            </React.Fragment>
        );
    }

    renderInfoPanel = () => {
        const { pageInfoPanels } = this.props.data;
        if (_.isEmpty(pageInfoPanels)) {
            return null;
        }


        return _.map(pageInfoPanels, (panelData, panelKey) => {
            const { panelColor, title, bullets, content, icon } = panelData;

            return (
                <Popup
                    wide
                    flowing
                    on={'click'}
                    key={panelKey}
                    trigger={
                        <Message
                            size='small'
                            color={panelColor}
                            icon={icon}
                            header={title}
                            content={content}
                        />
                    }
                    content={
                        <Message
                            size='small'
                            key={panelKey}
                            color={panelColor}
                            icon={icon}
                            header={title}
                            list={bullets}
                        />
                    }
                />
            );
        });
    }

    render() {
        if (!this.props.data || !this.props.settings) {
            return null;
        }

        return (
            <Fragment>
                {this.renderTitle()}

                {this.renderButtonsArea()}

                {this.renderInfoPanel()}

                <Divider />

                {this.renderDimmer()}

                {this.renderReadForm()}

                {this.renderRelatedData()}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.auth.translations,
        settings: state.auth.settings,
        data: state.readPage
    };
}

const actions = {
    fetchHeaderFormReadMode, deleteEntity, clearForm, downloadPdf, setCheckboxTreeExpanded,
    submitAdditionalAction, generateAndDownload, downloadFileByPath, fetchPriceListDataAndOpen,
    open, confirmRevisionTree
};

export default connect(mapStateToProps, actions)(ReadPage);

import React from 'react';
import _ from 'lodash';
import { Table } from 'semantic-ui-react';

class SimpleGrid extends React.Component {
    render = () => {
        const { data, header } = this.props;

        return (
            <Table structured color={"grey"}>
                <Table.Header>
                    <Table.Row>
                        { _.map(header, (field, key) => <Table.HeaderCell key={key}>{field.label}</Table.HeaderCell>)}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {_.map(data, (rowData, rowKey) => {
                        return (
                            <Table.Row key={rowKey}>
                                {_.map(rowData.fields, (field, fieldName) => {
                                    let { value, rowSpan } = field;
                                    return <Table.Cell rowSpan={rowSpan ? rowSpan : 1} key={fieldName + rowKey}>{value}</Table.Cell>;  
                                })}
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        );
    }
}

export default SimpleGrid;

import React from 'react';
import _ from 'lodash';
import { Form, Modal, Message } from 'semantic-ui-react';
import FormRow from './FormRow';
import { FormFieldProp } from '../../../enum/apiResultProp/FormFieldProp';
import { ModalFormProp } from '../../../enum/apiResultProp/ModalFormProp';
import { ScreenComponentProp } from '../../../enum/apiResultProp/ScreenComponentProp';
import ScreenTitle from '../../title/ScreenTitle';
import ButtonRow from '../formButton/row/ButtonRow';

export default ({
    screenComponentProps,
    onFormValueChange,
    updateFieldErrorAction,
    onActionMutateScreenWithPostData,
    onActionMutateScreenNoPostData,
    className,
    uniqueKey,

    onButtonClickMutateScreenWithPostData,
    onButtonClickMutateScreenNoPostData,
    reloadScreenWithPostDataOnButtonClick,
    onActionDownload,
    onActionLogout,
    openModalForm,
    onActionPostDataAndRedirect,

    onButtonClickMutateComponentWithPostData,
    onButtonClickMutateComponentNoPostData,
    onFieldChangeMutateComponentWithPostData,
}) => {
    const formFields = screenComponentProps[ModalFormProp.Fields];
    const verticalAlignCenter = screenComponentProps[ModalFormProp.VerticalAlignCenter];
    const size = screenComponentProps[ModalFormProp.Size];
    const isOpen = screenComponentProps[ModalFormProp.IsOpen];
    const titleProps = screenComponentProps[ModalFormProp.Title];

    const modalMessageIsHidden = screenComponentProps[ModalFormProp.ModalMessageIsHidden];
    const modalMessageText = screenComponentProps[ModalFormProp.ModalMessageText];
    const modalMessageStyle = screenComponentProps[ModalFormProp.ModalMessageStyle];

    const getFormErrors = () => {
        let formHasError = false;
        let errorMessagesByRowKey = {};

        _.forEach(formFields, (rowFields, rowKey) => {
            errorMessagesByRowKey[rowKey] = [];

            _.forEach(rowFields, (fieldProps, fieldName) => {
                const fieldHasError = fieldProps[FormFieldProp.HasError];
                const fieldErrorMessage = fieldProps[FormFieldProp.ErrorMessage];

                if (fieldHasError) {
                    formHasError = true;

                    errorMessagesByRowKey[rowKey].push(fieldErrorMessage);
                }
            });
        });

        return { formHasError, errorMessagesByRowKey };
    }

    const renderTitle = () => {
        return (
            <ScreenTitle
                key={1}
                uniqueKey={1}
                componentName={titleProps[ScreenComponentProp.Name]}
                screenComponentProps={titleProps}
            />
        );
    }

    const renderForm = () => {
        const { formHasError, errorMessagesByRowKey } = getFormErrors();

        return (
            <Form className={className} error={formHasError} key={uniqueKey}>
                {
                    _.map(formFields, (rowFields, rowKey) => {
                        return (
                            <FormRow
                                key={rowKey}
                                rowKey={rowKey}
                                rowFields={rowFields}
                                onFormValueChange={onFormValueChange}
                                updateFieldErrorAction={updateFieldErrorAction}
                                onActionMutateScreenWithPostData={onActionMutateScreenWithPostData}
                                onActionMutateScreenNoPostData={onActionMutateScreenNoPostData}
                                rowErrorMessages={errorMessagesByRowKey[rowKey]}
                                onFieldChangeMutateComponentWithPostData={onFieldChangeMutateComponentWithPostData}
                            />
                        );
                    })
                }
            </ Form >
        );
    }

    const renderFormButtons = () => {
        return (
            <ButtonRow
                key={1}
                uniqueKey={1}
                screenComponentProps={screenComponentProps}

                onActionMutateScreenWithPostData={onButtonClickMutateScreenWithPostData}
                onActionMutateScreenNoPostData={onButtonClickMutateScreenNoPostData}
                reloadScreenWithPostDataOnButtonClick={reloadScreenWithPostDataOnButtonClick}
                onActionDownload={onActionDownload}
                onActionLogout={onActionLogout}
                openModalForm={openModalForm}
                onActionPostDataAndRedirect={onActionPostDataAndRedirect}

                onButtonClickMutateComponentWithPostData={onButtonClickMutateComponentWithPostData}
                onButtonClickMutateComponentNoPostData={onButtonClickMutateComponentNoPostData}
            />
        )
    }

    const renderFormMessage = () => {
        if (modalMessageIsHidden) {
            return null;
        }

        const messageProps = {};
        if (modalMessageStyle) {
            messageProps[modalMessageStyle] = true;
        }

        return (
            <Message {...messageProps} >
                <Message.Content>
                    {modalMessageText}
                </Message.Content>
            </Message>
        );
    }

    return (
        <Modal
            size={size}
            open={isOpen}
            centered={verticalAlignCenter}
        >
            <Modal.Header>
                {renderTitle()}
            </Modal.Header>
            <Modal.Content>
                {renderFormMessage()}
                {renderForm()}
            </Modal.Content>
            <Modal.Actions>
                {renderFormButtons()}
            </Modal.Actions>
        </Modal>
    )
}

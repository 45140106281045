import React from 'react';
import { Form as SemanticForm } from 'semantic-ui-react';
import { FormFieldProp } from '../../../enum/apiResultProp/FormFieldProp';

export default ({ fieldProps, onValueChange, onInputBlur }) => {
    const value = fieldProps[FormFieldProp.Value];
    const placeholder = fieldProps[FormFieldProp.Placeholder];
    const showLabel = fieldProps[FormFieldProp.ShowLabel];
    const label = fieldProps[FormFieldProp.Label];
    const hasError = fieldProps[FormFieldProp.HasError];
    const showMandatorySign = fieldProps[FormFieldProp.ShowMandatorySign];
    const isReadOnly = fieldProps[FormFieldProp.IsReadOnly];
    const options = fieldProps[FormFieldProp.Options];

    let dropdownProps = {
        selectOnBlur: false,
        options: options,
        required: showMandatorySign,
        readOnly: isReadOnly,
        disabled: isReadOnly,
        error: hasError,
        placeholder: placeholder,
        value: value,
        onChange: async (e, { value }) => {
            await onValueChange({ value: value });
            onInputBlur({ value: value });
        },
        onKeyPress: e => {
            if (e.key === 'Enter') {
                e.preventDefault();
            }
        },
        // onBlur: () => onInputBlur({ value: value })
    };
    if (showLabel) {
        dropdownProps = { ...dropdownProps, label: label };
    }

    return (
        <SemanticForm.Select
            {...dropdownProps}
        />
    );
}
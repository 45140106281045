import _ from 'lodash';

export const openModal = (state) => {
    return {
        ...state,
        isOpen: true
    };
}

export const closeModal = (state) => {
    return {
        ...state,
        isOpen: false
    };
}

export const startSpinner = (state) => {
    return {
        ...state,
        showSpinner: true
    }
}

export const stopSpinner = (state) => {
    return {
        ...state,
        showSpinner: false
    }
}

export const fetchData = (state, action) => {
    const documentationData = action.payload;
    
    state = {
        ...state,
        documentationData: documentationData
    }

    return state;
}

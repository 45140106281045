import React from 'react';
import { Form, Label } from 'semantic-ui-react';
import _ from 'lodash';

import { EDIT_FILTER_MODE, CREATE_FILTER_MODE } from '../../../../helpers/const';
import { getValue } from '../../../../helpers/functions';

class FieldSelector extends React.Component {
    onSelect = (value) => {
        const { fields, comparators } = this.props;
        
        let selectedFieldKey = _.findKey(fields, field => field.columnKey === value);
        
        let selectedField = fields[selectedFieldKey];
        let showOnlyEqualityOperator = selectedField.showOnlyEqualityOperator;
        let selectedFieldDataType = selectedField.columnDataType;

        let selectedFieldComparators = comparators[selectedFieldDataType];
        if (showOnlyEqualityOperator) {
            selectedFieldComparators = _.filter(selectedFieldComparators, (comparator) => comparator.columnKey === 'equals');
        } 
        
        this.props.filterFormSelectColumn(selectedFieldKey, selectedFieldComparators, selectedFieldDataType); 
    }
    
    render () {
        const { fields, selectedFieldKey, mode, translations } = this.props;

        const value = fields[selectedFieldKey].columnKey;
        const disabled = mode === EDIT_FILTER_MODE;
        
        let options = [];
        _.forEach(fields, field => options.push({ key: field.columnKey, value: field.columnKey, text: field.columnName }));

        let modeLabel = getValue(translations, mode === CREATE_FILTER_MODE ? 'Create Filter' : 'Edit Filter');

        return  (
            <Form.Group>
                <label style={{paddingTop: '0.8em', paddingLeft: '1.1em', fontWeight: 'bold'}}>{modeLabel}</label>
                <Form.Select
                    selection 
                    options={options}
                    disabled={disabled}
                    value={value}
                    onChange={(e, {value}) => {this.onSelect(value)}}
                />
            </Form.Group>
        );
    } 
} 

export default FieldSelector;

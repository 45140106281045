import React from 'react';
import _ from 'lodash';
import { FlowProp } from '../../enum/apiResultProp/FlowProp';
import FlowRow from './FlowRow';

export default ({ screenComponentProps, uniqueKey, reloadScreenWithPostDataOnButtonClick }) => {
    const stepRows = screenComponentProps[FlowProp.StepRows];

    return (
        <React.Fragment key={uniqueKey}>
            {
                _.map(stepRows, (steps, stepRowKey) => {
                    return (
                        <FlowRow
                            steps={steps}
                            key={stepRowKey}
                            uniqueKey={stepRowKey}
                            reloadScreenWithPostDataOnButtonClick={reloadScreenWithPostDataOnButtonClick}
                        />
                    );
                })
            }
        </React.Fragment>
    );
}

import React from 'react';
import _ from 'lodash';
import { FlowStepProp } from '../../enum/apiResultProp/FlowStepProp';
import { Step } from 'semantic-ui-react';
import FlowRowStep from './FlowRowStep';

export default ({ steps, uniqueKey, reloadScreenWithPostDataOnButtonClick }) => {
    _.orderBy(steps, FlowStepProp.ColNumber);

    return (
        <Step.Group 
            key={uniqueKey}
            widths={_.keys(steps).length}
            style={{ marginBottom: '0px', marginTop: '6px' }}
        >
            {
                _.map(steps, ((step, stepKey) => {
                    return (
                        <FlowRowStep 
                            step={step}
                            key={stepKey}  
                            uniqueKey={stepKey}  
                            reloadScreenWithPostDataOnButtonClick={reloadScreenWithPostDataOnButtonClick}
                        />
                    );
                }))
            }
        </ Step.Group>
    );
}

export const ActionProp = Object.freeze({
    ActionType: 'actionType',
    BackEndUrl: 'backEndUrl',
    MakeBackEndCallOnlyIfDataIsValid: 'makeBackEndCallOnlyIfDataIsValid',
    CollectGridFilterData: 'collectGridFilterData',
    FileNameOnDownload: 'fileNameOnDownload',
    FrontEndRouteName: 'frontEndRouteName',
    FrontEndRouteParams: 'frontEndRouteParams',
    ModalFormComponentName: 'modalFormComponentName',
    CollectDataFromComponentName: 'collectDataFromComponentName'
});

import React from 'react';
import _ from 'lodash';
import { Message, Icon } from 'semantic-ui-react';
import { InfoPanelProp } from '../../enum/apiResultProp/InfoPanelProp';
import InfoPanelContent from './InfoPanelContent';

export default ({ screenComponentProps, actionHide }) => {
    const title = screenComponentProps[InfoPanelProp.Title];
    const text = screenComponentProps[InfoPanelProp.Text];
    const bulletMessages = screenComponentProps[InfoPanelProp.BulletMessages];
    const closable = screenComponentProps[InfoPanelProp.Closable];
    const hidden = screenComponentProps[InfoPanelProp.Hidden];
    const infoPanelStyle = screenComponentProps[InfoPanelProp.Style];
    const color = screenComponentProps[InfoPanelProp.Color];
    const size = screenComponentProps[InfoPanelProp.Size];
    const iconName = screenComponentProps[InfoPanelProp.IconName];
    const contentRows = screenComponentProps[InfoPanelProp.ContentRows];

    const renderTitle = () => {
        if (!title) {
            return null;
        }

        return <Message.Header>{title}</Message.Header>;
    }

    const renderText = () => {
        if (!text) {
            return null;
        }

        return <p>{text}</p>;
    }

    const renderBullets = () => {
        if (!bulletMessages) {
            return null;
        }

        return <Message.List items={bulletMessages} />;
    }

    const renderIcon = () => {
        if (!iconName) {
            return null;
        }

        return <Icon name={iconName} />;
    }

    const renderContentRows = () => {
        if (!contentRows) {
            return null;
        }

        return (
            <React.Fragment>
                {_.map(contentRows, (contentRowProps, contentRowKey) => <InfoPanelContent key={contentRowKey} props={contentRowProps} />)}
            </React.Fragment>
        );
    }

    const messageProps = {};
    if (hidden) {
        messageProps.hidden = true;
    }
    if (infoPanelStyle) {
        messageProps[infoPanelStyle] = true;
    }
    if (color) {
        messageProps.color = color;
    }
    if (size) {
        messageProps.size = size;
    }
    if (iconName) {
        messageProps.icon = true;
    }
    if (closable) {
        messageProps.onDismiss = actionHide;
    }

    return (
        <Message {...messageProps} >
            {renderIcon()}
            <Message.Content>
                {renderTitle()}
                {renderText()}
                {renderBullets()}
            </Message.Content>
            {renderContentRows()}
        </Message>
    );
}

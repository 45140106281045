import React from 'react';
import { Button, TransitionablePortal, Modal, Header, Table, Icon } from 'semantic-ui-react';
import SimpleGrid from './SimpleGrid';

class ModalWithSimpleInfoTable extends React.Component {
    state = { showModal: false };
    
    toggleShowModal = () => {
        let newShowModal = ! this.state.showModal;

        this.setState({ showModal: newShowModal });
    }

    portalOnClose = () => {
        if (this.state.showModal === true) {
            this.toggleShowModal();
        }
    }

    render = () => {
        return (
            <React.Fragment>
                <Table.Cell 
                    selectable 
                    style={{cursor: 'pointer', fontWeight: 'bold' }} 
                    onClick={this.toggleShowModal}
                >
                    {this.props.fieldValue}
                </Table.Cell>

                <TransitionablePortal 
                    onClose={this.portalOnClose} 
                    open={this.state.showModal} 
                    transition={{animation: 'slide down', duration: 400}}
                >
                     <Modal closeOnEscape={false} open={true} size='large'>
                        <Header content={this.props.header} />
                        <React.Fragment>
                            <Modal.Content scrolling>
                                <SimpleGrid
                                    data={this.props.tableData}
                                    header={this.props.gridHeader}
                                />
                            </Modal.Content>
                            
                            <Modal.Actions>
                                <Button color='red' onClick={this.toggleShowModal}>
                                    <Icon name='cancel' /> Close
                                </Button>
                            </Modal.Actions>
                        </React.Fragment>    
                    </Modal>
                </TransitionablePortal>
            </React.Fragment>
        );
    }
}

export default ModalWithSimpleInfoTable;

import actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
    shouldLogOut: false,
    userLogClosedByOtherProcess: false,  
    logoutModalIsVisible: false,
    decisionTimeRemaining: null,
    settingInactiveTimeValueInSeconds: null,
    settingDecisionTimeValueInSeconds: null,
};

const automaticLogout = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.GET_AUTOMATIC_LOGOUT_INFO:
            return {...action.payload};
        case actionTypes.RESET_AUTOMATIC_LOGOUT_INFO:
            return INITIAL_STATE;
        default: 
            return state;
    }
}

export default automaticLogout;

import React from 'react';
import { Button, Grid, Popup } from 'semantic-ui-react';
import _ from 'lodash';

import { getValue } from '../../helpers/functions';
import { STRINGS } from '../../helpers/strings';
import { isUndefined } from 'lodash';

class ButtonWithConfirmation extends React.Component {
    state = { showPopup: {} };
    
    toggleDeletePopup = (rowId) => {
        let newShowPopupState;

        if (typeof this.state.showPopup[rowId] === 'undefined') {
            newShowPopupState = {...this.state.showPopup, [rowId]: true }
            
            this.setState({ showPopup: newShowPopupState });
        } else {
            const newStatus = ! this.state.showPopup[rowId];
            newShowPopupState = {...this.state.showPopup, [rowId]: newStatus }
            
            this.setState({ showPopup: newShowPopupState });
        }
    }
    
    getPopupStatusByRowId = (rowId) => {
        let status;
        
        if (typeof this.state.showPopup[rowId] === 'undefined') {
            status = false;
        } else {
            status = this.state.showPopup[rowId];
        }

        return status;
    }

    onConfirm = async (rowId) => {
        this.toggleDeletePopup(rowId);
        
        await this.props.actionConfirm(rowId);

        if (this.props.onDeleteRowAjaxEvent !== undefined) {
            this.props.onDeleteRowAjaxEvent();
        }
    }

    render = () => {
        const {
            buttonColor, description, rowId, buttonConfirmTitle, translations, question, buttonConfirmDisabled, 
            floated, buttonDisabled, loading
        } = this.props;

        let defaultFloated = 'right';
        let floatedValue = defaultFloated;
        if (! _.isUndefined(floated)) {
            floatedValue = floated;
        }

        let defaultColor = 'red';
        let defaultButtonConfirmDisabled = false;
        let defaultButtonDisabled = false;
        let defaultButtonLoading = false;
        
        let color = '';
        if (isUndefined(buttonColor)) {
            color = defaultColor;
        } else {
            color = buttonColor;
        }

        let confirmDisabled;
        if (isUndefined(buttonConfirmDisabled)) {
            confirmDisabled = defaultButtonConfirmDisabled;
        } else {
            confirmDisabled = buttonConfirmDisabled;
        }

        let buttonIsDisabled;
        if (isUndefined(buttonDisabled)) {
            buttonIsDisabled = defaultButtonDisabled;
        } else {
            buttonIsDisabled = buttonDisabled;
        }

        let buttonIsLoading;
        if (isUndefined(loading)) {
            buttonIsLoading = defaultButtonLoading;
        } else {
            buttonIsLoading = loading;
        }

        let triggerButton;
        if (floated !== false) {
            triggerButton = <Button loading={buttonIsLoading} disabled={buttonIsDisabled} floated={floatedValue} color={color}>{buttonConfirmTitle}</Button>;
        } else {
            triggerButton = <Button loading={buttonIsLoading} disabled={buttonIsDisabled} color={color}>{buttonConfirmTitle}</Button>;
        }

        return (
            <Popup key={rowId}
                wide 
                open={this.getPopupStatusByRowId(rowId)}
                trigger={triggerButton} 
                onClose={() => this.toggleDeletePopup(rowId)}
                onOpen={() => this.toggleDeletePopup(rowId)}
                on='click'
            >
                <Grid>
                    <Grid.Row centered>
                        <h2>{question}</h2>
                        <p>{description}</p>
                    </Grid.Row>
                    <Grid.Row divided columns='equal'>
                        <Grid.Column>
                            <Button disabled={confirmDisabled} onClick={() => this.onConfirm(rowId)} color='red' content={getValue(translations, STRINGS.BUTTON_CONFIRM)} fluid />
                        </Grid.Column>
                        <Grid.Column>
                            <Button color='black' content={getValue(translations, STRINGS.BUTTON_CANCEL)} fluid onClick={() => this.toggleDeletePopup(this.props.rowId)} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Popup>
        );
    }
}

export default ButtonWithConfirmation;

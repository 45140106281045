import React from 'react';
import { Table } from 'semantic-ui-react';

import { FIELD_TYPES } from '../../../helpers/const';

class TableHeader extends React.Component {
    onFilterInputChange = (filterName, filterValue) => {
        this.props.onFilterInputChange(filterName, filterValue);
    }

    renderHeader() {
        const gridConfig = this.props.gridConfig;
        if (! gridConfig) {
            return <Table.Row><Table.HeaderCell></Table.HeaderCell></Table.Row>;
        }

        const gridColumnsObj = gridConfig.gridColumns;

        return (
            <Table.Row textAlign='center' size='huge'>
                {Object.keys(gridColumnsObj).map(columnName => {
                    let label = gridColumnsObj[columnName].label;
                    let order = gridColumnsObj[columnName].order;

                    let th;

                    if (gridColumnsObj[columnName].type === FIELD_TYPES.SKIP) {
                        th = null;
                    } else if (order) {
                        let direction = gridColumnsObj[columnName].direction;
                        let className= '';

                        if (direction === 'ASC') {
                            className = "sorted ascending";
                        } else if (direction === "DESC") {
                            className = "sorted descending";
                        }

                        th = (
                            <Table.HeaderCell
                                className={className} 
                                key={columnName}
                                onClick={() => this.props.onSortableHeaderClick(columnName, gridColumnsObj[columnName])}
                            >
                                {label}
                            </Table.HeaderCell>
                        );
                    } else {
                        th = ( 
                            <Table.HeaderCell key={columnName}>
                                {label}
                            </Table.HeaderCell>
                        );
                    }

                    return th;
                })}
            </Table.Row>
        );
    }
    
    render() {
        return(
            <Table.Header>
                {this.renderHeader()}
            </Table.Header>
        );
    }
}

export default TableHeader;

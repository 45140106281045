export const DataType = Object.freeze({
    Html: 'html',
    Array: 'array',
    Int: 'int',
    String: 'string',
    Date: 'date',
    Float: 'float',
    File: 'file',
    Bool: 'bool',
});

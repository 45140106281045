import _ from 'lodash';
import actionTypes from "../../actions/gridPage/actionTypes";
import { EMPTY_VALUE } from '../../helpers/const';

import { 
    filterFormClear, fetch, updateOnPage, changePage, changeSortingDirection, cancelFilter, clearFilters, addFilter, filterFormSelectComparator,
    toggleVisibility, editFilterValues, filterFormLoadForEdit, filterFormUpdateInput, filterFormSelectColumn, ajax, updateSendToMago,
    toggleGridCheckbox, uncheckAll, checkAll, onVariantChange, additionalActionSpinnerStart, additionalActionSpinnerStop
} from './stateMutators';

// const INITIAL_STATE = {
//     configuration: {
//         gridColumns: {},
//         pagination: {
//             totalRecords: 0,
//             totalPages: 0,
//             onPage: [10, 30, 50],
//             onPageSelectedIndex: 0,
//             currentPage: 1
//         },
//         filters: {},
//         modalFormFields: {},
//         modalFormFieldGroups: {},
//         PAGE_ACTIONS: [], //false
//         gridColsCount: 0,
//         filterForm: {
//             filterColumnsDropdown: {},
//             filterColumnsDropdownSelectedKey: {},
//             filterComparatorDropdown: {},
//             filterComparatorDropdownSelectedKey: {},
//             filterValue: {},
//             filterDataType: {},
//             mode: CREATE_FILTER_MODE,
//             isActive: false
//         }
//     },
//     data: {}
// };

const INITIAL_STATE = EMPTY_VALUE;

const commonGridReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.UNCHECK_ALL:
            return uncheckAll(state);
        case actionTypes.CHECK_ALL:
            return checkAll(state);
        case actionTypes.ON_VARIANT_CHANGE:
            return onVariantChange(state, action);
        case actionTypes.TOGGLE_GRID_CHECKBOX:
            return toggleGridCheckbox(state, action);
        case actionTypes.UPDATE_SEND_TO_MAGO_ACTION:
            return updateSendToMago(state, action);
        case actionTypes.CLEAR_STATE:
            return INITIAL_STATE;
        case actionTypes.FILTER_FORM_CLEAR:
            return filterFormClear(state, action);
        case actionTypes.AJAX_ACTION:
            return ajax(state, action);
        case actionTypes.ADDITIONAL_ACTION_SPINNER_START:
            return additionalActionSpinnerStart(state);
        case actionTypes.ADDITIONAL_ACTION_SPINNER_STOP:
            return additionalActionSpinnerStop(state);
        case actionTypes.FETCH_GRID_DATA:
            return fetch(state, action);
        case actionTypes.UPDATE_ON_PAGE:
            return updateOnPage(state, action);
        case actionTypes.CHANGE_PAGE:
            return changePage(state, action);
        case actionTypes.UPDATE_SORTABLE_HEADER_DIRECTION:
            return changeSortingDirection(state, action);
        case actionTypes.CANCEL_FILTER:
            return cancelFilter(state, action);
        case actionTypes.CLEAR_FILTERS:
            return clearFilters(state, action);
        case actionTypes.ADD_FILTER:
            return addFilter(state, action);
        case actionTypes.FILTER_FORM_SELECT_COLUMN:
            return filterFormSelectColumn(state, action);
        case actionTypes.FILTER_FORM_SELECT_COMPARATOR:
            return filterFormSelectComparator(state, action);
        case actionTypes.FILTER_FORM_UPDATE_INPUT:
            return filterFormUpdateInput(state, action);
        case actionTypes.FILTER_FORM_LOAD_FILTER_FOR_EDIT: 
            return filterFormLoadForEdit(state, action);
        case actionTypes.EDIT_FILTER_VALUES:
            return editFilterValues(state, action);
        case actionTypes.TOGGLE_FORM_VISIBILITY:
            return toggleVisibility(state, action);
        default: 
            return state;
    }
}

export default commonGridReducer;

import React from 'react';
import { Form } from 'semantic-ui-react';
import { FormFieldProp } from '../../../enum/apiResultProp/FormFieldProp';

export default ({ fieldProps, onValueChange }) => {
    const name = fieldProps[FormFieldProp.Name];
    const value = fieldProps[FormFieldProp.Value];
    const placeholder = fieldProps[FormFieldProp.Placeholder];
    const showLabel = fieldProps[FormFieldProp.ShowLabel];
    const label = fieldProps[FormFieldProp.Label];
    const hasError = fieldProps[FormFieldProp.HasError];
    const showMandatorySign = fieldProps[FormFieldProp.ShowMandatorySign];
    const isReadOnly = fieldProps[FormFieldProp.IsReadOnly];

    let textFieldProps = {
        checked: value,
        toggle: true,
        required: showMandatorySign,
        readOnly: isReadOnly,
        disabled: isReadOnly,
        error: hasError,
        placeholder: placeholder,
        onChange: (e) => onValueChange({ value: !value }),
        style: { paddingTop: '1em' }
    };
    if (showLabel) {
        textFieldProps = { ...textFieldProps, label: label };
    }

    return (
        <Form.Checkbox
            {...textFieldProps}
        />
    );
}

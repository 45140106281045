import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { Table, Icon, Flag, Image, Form, Popup } from 'semantic-ui-react';
import { FIELD_TYPES, EMPTY_VALUE, FORM_DATA_TYPES } from '../../../helpers/const';
import { getValue } from '../../../helpers/functions';
import ModalWithSimpleInfoTable from '../../customComponents/ModalWithSimpleInfoTable';
import TableCellWithContextMenu from './TableCellWithContextMenu';

const StandardDetailsGridRead = ({ fields, headerLabels, filedKeysForCreate, filedKeysForDelete, fieldKeysWithWarning, downloadFileByPath, translations }) => {
    const [openedContextMenuElementUniqueKey, setOpenedContextMenuElementUniqueKey] = useState(null);

    const renderBody = () => {
        if (fields === undefined) {
            return null;
        }

        return (
            <Table.Body>
                {Object.keys(fields).map(rowKey => {
                    const rowData = fields[rowKey];

                    let negative = false;
                    let positive = false;
                    let warning = false;
                    let rowStyle = {};
                    if (_.indexOf(filedKeysForDelete, rowKey) !== -1) {
                        negative = true;
                        rowStyle = { textDecoration: 'line-through' };
                    } else if (_.indexOf(filedKeysForCreate, rowKey) !== -1) {
                        positive = true;
                    } else if (_.indexOf(fieldKeysWithWarning, rowKey) !== -1) {
                        warning = true;
                    }

                    return (
                        <Table.Row key={rowKey} textAlign='center' negative={negative} positive={positive} warning={warning} style={rowStyle}>
                            {Object.keys(rowData).map(fieldName => {
                                const { value, type, oldValue, modalInfo, appHelpInfo } = rowData[fieldName];
                                const cellKey = fieldName + '_' + rowKey;

                                let cell = null;
                                switch (type) {
                                    case FORM_DATA_TYPES.READ_WITH_DOWNLOAD_URL:
                                        const { downloadUrl, serverFilePath, downloadedFileName } = rowData[fieldName];

                                        cell = (
                                            <Table.Cell
                                                textAlign='center'
                                                key={cellKey}
                                                selectable
                                                onClick={() => {
                                                    if (serverFilePath != EMPTY_VALUE) {
                                                        downloadFileByPath(downloadUrl, serverFilePath, downloadedFileName)
                                                    }
                                                }}
                                            >
                                                <a style={{ color: 'blue' }} href='' onClick={e => { e.preventDefault() }}>{value}</a>
                                            </Table.Cell>
                                        );
                                        break;
                                    case FIELD_TYPES.FLAG:
                                        const color = value ? 'green' : 'red';
                                        const name = value ? 'check' : 'close';

                                        cell = (
                                            <Table.Cell key={cellKey}>
                                                <Icon color={color} name={name} />
                                            </Table.Cell>
                                        );
                                        break;
                                    case FIELD_TYPES.COUNTRY_FLAG:
                                        cell = (
                                            <Table.Cell key={cellKey}>
                                                <Flag name={value.toLowerCase()} />
                                                {value}
                                            </Table.Cell>
                                        );
                                        break;
                                    case FIELD_TYPES.IMAGE:
                                        if (value != EMPTY_VALUE) {
                                            cell = (
                                                <Table.Cell key={cellKey}>
                                                    <Image src={value} size='small' centered />
                                                </Table.Cell>
                                            );
                                        } else {
                                            cell = (
                                                <Table.Cell key={cellKey}>
                                                    No Image
                                                </Table.Cell>
                                            );
                                        }
                                        break;
                                    case FIELD_TYPES.LINK_MODAL_INFO:
                                        return renderLinkModalInfoField(fieldName, value, rowKey, modalInfo);
                                    case FORM_DATA_TYPES.SKIP:
                                        return null;
                                    default:
                                        if (oldValue !== undefined) {
                                            cell = (
                                                <React.Fragment key={cellKey}>
                                                    <Popup
                                                        position='top center'
                                                        trigger={
                                                            <Table.Cell key={fieldName + rowKey} warning>
                                                                {value}
                                                            </Table.Cell>
                                                        }
                                                        content={getValue(translations, "Old Value: ") + oldValue}
                                                        size='small'
                                                    />
                                                </React.Fragment>
                                            );
                                        } else {
                                            cell = <Table.Cell key={cellKey}>{value}</Table.Cell>;
                                        }
                                }

                                if (appHelpInfo == undefined) {
                                    return cell;
                                } else {
                                    const isOpen = cellKey === openedContextMenuElementUniqueKey;

                                    return (
                                        <TableCellWithContextMenu
                                            key={cellKey}
                                            uniqueKey={cellKey}
                                            value={value}
                                            oldValue={oldValue}
                                            appHelpInfo={appHelpInfo}
                                            setOpenedContextMenuElementUniqueKey={setOpenedContextMenuElementUniqueKey} 
                                            isOpen={isOpen}
                                            translations={translations}
                                        />
                                    );
                                }
                            })}
                        </Table.Row>
                    );
                })}
            </Table.Body>
        );
    }

    const renderHeader = () => {
        return (
            <Table.Header>
                <Table.Row textAlign='center'>
                    {headerLabels.map(label => {
                        return <Table.HeaderCell key={label}>{label}</Table.HeaderCell>;
                    })}
                </Table.Row>
            </Table.Header>
        );
    }

    const renderLinkModalInfoField = (fieldName, fieldValue, id, modalInfo) => {
        const headerLabels = modalInfo.data.configuration;
        const tableData = modalInfo.data.data;

        return (
            <React.Fragment key={fieldName + '_' + id}>
                <ModalWithSimpleInfoTable
                    header={'Activity Report'}
                    gridHeader={headerLabels.gridColumns}
                    tableData={tableData}
                    fieldValue={fieldValue}
                />
            </React.Fragment>
        )
    }

    return (
        <Table celled sortable selectable color={"grey"} style={{ overflow: 'auto' }}>
            {renderHeader()}
            {renderBody()}
        </Table>
    );
}

export default StandardDetailsGridRead;

import actionTypes from "../../actions/revisionModalTree/actionTypes";
import { 
    open, close, toggleExpandOfNodeElem, changeStatusOfNodeElem, changeStatusOfChildElem, toggleSpinner, updateError,
    setExpandNoAll, setExpandYesAll, setStatusCheckedAll, setStatusUncheckedAll, setStatusCheckedNegativeAll,
    onAdditionalInfoFormValueChange
} from './stateMutators';

const INITIAL_STATE = {
    isOpen: false,
    showSpinner: false,
    
    statusChecked: [],
    statusUnchecked: [],
    statusCheckedNegative: [],
    statusDisabled: [],
    
    expandYes: [],
    expandNo: [],
    
    allValues: [],
    elements: [],
    
    confirmUrl: '',
    title: '',
    validationMessages: [],

    additionalInfoForm: {}
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.ADDITIONAL_INFO_FORM_VALUE_CHANGE:
            return onAdditionalInfoFormValueChange(state, action);
        case actionTypes.UPDATE_ERROR_MODAL_TREE:
            return updateError(state, action);
        case actionTypes.TOGGLE_SPINNER_MODAL_TREE:
            return toggleSpinner(state, action);
        case actionTypes.OPEN:
            return open(state, action);
        case actionTypes.CLOSE:
            return close(INITIAL_STATE);
        case actionTypes.TOGGLE_STATUS_NODE:
            return changeStatusOfNodeElem(state, action);
        case actionTypes.TOGGLE_STATUS_CHILD:
            return changeStatusOfChildElem(state, action);
        case actionTypes.TOGGLE_EXPAND_NODE:
            return toggleExpandOfNodeElem(state, action);
        case actionTypes.TOGGLE_EXPAND_CHILD:
            return toggleExpandOfNodeElem(state, action);
        case actionTypes.STATUS_CHECKED_ALL:
            return setStatusCheckedAll(state, action);
        case actionTypes.STATUS_CHECKED_NEGATIVE_ALL:
            return setStatusCheckedNegativeAll(state, action);
        case actionTypes.STATUS_UNCHECKED_ALL:
            return setStatusUncheckedAll(state, action);
        case actionTypes.EXPAND_YES_ALL:
            return setExpandYesAll(state, action);
        case actionTypes.EXPAND_NO_ALL:
            return setExpandNoAll(state, action);
        default: 
            return state;
    }
}

export default reducer;

import React from 'react';
import _ from 'lodash';
import { Table } from 'semantic-ui-react';
import { TableFormProp } from '../../../enum/apiResultProp/TableFormProp';
import AbstractButton from '../../form/formButton/button/AbstractButton';

export default ({ screenComponentProps, colsCount, onActionMutateScreenWithPostData, openModalForm }) => {
    const buttons = screenComponentProps[TableFormProp.TableFormButtons];

    return (
        <Table.Row>
            <Table.HeaderCell colSpan={colsCount}>
                {_.map(buttons, (buttonProps, key) => {
                    return (
                        <AbstractButton
                            key={key}
                            buttonProps={buttonProps}
                            onActionMutateScreenWithPostData={onActionMutateScreenWithPostData}
                            openModalForm={openModalForm}
                            onActionPostDataAndRedirect={() => console.log('TODO')}
                        />
                    );
                })}
            </Table.HeaderCell>
        </Table.Row>
    );
}

import React from 'react';
import TextDataCell from './TextDataCell';
import BoolDataCell from './BoolDataCell';
import { TableDataCellProp } from '../../../../enum/apiResultProp/TableDataCellProp';
import { TableDataCellType } from '../../../../enum/TableDataCellType';
import { Table } from 'semantic-ui-react';

export default ({ tableDataCellProps }) => {
    const cellType = tableDataCellProps[TableDataCellProp.CellType];

    let cell = null;
    if (cellType === TableDataCellType.Text) {
        cell = <TextDataCell tableDataCellProps={tableDataCellProps} />
    } else if (cellType === TableDataCellType.Bool) {
        cell = <BoolDataCell tableDataCellProps={tableDataCellProps} />
    } else {
        cell = <Table.Cell>Cell Type not registered</Table.Cell>
    }

    return cell;
}

import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Modal, Button, Dimmer, Loader, TransitionablePortal, Form, Message, Popup, Dropdown } from 'semantic-ui-react';
import { fetchPriceListDataAndOpen, close, updateFieldValue, submitFormAndDownloadReport, clear, sendEmail, onChangeAjax } from '../../actions/priceList/actions';
import { FORM_DATA_TYPES } from '../../helpers/const';
import { preparePostParamsNEW_NEW } from '../../helpers/functions';

class PriceListModal extends React.Component {
    onClose = async () => {
        await this.props.close();
        this.props.clear();
    }

    onSubmitClick = () => {
        const { actionGenerateAndDownload } = this.props.data;
        const { generateUrl, downloadUrl } = actionGenerateAndDownload;

        this.props.submitFormAndDownloadReport(generateUrl, downloadUrl);
    }

    onSendEmailClick = () => {
        const { generatedFile, actionSendEmail, modalFormConfig } = this.props.data;

        const { name, serverPath } = generatedFile;
        const { url } = actionSendEmail;

        const postData = new FormData();
        preparePostParamsNEW_NEW(postData, modalFormConfig);
        postData.append('fileName', name);
        postData.append('serverFilePath', serverPath);

        this.props.sendEmail(url, postData);
    }

    renderErrorMessage = () => {
        const { errorMessage } = this.props.data;

        if (!errorMessage) {
            return null;
        }

        return (
            <Message negative>
                <p>{errorMessage}</p>
            </Message>
        );
    }

    onDropdownValueChange = async (rowKey, fieldName, fieldValue, onChangeAjaxActionUrl) => {
        await this.props.updateFieldValue(rowKey, fieldName, fieldValue);

        if (! _.isUndefined(onChangeAjaxActionUrl)) {
            this.props.onChangeAjax(onChangeAjaxActionUrl);   
        }
    } 

    renderForm = () => {
        const { modalFormConfig } = this.props.data;

        return (
            <Form>
                {
                    _.map(modalFormConfig, (fields, rowKey) => {
                        return (
                            <Form.Group widths='equal' key={rowKey}>
                                {
                                    _.map(fields, (fieldObject, fieldName) => {
                                        const { dataType, value, label, placeholder, required, readOnly, options, onChangeAjaxActionUrl } = fieldObject;

                                        switch (dataType) {
                                            case FORM_DATA_TYPES.DROPDOWN:
                                                return (
                                                    <Form.Select
                                                        key={fieldName}
                                                        options={options}
                                                        required={required}
                                                        readOnly={readOnly}
                                                        disabled={readOnly}
                                                        label={label}
                                                        placeholder={placeholder}
                                                        value={value}
                                                        onChange={(e, data) => { this.onDropdownValueChange(rowKey, fieldName, data.value, onChangeAjaxActionUrl) }}
                                                        selectOnBlur={false}
                                                    />
                                                );
                                            case FORM_DATA_TYPES.DROPDOWN_MULTI:
                                                return (
                                                    <Form.Select
                                                        key={fieldName}
                                                        multiple selection
                                                        options={options}
                                                        required={required}
                                                        readOnly={readOnly}
                                                        disabled={readOnly}
                                                        label={label}
                                                        placeholder={placeholder}
                                                        value={value}
                                                        onChange={(e, data) => { this.onDropdownValueChange(rowKey, fieldName, data.value, onChangeAjaxActionUrl) }}
                                                        selectOnBlur={false}
                                                    />
                                                );
                                            default:
                                                return <p>Not registered type!</p>;
                                        }
                                    })
                                }
                            </Form.Group>
                        )
                    })
                }
            </Form>
        );
    }

    render = () => {
        const { isOpen, showSpinner, title, actionGenerateAndDownload, actionCancel, actionSendEmail, actionReset } = this.props.data;

        let sendEmailButton = null;
        if (actionSendEmail.visible) {
            if (actionSendEmail.disabled == true) {
                sendEmailButton = (
                    <Popup
                        basic
                        wide='very'
                        content={actionSendEmail.disabledMessage}
                        trigger={
                            <div style={{ display: "inline-block" }}>
                                <Button
                                    color='green'
                                    content={actionSendEmail.title}
                                    disabled
                                />
                            </div>
                        }
                    />
                )
            } else {
                sendEmailButton = (
                    <Button
                        color='green'
                        content={actionSendEmail.title}
                        onClick={this.onSendEmailClick}
                    />
                );
            }
        }

        let resetFormButton = null;
        if (actionReset.visible) {
            resetFormButton = (
                <Button
                    color='red'
                    content={actionReset.title}
                    onClick={() => this.props.fetchPriceListDataAndOpen(actionReset.url)}
                />
            );
        }

        return (
            <TransitionablePortal
                onClose={this.onClose}
                open={isOpen}
                transition={{ animation: 'slide down', duration: 400 }}
                closeOnEscape={false}
            >
                <Modal closeOnEscape={false} open={true} size='large'>
                    <Dimmer active={showSpinner} inverted>
                        <Loader inverted size='huge'>Loading</Loader>
                    </Dimmer>
                    <Modal.Header>
                        {title}
                    </Modal.Header>
                    <Modal.Content>
                        {this.renderErrorMessage()}
                        {this.renderForm()}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive content={actionGenerateAndDownload.title} onClick={this.onSubmitClick} />
                        {sendEmailButton}
                        {resetFormButton}
                        <Button color='black' content={actionCancel.title} onClick={this.props.close} />
                    </Modal.Actions>
                </Modal>
            </TransitionablePortal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.auth.translations,
        data: state.priceListModalData,
    };
}

const actions = { fetchPriceListDataAndOpen, close, updateFieldValue, submitFormAndDownloadReport, clear, sendEmail, onChangeAjax };

export default connect(mapStateToProps, actions)(PriceListModal);

import { combineReducers } from 'redux';

import authReducer from "./auth/authReducer";
import gridModalReducer from "./common/gridModalReducer";
import messageReducer from "./common/messageReducer";

import gridPageReducer from "./gridPage/reducer";
import readPageReducer from './readPage/reducer';
import createEditPageReducer from './createEditPage/reducer';

import staticDataReducer from "./staticDataReducer";
import automaticLogout from "./automaticLogout";
import dashboard from "./dashboard";
import notificationBarReducer from "./notificationBar/notificationBarReducer";
import revisionTreeReducer from "./revisionTree/revisionTreeReducer";
import checkListReducer from "./checkList/checkListReducer";
import sysAdminPanelReducer from "./sysAdminPanelReducer";
import priceListModalReducer from "./priceListModal/priceListModalReducer";
import treePageWithSidePaneReducer from "./treePageWithSidePane/reducer";
import gridFilterParamsReducer from "./gridFilterParams/reducer";
import appDocumentationReducer from "./appDocumentation/reducer";
import appVersionReducer from "./appVersion/reducer";
import newScreenRducer from "./newScreen/newScreenRducer";

export default combineReducers({
    auth: authReducer,
    gridModal: gridModalReducer,
    message: messageReducer,

    gridPage: gridPageReducer,
    readPage: readPageReducer,
    createEditPage: createEditPageReducer,

    staticData: staticDataReducer,
    notificationBar: notificationBarReducer,
    revisionTreeModal: revisionTreeReducer,
    checkList: checkListReducer,
    automaticLogout: automaticLogout,
    dashboard: dashboard,
    sysAdminPanel: sysAdminPanelReducer,
    priceListModalData: priceListModalReducer,
    treePageWithSidePaneReducer: treePageWithSidePaneReducer,
    gridFilterParamsReducer: gridFilterParamsReducer,
    appDocumentation: appDocumentationReducer,
    appVersion: appVersionReducer,

    screenData: newScreenRducer,
});

import ioms from '../../apis/ioms';
import _ from 'lodash';
import history from '../../history';
import actionTypes from './actionTypes';
import { addMessage } from '../messageActions';
import { MESSAGE_ERROR } from '../../helpers/const';
import { ROUTES } from '../../helpers/routes';

export const toggleVisibility = () => {
    return {
        type: actionTypes.TOGGLE_VISIBILITY,
    }
}

export const setIsInCreateEditPage = (isInCreateEditPage) => {
    return {
        type: actionTypes.SET_IS_IN_CREATE_EDIT_PAGE,
        payload: { isInCreateEditPage }
    }
}

export const openPromptModal = (linkRouteName, linkRouteUrl, linkName) => {
    return {
        type: actionTypes.OPEN_PROMPT_MODAL,
        payload: { linkRouteName, linkRouteUrl, linkName }
    }
}

export const closePromptModal = () => {
    return {
        type: actionTypes.CLOSE_PROMPT_MODAL,
        payload: {}
    }
}

export const clearNotificationBar = () => {
    return {
        type: actionTypes.CLEAR_NOTIFICATIONS_STATE,
    }
}

export const updateReadUnreadButtonLoading = (notificationId, loading) => {
    return {
        type: actionTypes.UPDATE_READ_UNREAD_BUTTON_LOADING,
        payload: { notificationId: notificationId, loading: loading }
    }
}

export const fetch = () => async (dispatch) => {
    try {
        const response = await ioms.get('get/notifications', { withCredentials: true });

        if (response.data.success) {
            dispatch({
                type: actionTypes.FETCH_NOTIFICATIONS,
                payload: response.data.result
            });
        } else {
            const { sessionExpired, closedUserLog, permission, shouldChangePassword, shouldAcceptTermsAndConditions } = response.data;
            
            let route;
            let shouldAddErrorMessage = true;
            if (sessionExpired) {
                route = ROUTES.LOGIN_ROUTE.URL;
            } else if (closedUserLog) {
                route = ROUTES.LOGIN_ROUTE.URL;
            } else if (permission) {
                route = ROUTES.HOME_SCREEN_ROUTE.URL;
            } else if (shouldChangePassword) {
                route = ROUTES.EDIT_PASSWORD_ROUTE.URL;
            } else if (shouldAcceptTermsAndConditions) {
                route = ROUTES.TERMS_AND_CONDITIONS_DECISION_SCREEN.URL;
                shouldAddErrorMessage = false;
            }
            
            if (shouldAddErrorMessage) {
                dispatch(addMessage(MESSAGE_ERROR, { content: response.data.messages[0].text, visible: true }));
            }

            history.push(route);
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
}

//This action is used in grid.doEdit, grid.confirmRevisionTree, createEditPage.confirmEditMode
export const refresh = () => async (dispatch, getState) => {
    try {
        dispatch(fetch());

        // let postData = _.keys(getState().notificationBar.notificationsList);
        // const response = await ioms.post('refresh/notifications', postData, {withCredentials: true});

        // if (response.data.success) {
        //     dispatch({
        //         type: actionTypes.REFRESH_NOTIFICATIONS,
        //         payload: response.data.result
        //     });
        // } else {
        //     let route;
        //     dispatch(addMessage(MESSAGE_ERROR, {content: response.data.messages[0].text, visible: true}));

        //     const { sessionExpired, closedUserLog, permission, shouldChangePassword } = response.data;
        //     if (sessionExpired) {
        //         route = ROUTES.LOGIN_ROUTE.URL;
        //     } else if (closedUserLog) {
        //         route = ROUTES.LOGIN_ROUTE.URL;
        //     } else if (permission) {
        //         route = ROUTES.HOME_SCREEN_ROUTE.URL;
        //     } else if (shouldChangePassword) {
        //         route = ROUTES.EDIT_PASSWORD_ROUTE.URL;
        //     }

        //     history.push(route);
        // }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
}

export const readUnreadAction = (notificationId, url) => async (dispatch) => {
    try {
        await dispatch(updateReadUnreadButtonLoading(notificationId, true));

        const response = await ioms.get(url, { withCredentials: true });

        if (response.data.success) {
            dispatch({
                type: actionTypes.UPDATE_NOTIFICATION_READ_UNREAD,
                payload: response.data.result
            });
        } else {
            dispatch(addMessage(MESSAGE_ERROR, { content: response.data.messages[0].text, visible: true }));
        }

        await dispatch(updateReadUnreadButtonLoading(notificationId, false));
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
        await dispatch(updateReadUnreadButtonLoading(notificationId, false));
    }
}

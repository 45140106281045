export default {
    OPEN: 'OPEN',
    CLOSE: 'CLOSE',
    TOGGLE_STATUS_NODE: 'TOGGLE_STATUS_NODE',
    TOGGLE_STATUS_CHILD: 'TOGGLE_STATUS_CHILD',
    TOGGLE_EXPAND_NODE: 'TOGGLE_EXPAND_NODE',
    TOGGLE_EXPAND_CHILD: 'TOGGLE_EXPAND_CHILD',
    
    STATUS_CHECKED_ALL: 'STATUS_CHECKED_ALL',
    STATUS_CHECKED_NEGATIVE_ALL: 'STATUS_CHECKED_NEGATIVE_ALL',
    STATUS_UNCHECKED_ALL: 'STATUS_UNCHECKED_ALL',
    EXPAND_YES_ALL: 'EXPAND_YES_ALL',
    EXPAND_NO_ALL: 'EXPAND_NO_ALL',
    
    TOGGLE_SPINNER_MODAL_TREE: 'TOGGLE_SPINNER_MODAL_TREE',
    UPDATE_ERROR_MODAL_TREE: 'UPDATE_ERROR_MODAL_TREE',

    ADDITIONAL_INFO_FORM_VALUE_CHANGE: 'ADDITIONAL_INFO_FORM_VALUE_CHANGE',
};

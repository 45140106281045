import React from 'react';

const Footer = () => {
    return (
        <footer
            className="ui vertical footer segment"
            style={{ height: "50px" }}
        >
            {/* <div style={{ marginLeft: "10px", color: '#2887e6' }}>
                Powered by <b>WorkSoft Pro</b>
            </div> */}
            <div style={{ marginLeft: "10px", color: '#2887e6' }}>
                Powered by <b>
                    <a
                        href="https://worksoftpro.com/en/"
                        style={{ color: "black" }}
                        target="_blank"
                    >
                        <img
                            src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTX1DcEmXS-m6Qin_x6YB-v6BCA35cKUjjymA&s' 
                            style={{ width: '20px', height: '20px' }}>
                        </img> WorkSoft Pro
                    </a>
                </b>
            </div>
        </footer>
    );

}

export default Footer;

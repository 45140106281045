import React from 'react';
import _ from 'lodash';
import { Form, Message } from 'semantic-ui-react';
import AbstractField from '../formField/AbstractField';
import { FormFieldProp } from '../../../enum/apiResultProp/FormFieldProp';

export default ({
    rowKey,
    rowFields,
    onFormValueChange,
    updateFieldErrorAction,
    onActionMutateScreenWithPostData,
    onActionMutateScreenNoPostData,
    rowErrorMessages,
    onFieldChangeMutateComponentWithPostData,
    actionDownload,
}) => {
    _.orderBy(rowFields, FormFieldProp.PositionColumn);

    const renderErrorMessages = () => {
        let errorMessages = null;
        if (rowErrorMessages.length > 0) {
            errorMessages = (
                <Message
                    className='MyFormErrorRow'
                    error
                    list={rowErrorMessages}
                />
            );
        }

        return errorMessages;
    }

    return (
        <>
            {/* <Form.Group widths="equal" style={{ marginBottom: '0px', textAlign: 'center' }}> */}
            <Form.Group widths="equal" style={{ marginBottom: '0px' }}>
                {
                    _.map(rowFields, (fieldProps, fieldName) => {
                        return (
                            <AbstractField
                                key={fieldName}
                                fieldName={fieldName}
                                rowKey={rowKey}
                                fieldProps={fieldProps}
                                onChange={onFormValueChange}
                                updateFieldErrorAction={updateFieldErrorAction}
                                onActionMutateScreenWithPostData={onActionMutateScreenWithPostData}
                                onActionMutateScreenNoPostData={onActionMutateScreenNoPostData}
                                onFieldChangeMutateComponentWithPostData={onFieldChangeMutateComponentWithPostData}
                                actionDownload={actionDownload}
                            />
                        );
                    })
                }
            </Form.Group>

            {renderErrorMessages()}
        </>
    );
}

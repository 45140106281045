import React, { useState } from 'react';
import { Button, Popup, Grid, Icon } from 'semantic-ui-react';
import { FormButtonProp } from '../../../../enum/apiResultProp/FormButtonProp';

export default ({ buttonProps, onButtonClick, size }) => {
    const [open, setOpen] = useState(false);

    const buttonName = buttonProps[FormButtonProp.ButtonName];
    const text = buttonProps[FormButtonProp.Text];
    const iconName = buttonProps[FormButtonProp.IconName];
    const disabled = buttonProps[FormButtonProp.Disabled];
    const hidden = buttonProps[FormButtonProp.Hidden];
    const showSpinner = buttonProps[FormButtonProp.ShowSpinner];
    const color = buttonProps[FormButtonProp.Color];
    const popupQuestion = buttonProps[FormButtonProp.PopupQuestion];
    const popupPositiveText = buttonProps[FormButtonProp.PopupPositiveText];
    const popupNegativeText = buttonProps[FormButtonProp.PopupNegativeText];

    if (hidden) {
        return null;
    }

    const onPositiveButtonClick = () => {
        setOpen(false);
        onButtonClick();
    }

    let iconElement = null;
    const buttonSettings = {
        key: buttonName,
        disabled: disabled,
        color: color,
        size: size,
        icon: false
    };
    if (iconName) {
        buttonSettings.icon = true;
        iconElement = <Icon name={iconName} />

        if (text) {
            buttonSettings.labelPosition = 'left';
        }
    }
    const triggerButton = (
        <Button
            {...buttonSettings}
        >
            {text}
            {iconElement}
        </Button>
    );

    return (
        <Popup
            wide
            open={open}
            trigger={triggerButton}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            on='click'
        >
            <Grid>
                <Grid.Row centered>
                    <h2>{popupQuestion}</h2>
                </Grid.Row>
                <Grid.Row divided columns='equal'>
                    <Grid.Column>
                        <Button onClick={onPositiveButtonClick} color={color} content={popupPositiveText} fluid />
                    </Grid.Column>
                    <Grid.Column>
                        <Button color='black' content={popupNegativeText} fluid onClick={() => setOpen(false)} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Popup>
    );
}
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Divider, Dimmer, Loader, Header, Form as SemanticForm, Message, Popup } from 'semantic-ui-react';

import history from '../../../history';
import { STRINGS } from '../../../helpers/strings';
import { MESSAGE_ERROR, EMPTY_VALUE, FORM_CREATE_MODE, FORM_EDIT_MODE } from '../../../helpers/const';
import Form from '../../customComponents/Form';
import CodeVisualizer from '../../customComponents/CodeVisualizer';
import Accordion from '../../customComponents/Accordion';
import Steps from '../../customComponents/Steps';
import ButtonWithConfirmation from '../../customComponents/ButtonWithConfirmation';
import StandardDetailGridForm from './StandardDetailGridForm';
import {
    validatePage, preparePostParamsNEW_NEW, prepareRelatedDataPostParamsNEW_NEW, getValue, getRouteObj
} from '../../../helpers/functions';
import { addMessage } from '../../../actions/messageActions';
import { setIsInCreateEditPage } from '../../../actions/notificationBar/actions';
import {
    fetch, clear, submit, updateInput, updateError, updateAllErrors, updateRelatedDataInput, addRelatedDataRow,
    removeRelatedDataRow, updateRelatedDataError, updateFormAjax, revisionTreeHandleChecked, revisionTreeHandleExpand,
    lock, unlock, updateRelatedDataFieldDisabled, revisionDescriptionUpdateInput, downloadInstructions, importEntities,
    updateRelatedDataInputSliders, updateRelatedDataInputOfDimensionField, updateRelatedDataErrorOfDimensionField,
    updateFormOnStepActionAjax, updateRelatedDataInputButtonOptions
} from '../../../actions/createEditPage/actions';

import { downloadFileByPath } from '../../../actions/gridPage/existingRecordActions';

class CreateEditPage extends React.Component {
    state = {
        routeInfo: getRouteObj(this.props.match.path),
        detailedTabDropdownChanged: false
    };

    toggleDetailedTabDropdownChanged = () => {
        const toggledValue = !this.state.detailedTabDropdownChanged;
        this.setState({ detailedTabDropdownChanged: toggledValue })
    }

    componentDidMount() {
        let fetchUrl = this.state.routeInfo.API_FETCH_URL;
        const routeParams = this.props.match.params;

        if (routeParams) { //Edit Page
            _.forEach(this.props.match.params, (paramValue, paramName) => {
                fetchUrl = fetchUrl.replace(':' + paramName, paramValue);
            });
        } else { //Create Page
            fetchUrl = fetchUrl;
        }

        this.props.fetch(fetchUrl);
        this.props.setIsInCreateEditPage(true);
    };

    componentWillUnmount = () => {
        this.props.setIsInCreateEditPage(false);
        this.props.clear();
    }

    //This hadles the change in the ID when we are in Edit mode -> that way we rerender with the new information
    componentDidUpdate = (prevProps) => {
        const previousId = prevProps.match.params.id;
        const newId = this.props.match.params.id;

        if (previousId != newId) {
            let fetchUrl = this.state.routeInfo.API_FETCH_URL;
            fetchUrl = fetchUrl.replace(':id', newId);

            this.props.fetch(fetchUrl);
        }
    }

    onSaveClick = async () => {
        const [isValid, message, errors] = validatePage(this.props.data);

        if (isValid) {
            const { fields, relatedData, revisionLogic, mode } = this.props.data;
            const { newRevisionDescription, forRevision } = revisionLogic;

            const formData = new FormData();
            preparePostParamsNEW_NEW(formData, fields, 'header');
            prepareRelatedDataPostParamsNEW_NEW(relatedData, 'relatedData', formData);

            formData.append('forRevision', forRevision ? 1 : 0);
            if (forRevision === true) {
                formData.append('revisionDescription', newRevisionDescription);
            }

            let submitUrl = this.state.routeInfo.API_SUBMIT_URL;
            let redirectRoute = this.state.routeInfo.URL_SAVE_REDIRECT;
            if (mode === FORM_EDIT_MODE) {
                const routeParams = this.props.match.params;
                submitUrl = submitUrl.replace(':id', routeParams.id);
                redirectRoute = redirectRoute.replace(':id', routeParams.id);
            } else if (mode === FORM_CREATE_MODE) {
                submitUrl = submitUrl;
                redirectRoute = redirectRoute;
            }

            this.props.submit(submitUrl, formData, redirectRoute, mode);
        } else {
            await this.props.updateAllErrors(errors);

            this.props.addMessage(MESSAGE_ERROR, { content: message, visible: true })
        }
    }

    onCancelClick = () => {
        const { mode } = this.props.data;
        let redirectRoute = this.state.routeInfo.URL_BACK;

        if (mode === FORM_EDIT_MODE) {
            const routeParams = this.props.match.params;
            redirectRoute = redirectRoute.replace(':id', routeParams.id);
        } else if (mode === FORM_CREATE_MODE) {
            redirectRoute = redirectRoute;
        }

        history.push(redirectRoute);
    }

    renderTitle = () => {
        return (
            <Header as='h2' textAlign='center' style={{ marginTop: '0px' }}>
                <Header.Content>{this.props.data.title}</Header.Content>
            </Header>
        )
    };

    renderLockLogic = () => {
        const { fields, lockLogic, relatedData } = this.props.data;
        const {
            hasLockLogic, isLocked, requiredFieldsForUnlock, postParamsFields, urlLock, urlUnlock,
            lockButtonMessageWhenDisabled, saveButtonDisabled, saveButtonMessageWhenDisabled
        } = lockLogic;

        if (_.isEmpty(fields) || hasLockLogic === false) {
            return null;
        }

        let requiredFieldsAreSet = true;
        _.forEach(requiredFieldsForUnlock, (field, index) => {
            const value = fields[field].value;

            if (value === EMPTY_VALUE) {
                requiredFieldsAreSet = false;
            }
        });

        let postData = {};
        _.forEach(postParamsFields, (field, index) => {
            postData[field] = fields[field].value;
        });
        postData = { ...postData, relatedData };

        let button = null
        if (isLocked) {
            button = (
                <ButtonWithConfirmation
                    translations={this.props.translations}
                    rowId={1}
                    key={'unlock_1'}
                    question={getValue(this.props.translations, STRINGS.UNLOCK_CONFIRM_QUESTION)}
                    buttonConfirmTitle={getValue(this.props.translations, STRINGS.BUTTON_UNLOCK)}
                    actionConfirm={() => this.props.unlock(urlUnlock, postData)}
                />
            )
        } else {
            if (requiredFieldsAreSet === false) {
                button = (
                    <Popup
                        wide='very'
                        trigger={
                            <div style={{ display: "inline-block" }}>
                                <button onClick={() => this.props.lock(urlLock, postData)} className="ui blue button disabled">
                                    <i className="cogs icon"></i>{getValue(this.props.translations, STRINGS.BUTTON_LOCK)}
                                </button>
                            </div>
                        }
                        content={lockButtonMessageWhenDisabled}
                        basic
                    />
                );
            } else {
                button = (
                    <button onClick={() => this.props.lock(urlLock, postData)} className="ui blue button">
                        <i className="cogs icon"></i>{getValue(this.props.translations, STRINGS.BUTTON_LOCK)}
                    </button>
                );
            }
        }

        return button;
    }

    renderButtonsArea = () => {
        const { hideActions, lockLogic } = this.props.data;
        const {
            hasLockLogic, saveButtonDisabled, saveButtonMessageWhenDisabled
        } = lockLogic;

        if (hideActions) {
            return null;
        }

        let saveButton = null;
        if (hasLockLogic) {
            if (saveButtonDisabled) {
                saveButton = (
                    <Popup
                        wide='very'
                        trigger={
                            <div style={{ display: "inline-block" }}>
                                <button
                                    className="ui green button disabled"
                                    onClick={this.onSaveClick}
                                >
                                    <i className="save icon"></i>{getValue(this.props.translations, STRINGS.BUTTON_SAVE)}
                                </button>
                            </div>
                        }
                        content={saveButtonMessageWhenDisabled}
                        basic
                    />
                );
            } else {
                saveButton = (
                    <button
                        className="ui green button"
                        onClick={this.onSaveClick}
                    >
                        <i className="save icon"></i>{getValue(this.props.translations, STRINGS.BUTTON_SAVE)}
                    </button>
                );
            }
        } else {
            saveButton = (
                <button
                    className="ui green button"
                    onClick={this.onSaveClick}
                >
                    <i className="save icon"></i>{getValue(this.props.translations, STRINGS.BUTTON_SAVE)}
                </button>
            );
        }

        return (
            <React.Fragment>
                {saveButton}

                <button
                    className="ui red button"
                    onClick={this.onCancelClick}
                >
                    <i className="window close outline icon"></i>{getValue(this.props.translations, STRINGS.BUTTON_CANCEL)}
                </button>
                {this.renderLockLogic()}
            </React.Fragment>
        );
    }

    renderMainForm = () => {
        const { translations, data } = this.props;
        const { mode, fields, fieldGroups, invalidFieldsNumber } = data;

        if (fields === EMPTY_VALUE) {
            return null;
        }

        return (
            <Form
                translations={translations}
                mode={mode}
                fields={fields}
                fieldGroups={fieldGroups}
                invalidFieldsNumber={invalidFieldsNumber}
                onInputChange={this.props.updateInput}
                errorUpdate={this.props.updateError}
                handleAjaxWitUrlAction={(url, postData, rowKey, mode) => this.handleAjaxWitUrlAction(url, postData, mode, rowKey)}
                downloadFileByPath={this.props.downloadFileByPath}
            />
        );
    }

    handleAjaxWitUrlAction = (url, postData, relatedDataMode, rowKey, relatedDataName) => {
        const { fields, relatedData, revisionLogic, mode } = this.props.data;
        const { revisionBomTreeData, newRevisionDescription, forRevision } = revisionLogic;

        //mode = page mode
        postData = { ...postData, fields, relatedData, revisionBomTreeData, mode, rowKey, relatedDataMode };

        this.props.updateFormAjax(url, postData, relatedDataName, relatedDataMode, rowKey);
    }

    importEntities = (file, importUrl) => {
        const nextRoute = this.state.routeInfo.URL_SAVE_REDIRECT.replace(':id', this.props.match.params.id);

        this.props.importEntities(file, importUrl, nextRoute);
    }

    renderRelatedDataForm = (relatedDataName, data) => {
        const {
            gridColumnsCount, fieldGroups, headerLabels, rowsForCreate, rowsForEdit,
            formFieldsConfigSubtotal, actionAdd, actionDelete, onDeleteRowAjaxUrl, importAction,
            actionDeleteEditModeSkip, tabInformation, rowKeysNoDelete
            //this actionDeleteEditModeSkip is temp logic
        } = data;

        let tabInfoMessage = null;
        if (!_.isUndefined(tabInformation)) {
            tabInfoMessage = <Message warning header={tabInformation} />;
        }

        return (
            <React.Fragment>
                {tabInfoMessage}

                <StandardDetailGridForm
                    toggleDetailedTabDropdownChanged={this.toggleDetailedTabDropdownChanged}
                    actionAdd={actionAdd}
                    actionDeleteEditModeSkip={actionDeleteEditModeSkip}
                    rowKeysNoDelete={rowKeysNoDelete}
                    actionDelete={actionDelete}
                    translations={this.props.translations}
                    fieldGroups={fieldGroups}
                    headerLabels={headerLabels}
                    gridColumnsCount={gridColumnsCount}
                    rowsForCreate={rowsForCreate}
                    rowsForEdit={rowsForEdit}
                    formFieldsConfigSubtotal={formFieldsConfigSubtotal}
                    updateInput={(rowKey, fieldName, fieldValue, mode) => this.props.updateRelatedDataInput(relatedDataName, mode, rowKey, fieldName, fieldValue)}
                    updateSliderInput={(params) => this.props.updateRelatedDataInputSliders({ ...params, relatedDataName })}
                    updateButtonOptionsInput={(parameters) => this.props.updateRelatedDataInputButtonOptions({ ...parameters, relatedDataName: relatedDataName })}
                    addRow={() => this.props.addRelatedDataRow(relatedDataName)}
                    cancelRow={(rowId, mode) => this.props.removeRelatedDataRow(relatedDataName, mode, rowId)}
                    errorUpdate={(rowKey, fieldName, error, errorMessage, mode) => this.props.updateRelatedDataError(relatedDataName, mode, rowKey, fieldName, error, errorMessage)}
                    handleAjaxWitUrlAction={(url, postData, rowKey, mode) => this.handleAjaxWitUrlAction(url, postData, mode, rowKey, relatedDataName)}
                    updateFieldDisabled={(rowKey, mode, fieldName, disabled) => this.props.updateRelatedDataFieldDisabled(relatedDataName, mode, rowKey, fieldName, disabled)}
                    onDeleteRowAjaxEvent={() => this.handleAjaxWitUrlAction(onDeleteRowAjaxUrl, {})}
                    importAction={importAction}
                    importEntities={this.importEntities}
                    downloadInstructions={this.props.downloadInstructions}
                    downloadFileByPath={this.props.downloadFileByPath}

                    // errorUpdateOfDimensionField={(rowKey, fieldName, error, errorMessage, mode, dimensionName) => this.props.updateRelatedDataErrorOfDimensionField(relatedDataName, mode, rowKey, fieldName, error, errorMessage, dimensionName)}
                    updateInputOfDimensionField={(rowKey, fieldName, fieldValue, mode, dimensionName) => this.props.updateRelatedDataInputOfDimensionField(relatedDataName, mode, rowKey, fieldName, fieldValue, dimensionName)}
                />
            </React.Fragment>
        );
    }

    renderRelatedData = () => {
        const { relatedData, relatedDataPositions } = this.props.data;

        if (_.isEmpty(relatedData)) {
            return null;
        }

        const relatedDataPositionsOrdered = _.orderBy(relatedDataPositions, ['position'], ['asc'])

        return _.map(relatedDataPositionsOrdered, ({ relatedDataName }) => {
            const data = relatedData[relatedDataName];

            if (data === EMPTY_VALUE) {
                return null;
            }

            let { title, type, codes, subTitle } = data;

            let res = null;
            if (type === 'DETAIL_TAB_GRID') {
                const someDropdownToggledItsStateInRelatedTabForms = this.state.detailedTabDropdownChanged;
                res = (
                    <React.Fragment key={relatedDataName}>
                        <Divider />
                        <Accordion
                            someDropdownToggledItsStateInRelatedTabForms={someDropdownToggledItsStateInRelatedTabForms}
                            isActiveInitialValue={this.props.settings.detailExpanded}
                            style={{ marginTop: '25px' }}
                            title={title}
                            renderContent={() => this.renderRelatedDataForm(relatedDataName, data)}
                        />
                    </React.Fragment>
                );
            } else if (type === 'DETAIL_TAB_CODE_VISUALIZER') {
                res = (
                    <React.Fragment key={relatedDataName}>
                        <Accordion
                            isActiveInitialValue={this.props.settings.detailExpanded}
                            style={{ marginTop: '25px' }}
                            title={title}
                            renderContent={() => <CodeVisualizer codes={codes} title={subTitle} />}
                        />
                    </React.Fragment>
                );
                return res;
            } else {
                res = (
                    <React.Fragment key={relatedDataName}>
                        <Accordion
                            isActiveInitialValue={this.props.settings.detailExpanded}
                            style={{ marginTop: '25px' }}
                            title={title}
                            renderContent={() => <p>Invalid related data detail type</p>}
                        />
                    </React.Fragment>
                );
                return res;
            }

            return res;
        });
    }

    renderMessage = () => {
        const { header, content } = this.props.data.message;
        if (header === undefined || content === undefined) {
            return null;
        }

        return (
            <Message
                warning
                header={header}
                content={content}
            />
        );
    }

    onPhasesBackClick = () => {
        history.push(this.state.routeInfo.URL_BACK);
    }

    onPhaseSubmitClick = async (submitUrl) => {
        const [isValid, message, errors] = validatePage(this.props.data);

        if (isValid) {
            const { fields, relatedData } = this.props.data;

            const formData = new FormData();
            preparePostParamsNEW_NEW(formData, fields, 'header');
            prepareRelatedDataPostParamsNEW_NEW(relatedData, 'relatedData', formData);

            const homeRoute = this.state.routeInfo.URL_BACK;

            this.props.updateFormOnStepActionAjax(submitUrl, formData, homeRoute);
        } else {
            await this.props.updateAllErrors(errors);

            this.props.addMessage(MESSAGE_ERROR, { content: message, visible: true })
        }
    }

    onPhaseActivateClick = (url) => {
        this.props.updateFormOnStepActionAjax(url);
    }

    onPhaseStartClick = (url) => {
        this.props.updateFormOnStepActionAjax(url);
    }

    onPhaseDeclineClick = (url) => {
        this.props.updateFormOnStepActionAjax(url);
    }

    onPhaseClick = (url) => {
        this.props.updateFormOnStepActionAjax(url);
    }

    renderSteps = () => {
        const { stepsConfiguration } = this.props.data;
        if (stepsConfiguration === undefined || stepsConfiguration === EMPTY_VALUE) {
            return null;
        }

        return (
            <Steps
                //Data
                stepsConfiguration={stepsConfiguration}
                translations={this.props.translations}
                //Button Actions
                onBackClick={this.onPhasesBackClick}
                onActivateClick={this.onPhaseActivateClick} //updateFormOnStepActionAjax
                onStartWorkClick={this.onPhaseStartClick} //updateFormOnStepActionAjax
                onSubmitClick={this.onPhaseSubmitClick}
                onDeclineClick={this.onPhaseDeclineClick} //updateFormOnStepActionAjax
                download={this.props.downloadInstructions}
                //On Phase Click
                onPhaseClick={this.onPhaseClick} //updateFormOnStepActionAjax
            />
        );
    }

    render() {
        if (!this.props.data || !this.props.settings) {
            return null;
        }

        return (
            <Fragment>
                {this.renderTitle()}

                {this.renderButtonsArea()}

                {this.renderMessage()}

                {this.renderSteps()}

                <Dimmer active={this.props.data.showSpinner} inverted>
                    <Loader inverted />
                </Dimmer>

                <Divider />

                {this.renderMainForm()}

                {this.renderRelatedData()}

                <Divider />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.auth.translations,
        settings: state.auth.settings,
        data: state.createEditPage,
    };
}

const actions = {
    fetch, updateInput, updateError, submit, addMessage, updateAllErrors, clear, updateRelatedDataFieldDisabled,
    updateRelatedDataInput, addRelatedDataRow, removeRelatedDataRow, updateRelatedDataError, updateFormAjax,
    revisionTreeHandleChecked, revisionTreeHandleExpand, lock, unlock, revisionDescriptionUpdateInput,
    downloadInstructions, importEntities, updateRelatedDataInputSliders, updateRelatedDataInputOfDimensionField,
    updateRelatedDataErrorOfDimensionField, updateFormOnStepActionAjax, updateRelatedDataInputButtonOptions,
    downloadFileByPath, setIsInCreateEditPage
};

export default connect(mapStateToProps, actions)(CreateEditPage);

export default {
    TOGGLE_VISIBILITY: 'TOGGLE_VISIBILITY',
    FETCH_NOTIFICATIONS: 'FETCH_NOTIFICATIONS',
    REFRESH_NOTIFICATIONS: 'REFRESH_NOTIFICATIONS',
    CLEAR_NOTIFICATIONS_STATE: 'CLEAR_NOTIFICATIONS_STATE',
    SET_IS_IN_CREATE_EDIT_PAGE: 'SET_IS_IN_CREATE_EDIT_PAGE',
    CLOSE_PROMPT_MODAL: 'CLOSE_PROMPT_MODAL',
    OPEN_PROMPT_MODAL: 'OPEN_PROMPT_MODAL',
    UPDATE_READ_UNREAD_BUTTON_LOADING: 'UPDATE_READ_UNREAD_BUTTON_LOADING',
    UPDATE_NOTIFICATION_READ_UNREAD: 'UPDATE_NOTIFICATION_READ_UNREAD'
};
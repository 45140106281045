import React from 'react';
import _, { add } from 'lodash';
import { LabelProp } from '../../enum/apiResultProp/LabelProp';
import { Label } from 'semantic-ui-react';
import { LabelShape } from '../../enum/LabelShape';
import { LabelCornerLocation } from '../../enum/LabelCornerLocation';
import CustomPopup from './CustomPopup';

export default ({ labelProps, style }) => {
    const shape = labelProps[LabelProp.Shape];
    const cornerLocation = labelProps[LabelProp.CornerLocation];
    const text = labelProps[LabelProp.Text];
    const textColor = labelProps[LabelProp.TextColor];
    const backgroundColor = labelProps[LabelProp.BackgroundColor];
    const popupProps = labelProps[LabelProp.PopupInfo];
    const size = labelProps[LabelProp.Size];

    let additionalProps = {};

    if (shape === LabelShape.Circular) {
        additionalProps.circular = true;
    }

    if (cornerLocation === LabelCornerLocation.Left) {
        additionalProps.corner = 'left';
    } else if (cornerLocation === LabelCornerLocation.Right) {
        additionalProps.corner = 'right';
    }

    let label = (
        <Label color={backgroundColor} size={size} {...additionalProps}>
            {text}
        </Label>
    );

    if (popupProps) {
        label = <CustomPopup popupProps={popupProps} triggerComponent={label} />
    }

    return label;
}

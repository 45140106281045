import React from 'react';
import { Table } from 'semantic-ui-react';

import { ACCESS_LEVEL } from '../../../helpers/const';

class TableFooter extends React.Component {
    onPageChange = (pageIndex) => {
        this.props.updateOnPage(pageIndex);
    }

    renderPagingDropDown = () => {
        let onPageSelectedIndex = this.props.grid.configuration.pagination.onPageSelectedIndex;
        let onPageObject = this.props.grid.configuration.pagination.onPage;

        return (
            <div className="ui left floated menu form">
                <select 
                    value={onPageSelectedIndex}
                    onChange={e => this.onPageChange(e.target.value)}
                >
                    {Object.keys(onPageObject).map(index => {
                        let value = onPageObject[index];

                        return (
                            <option value={index} key={index}>
                                {value}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    }

    generatePages = (totalPages, currentPage) => {
        let pages = [];

        if (totalPages > 6 && (currentPage <= 3 || currentPage >= totalPages - 3)) {
            for (let i = 1; i <= 3; i++) {
                let isCurrentPage = i === currentPage;
    
                pages.push({
                    value: i,
                    class: isCurrentPage ? 'active' : '',
                    key: i
                });
            }

            pages.push({
                value: '...',
                class: 'disabled',
                key: Date.now()-100
            });

            for (let i = totalPages - 3; i <= totalPages; i++) {
                let isCurrentPage = i === currentPage;
    
                pages.push({
                    value: i,
                    class: isCurrentPage ? 'active' : '',
                    key: i
                });
            }
        } else if (currentPage > 3 && currentPage <= totalPages - 3) {
            pages.push({
                value: 1,
                class: '',
                key: 1
            });

            pages.push({
                value: '...',
                class: 'disabled',
                key: Date.now()+200
            });

            for (let i = currentPage - 2; i < currentPage + 2; i++) {
                let isCurrentPage = i === currentPage;
    
                pages.push({
                    value: i,
                    class: isCurrentPage ? 'active' : '',
                    key: i
                });
            }

            pages.push({
                value: '...',
                class: 'disabled',
                key: Date.now()-355
            });

            pages.push({
                value: totalPages,
                class: '',
                key: totalPages
            });
        } else {
            for (let i = 1; i <= totalPages; i++) {
                let isCurrentPage = i === currentPage;
    
                pages.push({
                    value: i,
                    class: isCurrentPage ? 'active' : '',
                    key: i
                });
            }
        }

        return pages;
    } 

    onChangePageClick = (e, pageNumber, disabled) => {
        e.preventDefault();

        if (! disabled && this.props.grid.configuration.pagination.currentPage !== pageNumber) {
            this.props.changePage(pageNumber);
        }
    }

    renderPagination = () => {
        let {totalPages, currentPage} = this.props.grid.configuration.pagination;

        let isFirstPage = currentPage === 1;
        let isLastPage = currentPage === totalPages;

        let rightArrowButtonsClass = isLastPage ? 'disabled' : '';
        let leftArrowButtonsClass = isFirstPage ? 'disabled' : '';

        let pages = this.generatePages(totalPages, currentPage);

        return (
            <div className="ui right floated pagination menu">
                <a className="icon item" href="/" onClick={(e) => this.onChangePageClick(e, totalPages, rightArrowButtonsClass === 'disabled')}>
                    <i className={`angle double right ${rightArrowButtonsClass} icon`}></i>
                </a>
                <a className="icon item" href="/" onClick={(e) => this.onChangePageClick(e, currentPage + 1, rightArrowButtonsClass === 'disabled')}>
                    <i className={`angle right ${rightArrowButtonsClass} icon`}></i>
                </a>
                { 
                    pages.map((pageObject) => {
                        return (
                            <a 
                                href="/"
                                key={pageObject.key}
                                className={`${pageObject.class} item`} 
                                onClick={(e) => this.onChangePageClick(e, pageObject.value, pageObject.class === 'disabled')}
                            >   
                                {pageObject.value}
                            </a>
                        );
                    })
                }
                <a className="icon item" href="/" onClick={(e) => this.onChangePageClick(e, currentPage - 1, leftArrowButtonsClass === 'disabled')}>
                    <i className={`angle left ${leftArrowButtonsClass} icon`}></i>
                </a>
                <a className="icon item" href="/" onClick={(e) => this.onChangePageClick(e, 1, leftArrowButtonsClass === 'disabled')}>
                    <i className={`angle double left ${leftArrowButtonsClass} icon`}></i>
                </a>
            </div>
        );
    }

    getColumnsCount = () => {
        let columnsCount = this.props.gridColumnsCount;
        if (this.props.access >= ACCESS_LEVEL.WRITE) {
            columnsCount++;
        } 

        return columnsCount;
    }

    render() {
        return (
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan={this.getColumnsCount()}>
                        { this.renderPagingDropDown() }
                        { this.renderPagination() }
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        );
    }
}

export default TableFooter;

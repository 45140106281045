import ioms from '../apis/ioms';
import actionTypes from '../actions/actionTypes';
import _ from 'lodash';
    
export const updateGridFormInput = (fieldName, fieldValue) => {
    return {
        type: actionTypes.UPDATE_GRID_FORM_INPUT,
        payload: { fieldName, fieldValue }
    };
}

export const updateGridFormError = (fieldName, error, errorMessage) => {
    return {
        type: actionTypes.UPDATE_GRID_FORM_ERROR,
        payload: { fieldName, error, errorMessage }
    };
}

export const updateAllGridFormErrors = (fieldErrorsForUpdate) => {
    return {
        type: actionTypes.UPDATE_ALL_GRID_FORM_ERRORS,
        payload: { fieldErrorsForUpdate }
    };
}

export const updateGridModalError = (validationMessages) => {
    return {
        type: actionTypes.UPDATE_GRID_MODAL_ERROR,
        payload: { validationMessages }
    };
}

export const closeGridModal = () => {
    return {
        type: actionTypes.CLOSE_GRID_MODAL
    };
}

export const openGridModalCreate = (fieldsConfig, fieldGroupsConfig) => {
    return {
        type: actionTypes.OPEN_GRID_MODAL_CREATE,
        payload: {fieldsConfig, fieldGroupsConfig}
    };
}

export const openGridModalEdit = (entityId, fieldValues, fieldsConfig, fieldGroupsConfig,) => async(dispatch) => {
    dispatch({
        type: actionTypes.OPEN_GRID_MODAL_EDIT,
        payload: {fieldsConfig, entityId, fieldValues, fieldGroupsConfig }
    });
}

export const toggleSpinner = () => {
    return {
        type: actionTypes.TOGGLE_SPINNER,
    }
}

export const updateFieldObjectAjax = (url, postData) => async (dispatch) => {
    const response = await ioms.post(url, postData, { withCredentials: true });

    const {success, result, messages} = response.data; 

    if (success) {
        dispatch({
            type: actionTypes.UPDATE_FILED_OBJECT_AJAX,
            payload: result 
        });
    } else {
        if (! _.isUndefined(messages) && ! _.isUndefined(messages[0])) {
            dispatch(updateGridModalError([messages[0].text]));
        }
    }
};

export const setBomTreeExpanded = (expanded) => {
    return {
        type: actionTypes.SET_BOM_TREE_EXPANDED_MODAL,
        payload: expanded
    }
}

export const setBomTreeChecked = (checked, second) => {
    return {
        type: actionTypes.SET_BOM_TREE_CHECKED_MODAL,
        payload: checked
    }
}

import actionTypes from "./actionTypes";

export const saveGridPageParams = (gridRouteName, formData) => {
    return {
        type: actionTypes.SAVE_PARAMS,
        payload: { gridRouteName, formData }
    }
}

export const deleteGridPageParams = (gridRouteName) => {
    return {
        type: actionTypes.DELETE_PARAMS,
        payload: { gridRouteName }
    }
}

export const resetAllGridPageParams = () => {
    return {
        type: actionTypes.RESET_ALL_PARAMS,
        payload: {}
    }
}

// This strings are send for translation to the BE
// Register all the FE Strings here
export const STRINGS = {
    //button labels
    LOADING: 'Loading',
    BUTTON_CONFIRM: 'Confirm',
    UNLOCK_CONFIRM_QUESTION: 'Are you sure? You will delete detail on confirm.',
    BUTTON_CLOSE: 'Close',
    BUTTON_SAVE: 'Save',
    BUTTON_EDIT: 'Edit',
    BUTTON_CANCEL: 'Cancel',
    BUTTON_CANCEL_CONFIRM_QUESTION: 'Are you sure you want to cancel this row?',
    BUTTON_DELETE: 'Delete',
    BUTTON_DELETE_CONFIRM_QUESTION: 'Are you sure you want to delete this row?',
    BUTTON_BACK: 'Back',
    
    ERRORS_IN_SCREEN: 'Validation errors found. Please fix them and submit after that.',
    YOUR_DATA_IS_NOT_SAVED: 'Your data is not saved. Do you want to proceed to',
    APPLY_FILTERS: 'APPLY FILTERS',
    DELETE_FILTERS: 'DELETE FILTERS',
    COLUMN: 'Column',
    OPERATOR: 'Operator',
    VALUE: 'Value',
    APPLIED: 'Applied',
    FILTERS: 'FILTERS',
}
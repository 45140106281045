import actionTypes from "../../actions/gridFilterParams/actionTypes";
import { saveParams, deleteParams, reset } from './stateMutators';

const DEFAULT_STATE = {};

const gridParamsSavedReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case actionTypes.SAVE_PARAMS:
            return saveParams(state, action);
        case actionTypes.DELETE_PARAMS:
            return deleteParams(state, action);
        case actionTypes.RESET_ALL_PARAMS:
            return reset(DEFAULT_STATE);
        default: 
            return state;
    }
}

export default gridParamsSavedReducer;

import React from 'react';
import _ from 'lodash';
import { Table, Icon } from 'semantic-ui-react';
import { TableDataRowProp } from '../../../enum/apiResultProp/TableDataRowProp';
import AbstractDataCell from './cell/AbstractDataCell';
import { TableDataRowColor } from '../../../enum/TableDataRowColor';
import { ColumnProp } from '../../../enum/dataGrid/ColumnProp';

export default ({ tableDataRowProps, columns, selectable, selectedRowIds, unselectableRowIds, selectSingle, mutateComponentWitPostData, openModalForm, onActionDownload }) => {
    const cells = tableDataRowProps[TableDataRowProp.Cells];
    const color = tableDataRowProps[TableDataRowProp.Color];
    const rowId = tableDataRowProps[TableDataRowProp.RowKey];

    const renderSelectableCell = () => {
        if (!selectable) {
            return null;
        }

        if (_.includes(unselectableRowIds, rowId)) {
            return (
                <Table.Cell textAlign='center'>
                </Table.Cell>
            );
        }

        let iconName = 'square outline';
        let currentlyClicked = false;
        if (_.includes(selectedRowIds, rowId)) {
            iconName = 'check square';
            currentlyClicked = true;
        }

        return (
            <Table.Cell textAlign='center'>
                <Icon name={iconName} size='large' style={{ color: '#3C8AD9', cursor: 'pointer' }} onClick={() => selectSingle({ rowId, currentlyClicked })} />
            </Table.Cell>
        )
    }

    let warning = false;
    let positive = false;
    let negative = false;
    if (color === TableDataRowColor.Orange) {
        warning = true;
    } else if (color === TableDataRowColor.Red) {
        negative = true;
    } else if (color === TableDataRowColor.Green) {
        positive = true;
    }

    return (
        <Table.Row warning={warning} positive={positive} negative={negative}>
            {renderSelectableCell()}
            {
                _.map(columns, (columnProps) => {
                    const hidden = columnProps[ColumnProp.Hidden];
                    const columnName = columnProps[ColumnProp.Name];
                    if (hidden) {
                        return null;
                    }

                    const tableDataCellProps = cells[columnName];

                    return <AbstractDataCell 
                        key={columnName} 
                        tableDataCellProps={tableDataCellProps} 
                        mutateComponentWitPostData={mutateComponentWitPostData}
                        openModalForm={openModalForm} 
                        onActionDownload={onActionDownload}
                    />
                })
            }
        </Table.Row>
    );
}

import React from 'react';
import _ from 'lodash';
import DOMPurify from 'dompurify';
import { Segment } from 'semantic-ui-react';

const HtmlViewer = ({ html, key }) => {
    let elementKey;
    if (_.isUndefined(key)) {
        elementKey = Math.random(1);
    } else {
        elementKey = key;
    }

    return (
        <Segment
            key={elementKey}
            style={{ width: '100%' }}
            size={'massive'}
        >
            <div
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}
                style={{ all: 'initial' }}
            />
        </Segment>
    );
}

export default HtmlViewer;

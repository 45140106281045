export default {
    FETCH: 'FETCH',
    CLEAR_DATA: 'CLEAR_DATA',
    UPDATE_FORM_AJAX: 'UPDATE_FORM_AJAX',
    TOGGLE_SPINNER_C_E_P: 'TOGGLE_SPINNER_C_E_P',

    UPDATE_ALL_ERRORS: 'UPDATE_ALL_ERRORS',
    
    UPDATE_INPUT: 'UPDATE_INPUT',
    UPDATE_ERROR: 'UPDATE_ERROR',
    
    UPDATE_RELATED_DATA_INPUT: 'UPDATE_RELATED_DATA_INPUT',
    UPDATE_RELATED_DATA_INPUT_BUTTON_OPTIONS_FIELD: 'UPDATE_RELATED_DATA_INPUT_BUTTON_OPTIONS_FIELD',
    UPDATE_RELATED_DATA_INPUT_DIMENSION_FIELD: 'UPDATE_RELATED_DATA_INPUT_DIMENSION_FIELD',
    UPDATE_RELATED_DATA_INPUT_SLIDERS: 'UPDATE_RELATED_DATA_INPUT_SLIDERS',
    UPDATE_RELATED_DATA_ERROR: 'UPDATE_RELATED_DATA_ERROR',
    UPDATE_RELATED_DATA_ERROR_DIMENSION_FIELD: 'UPDATE_RELATED_DATA_ERROR_DIMENSION_FIELD',
    ADD_RELATED_DATA_ROW: 'ADD_RELATED_DATA_ROW',
    REMOVE_RELATED_DATA_ROW: 'REMOVE_RELATED_DATA_ROW',
    UPDATE_RELATED_DATA_FIELD_DISABLED: 'UPDATE_RELATED_DATA_FIELD_DISABLED',

    REVISION_TREE_HANDLE_EXPAND: 'REVISION_TREE_HANDLE_EXPAND', 
    REVISION_TREE_HANDLE_CHECK: 'REVISION_TREE_HANDLE_CHECK', 
    REVISION_DESCRIPTION_UPDATE_INPUT: 'REVISION_DESCRIPTION_UPDATE_INPUT', 
    
    HANDLE_LOCK: 'HANDLE_LOCK', 
    UPDATE_ON_STEP_CLICK_AJAX: 'UPDATE_ON_STEP_CLICK_AJAX', 
};
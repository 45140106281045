import React from 'react';
import { Button, Table, Form, Label, Message } from 'semantic-ui-react';
import _ from 'lodash';

import ImportField from '../../customComponents/ImportField';
import TableForm from '../../customComponents/TableForm';
import { FORM_EDIT_MODE, FORM_CREATE_MODE, BUTTON_TYPES  } from '../../../helpers/const';

class StandardDetailGridForm extends React.Component {
    renderGrid = (mode, rows) => {
        if (_.isEmpty(rows)) {
            return null;
        }

        return Object.keys(rows).map(rowKey => {
            const rowFields = rows[rowKey];
            
            let actions = [];
            if (this.props.actionDelete === true) {
                if (! _.isUndefined(this.props.rowKeysNoDelete) && _.isArray(this.props.rowKeysNoDelete)) {
                    if (! _.includes(this.props.rowKeysNoDelete, rowKey)) {
                        if (mode === FORM_EDIT_MODE) {
                            actions.push(BUTTON_TYPES.DELETE);
                        } else if (mode === FORM_CREATE_MODE) {
                            actions.push(BUTTON_TYPES.CANCEL_CREATE);
                        }       
                    }
                } else {
                    if (! _.includes(this.props.rowKeysNoDelete, rowKey)) {
                        if (mode === FORM_EDIT_MODE) {
                            actions.push(BUTTON_TYPES.DELETE);
                        } else if (mode === FORM_CREATE_MODE) {
                            actions.push(BUTTON_TYPES.CANCEL_CREATE);
                        }       
                    }
                }
            }
            
            return (
                <React.Fragment key={rowKey}>
                    <TableForm
                        toggleDetailedTabDropdownChanged={this.props.toggleDetailedTabDropdownChanged}
                        translations={this.props.translations}
                        gridColumnsCount={this.props.gridColumnsCount}
                        rowKey={rowKey} 
                        mode={mode}
                        fields={rowFields}
                        fieldGroups={this.props.fieldGroups}
                        invalidFieldsNumber={0}
                        actions={actions}
                        onCancelCreateClick={(rowId) => this.props.cancelRow(rowId, mode)}
                        onDeleteRowAjaxEvent={this.props.onDeleteRowAjaxEvent}
                        onDeleteClick={(rowId) => this.props.cancelRow(rowId, mode)}
                        onInputSliderChange={(params) => this.props.updateSliderInput({...params, rowKey, mode})}
                        onInputChange={(fieldName, fieldValue) => this.props.updateInput(rowKey, fieldName, fieldValue, mode)}
                        onInputButtonOptionClick={(parameters) => this.props.updateButtonOptionsInput({...parameters, rowKey: rowKey, mode: mode})}
                        errorUpdate={(fieldName, error, errorMessage) => this.props.errorUpdate(rowKey, fieldName, error, errorMessage, mode)}
                        handleAjaxWitUrlAction={this.props.handleAjaxWitUrlAction}
                        disabledUpdate={(fieldName, disabled) => this.props.updateFieldDisabled(rowKey, mode, fieldName, disabled)}
                        onInputChangeDimensionField={(fieldName, fieldValue, dimensionName) => this.props.updateInputOfDimensionField(rowKey, fieldName, fieldValue, mode, dimensionName)}
                        // errorUpdateDimensionField={(fieldName, error, errorMessage, dimensionName) => this.props.errorUpdateOfDimensionField(rowKey, fieldName, error, errorMessage, mode, dimensionName)}
                        downloadFileByPath={this.props.downloadFileByPath}
                    />
                </React.Fragment>
            );
        });
    }

    getHeaderActionCell = () => {
        if (this.props.actionDelete === false) {
            return null;
        }

        return (
            <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
        );
    }

    renderHeader = () => {
        const fieldNames = this.props.fieldGroups[Object.keys(this.props.fieldGroups)[0]];
        
        if (fieldNames === undefined) {
            return null;
        }
        
        return (
            <Table.Row textAlign='center'>
                {fieldNames.map(fieldName => {
                    const headerObject = this.props.headerLabels[fieldName];

                    let style = {paddingLeft: '35px', paddingRight: '35px'};
                    if (! _.isEmpty(headerObject)) {
                        return <Table.HeaderCell style={style} key={headerObject.name} width={headerObject.width}>{headerObject.name}</Table.HeaderCell>
                    } else {
                        return null;
                    }
                })}

                { this.getHeaderActionCell() }
            </Table.Row>
        );
    } 

    renderAddAction = () => {
        if (this.props.actionAdd === false) {
            return null;
        }

        return (
            <Table.Row>
                <Table.HeaderCell colSpan={this.props.gridColumnsCount}>
                    <Button size='tiny' color='green' onClick={this.props.addRow}>+</Button>    
                </Table.HeaderCell>
            </Table.Row>
        );
    }

    //No business logic
    renderLastSubtotalCell = () => {
        if (this.props.actionAdd === false) {
            return null;
        }

        return <Table.HeaderCell key={"actionEmptyCell"}/>;
    }

    renderSubtotals = () => {
        const {fieldGroups, formFieldsConfigSubtotal} = this.props;
        if (formFieldsConfigSubtotal === false) {
            return null;
        }

        const result = (
            <Table.Row>
                {
                    _.map(fieldGroups, (group) => {
                        return _.map(group, (fieldName) => {
                            const subTotalObject = formFieldsConfigSubtotal[fieldName];
                            const subtotalValue = subTotalObject === undefined ? "" : subTotalObject.value; 
                            
                            let res;

                            if (subTotalObject === undefined) {
                                res = (
                                    <Table.HeaderCell key={fieldName}/>
                                );
                            } else {
                                res = (
                                    <Table.HeaderCell key={fieldName} textAlign={"center"}>
                                        <Label size={"large"} color='black' horizontal>{subtotalValue}</Label>
                                    </Table.HeaderCell>
                                );
                            }
                            return res;
                        })
                    })
                }
                {this.renderLastSubtotalCell()}
            </Table.Row>
        );

        return result;
    }

    renderTable = () => {
        return (
            <Table color={"orange"}>
                <Table.Header>
                    {this.renderHeader()}
                </Table.Header>
                <Table.Body>
                    { this.renderGrid(FORM_EDIT_MODE, this.props.rowsForEdit) }
                    { this.renderGrid(FORM_CREATE_MODE, this.props.rowsForCreate) }
                </Table.Body>
                <Table.Footer>
                    { this.renderSubtotals() }
                    { this.renderAddAction() }
                </Table.Footer>
            </Table>
        );
    }

    renderNegativeMessage = () => {
        if (this.props.negativeMessage === undefined || this.props.negativeMessage === "") {
            return null;
        }
        
        return (
            <Message
                negative
                content={this.props.negativeMessage}
            />
        );
    }

    renderImportButtonOld = () => {
        if (this.props.importLogic !== undefined) {
            const {isActive, downloadInstructionsUrl, importUrl, instructionFileName} = this.props.importLogic;

            let hasRowsForCreate =  false;
            if (this.props.rowsForCreate !== undefined && ! _.isEmpty(this.props.rowsForCreate)) {
                hasRowsForCreate = true;
            }

            let importDisabled = true;
            if (isActive && hasRowsForCreate === false) {
                importDisabled = false;
            }

            return (
                <Form>
                    <ImportField
                        importDisabled={importDisabled}
                        translations={this.props.translations}
                        doImport={(file) => this.props.importEntities(file, importUrl)}
                        downloadInstructions={() => this.props.downloadInstructions(downloadInstructionsUrl, instructionFileName)}
                    />
                </Form>
            )
        }

        return null;
    }
    
    renderImportButton = () => {
        const { importAction } = this.props;
        if (_.isUndefined(importAction)) {
            return null;
        }
        const {importActionData, downloadTemplateData} = importAction;  

        const { importLabel, importButtonName, importUrl, isActive } = importActionData;
        const { templateButtonName, templateFileName, templateDownloadUrl } = downloadTemplateData;


        // const {isActive, downloadInstructionsUrl, importUrl, instructionFileName} = this.props.importLogic;

        let hasRowsForCreate =  false;
        if (this.props.rowsForCreate !== undefined && ! _.isEmpty(this.props.rowsForCreate)) {
            hasRowsForCreate = true;
        }

        let importDisabled = true;
        if (isActive && hasRowsForCreate === false) {
            importDisabled = false;
        }

        return (
            <Form>
                <ImportField
                    importDisabled={importDisabled}
                    importLabel={importLabel}
                    importButtonName={importButtonName}
                    doImport={(file) => this.props.importEntities(file, importUrl)}

                    templateButtonName={templateButtonName}
                    templateDownloadAction={() => this.props.downloadInstructions(templateDownloadUrl, templateFileName)}
                />
            </Form>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.renderNegativeMessage()}

                {this.renderImportButton()}

                <Form warning error>
                    {this.renderTable()}
                </Form>
            </React.Fragment>
        );
    }
}

export default StandardDetailGridForm;

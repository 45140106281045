import React from 'react';
import { connect } from 'react-redux';
import { Button, Header, Icon, Modal, Message, Loader, Dimmer, TransitionablePortal, Divider } from 'semantic-ui-react';

import Form from '../Form';
import { FORM_CREATE_MODE, EMPTY_VALUE } from '../../../helpers/const';
import { STRINGS } from '../../../helpers/strings';
import { getValue } from '../../../helpers/functions';
import { validateDetailedPageSingleForm, preparePostParamsNEW_NEW } from '../../../helpers/functions';
import {
    updateGridFormInput,
    updateGridFormError,
    closeGridModal,
    updateFieldObjectAjax,
    setBomTreeExpanded,
    setBomTreeChecked,
    updateAllGridFormErrors
} from '../../../actions/gridModalActions';

class GridModal extends React.Component {
    renderFormErrorMessages = () => {
        if (this.props.modalInfo.validationMessages.length > 0) {
            return (
                <Message
                    error
                    header='BE Validation'
                    list={this.props.modalInfo.validationMessages}
                />
            );
        }
    }

    renderHeader = () => {
        const title = this.props.modalInfo.mode === FORM_CREATE_MODE ? STRINGS.BUTTON_CREATE : STRINGS.BUTTON_EDIT;

        return (
            <Header icon='edit outline' content={getValue(this.props.translations, title)} />
        );
    }

    handleAjaxWitUrlAction = (url, postData) => {
        const { bomTreeData } = this.props.modalInfo;

        postData = { ...postData, bomTreeData };

        this.props.updateFieldObjectAjax(url, postData);
    }

    renderContent = () => {
        const { mode, fields, fieldGroups } = this.props.modalInfo;

        return (
            <Modal.Content >
                {this.renderFormErrorMessages()}

                <Form
                    mode={mode}
                    fields={fields}
                    fieldGroups={fieldGroups}
                    onInputChange={this.props.updateGridFormInput}
                    errorUpdate={this.props.updateGridFormError}
                    handleAjaxWitUrlAction={this.handleAjaxWitUrlAction}
                />
            </Modal.Content>
        );
    }

    onCreateSaveClick = () => {
        const { fields } = this.props.modalInfo;

        const [isValid, errorsForUpdate] = validateDetailedPageSingleForm(fields, FORM_CREATE_MODE);
        if (isValid) {
            const postData = new FormData();
            preparePostParamsNEW_NEW(postData, fields);

            this.props.actionCreateEntity(postData);
        } else {
            this.props.updateAllGridFormErrors(errorsForUpdate);
        }
    }

    onEditSaveClick = () => {
        const { fields, editedEntityId } = this.props.modalInfo;

        const [isValid, errorsForUpdate] = validateDetailedPageSingleForm(fields, FORM_CREATE_MODE);
        if (isValid) {
            const postData = new FormData();
            preparePostParamsNEW_NEW(postData, fields);

            this.props.actionEditEntity(postData, editedEntityId);
        } else {
            this.props.updateAllGridFormErrors(errorsForUpdate);
        }
    }

    getSaveButtonOnClickAction = () => {
        let action;
        if (this.props.modalInfo.mode === FORM_CREATE_MODE) {
            action = this.onCreateSaveClick;
        } else {
            action = this.onEditSaveClick;
        }

        return action;
    }

    renderActions = () => {
        return (
            <Modal.Actions>
                <Button color='red' onClick={this.props.closeGridModal}>
                    <Icon name='cancel' /> {getValue(this.props.translations, STRINGS.BUTTON_CANCEL)}
                </Button>
                <Button
                    disabled={this.props.modalInfo.invalidFieldsNumber >= 1}
                    onClick={this.getSaveButtonOnClickAction()}
                    color='green'
                >
                    <Icon name='checkmark' /> {getValue(this.props.translations, STRINGS.BUTTON_SAVE)}
                </Button>
            </Modal.Actions>
        );
    }

    render() {
        return (
            <React.Fragment>
                <TransitionablePortal
                    onClose={this.props.closeGridModal}
                    open={this.props.modalInfo.visible}
                    transition={{ animation: 'slide down', duration: 400 }}
                >
                    <Modal closeOnEscape={false} open={true} size='large'>
                        {this.renderHeader()}
                        <React.Fragment>
                            <Dimmer active={this.props.modalInfo.showSpinner} inverted>
                                <Loader inverted />
                            </Dimmer>

                            {this.renderContent()}
                            {this.renderActions()}
                        </React.Fragment>
                    </Modal>

                </TransitionablePortal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return { modalInfo: state.gridModal }
}

const actions = {
    updateGridFormInput, updateGridFormError, closeGridModal, updateFieldObjectAjax,
    setBomTreeExpanded, setBomTreeChecked, updateAllGridFormErrors
};

export default connect(mapStateToProps, actions)(GridModal);

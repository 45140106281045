import React from 'react';
import _, { size } from 'lodash';
import { Table } from 'semantic-ui-react';
import { ColumnProp } from '../../../enum/dataGrid/ColumnProp';
import { TableDataRowProp } from '../../../enum/apiResultProp/TableDataRowProp';

export default ({ columns, subtotalDataRow, selectable, selectedRowIds }) => {
    if (!subtotalDataRow && !selectable) {
        return null;
    }

    const renderSelectableCell = () => {
        if (!selectable) {
            return null;
        }

        return (
            <Table.HeaderCell textAlign='center' style={{ fontWeight: 'bold', whiteSpace: 'nowrap', color: '#3C8AD9' }}>
                {size(selectedRowIds)} Rows
            </Table.HeaderCell>
        )
    }

    return (
        <Table.Row textAlign='left' style={{ lineHeight: '0.5em' }}>
            {renderSelectableCell()}
            {
                _.map(columns, (columnProps) => {
                    const isHidden = columnProps[ColumnProp.Hidden];
                    const columnName = columnProps[ColumnProp.Name];
                    if (isHidden) {
                        return null;
                    }

                    let subtotalText = '';
                    if (subtotalDataRow) {
                        const cells = subtotalDataRow[TableDataRowProp.Cells];

                        const columnSubotal = cells[columnName];

                        if (columnSubotal) {
                            subtotalText = <span style={{ fontWeight: 'bold', color: '#3C8AD9' }}>{columnSubotal.text}</span>;
                        }
                    }

                    return <Table.HeaderCell key={columnName}>{subtotalText}</Table.HeaderCell>;
                })
            }
        </Table.Row>
    );
}
import React from 'react';
import { Form } from 'semantic-ui-react';
import { FormFieldProp } from '../../../enum/apiResultProp/FormFieldProp';

export default ({ fieldProps, onValueChange, performDataValidation }) => {
    const value = fieldProps[FormFieldProp.Value];
    const placeholder = fieldProps[FormFieldProp.Placeholder];
    const showLabel = fieldProps[FormFieldProp.ShowLabel];
    const label = fieldProps[FormFieldProp.Label];
    const hasError = fieldProps[FormFieldProp.HasError];
    const showMandatorySign = fieldProps[FormFieldProp.ShowMandatorySign];
    const isReadOnly = fieldProps[FormFieldProp.IsReadOnly];

    let textAreaProps = {
        rows: 1,
        required: showMandatorySign,
        readOnly: isReadOnly,
        error: hasError,
        placeholder: placeholder,
        value: value,
        onChange: e => onValueChange({ value: e.target.value }),
        onBlur: () => performDataValidation({ value: value }),
        style: { minWidth: '13em' }
    };
    if (showLabel) {
        textAreaProps = { ...textAreaProps, label: label };
    }

    return <Form.TextArea {...textAreaProps} />;
}
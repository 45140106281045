import React from 'react';
import _ from 'lodash';
import { Message, Icon } from 'semantic-ui-react';
import { InfoPanelContentProp } from '../../enum/apiResultProp/InfoPanelContentProp';

export default ({ props }) => {
    const position = props[InfoPanelContentProp.Position];
    const text = props[InfoPanelContentProp.Text];
    const textIsBold = props[InfoPanelContentProp.TextIsBold];
    const textColor = props[InfoPanelContentProp.TextColor];
    const iconName = props[InfoPanelContentProp.IconName];
    const iconColor = props[InfoPanelContentProp.IconColor];
    const isHidden = props[InfoPanelContentProp.IsHidden];

    const renderIcon = () => {
        if (!iconName) {
            return null;
        }

        const iconProps = { name: iconName };
        if (iconColor) {
            iconProps.color = iconColor;
        }

        return (
            <Icon {...iconProps} />
        );
    }

    const getStyle = () => {
        const style = {};

        if (textColor) {
            style.color = textColor;
        }

        if (textIsBold) {
            style.fontWeight = 'bold';
        }

        return style;
    }

    if (isHidden) {
        return null;
    }

    const contentProps = { key: position };
    const style = getStyle();
    if (style) {
        contentProps.style = style;
    }

    return (
        <Message.Content {...contentProps}>
            {renderIcon()}
            {text}
        </Message.Content>
    );
}

import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import { Modal, Button, List, Dimmer, Loader, TransitionablePortal, Transition, Message, Icon, Form, Divider } from 'semantic-ui-react';

import { 
    open, close, toggleStatusOfNodeElem, toggleStatusOfChildElem, toggleExpandOfNodeElem, toggleExpandOfChildElem,
    setStatusCheckedAll, setStatusCheckedNegativeAll, setStatusUncheckedAll, setExpandYesAll, setExpandNoAll,
    onAdditionalInfoFormValueChange  
} from '../../actions/revisionModalTree/actions';
import {getValue} from '../../helpers/functions';
import { STRINGS } from '../../helpers/strings';
import { FORM_DATA_TYPES } from '../../helpers/const';

class RevisionModal extends React.Component {
    renderComparedValues = (comparedValues) => {
        if (comparedValues === undefined || ! comparedValues) {
            return null;
        }

        return (
            <React.Fragment>
                {_.map(comparedValues, comparedValue => {
                    const { name, newValue, currentValue, uom, icon, color, comparison} = comparedValue;
                    
                    let message = '';
                    if (comparison !== 'equal') {
                        message = (
                            <React.Fragment>
                                <b>{name}</b> will change from <b>{currentValue} {uom}</b> to <b>{newValue} {uom}</b>
                            </React.Fragment>
                        );
                    } else {
                        message = (
                            <React.Fragment>
                                <b>{name}</b> will keep it's value - <b>{currentValue} {uom}</b>
                            </React.Fragment>
                        );
                    }

                    return (
                        <List.Description key={name}>
                            <Icon name={icon} color={color}/>
                            {message}
                        </List.Description>
                    );
                })}
            </React.Fragment>
        );
    }

    renderSingleElement = (elem, isNode, parentIsExpand = true) => {
        const {statusUnchecked, statusChecked, statusCheckedNegative, statusDisabled, expandYes, expandNo} = this.props.data;
        const {inactive, label, value, children, description, allParents, allChildren, comparedValues} = elem;
        const hasChildren = children !== undefined && ! _.isEmpty(children);
        
        const disabledIcon = 'cancel';
        const disabledIconColor = 'red';
        const noChildrenIcon = 'file';
        const noChildrenIconColor = 'yellow';
        const hasChildrenClosedIcon = 'folder';
        const hasChildrenClosedIconColor = 'yellow';
        const hasChildrenOpenIcon = 'folder open';
        const hasChildrenOpenIconColor = 'yellow';

        const statusUncheckedIcon = 'square outline';
        const statusUncheckedIconColor = 'black';
        const statusCheckedIcon = 'check square outline';
        const statusCheckedIconColor = 'blue';
        const statusCheckedNegativeIcon = 'minus square outline';
        const statusCheckedNegativeIconColor = 'red';

        let statusIcon = '';
        let statusIconColor = '';
        let isDisabled = false;
        let isUnchecked = false;
        let isChecked = false;
        let isCheckedNegative = false;

        if (statusDisabled.includes(value)) {
            statusIcon = disabledIcon;
            statusIconColor = disabledIconColor;
            isDisabled = true;
        } else if (statusUnchecked.includes(value)) {
            statusIcon = statusUncheckedIcon;
            statusIconColor = statusUncheckedIconColor;
            isUnchecked = true;
        } else if (statusChecked.includes(value)) {
            statusIcon = statusCheckedIcon;
            statusIconColor = statusCheckedIconColor;
            isChecked = true;
        } else if (statusCheckedNegative.includes(value)) {
            statusIcon = statusCheckedNegativeIcon;
            statusIconColor = statusCheckedNegativeIconColor;
            isCheckedNegative = true;
        }

        const isExpand = expandYes.includes(value);
        let foldIcon = '';
        let foldIconColor = '';
        if (! hasChildren) {
            foldIcon = noChildrenIcon;
            foldIconColor = noChildrenIconColor;
        } else if (hasChildren && expandYes.includes(value)) {
            foldIcon = hasChildrenOpenIcon;
            foldIconColor = hasChildrenOpenIconColor;
        } else if (hasChildren && expandNo.includes(value)) {
            foldIcon = hasChildrenClosedIcon;
            foldIconColor = hasChildrenClosedIconColor;
        }

        let foldingAction = '';
        let changeStatusAction = '';
        if (isNode) {
            changeStatusAction = this.props.toggleStatusOfNodeElem;
            foldingAction = this.props.toggleExpandOfNodeElem;
        } else {
            changeStatusAction = this.props.toggleStatusOfChildElem;
            foldingAction = this.props.toggleExpandOfChildElem;
        }

        const paramsForChangeStatus = {value, allParents, allChildren, isDisabled, isChecked, isCheckedNegative, isUnchecked};
        const paramsForToggleFold = {value, allParents, allChildren, isExpand};
        
        let statusIconElement = null;
        if (inactive !== true) {
            statusIconElement = (
                <List.Icon 
                    name={statusIcon}
                    color={statusIconColor}
                    onClick={() => changeStatusAction(paramsForChangeStatus)}
                    size='large'
                />
            );
        }

        let expandIconElement = (
            <List.Icon 
                name={foldIcon}
                color={foldIconColor}
                onClick={() => foldingAction(paramsForToggleFold)}
                size='large'
            />
        );

        return (
            <Transition key={value} visible={parentIsExpand} animation='slide down' duration={300} unmountOnHide>
                <List.Item key={value}>
                    {/* <List.Icon 
                        name={statusIcon}
                        color={statusIconColor}
                        onClick={() => changeStatusAction(paramsForChangeStatus)}
                        size='large'
                    /> */}
                    {statusIconElement}
                    {/* <List.Icon 
                        name={foldIcon}
                        color={foldIconColor}
                        onClick={() => foldingAction(paramsForToggleFold)}
                        size='large'
                    /> */}
                    {expandIconElement}
                    <List.Content>
                        <List.Header>{label}</List.Header>
                        <List.Description>{description}</List.Description>
                        {this.renderComparedValues(comparedValues)}
                        {this.renderElementChildren(expandYes.includes(value), hasChildren, children)}
                    </List.Content>
                </List.Item>
            </Transition>
        );
    }

    renderCheckBoxTree = () => {
        const {elements} = this.props.data;
        if (_.isEmpty(elements)) {
            return null;
        }

        return (
            <List>
                { _.map(elements, elem => this.renderSingleElement(elem, true)) }
            </List>
        );
    }

    renderElementChildren = (expanded, hasChildren, elements) => {
        if (! hasChildren) {
            return null;
        }

        return (
            <List.List>
                { _.map(elements, elem => this.renderSingleElement(elem, false, expanded)) }
            </List.List>
        );
    }

    renderErrorMessages = () => {
        if (this.props.data.validationMessages.length > 0) {
            return(
                <Message
                    error
                    header='Error'
                    list={this.props.data.validationMessages}
                />
            );
        }
    }

    renderActions = () => {
        return (
            <Modal.Content>
                <Button icon floated='right' color='red' onClick={this.props.setExpandNoAll}>
                    <Icon size='large' name='folder' color='yellow'/>
                </Button>
                <Button icon floated='right' color='blue' onClick={this.props.setExpandYesAll}>
                    <Icon size='large' name='folder open' color='yellow' />
                </Button>
                <Button icon floated='left' color='blue' onClick={this.props.setStatusCheckedAll}>
                    <Icon size='large' name='check square outline' />
                </Button>
                <Button icon floated='left' color='red' onClick={this.props.setStatusCheckedNegativeAll}>
                    <Icon size='large' name='minus square outline' />
                </Button>
                <Button icon floated='left' onClick={this.props.setStatusUncheckedAll}>
                    <Icon size='large' name='square outline' />
                </Button>
            </Modal.Content>
        );
    }
    
    renderAdditionalForm = () => {
        const {additionalInfoForm} = this.props.data;

        if (_.isEmpty(additionalInfoForm)) {
            return null;
        }

        return (
            <React.Fragment>
                <Form>
                    <Form.Group widths='equal'>
                        {_.map(additionalInfoForm, (fieldData, fieldName) => {
                            const {
                                fieldType, label, required, readOnly, disabled, value, options, placeholder
                            } = fieldData;
                            
                            switch (fieldType) {
                                case FORM_DATA_TYPES.DROPDOWN:

                                    return (
                                        <Form.Select 
                                            key={fieldName}
                                            options={options} 
                                            required={required} 
                                            readOnly={readOnly} 
                                            disabled={disabled}
                                            label={label} 
                                            value={value}
                                            onChange={(e, data) => { this.props.onAdditionalInfoFormValueChange(fieldName, data.value) }}
                                            selectOnBlur={false}
                                        />
                                    );
                                case FORM_DATA_TYPES.TEXTAREA:
                                        
                                    return (
                                        <Form.TextArea
                                            key={fieldName}
                                            required={required} 
                                            readOnly={readOnly} 
                                            label={label} 
                                            rows={1}
                                            placeholder={placeholder} 
                                            value={value}
                                            onChange={(e, data) => { this.props.onAdditionalInfoFormValueChange(fieldName, data.value) }}
                                        />
                                    );
                                default:
                                    return <p key={fieldName}>Not registered field</p>;
                            }
                        })}
                    </Form.Group>
                </Form>
            </React.Fragment>
        );
    }
    
    render = () => {
        const {isOpen, title, confirmUrl, showSpinner, statusChecked, statusCheckedNegative, additionalInfoForm} = this.props.data; 
        
        const postData = {statusChecked, statusCheckedNegative};

        if (! _.isEmpty(additionalInfoForm)) {
            postData['additionalInfoForm'] = {};
            
            _.forEach(additionalInfoForm, (fieldData, fieldName) => {
                postData['additionalInfoForm'][fieldName] = fieldData.value;
            })
        }

        return (
            <TransitionablePortal 
                onClose={this.props.close} 
                open={isOpen} 
                transition={{animation: 'slide down', duration: 400}}
            >
                <Modal closeOnEscape={false} open={true}>
                    <Dimmer active={showSpinner} inverted>
                        <Loader inverted size='huge'>Loading</Loader>
                    </Dimmer>
                    <Modal.Header>
                        {title}
                    </Modal.Header>
                    <Modal.Content>
                        {this.renderAdditionalForm()}
                        {this.renderActions()}
                    </Modal.Content>
                    <Modal.Content scrolling>
                        {this.renderErrorMessages()}
                        {this.renderCheckBoxTree()}        
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='black' onClick={this.props.close}>
                            {getValue(this.props.translations, STRINGS.BUTTON_CANCEL)}
                        </Button>
                        <Button
                            content={getValue(this.props.translations, STRINGS.BUTTON_CONFIRM)}
                            onClick={() => this.props.actionConfirm(postData, confirmUrl)}
                            positive
                        />
                    </Modal.Actions>
                </Modal>
            </TransitionablePortal>
        );
    }
}

const mapStateToProps = (state) => {
    return { 
        translations: state.auth.translations,
        data: state.revisionTreeModal, 
    };
}

const actions = {
    open, close, toggleStatusOfNodeElem, toggleStatusOfChildElem, toggleExpandOfNodeElem, toggleExpandOfChildElem,
    setStatusCheckedAll, setStatusCheckedNegativeAll, setStatusUncheckedAll, setExpandYesAll, setExpandNoAll,
    onAdditionalInfoFormValueChange
};

export default connect(mapStateToProps, actions)(RevisionModal);

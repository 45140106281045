import _ from 'lodash';
import { ScreenComponentType } from '../enum/ScreenComponentType';
import { ScreenComponentProp } from '../enum/apiResultProp/ScreenComponentProp';
import { FormProp } from '../enum/apiResultProp/FormProp';
import { FormFieldProp } from '../enum/apiResultProp/FormFieldProp';
import { DataValidationRuleProp } from '../enum/apiResultProp/DataValidationRuleProp';
import { TableFormDataRowProp } from '../enum/apiResultProp/TableFormDataRowProp';
import { TableFormProp } from '../enum/apiResultProp/TableFormProp';
import { TableFormCellProp } from '../enum/apiResultProp/TableFormCellProp';
import { TableFormCellType } from '../enum/TableFormCellType';
import { DataValidationRuleType } from '../enum/DataValidationRuleType';
import { DataType } from '../enum/DataType';

export const validateNewScreenComponentsFields = ({ components, onFormUpdateErrorAction, onTableFormUpdateErrorAction }) => {
    let allFieldsAreValid = true;

    _.forEach(components, (screenComponentProps, componentName) => {
        const componentType = screenComponentProps[ScreenComponentProp.ScreenComponentType];

        switch (componentType) {
            case ScreenComponentType.Title:
                return;
            case ScreenComponentType.Form:
                const formFields = screenComponentProps[FormProp.Fields];
                _.forEach(formFields, (rowFields, rowKey) => {
                    _.forEach(rowFields, (fieldProps, fieldName) => {
                        const hasError = performFieldDataValidation({
                            fieldProps: fieldProps,
                            updateFieldErrorAction: ({ errorMessage, hasError }) => onFormUpdateErrorAction(
                                { errorMessage, hasError, componentName, componentType, rowKey, fieldName }
                            )
                        });
                        if (hasError) {
                            allFieldsAreValid = false;
                        }
                    });
                });
            case ScreenComponentType.TableForm:
                const tableDataRows = screenComponentProps[TableFormProp.TableFormDataRows];

                _.forEach(tableDataRows, (tableDataRow, rowKey) => {
                    const cells = tableDataRow[TableFormDataRowProp.Cells];

                    _.forEach(cells, (cell, cellKey) => {
                        const cellType = cell[TableFormCellProp.CellType];
                        if (cellType === TableFormCellType.InputField) {
                            const fieldProps = cell[TableFormCellProp.FieldProps];

                            const hasError = performFieldDataValidation({
                                fieldProps: fieldProps,
                                updateFieldErrorAction: ({ errorMessage, hasError }) => onTableFormUpdateErrorAction(
                                    { errorMessage, hasError, componentName, componentType, rowKey, cellKey }
                                )
                            });
                            if (hasError) {
                                allFieldsAreValid = false;
                            }
                        }
                    })
                })
            case ScreenComponentType.Table:
                return;
            default:
                return;
        }
    });

    return allFieldsAreValid;
}

export const performFieldDataValidation = ({ fieldProps, updateFieldErrorAction }) => {
    let hasError = false;

    const fieldValue = fieldProps[FormFieldProp.Value];
    const fieldDataType = fieldProps[FormFieldProp.DataType];
    const fieldDataValidation = fieldProps[FormFieldProp.DataValidation];
    const name = fieldProps[FormFieldProp.Name];

    _.orderBy(fieldDataValidation, DataValidationRuleProp.Precedence);
    _.forEach(fieldDataValidation, dataValidationRuleProps => {
        /** When we find the first error we show it and stop making checks for the next rules **/
        if (!hasError) {
            const ruleType = dataValidationRuleProps[DataValidationRuleProp.RuleType];

            if (ruleType === DataValidationRuleType.Required) {
                hasError = validateRequiredRule({ fieldValue, fieldDataType });
            } else if (ruleType === DataValidationRuleType.NumericMinValue) {
                hasError = validateMinNumericValue({ fieldValue, fieldDataType, ruleProps: dataValidationRuleProps });
            } else if (ruleType === DataValidationRuleType.NumericMinOrEqualValue) {
                hasError = validateMinEqualNumericValue({ fieldValue, fieldDataType, ruleProps: dataValidationRuleProps });
            } else if (ruleType === DataValidationRuleType.NumericMaxValue) {
                hasError = validateMaxNumericValue({ fieldValue, fieldDataType, ruleProps: dataValidationRuleProps });
            } else if (ruleType === DataValidationRuleType.FloatMaxPrecision) {
                hasError = validateFloatMaxPrecision({ fieldValue, fieldDataType, ruleProps: dataValidationRuleProps });
            } else if (ruleType === DataValidationRuleType.OnlyIntegerValue) {
                hasError = validateOnlyIntegerValue({ fieldValue, fieldDataType });
            }

            // console.log(ruleType, hasError, dataValidationRuleProps);
            if (hasError) {
                let errorMessage = dataValidationRuleProps[DataValidationRuleProp.ErrorMessage];
                updateFieldErrorAction({ errorMessage, hasError });
            }
        }
    });

    /** When we havent found any errors we reset field errors props **/
    if (!hasError) {
        updateFieldErrorAction({ errorMessage: '', hasError: false });
    }

    return hasError;
}

const validateRequiredRule = ({ fieldValue, fieldDataType }) => {
    let hasError = false;

    if (_.isNull(fieldValue) || fieldValue === '' || _.isUndefined(fieldValue) || fieldValue === -1000) {
        hasError = true;
    }

    if (fieldDataType == DataType.Date && new Date(fieldValue) === 'Invalid Date') {
        hasError = true;
    } else if ((fieldDataType == DataType.Float || fieldDataType == DataType.Int) && isNaN(fieldValue)) {
        hasError = true;
    } else if (fieldDataType == DataType.String && !_.isString(fieldValue)) {
        hasError = true;
    } else if (fieldDataType == DataType.Array) {
        if (!_.isArray(fieldValue) || fieldValue.length === 0) {
            hasError = true;
        }
    }

    return hasError;
}

const validateMinNumericValue = ({ fieldValue, fieldDataType, ruleProps }) => {
    let hasError = false;

    const minValue = ruleProps[DataValidationRuleProp.MinValue];

    if (fieldDataType === DataType.Float || fieldDataType === DataType.Int) {
        if (!isNaN(fieldValue)) {
            if (parseFloat(fieldValue) < parseFloat(minValue)) {
                hasError = true;
            }
        }
    }

    return hasError;
}

const validateMinEqualNumericValue = ({ fieldValue, fieldDataType, ruleProps }) => {
    let hasError = false;

    const minValue = ruleProps[DataValidationRuleProp.MinValue];

    if (fieldDataType === DataType.Float || fieldDataType === DataType.Int) {
        if (!isNaN(fieldValue)) {
            if (parseFloat(fieldValue) <= parseFloat(minValue)) {
                hasError = true;
            }
        }
    }

    return hasError;
}

const validateMaxNumericValue = ({ fieldValue, fieldDataType, ruleProps }) => {
    let hasError = false;

    const maxValue = ruleProps[DataValidationRuleProp.MaxValue];

    if (fieldDataType === DataType.Float || fieldDataType === DataType.Int) {
        if (!isNaN(fieldValue)) {
            if (parseFloat(fieldValue) > parseFloat(maxValue)) {
                hasError = true;
            }
        }
    }

    return hasError;
}

const validateFloatMaxPrecision = ({ fieldValue, fieldDataType, ruleProps }) => {
    let hasError = false;

    const countOfDigitsAfterDecimalPoint = ruleProps[DataValidationRuleProp.CountOfDigitsAfterDecimalPoint];

    if (fieldDataType === DataType.Float && !isNaN(fieldValue) && !isInt(fieldValue)) {
        const numberElements = fieldValue.toString().split('.');
        const precisionElement = numberElements[1];

        if (precisionElement.length > countOfDigitsAfterDecimalPoint) {
            hasError = true;
        }
    }

    return hasError;
}

const validateOnlyIntegerValue = ({ fieldValue, fieldDataType }) => {
    let hasError = false;

    if (fieldDataType === DataType.Int && !isInt(fieldValue)) {
        hasError = true;
    }

    return hasError;
}

const isInt = (n) => {
    return n % 1 === 0;
}

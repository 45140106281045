export default {
    //Screen Update
    TREE_PAGE_WITH_SIDE_PANE_UPDATE_SCREEN_DATA: 'TREE_PAGE_WITH_SIDE_PANE_UPDATE_SCREEN_DATA',
    
    //Select Element Action - it loads also the side pane data
    TREE_PAGE_WITH_SIDE_PANE_SET_SELECTED_ELEMENT: 'TREE_PAGE_WITH_SIDE_PANE_SET_SELECTED_ELEMENT',

    //Enable page elements / Disable page elements Actions
    TREE_PAGE_WITH_SIDE_PANE_RESET_BTN_SET_IS_ACTIVE: 'TREE_PAGE_WITH_SIDE_PANE_RESET_BTN_SET_IS_ACTIVE',
    TREE_PAGE_WITH_SIDE_PANE_LOAD_BTN_SET_IS_ACTIVE: 'TREE_PAGE_WITH_SIDE_PANE_LOAD_BTN_SET_IS_ACTIVE',
    TREE_PAGE_WITH_SIDE_PANE_SELECTOR_FIELDS_SET_IS_ACTIVE: 'TREE_PAGE_WITH_SIDE_PANE_SELECTOR_FIELDS_SET_IS_ACTIVE',
    TREE_PAGE_WITH_SIDE_PANE_TOGGLE_SPINNER_IS_ACTIVE: 'TREE_PAGE_WITH_SIDE_PANE_TOGGLE_SPINNER_IS_ACTIVE',

    //Clear Action
    TREE_PAGE_WITH_SIDE_PANE_CLEAR: 'TREE_PAGE_WITH_SIDE_PANE_CLEAR',

    //Filter Fields Actions
    TREE_PAGE_WITH_SIDE_PANE_FILTER_FIELD_UPDATE_VALUE: 'TREE_PAGE_WITH_SIDE_PANE_FILTER_FIELD_UPDATE_VALUE',

    //
    TREE_PAGE_WITH_SIDE_PANE_EXPAND_YES_ALL: 'TREE_PAGE_WITH_SIDE_PANE_EXPAND_YES_ALL',
    TREE_PAGE_WITH_SIDE_PANE_EXPAND_NO_ALL: 'TREE_PAGE_WITH_SIDE_PANE_EXPAND_NO_ALL',
    TREE_PAGE_WITH_SIDE_PANE_TOGGLE_EXPAND_NODE_ELEMENT: 'TREE_PAGE_WITH_SIDE_PANE_TOGGLE_EXPAND_NODE_ELEMENT',
};

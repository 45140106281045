import actionTypes from "../../actions/appVersion/actionTypes";
import { openTab, closeTab, openAllTabs, closeAllTabs, startSpinner, stopSpinner, fetchData } from './stateMutators';

const INITIAL_STATE = {
    showSpinner: false,
    title: '',
    activeIndexes: [], 
    tabObjects: {},
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.APP_VERSION_OPEN_TAB:
            return openTab(state, action);
        case actionTypes.APP_VERSION_CLOSE_TAB:
            return closeTab(state, action);
        case actionTypes.APP_VERSION_OPEN_ALL_TABS:
            return openAllTabs(state);
        case actionTypes.APP_VERSION_CLOSE_ALL_TABS:
            return closeAllTabs(state);
        case actionTypes.APP_VERSION_START_SPINNER:
            return startSpinner(state);
        case actionTypes.APP_VERSION_STOP_SPINNER:
            return stopSpinner(state);
        case actionTypes.APP_VERSION_FETCH_DATA:
            return fetchData(state, action);
        default: 
            return state;
    }
}

export default reducer;

import React from 'react';

class ImportField extends React.Component {
    state = { selectedFile: null };

    fileSelectedHandler = event => {
        this.setState({ selectedFile: event.target.files[0] });
    }

    fileUploadHandler = () => {
        if (this.state.selectedFile !== null) {
            const fd = new FormData();
            fd.append('file', this.state.selectedFile, this.state.selectedFile.name);
            this.props.doImport(fd);
        }
    }

    render = () => {
        let buttonClass = "ui green button";
        if ((this.props.importDisabled === true) || (this.state.selectedFile === undefined || this.state.selectedFile === null)) {
            buttonClass += " disabled";
        }

        return (
            <React.Fragment>
                <div className="inline fields">
                    <div className="field">
                        <label>{this.props.importLabel}</label>
                        <input type="file" accept=".csv, .txt" onChange={this.fileSelectedHandler} />
                    </div>
                    <div className="field">
                        <div className={buttonClass} onClick={this.fileUploadHandler}>{this.props.importButtonName}</div>
                    </div>
                    <div className="field">
                        <div className="ui blue button" onClick={this.props.templateDownloadAction}>
                            <i className="download icon"></i>
                            {this.props.templateButtonName}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ImportField;

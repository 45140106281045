import React from 'react';
import {connect} from 'react-redux';    
import { Modal, Button } from 'semantic-ui-react';

import { getAutomaticLogoutInfo, stayInApp, resetAutomaticLogoutInfo } from '../../actions/automaticLogoutActions';
import { logout, automaticLogout, clearSession } from '../../actions/authActions';

class InactiveTimeModal extends React.Component {    
    intervalID = null;    
    
    componentDidMount = async () => {
        const intervalId = await setInterval(this.props.getAutomaticLogoutInfo, 60000);
        this.intervalID = intervalId;
    }

    componentWillUnmount = () => {
        this.props.resetAutomaticLogoutInfo();
        clearInterval(this.intervalID);
    }

    onButtonLogoutClick = () => {
        this.props.logout();
    }

    render = () => {
        const {userLogClosedByOtherProcess, shouldLogOut, settingInactiveTimeValueInSeconds, settingDecisionTimeValueInSeconds, decisionTimeRemaining} = this.props;

        if (userLogClosedByOtherProcess) {
            this.props.clearSession();
        }

        if (shouldLogOut) {
            this.props.automaticLogout();
        }

        return (
            <Modal
                centered={false}
                dimmer='blurring'
                closeOnDimmerClick={false}
                closeOnEscape={false}
                open={this.props.logoutModalIsVisible}
            >
                <Modal.Header>{`You have been inactive for ${settingInactiveTimeValueInSeconds} seconds!`}</Modal.Header>
                <Modal.Content>
                    {`You will be Logged Out after ${settingDecisionTimeValueInSeconds} seconds. Do you want to stay?`}
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={this.onButtonLogoutClick}>
                        {`Logout (${decisionTimeRemaining} s)`}
                    </Button>
                    <Button positive onClick={this.props.stayInApp}>
                        Stay
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    };
};

const mapStateToProps = (state) => {
    return state.automaticLogout;
}

const actions = { getAutomaticLogoutInfo, automaticLogout, clearSession, logout, stayInApp, resetAutomaticLogoutInfo };

export default connect(mapStateToProps, actions)(InactiveTimeModal);
import _ from 'lodash';
import actionTypes from "../../actions/actionTypes";
import { FORM_EDIT_MODE, FORM_CREATE_MODE, EMPTY_VALUE, FORM_DATA_TYPES } from '../../helpers/const';

const INITIAL_STATE = {
    visible: false,
    showSpinner: false,
    mode: FORM_EDIT_MODE,
    editedEntityId: 0,
    invalidFieldsNumber: 0,
    validationMessages: [],
    fields: {},
    fieldGroups: {},
    bomTreeData: EMPTY_VALUE,
}

const gridModalReducer = (state = INITIAL_STATE, action) => {
    let newState, entityId, fieldsConfig, fieldGroupsConfig, fieldValues, 
        fieldName, fieldValue, error, errorMessage, validationMessages
    ;
    
    switch (action.type) {
        case actionTypes.UPDATE_ALL_GRID_FORM_ERRORS:
            const {fieldErrorsForUpdate} = action.payload;

            let invalidFieldsNumber = 0;

            _.forEach(fieldErrorsForUpdate, (fieldObj, fieldName) => {
                ({ error, errorMessage} = fieldObj);

                state = {
                    ...state,
                    fields: {
                        ...state.fields,
                        [fieldName]: {
                            ...state.fields[fieldName],
                            error: error,
                            errorMessage: errorMessage,
                        }
                    }
                };

                invalidFieldsNumber = error === true ? invalidFieldsNumber + 1 : invalidFieldsNumber;
            });

            return {
                ...state,
                invalidFieldsNumber: invalidFieldsNumber
            };
        case actionTypes.SET_BOM_TREE_EXPANDED_MODAL:
            state = {
                ...state,
                bomTreeData: {
                    ...state.bomTreeData,
                    expanded: action.payload
                }
            };

            return state;

        case actionTypes.SET_BOM_TREE_CHECKED_MODAL:
            state = {
                ...state,
                bomTreeData: {
                    ...state.bomTreeData,
                    checked: action.payload
                }
            };

            return state;

        case actionTypes.UPDATE_FILED_OBJECT_AJAX:
            newState = state;

            const {fieldsForUpdate, bomData} = action.payload;
            if (fieldsForUpdate) {
                Object.keys(fieldsForUpdate).forEach(fieldName => {
                    const fieldObjectProps = fieldsForUpdate[fieldName];
                    
                    Object.keys(fieldObjectProps).forEach(propName => {
                        const propValue = fieldObjectProps[propName];
    
                        switch (propName) {
                            case 'event':
                            case 'validationRules':
                                newState = {...newState, 
                                    fields: {...newState.fields, 
                                        [fieldName]: {...newState.fields[fieldName], 
                                            [propName]: []
                                        }
                                    }
                                };
                                
                                // if (propValue != EMPTY_VALUE) { ???? 
                                if (propValue !== EMPTY_VALUE) { 
                                    propValue.forEach(eventObj => {
                                        newState.fields[fieldName][propName].push({...eventObj});
                                    });
                                } 
    
                                break;
                            case 'options':
                            case 'value':
                            case 'error':
                            case 'errorMessage':
                                newState = {...newState, 
                                    fields: {...newState.fields,
                                        [fieldName]: {...newState.fields[fieldName],
                                            [propName]: propValue
                                        }
                                    }
                                };
    
                                break;
                            case 'required':
                            case 'readOnly':
                                newState = {...newState, 
                                    fields: {...newState.fields,
                                        [fieldName]: {...newState.fields[fieldName],
                                            [propName]: {...newState.fields[fieldName][propName],
                                                FORM_CREATE_MODE: propValue.FORM_CREATE_MODE,
                                                FORM_EDIT_MODE: propValue.FORM_EDIT_MODE
                                            }
                                        }
                                    }
                                };
    
                                break;
                            default:
                                console.log('Not registered Field Object property name for mutation - ' + propName);
                        }
                    })
                });
            }
            
            if (bomData !== undefined) {
                if (bomData === EMPTY_VALUE) {
                    newState = {
                        ...newState, 
                        bomTreeData: EMPTY_VALUE
                    };   
                } else {
                    const {initBomTree, bomTreeDataInitNodes, nodesForDelete, nodesToAdd, nodesForUncheck} = bomData;
    
                    if (initBomTree) {
                        newState = {
                            ...newState, 
                            bomTreeData: {
                                ...newState.bomTreeData,
                                nodes: bomTreeDataInitNodes,
                                checked: [],
                                expanded: [],
                            }
                        };        
                    }
                    
                    if (nodesForDelete !== undefined) {
                        newState = {
                            ...newState,
                            bomTreeData: {
                                ...newState.bomTreeData,
                                nodes: newState.bomTreeData.nodes.filter(node => ! _.includes(nodesForDelete, node.value))
                            }
                        }
                    }

                    if (nodesToAdd !== undefined) {
                        _.forEach(nodesToAdd, nodeToAdd => {
                            newState = {
                                ...newState,
                                bomTreeData: {
                                    ...newState.bomTreeData,
                                    nodes: [...newState.bomTreeData.nodes, nodeToAdd]
                                }
                            }
                        });
                    }

                    if (nodesForUncheck !== undefined) {
                        newState = {
                            ...newState,
                            bomTreeData: {
                                ...newState.bomTreeData,
                                checked: newState.bomTreeData.checked.filter(nodeValue => ! _.includes(nodesForUncheck, nodeValue)),
                                expanded: newState.bomTreeData.expanded.filter(nodeValue => ! _.includes(nodesForUncheck, nodeValue))
                            }           
                        }
                    }
                }
            }

            return newState;
        case actionTypes.UPDATE_GRID_FORM_INPUT:
            ({fieldName, fieldValue} = action.payload);

            newState = {
                ...state,
                fields: {
                    ...state.fields,
                    [fieldName]: {
                        ...state.fields[fieldName],
                        value: fieldValue
                    }
                }
            };

            return newState;
        case actionTypes.UPDATE_GRID_FORM_ERROR:
            ({error, errorMessage, fieldName} = action.payload);
            
            state = {
                ...state,
                fields: {
                    ...state.fields,
                    [fieldName]: {
                        ...state.fields[fieldName],
                        error: error,
                        errorMessage: errorMessage,
                    }
                },
            }; 

            let newInvalidFieldsNumber = 0;
            _.forEach(state.fields, (fieldObj) => {
                ({ error } = fieldObj);

                newInvalidFieldsNumber = error === true ? newInvalidFieldsNumber + 1 : newInvalidFieldsNumber;
            });

            console.log(newInvalidFieldsNumber);

            state = {
                ...state,
                invalidFieldsNumber: newInvalidFieldsNumber
            }

            return state;
        case actionTypes.UPDATE_GRID_MODAL_ERROR:
            ({validationMessages} = action.payload);

            return {...state, validationMessages: validationMessages }; 
        case actionTypes.CLOSE_GRID_MODAL:

            return INITIAL_STATE;
        case actionTypes.TOGGLE_SPINNER:
    
            return {...state, showSpinner: ! state.showSpinner};
        case actionTypes.OPEN_GRID_MODAL_CREATE:
            ({fieldsConfig, fieldGroupsConfig} = action.payload);

            state = {
                ...state,
                visible: true,
                mode: FORM_CREATE_MODE,
                entityId: false,
                invalidFieldsNumber: 0,
                showSpinner: false,
                validationMessages: [],
                fields: fieldsConfig,
                fieldGroups: fieldGroupsConfig
            }

            Object.keys(fieldsConfig).forEach(fieldName => {
                const defaultValue = fieldsConfig[fieldName].defaultValue === undefined ? "" : fieldsConfig[fieldName].defaultValue;

                state = {
                    ...state,
                    fields: {
                        ...state.fields,
                        [fieldName]: {
                            ...state.fields[fieldName],
                            value: defaultValue,
                            error: false,
                            errorMessage: '',
                        }
                    }
                }
            });

            return state;
        case actionTypes.OPEN_GRID_MODAL_EDIT:
            ({entityId, fieldsConfig, fieldGroupsConfig, fieldValues} = action.payload);

            state = {
                ...state,
                visible: true,
                mode: FORM_EDIT_MODE,
                editedEntityId: entityId,
                showSpinner: false,
                invalidFieldsNumber: 0,
                validationMessages: [],
                fields: fieldsConfig,
                fieldGroups: fieldGroupsConfig,
            }


            Object.keys(fieldsConfig).forEach(fieldName => {                
                let fieldValue = false;

                if (fieldName === 'password' || fieldName === 'confirm_password') { //If pass and have value -> show it in placeholder
                    if (fieldValues[fieldName].value != EMPTY_VALUE) {
                        // fieldValue = fieldValues[fieldName].value;
                        fieldValue = null;
                        fieldsConfig[fieldName].placeholder = fieldValues[fieldName].value;
                    } else {
                        fieldValue = null;
                    }
                } else if(fieldsConfig[fieldName].dataType === 'DROPDOWN_MULTI') {
                    fieldValue = fieldValues[fieldName].value.split(', ');
                } else if (fieldsConfig[fieldName].dataType === 'READ') {

                } else {
                    fieldValue = fieldValues[fieldName].value;
                }   

                //We set this here because the form is generated in the FE
                if (fieldsConfig[fieldName].readOnlyConditionsEditMode !== undefined) {
                    let isEditable = true;
                    fieldsConfig[fieldName].readOnlyConditionsEditMode.forEach(condition => {
                        const {compareWith, values} = condition;
                        const compareWithValue = fieldValues[compareWith].value;

                        const isEditableCurrentRow = values.includes(compareWithValue);
                        if (isEditableCurrentRow === false) {
                            isEditable = false;
                        }
                    });

                    state = {
                        ...state,
                        fields: {
                            ...state.fields,
                            [fieldName]: {
                                ...state.fields[fieldName],
                                readOnly: {
                                    ...state.fields[fieldName].readOnly,
                                    FORM_EDIT_MODE: ! isEditable
                                },
                            }
                        }
                    }
                }

                if (fieldsConfig[fieldName].requiredConditionsEditMode !== undefined) {
                    let isRequired = true;
                    
                    fieldsConfig[fieldName].requiredConditionsEditMode.forEach(condition => {
                        const {compareWith, values} = condition;
                        const compareWithValue = fieldValues[compareWith].value;

                        const isRequiredCurrentRow = values.includes(compareWithValue);
                        if (isRequiredCurrentRow === false) {
                            isRequired = false;
                        }
                    });

                    state = {
                        ...state,
                        fields: {
                            ...state.fields,
                            [fieldName]: {
                                ...state.fields[fieldName],
                                required: {
                                    ...state.fields[fieldName].required,
                                    FORM_EDIT_MODE: isRequired
                                },
                            }
                        }
                    }
                }

                if (fieldsConfig[fieldName].validationRulesIsNotActiveEditModeConditions !== undefined) {
                    let isNotActive = true;
                    
                    fieldsConfig[fieldName].validationRulesIsNotActiveEditModeConditions.forEach(condition => {
                        const {compareWith, values} = condition;
                        const compareWithValue = fieldValues[compareWith].value;
                            
                        const isRequiredCurrentRow = values.includes(compareWithValue);
                        if (isRequiredCurrentRow === false) {
                            isNotActive = false;
                        }
                    });

                    if (! isNotActive) {
                        state = {
                            ...state,
                            fields: {
                                ...state.fields,
                                [fieldName]: {
                                    ...state.fields[fieldName],
                                    validationRules: [],
                                }
                            }
                        }
                    } 
                }

                if (fieldsConfig[fieldName].dataTypeLogic !== undefined) {
                    const { dependingOnField, dictionary, getOptionsFormField } = fieldsConfig[fieldName].dataTypeLogic; 

                    const dependingOnFieldValue = fieldValues[dependingOnField].value;
                    const dataType = dictionary[dependingOnFieldValue];

                    state = {
                        ...state,
                        fields: {
                            ...state.fields,
                            [fieldName]: {
                                ...state.fields[fieldName],
                                dataType: dataType,
                            }
                        }
                    }

                    if (dataType === FORM_DATA_TYPES.DROPDOWN) {
                        const options = fieldValues[getOptionsFormField].value;
                        
                        state = {
                            ...state,
                            fields: {
                                ...state.fields,
                                [fieldName]: {
                                    ...state.fields[fieldName],
                                    options: options,
                                }
                            }
                        }
                    }
                }

                state = {
                    ...state,
                    fields: {
                        ...state.fields,
                        [fieldName]: {
                            ...state.fields[fieldName],
                            error: false,
                            errorMessage: '',
                            value: fieldValue,
                            dbValue: fieldValue,
                        }
                    }
                }
            });
            
            return state;
        default: 
            return state;
    }
}

export default gridModalReducer;

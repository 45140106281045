import _ from 'lodash';

export const openTab = (state, action) => {
    const { index } = action.payload;

    return {
        ...state,
        activeIndexes: [...state.activeIndexes, index]
    };
}

export const openAllTabs = (state) => {
    return {
        ...state,
        activeIndexes: _.keys(state.tabObjects)
    };
}

export const closeTab = (state, action) => {
    const { index } = action.payload;
    
    return {
        ...state,
        activeIndexes: state.activeIndexes.filter(idx => idx !== index)
    };
}

export const closeAllTabs = (state) => {
    return {
        ...state,
        activeIndexes: []
    };
}

export const startSpinner = (state) => {
    return {
        ...state,
        showSpinner: true
    }
}

export const stopSpinner = (state) => {
    return {
        ...state,
        showSpinner: false
    }
}

export const fetchData = (state, action) => {
    const { title, tabObjects, activeTabs } = action.payload;
    
    state = {
        ...state,
        title: title, 
        tabObjects: tabObjects, 
        activeIndexes: activeTabs
    }

    return state;
}

import _ from 'lodash';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { EMPTY_VALUE, FORM_DATA_TYPES } from '../../helpers/const';

export const updateOnStepAction = (state, action) => {
    const {relatedData, relatedDataPositions, fields, fieldGroups, stepsConfiguration, lockLogic} = action.payload;
    
    // fields
    if (fields !== undefined) {
        state = {
            ...state, 
            fields: {}
        };

        _.forEach(fields, (field, fieldName) => {
            state = {
                ...state, 
                fields: {
                    ...state.fields,
                    [fieldName]: field
                }
            };
        });
    }

    // fieldGroups
    if (fieldGroups !== undefined) {
        state = {
            ...state, 
            fieldGroups: {}
        };
        
        _.forEach(fieldGroups, (fieldGroup, rowKey) => {
            state = {
                ...state, 
                fieldGroups: {
                    ...state.fieldGroups,
                    [rowKey]: []
                }
            };

            _.forEach(fieldGroup, fieldName => {
                state = {
                    ...state, 
                    fieldGroups: {
                        ...state.fieldGroups,
                        [rowKey]: [...state.fieldGroups[rowKey], fieldName]
                    }
                };  
            })
        });
    }

    // related data
    if (relatedData !== undefined) {
        _.forEach(relatedData, (value, name) => {
            state = {
                ...state,
                relatedData: {
                    ...state.relatedData,
                    [name]: value
                }
            }
        });
    }

    // related data positions
    if (! _.isUndefined(relatedDataPositions)) {
        state = {
            ...state,
            relatedDataPositions: relatedDataPositions
        }
    }

    //TODO lockLogic
    if (lockLogic !== undefined) {
        if (lockLogic === EMPTY_VALUE) {
            state = {
                ...state,
                lockLogic: {
                    hasLockLogic: false,
                    isLocked: false,
                    requiredFieldsForUnlock: [], 
                    postParamsFields: [], 
                    postParamsRelatedData: [], 
                    urlLock: '', 
                    urlUnlock: '', 

                    messageWhenLockDisabled: '', 
                    saveButtonDisabled: false, 
                    saveButtonMessageWhenDisabled: '',
                }
            };
        } else {
            state = {
                ...state,
                lockLogic: lockLogic
            };
        }
    }

    // //TODO stepsConfiguration
    if (stepsConfiguration !== undefined) {
        const {rows, actions, subTitle} = stepsConfiguration;

        state = {
            ...state,
            stepsConfiguration: {
                rows: {},
                actions: {},
                subTitle: subTitle,
            }
        };

        _.forEach(rows, (row, rowCode) => {
            state = {
                ...state,
                stepsConfiguration: {
                    ...state.stepsConfiguration,
                    rows: {
                        ...state.stepsConfiguration.rows,
                        [rowCode]: {}
                    }
                }
            };

            _.forEach(row, (phase, phaseCode) => {
                state = {
                    ...state,
                    stepsConfiguration: {
                        ...state.stepsConfiguration,
                        rows: {
                            ...state.stepsConfiguration.rows,
                            [rowCode]: {
                                ...state.stepsConfiguration.rows[rowCode],
                                [phaseCode]: phase
                            }
                        }
                    }
                };   
            });
        });
    
        _.forEach(actions, (action, actionName) => {
            state = {
                ...state,
                stepsConfiguration: {
                    ...state.stepsConfiguration,
                    actions: {
                        ...state.stepsConfiguration.actions,
                        [actionName]: action
                    }
                }
            }
        });
    }

    return state;
}

export const handleLock = (state, action) => {
    let {headerData, relatedData, isLocked, saveButtonDisabled, relatedDataPositions} = action.payload;

    state = {
        ...state,
        lockLogic: {
            ...state.lockLogic,
            isLocked: isLocked,
            saveButtonDisabled: saveButtonDisabled,
        },
        relatedDataPositions: relatedDataPositions,
    };

    _.forEach(relatedData, (value, name) => {
        state = {
            ...state,
            relatedData: {
                ...state.relatedData,
                [name]: value
            }
        }
    });

    //TODO extract in method
    _.forEach(headerData, (fieldObjectProps, fieldName) => {
        _.forEach(fieldObjectProps, (propValue, propName) => {
            switch (propName) {
                case 'event':
                case 'validationRules':
                        state = {...state, 
                        fields: {...state.fields, 
                            [fieldName]: {...state.fields[fieldName], 
                                [propName]: []
                            }
                        }
                    };
                    
                    if (propValue !== EMPTY_VALUE) { 
                        propValue.forEach(propObj => {
                            state.fields[fieldName][propName].push({...propObj});
                        });
                    } 

                    break;
                case 'dataType':
                case 'options':
                case 'value':
                case 'label':
                case 'error':
                case 'errorMessage':
                    state = {...state, 
                        fields: {...state.fields, 
                            [fieldName]: {...state.fields[fieldName],
                                [propName]:  propValue
                            }
                        }
                    };

                    break;
                case 'required':
                case 'readOnly':
                    state = {...state, 
                        fields: {...state.fields, 
                            [fieldName]: {...state.fields[fieldName],
                                [propName]: {...state.fields[fieldName][propName],
                                    FORM_CREATE_MODE: propValue.FORM_CREATE_MODE,
                                }
                            }
                        }
                    };

                    break;
                default:
                    console.log('Not registered Field Object property name for mutation - ' + propName);
            }
        });
    });

    return state;
}

export const revisionTreeHandleExpand = (state, action) => {
    return {
        ...state,
        revisionLogic: {
            ...state.revisionLogic,
            revisionBomTreeData: {
                ...state.revisionLogic.revisionBomTreeData,
                expanded: action.payload
            }
        }
    };
}

export const revisionTreeHandleCheck = (state, action) => {
    return {
        ...state,
        revisionLogic: {
            ...state.revisionLogic,
            revisionBomTreeData: {
                ...state.revisionLogic.revisionBomTreeData,
                checked: action.payload
            }
        }
    };
}

export const updateFormAjax = (state, action) => {
    let {
        revisionBomTreeData, headerData, relatedData, relatedDataRow, forRevision, emptyRowForUpdate,
        fields, fieldGroups, lockLogic, stepsConfiguration, relatedDataForAddOrDelete, 
        relatedDataRowsForAdd, relatedDataRowKeysForDelete
    } = action.payload;

    if (relatedDataRowKeysForDelete) {
        let newCollectionRows = null;

        _.forEach(relatedDataRowKeysForDelete, (rowsForCreateForDeletion, relatedDataName) => {
            _.forEach(rowsForCreateForDeletion, rowKey => {
                newCollectionRows = _.omit(state.relatedData[relatedDataName].rowsForCreate, rowKey);
                
                state = {
                    ...state, 
                    relatedData: {
                        ...state.relatedData,
                        [relatedDataName]: {
                            ...state.relatedData[relatedDataName],
                            rowsForCreate: newCollectionRows
                        }
                    }
                };
            })  
        });
    }

    if (relatedDataRowsForAdd !== undefined) {
        _.forEach(relatedDataRowsForAdd, (rowsForCreateForAdd, relatedDataName) => {
            _.forEach(rowsForCreateForAdd, (row, rowKey) => {
                if (state.relatedData[relatedDataName].rowsForCreate === undefined) { //If no rows for create, we should initialize the object
                    state = {
                        ...state,
                        relatedData: {
                            ...state.relatedData,
                            [relatedDataName]: {
                                ...state.relatedData[relatedDataName],
                                rowsForCreate: {
                                    [rowKey]: row
                                },
                            }
                        }  
                    };
                } else {
                    state = {
                        ...state,
                        relatedData: {
                            ...state.relatedData,
                            [relatedDataName]: {
                                ...state.relatedData[relatedDataName],
                                rowsForCreate: {
                                    ...state.relatedData[relatedDataName].rowsForCreate,
                                    [rowKey]: row
                                },
                            }
                        }
                    };
                }
            })
        })
    }

    if (relatedDataForAddOrDelete !== undefined) {
        _.forEach(relatedDataForAddOrDelete, (value, name) => {
            state = {
                ...state,
                relatedData: {
                    ...state.relatedData,
                    [name]: value
                }
            }
        });
    }

    if (emptyRowForUpdate !== undefined) {
        _.forEach(emptyRowForUpdate, (relatedDataObjectFields, relatedDataName) => {
            _.forEach(relatedDataObjectFields, (fieldProps, fieldName) => {
                _.forEach(fieldProps, (propValue, propName) => {
                    state = mutateRelatedDataEmptyRowProperty(state, relatedDataName, fieldName, propName, propValue);
                })
            })
        });
    }

    if (forRevision !== undefined) {
        state = {
            ...state,
            revisionLogic: {
                ...state.revisionLogic,
                forRevision: forRevision
            }
        }
    }

    if (revisionBomTreeData !== undefined) {
        if (revisionBomTreeData === EMPTY_VALUE) {
            state = {
                ...state, 
                revisionLogic: {
                    ...state.revisionLogic,
                    revisionBomTreeData: EMPTY_VALUE
                }
            };   
        } else {
            const {initBomTree, bomTreeDataInitNodes, nodesForDelete, nodesToAdd, nodesForUncheck} = revisionBomTreeData;

            if (initBomTree) {
                state = {
                    ...state, 
                    revisionLogic: {
                        ...state.revisionLogic,
                        revisionBomTreeData: {
                            ...state.revisionLogic.revisionBomTreeData,
                            nodes: bomTreeDataInitNodes,
                            checked: [],
                            expanded: [],
                        }
                    }
                };        
            }
            
            if (nodesForDelete !== undefined) {
                state = {
                    ...state,
                    revisionLogic: {
                        ...state.revisionLogic,
                        revisionBomTreeData: {
                            ...state.revisionLogic.revisionBomTreeData,
                            nodes: state.revisionLogic.revisionBomTreeData.nodes.filter(node => ! _.includes(nodesForDelete, node.value))
                        }
                    }
                }
            }

            if (nodesToAdd !== undefined) {
                _.forEach(nodesToAdd, nodeToAdd => {
                    state = {
                        ...state,
                        revisionLogic: {
                            ...state.revisionLogic,
                            revisionBomTreeData: {
                                ...state.revisionLogic.revisionBomTreeData,
                                nodes: [...state.revisionLogic.revisionBomTreeData.nodes, nodeToAdd]
                            }
                        }
                    }
                });
            }

            if (nodesForUncheck !== undefined) {
                state = {
                    ...state,
                    revisionLogic: {
                        ...state.revisionLogic,
                        revisionBomTreeData: {
                            ...state.revisionLogic.revisionBomTreeData,
                            checked: state.revisionLogic.revisionBomTreeData.checked.filter(nodeValue => ! _.includes(nodesForUncheck, nodeValue)),
                            expanded: state.revisionLogic.revisionBomTreeData.expanded.filter(nodeValue => ! _.includes(nodesForUncheck, nodeValue))
                        }
                    }           
                }
            }
        }
    }

    if (headerData !== undefined) {
        _.forEach(headerData, (fieldObjectProps, fieldName) => {
            _.forEach(fieldObjectProps, (propValue, propName) => {
                switch (propName) {
                    case 'dataType':
                    case 'options':
                    case 'value':
                    case 'label':
                    case 'error':
                    case 'errorMessage':
                        state = {
                            ...state, 
                            fields: {
                                ...state.fields, 
                                [fieldName]: {
                                    ...state.fields[fieldName],
                                    [propName]:  propValue
                                }
                            }
                        };

                        break;
                    case 'required':
                    case 'readOnly':
                        state = {...state, 
                            fields: {...state.fields, 
                                [fieldName]: {...state.fields[fieldName],
                                    [propName]: {...state.fields[fieldName][propName],
                                        FORM_CREATE_MODE: propValue.FORM_CREATE_MODE,
                                        FORM_EDIT_MODE: propValue.FORM_EDIT_MODE,
                                    }
                                }
                            }
                        };

                        break;
                    case 'event':
                    case 'validationRules':
                            state = {...state, 
                            fields: {...state.fields, 
                                [fieldName]: {...state.fields[fieldName], 
                                    [propName]: []
                                }
                            }
                        };
                        
                        if (propValue !== EMPTY_VALUE) { 
                            propValue.forEach(propObj => {
                                state.fields[fieldName][propName].push({...propObj});
                            });
                        } 

                        break;
                    default:
                        console.log('Not registered Field Object property name for mutation - ' + propName);
                }
            });
        });
    }

    if (relatedDataRow !== undefined) {
        let {relatedDataName, mode, rowKey} = action.payload;

        let collection = '';
        if (mode === 'FORM_CREATE_MODE') {
            collection = 'rowsForCreate';
        } else if (mode === 'FORM_EDIT_MODE') {
            collection = 'rowsForEdit';
        }
        
        _.forEach(relatedDataRow, (fieldProps, fieldName) => {
            _.forEach(fieldProps, (propValue, propName) => {
                state = mutateRelatedDataFieldProperty(state, relatedDataName, collection, rowKey, fieldName, propName, propValue);
            });
        });
    }

    if (relatedData !== undefined) {
        _.forEach(relatedData, (relatedDataSingleObj, relatedDataName) => {
            const { rowsForEdit, rowsForCreate, formFieldsConfigSubtotal } = relatedDataSingleObj;

            if (rowsForEdit !== undefined) {
                let collection = 'rowsForEdit';

                _.forEach(rowsForEdit, (rowForEdit, rowKey) => {
                    _.forEach(rowForEdit, (fieldProps, fieldName) => {
                        _.forEach(fieldProps, (propValue, propName) => {
                            state = mutateRelatedDataFieldProperty(state, relatedDataName, collection, rowKey, fieldName, propName, propValue);
                        });
                    });
                });
            }
            
            if (rowsForCreate !== undefined) {
                let collection = 'rowsForCreate';

                _.forEach(rowsForCreate, (rowForCreate, rowKey) => {
                    _.forEach(rowForCreate, (fieldProps, fieldName) => {
                        _.forEach(fieldProps, (propValue, propName) => {
                            state = mutateRelatedDataFieldProperty(state, relatedDataName, collection, rowKey, fieldName, propName, propValue);
                        });
                    });
                });
            }

            if (formFieldsConfigSubtotal !== undefined) {
                _.forEach(formFieldsConfigSubtotal, (value, fieldName) => {
                    state = {...state,
                        relatedData: {
                            ...state.relatedData,
                            [relatedDataName]: {
                                ...state.relatedData[relatedDataName],
                                formFieldsConfigSubtotal: {
                                    ...state.relatedData[relatedDataName].formFieldsConfigSubtotal,
                                    [fieldName]: {
                                        ...state.relatedData[relatedDataName].formFieldsConfigSubtotal[fieldName],
                                        value: value
                                    }
                                }
                            }
                        }
                    };
                });
            }
        })
    }

    if (fields !== undefined) {
        state = {
            ...state, 
            fields: {}
        };

        _.forEach(fields, (field, fieldName) => {
            state = {
                ...state, 
                fields: {
                    [fieldName]: field
                }
            };
        });
    }

    if (fieldGroups !== undefined) {
        state = {
            ...state, 
            fieldGroups: {}
        };
        
        _.forEach(fieldGroups, (fieldGroup, rowKey) => {
            state = {
                ...state, 
                fieldGroups: {
                    [rowKey]: []
                }
            };

            _.forEach(fieldGroup, fieldName => {
                state = {
                    ...state, 
                    fieldGroups: {
                        [rowKey]: [...state.fieldGroups[rowKey], fieldName]
                    }
                };  
            })
        });
    }

    if (lockLogic !== undefined) {
        if (lockLogic === EMPTY_VALUE) {
            state = {
                ...state,
                lockLogic: {
                    hasLockLogic: false,
                    isLocked: false,
                    requiredFieldsForUnlock: [], 
                    postParamsFields: [], 
                    urlLock: '', 
                    urlUnlock: '', 
                }
            };
        } else {
            state = {
                ...state,
                lockLogic: lockLogic
            };
        }
    }

    if (stepsConfiguration !== undefined) {
        state = {
            ...state,
            stepsConfiguration: {
                rows: {},
                actions: {}
            }
        };

        const {rows, actions} = stepsConfiguration;
        _.forEach(rows, (row, rowCode) => {
            state = {
                ...state,
                stepsConfiguration: {
                    ...state.stepsConfiguration,
                    rows: {
                        ...state.stepsConfiguration.rows,
                        [rowCode]: {}
                    }
                }
            };

            _.forEach(row, (phase, phaseCode) => {
                state = {
                    ...state,
                    stepsConfiguration: {
                        ...state.stepsConfiguration,
                        rows: {
                            ...state.stepsConfiguration.rows,
                            [rowCode]: {
                                ...state.stepsConfiguration.rows[rowCode],
                                [phaseCode]: phase
                            }
                        }
                    }
                };   
            });
        });
    
        _.forEach(actions, (action, actionName) => {
            state = {
                ...state,
                stepsConfiguration: {
                    ...state.stepsConfiguration,
                    actions: {
                        ...state.stepsConfiguration.actions,
                        [actionName]: action
                    }
                }
            }
        });
    }

    return state;
}

export const updateAllErrors = (state, action) => {
    let errors = action.payload.errors;
    const formFields = errors.headerForm;
    const details =  _.omit(errors, ['headerForm']);

    if (! _.isEmpty(formFields)) {
        _.forEach(formFields, (fieldConfiguration, fieldName) => {
            state = {
                ...state, 
                fields: {
                    ...state.fields, 
                    [fieldName]: {
                        ...state.fields[fieldName],
                        error: fieldConfiguration.error,
                        errorMessage: fieldConfiguration.errorMessage
                    }
                }
            };
        });
    }

    if (! _.isEmpty(details)) {
        _.forEach(details, (detailObj, relatedDataName) => {
            _.forEach(detailObj, (rows, collectionName) => {
                _.forEach(rows, (rowFields, rowKey) => {
                    _.forEach(rowFields, (fieldObj, fieldName) => {
                        let {error, errorMessage} = fieldObj;
                        
                        state = {
                            ...state,
                            relatedData: {
                                ...state.relatedData,
                                [relatedDataName]: {
                                    ...state.relatedData[relatedDataName],
                                    [collectionName]: {
                                        ...state.relatedData[relatedDataName][collectionName],
                                        [rowKey]: {
                                            ...state.relatedData[relatedDataName][collectionName][rowKey],
                                            [fieldName]: {
                                                ...state.relatedData[relatedDataName][collectionName][rowKey][fieldName],
                                                error: error,
                                                errorMessage: errorMessage
                                            }
                                        }
                                    }
                                }
                            }
                        };
                    });
                });
            });
        });
    }

    return state;
}

export const updateRelatedDataError = (state, action) => {
    let { relatedDataName, mode, rowKey, field, error, errorMessage } = action.payload;

    let collection = mode === "FORM_CREATE_MODE" ? 'rowsForCreate' : 'rowsForEdit';

    state = {
        ...state,
        relatedData: {
            ...state.relatedData,
            [relatedDataName]: {
                ...state.relatedData[relatedDataName],
                [collection]: {
                    ...state.relatedData[relatedDataName][collection],
                    [rowKey]: {
                        ...state.relatedData[relatedDataName][collection][rowKey],
                        [field]: {
                            ...state.relatedData[relatedDataName][collection][rowKey][field],
                            error: error,
                            errorMessage: errorMessage
                        }
                    }
                }
            }
        }
    }

    return state;
}

export const addRelatedDataRow = (state, action) => {
    let { relatedDataName } = action.payload;

    let nextRowForCreateIndex = state.relatedData[relatedDataName].currentRowsForCreateIndex + 1;
    let nextRowForCreateKey = 'row_' + nextRowForCreateIndex;    

    if (state.relatedData[relatedDataName].rowsForCreate === undefined) { //If no rows for create, we should initialize the object
        state = {
            ...state,
            relatedData: {
                ...state.relatedData,
                [relatedDataName]: {
                    ...state.relatedData[relatedDataName],
                    rowsForCreate: {
                        [nextRowForCreateKey]: state.relatedData[relatedDataName].emptyRow
                    },
                    currentRowsForCreateIndex: nextRowForCreateIndex
                }
            }  
        };
    } else {
        state = {
            ...state,
            relatedData: {
                ...state.relatedData,
                [relatedDataName]: {
                    ...state.relatedData[relatedDataName],
                    rowsForCreate: {
                        ...state.relatedData[relatedDataName].rowsForCreate,
                        [nextRowForCreateKey]: state.relatedData[relatedDataName].emptyRow
                    },
                    currentRowsForCreateIndex: nextRowForCreateIndex
                }
            }
        };
    }

    return state;
}

export const removeRelatedDataRow = (state, action) => {
    let { relatedDataName, mode, rowKey } = action.payload;
    let collection = mode === "FORM_CREATE_MODE" ? "rowsForCreate" : "rowsForEdit";
   
    if (mode === "FORM_EDIT_MODE") { //This row exists and should be deleted
        let recordIdForDelete = state.relatedData[relatedDataName][collection][rowKey].id.value;

        if (state.relatedData[relatedDataName].rowsForDelete === undefined) { //If no rows for delete, we should initialize the object
            state = {
                ...state,
                relatedData: {
                    ...state.relatedData,
                    [relatedDataName]: {
                        ...state.relatedData[relatedDataName],
                        rowsForDelete: [recordIdForDelete]
                    }
                }
            };
        } else {
            state = {
                ...state,
                relatedData: {
                    ...state.relatedData,
                    [relatedDataName]: {
                        ...state.relatedData[relatedDataName],
                        rowsForDelete: [
                            ...state.relatedData[relatedDataName].rowsForDelete,
                            recordIdForDelete
                        ]
                    }
                }
            };
        }
    }

    let newCollectionRows = _.omit(state.relatedData[relatedDataName][collection], rowKey);
    state = {
        ...state, 
        relatedData: {
            ...state.relatedData,
            [relatedDataName]: {
                ...state.relatedData[relatedDataName],
                [collection]: newCollectionRows
            }
        }
    };
   
    return state;
}

export const fetch = (state, action) => {
    const { data } = action.payload;
    let { 
        hasImportFilesLogic, fields, fieldGroups, relatedData, relatedDataPositions, title, mode, message,
        lockLogic, forRevision, revisionDescription, newRevisionDescription, bomTreeData, hideActions, stepsConfiguration 
    } = data;

    //Init Rich Text Editor State
    _.forEach(fields, (fieldObject, fieldName) => {
        const { dataType } = fieldObject;

        if (dataType === FORM_DATA_TYPES.INPUT_FIELD_RICH_TEXT_EDITOR) {
            const { richTextEditorHtml } = fieldObject;
            if (richTextEditorHtml) {
                const contentBlock = htmlToDraft(richTextEditorHtml);
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);

                fields[fieldName].value = editorState;
            }
        }
    });

    state = {
        ...state,
        showSpinner: false,
        fields: fields,
        fieldGroups: fieldGroups,
        relatedData: relatedData,
        relatedDataPositions: relatedDataPositions,
        title: title,
        mode: mode,
    };

    if (message !== undefined) {
        state = {...state, message: message};
    }

    if (stepsConfiguration !== undefined) {
        state = {...state, stepsConfiguration: stepsConfiguration};
    }

    if (hasImportFilesLogic !== undefined) {
        state = {...state, hasImportFilesLogic: hasImportFilesLogic};
    }

    if (hideActions !== undefined) {
        state = {...state, hideActions: hideActions};
    }

    if (lockLogic !== undefined) {
        state = {
            ...state,
            lockLogic: lockLogic
        }
    }

    if (bomTreeData !== undefined) {
        state = {
            ...state,
            revisionLogic: {
                ...state.revisionLogic,
                revisionBomTreeData: bomTreeData
            }
        }
    }

    if (forRevision !== undefined) {
        state = {
            ...state,
            revisionLogic: {
                ...state.revisionLogic,
                forRevision: forRevision
            }
        }
    }

    if (newRevisionDescription !== undefined) {
        state = {
            ...state,
            revisionLogic: {
                ...state.revisionLogic,
                newRevisionDescription: newRevisionDescription
            }
        }
    }

    if (revisionDescription !== undefined) {
        state = {
            ...state,
            revisionLogic: {
                ...state.revisionLogic,
                revisionDescription: revisionDescription
            }
        }
    }

    return state;
}

export const updateInput = (state, action) => {
    let { name, value } = action.payload; 
            
    return {
        ...state,
        fields: {
            ...state.fields,
            [name]: {
                ...state.fields[name],
                value: value
            }
        }
    };
}

export const updateError = (state, action) => {
    let { name, hasError, errorMsg } = action.payload; 
            
    return {
        ...state,
        fields: {
            ...state.fields,
            [name]: {
                ...state.fields[name],
                error: hasError,
                errorMessage: errorMsg,
            }
        }
    };
}

export const toggleSpinner = (state, action) => {
    const showSpinnerNewVal = ! state.showSpinner;

    return {...state, showSpinner: showSpinnerNewVal };
}

export const updateRelatedDataInput = (state, action) => {
    const { relatedDataName, mode, rowKey, field, value } = action.payload;
        
    const collection = mode === 'FORM_CREATE_MODE' ? 'rowsForCreate' : 'rowsForEdit';
    
    return {
        ...state,
        relatedData: {
            ...state.relatedData,
            [relatedDataName]: {
                ...state.relatedData[relatedDataName],
                [collection]: {
                    ...state.relatedData[relatedDataName][collection],
                    [rowKey]: {
                        ...state.relatedData[relatedDataName][collection][rowKey],
                        [field]: {
                            ...state.relatedData[relatedDataName][collection][rowKey][field],
                            value: value
                        }
                    }
                }
            }
        }
    };
}

export const updateRelatedDataInputButtonOptions = (state, action) => {
    const { 
        relatedDataName, mode, rowKey, fieldName, optionValue, clickedValuesArr, negativeOption, 
        minPositiveOption, fullAccessOption, positiveOptions, slidersFieldName, slidersFullAccess,
        slidersNoAccess 
    } = action.payload;

    const collection = mode === 'FORM_CREATE_MODE' ? 'rowsForCreate' : 'rowsForEdit';

    const currentClickedOptionIsTheFullAccessOption = optionValue === fullAccessOption;
    const currentClickedOptionIsTheNegativeOption = optionValue === negativeOption;
    const currentClickedOptionIsTheMinPositiveOption = optionValue === minPositiveOption;
    
    const currentClickedOptionIsAlreadyClicked = _.indexOf(clickedValuesArr, optionValue) !== -1;
    const negativeOptionIsClicked = _.indexOf(clickedValuesArr, negativeOption) !== -1;
    const minPositiveOptionIsClicked = _.indexOf(clickedValuesArr, minPositiveOption) !== -1;
    const fullAccessOptionIsClicked = _.indexOf(clickedValuesArr, fullAccessOption) !== -1;
    
    let updateAdditionalActionSliders = false; 
    let newCheckedSlidersValue = false; 
    let rowHaveLinkedSliders = slidersFieldName != EMPTY_VALUE;
    
    let newValues = [];
    if (currentClickedOptionIsTheFullAccessOption) { //Clicked on All
        if (! fullAccessOptionIsClicked) { //it is newly clicked
            if (! _.isUndefined(slidersFullAccess)) {
                updateAdditionalActionSliders = true;
                newCheckedSlidersValue = slidersFullAccess;
            }
        }
        
        newValues = [...positiveOptions, fullAccessOption];
    } else if (currentClickedOptionIsTheNegativeOption) { //Clicked on NO
        if (! negativeOptionIsClicked) { //it is newly clicked
            if (! _.isUndefined(slidersNoAccess)) { //Uncheck all sliders
                updateAdditionalActionSliders = true;
                newCheckedSlidersValue = slidersNoAccess;
            }
        }

        newValues = [negativeOption];
    } else if (currentClickedOptionIsTheMinPositiveOption) { //Clicked on READ
        if (currentClickedOptionIsAlreadyClicked) {
            if (! _.isUndefined(slidersNoAccess)) { //Uncheck all sliders
                updateAdditionalActionSliders = true;
                newCheckedSlidersValue = slidersNoAccess;
            }
            
            newValues = [negativeOption];
        } else {
            newValues = [minPositiveOption];
        }
    } else { //Clicked on CREATE, UPDATE, DELETE
        if (currentClickedOptionIsAlreadyClicked) { //Is checked before the clikc
            newValues = clickedValuesArr.filter(element => element !== optionValue);
            
            if (newValues.length === 0) {
                newValues = [negativeOption];
            }
        } else { // Is not checked before the click
            if (negativeOptionIsClicked) { //NO is clicked
                newValues = clickedValuesArr.filter(element => element !== negativeOption); //Uncheck it
            } else { 
                newValues = clickedValuesArr; //Keep the old checked values
            }

            if (optionValue !== minPositiveOption && ! minPositiveOptionIsClicked) { //Check if its already clicked and if it is not the cicking one
                newValues = [...newValues, minPositiveOption]; //Add the min positive option
            }
            
            newValues = [...newValues, optionValue]; //Add the newly clicked valie
        }   
    }

    //Handle Full Access button, depending on positive actions 
    const allPositiveOptionsAreClicked = _.difference(positiveOptions, newValues).length === 0;
    if (! fullAccessOptionIsClicked && allPositiveOptionsAreClicked) { //fullAccess is not clicked AND all positive options are clicked
        let sliderConditionIsOk = false;
        
        if (rowHaveLinkedSliders) {
            const currentCheckedSliders = state.relatedData[relatedDataName][collection][rowKey][slidersFieldName].checkedSliders;
            if (! _.isUndefined(currentCheckedSliders)) {
                const allSlidersAreOn = _.isEqual(currentCheckedSliders, slidersFullAccess);    
                if (allSlidersAreOn) { //All sliders are checked, so we can turn full access on
                    sliderConditionIsOk = true;
                }
            } else {
                sliderConditionIsOk = true; //No sliders, so we don't keep them in mind
            }
        } else {
            sliderConditionIsOk = true;
        }
        
        if (sliderConditionIsOk) {
            newValues = [...newValues, fullAccessOption]; //turn full access on
        }
    } else if (fullAccessOptionIsClicked && ! allPositiveOptionsAreClicked) { //full access is clicked AND all positive options are not clicked
        newValues = newValues.filter(element => element !== fullAccessOption); //turn full access off
    }

    //Handle Sliders if needed
    if (updateAdditionalActionSliders) {
        state = {
            ...state,
            relatedData: {
                ...state.relatedData,
                [relatedDataName]: {
                    ...state.relatedData[relatedDataName],
                    [collection]: {
                        ...state.relatedData[relatedDataName][collection],
                        [rowKey]: {
                            ...state.relatedData[relatedDataName][collection][rowKey],
                            [slidersFieldName]: {
                                ...state.relatedData[relatedDataName][collection][rowKey][slidersFieldName],
                                checkedSliders: newCheckedSlidersValue
                            }
                        }
                    }
                }
            }
        }
    }  

    //Delete invalid values - this is for commodity type more simple logic. It is temp decision
    newValues = newValues.filter(elem => ! _.isUndefined(elem))

    return {
        ...state,
        relatedData: {
            ...state.relatedData,
            [relatedDataName]: {
                ...state.relatedData[relatedDataName],
                [collection]: {
                    ...state.relatedData[relatedDataName][collection],
                    [rowKey]: {
                        ...state.relatedData[relatedDataName][collection][rowKey],
                        [fieldName]: {
                            ...state.relatedData[relatedDataName][collection][rowKey][fieldName],
                            values: newValues
                        }
                    }
                }
            }
        }
    };
}

export const updateRelatedDataInputSliders = (state, action) => {
    const { 
        relatedDataName, mode, rowKey, fieldName, actionId, checkedSlidersFullAccess, minPositiveOptionValue,
        buttonOptionsFieldName, noAccessOptionValue, fullAccessOptionValue, positiveOptions 
    } = action.payload;

    const collection = mode === 'FORM_CREATE_MODE' ? 'rowsForCreate' : 'rowsForEdit';
    const newSliderValue = ! state.relatedData[relatedDataName][collection][rowKey][fieldName].checkedSliders[actionId]; 
    
    state = {
        ...state,
        relatedData: {
            ...state.relatedData,
            [relatedDataName]: {
                ...state.relatedData[relatedDataName],
                [collection]: {
                    ...state.relatedData[relatedDataName][collection],
                    [rowKey]: {
                        ...state.relatedData[relatedDataName][collection][rowKey],
                        [fieldName]: {
                            ...state.relatedData[relatedDataName][collection][rowKey][fieldName],
                            checkedSliders: {
                                ...state.relatedData[relatedDataName][collection][rowKey][fieldName].checkedSliders,
                                [actionId]: newSliderValue
                            }
                        }
                    }
                }
            }
        }
    };

    //Handle No Access and Full Access buttons if needed
    const buttonOptionsClicked = state.relatedData[relatedDataName][collection][rowKey][buttonOptionsFieldName].values;
    const fullAccessButtonIsChecked = _.indexOf(buttonOptionsClicked, fullAccessOptionValue) !== -1;
    const noAccessButtonIsChecked = _.indexOf(buttonOptionsClicked, noAccessOptionValue) !== -1;
    const allPositiveOptionsAreClicked = _.difference(positiveOptions, buttonOptionsClicked).length === 0;
    
    const currentCheckedSliders = state.relatedData[relatedDataName][collection][rowKey][fieldName].checkedSliders;
    const allSlidersAreOn = _.isEqual(currentCheckedSliders, checkedSlidersFullAccess);    

    let shouldUpdateButtonOptions = false;
    let newButtonOptions = [];
    //if fullAccess button is checked and current value is false => uncheck the fullAccess button
    if (fullAccessButtonIsChecked && newSliderValue === false) {
        shouldUpdateButtonOptions = true;
        newButtonOptions = buttonOptionsClicked.filter(element => element !== fullAccessOptionValue);
    }

    //if fullAccess button is not checked, all positive options are checked AND all sliders are checked => check FullAccess btn
    if (! fullAccessButtonIsChecked && allPositiveOptionsAreClicked && allSlidersAreOn) {
        shouldUpdateButtonOptions = true;
        newButtonOptions = [...buttonOptionsClicked, fullAccessOptionValue];
    }

    //if noAccess button is checked and current value is true => uncheck noAccess and check readAccess 
    if (noAccessButtonIsChecked && newSliderValue === true) {
        shouldUpdateButtonOptions = true;
        newButtonOptions = buttonOptionsClicked.filter(element => element !== noAccessOptionValue);
        newButtonOptions = [...newButtonOptions, minPositiveOptionValue];
    }

    if (shouldUpdateButtonOptions) {
        state = {
            ...state,
            relatedData: {
                ...state.relatedData,
                [relatedDataName]: {
                    ...state.relatedData[relatedDataName],
                    [collection]: {
                        ...state.relatedData[relatedDataName][collection],
                        [rowKey]: {
                            ...state.relatedData[relatedDataName][collection][rowKey],
                            [buttonOptionsFieldName]: {
                                ...state.relatedData[relatedDataName][collection][rowKey][buttonOptionsFieldName],
                                values: newButtonOptions
                            }
                        }
                    }
                }
            }
        }
    }

    return state;
}

export const updateRelatedDataFieldDisabled = (state, action) => {
    let { relatedDataName, mode, rowKey, fieldName, disabled, collection } = action.payload; 

    return {
        ...state,
        relatedData: {
            ...state.relatedData,
            [relatedDataName]: {
                ...state.relatedData[relatedDataName],
                [collection]: {
                    ...state.relatedData[relatedDataName][collection],
                    [rowKey]: {
                        ...state.relatedData[relatedDataName][collection][rowKey], 
                        [fieldName]: {
                            ...state.relatedData[relatedDataName][collection][rowKey][fieldName],
                            readOnly: {
                                ...state.relatedData[relatedDataName][collection][rowKey][fieldName].readOnly,
                                [mode]: disabled 
                            }
                        }
                    }
                }
            }
        }
    };
}

export const revisionDescriptionUpdateInput = (state, action) => {
    return {
        ...state,
        revisionLogic: {
            ...state.revisionLogic,
            newRevisionDescription: action.payload
        }
    };
}

export const updateRelatedDataInputDimensionField = (state, action) => {
    const { relatedDataName, mode, rowKey, field, value, dimensionName } = action.payload;
        
    const collection = mode === 'FORM_CREATE_MODE' ? 'rowsForCreate' : 'rowsForEdit';
    
    return {
        ...state,
        relatedData: {
            ...state.relatedData,
            [relatedDataName]: {
                ...state.relatedData[relatedDataName],
                [collection]: {
                    ...state.relatedData[relatedDataName][collection],
                    [rowKey]: {
                        ...state.relatedData[relatedDataName][collection][rowKey],
                        [field]: {
                            ...state.relatedData[relatedDataName][collection][rowKey][field],
                            dimensionsList: {
                                ...state.relatedData[relatedDataName][collection][rowKey][field].dimensionsList,
                                [dimensionName]: {
                                    ...state.relatedData[relatedDataName][collection][rowKey][field].dimensionsList[dimensionName],
                                    value: value
                                }
                            }
                        }
                    }
                }
            }
        }
    };
}

export const updateRelatedDataErrorDimensionField = (state, action) => {
    let { relatedDataName, mode, rowKey, field, error, errorMessage, dimensionName } = action.payload;

    let collection = mode === "FORM_CREATE_MODE" ? 'rowsForCreate' : 'rowsForEdit';

    return {
        ...state,
        relatedData: {
            ...state.relatedData,
            [relatedDataName]: {
                ...state.relatedData[relatedDataName],
                [collection]: {
                    ...state.relatedData[relatedDataName][collection],
                    [rowKey]: {
                        ...state.relatedData[relatedDataName][collection][rowKey],
                        [field]: {
                            ...state.relatedData[relatedDataName][collection][rowKey][field],
                            dimensionsList: {
                                ...state.relatedData[relatedDataName][collection][rowKey][field].dimensionsList,
                                [dimensionName]: {
                                    ...state.relatedData[relatedDataName][collection][rowKey][field].dimensionsList[dimensionName],
                                    error: error,
                                    errorMessage: errorMessage,
                                }
                            }
                        }
                    }
                }
            }
        }
    };
}

const mutateRelatedDataFieldProperty = (oldState, relatedDataName, collection, rowKey, fieldName, propName, propValue) => {
    let state = {...oldState};
    
    switch (propName) {
        case 'event':
        case 'validationRules':
            state = {...state, 
                relatedData: {
                    ...state.relatedData,
                    [relatedDataName]: {
                        ...state.relatedData[relatedDataName],
                        [collection]: {
                            ...state.relatedData[relatedDataName][collection], 
                            [rowKey]: {
                                ...state.relatedData[relatedDataName][collection][rowKey], 
                                [fieldName]: {
                                    ...state.relatedData[relatedDataName][collection][rowKey][fieldName],
                                    [propName]: []
                                }
                            }
                        }
                    }
                }
            };

            if (propValue !== EMPTY_VALUE) { 
                propValue.forEach(validationObj => {
                    state.relatedData[relatedDataName][collection][rowKey][fieldName][propName].push({...validationObj});
                });
            } 

            break;
        case 'value':
        case 'options':
        case 'error':
        case 'errorMessage':
            state = {...state,
                relatedData: {
                    ...state.relatedData,
                    [relatedDataName]: {
                        ...state.relatedData[relatedDataName],
                        [collection]: {
                            ...state.relatedData[relatedDataName][collection], 
                            [rowKey]: {
                                ...state.relatedData[relatedDataName][collection][rowKey], 
                                [fieldName]: {
                                    ...state.relatedData[relatedDataName][collection][rowKey][fieldName],
                                    [propName]: propValue
                                }
                            }
                        }
                    }
                }
            };

            break;
        case 'required':
        case 'readOnly':
            state = {...state, 
                relatedData: {
                    ...state.relatedData,
                    [relatedDataName]: {
                        ...state.relatedData[relatedDataName],
                        [collection]: {
                            ...state.relatedData[relatedDataName][collection], 
                            [rowKey]: {
                                ...state.relatedData[relatedDataName][collection][rowKey], 
                                [fieldName]: {
                                    ...state.relatedData[relatedDataName][collection][rowKey][fieldName],
                                    [propName]: {
                                        ...state.relatedData[relatedDataName][collection][rowKey][fieldName][propName],
                                        FORM_CREATE_MODE: propValue.FORM_CREATE_MODE,
                                        FORM_EDIT_MODE: propValue.FORM_EDIT_MODE
                                    }
                                }
                            }
                        }
                    }
                }
            };

            break;
        default:
            console.log('Not registered Field Object property name for mutation - ' + propName);
    }

    return state;
}

const mutateRelatedDataEmptyRowProperty = (oldState, relatedDataName, fieldName, propName, propValue) => {
    let state = {...oldState};
    
    switch (propName) {
        case 'event':
        case 'validationRules':
            state = {...state, 
                relatedData: {
                    ...state.relatedData,
                    [relatedDataName]: {
                        ...state.relatedData[relatedDataName],
                        emptyRow: {
                            ...state.relatedData[relatedDataName].emptyRow, 
                            [fieldName]: {
                                ...state.relatedData[relatedDataName].emptyRow[fieldName],
                                [propName]: []
                            }
                        }
                    }
                }
            };

            if (propValue !== EMPTY_VALUE) { 
                propValue.forEach(validationObj => {
                    state.relatedData[relatedDataName].emptyRow[fieldName][propName].push({...validationObj});
                });
            } 

            break;
        case 'value':
        case 'options':
        case 'error':
        case 'errorMessage':
            state = {...state,
                relatedData: {
                    ...state.relatedData,
                    [relatedDataName]: {
                        ...state.relatedData[relatedDataName],
                        emptyRow: {
                            ...state.relatedData[relatedDataName].emptyRow, 
                            [fieldName]: {
                                ...state.relatedData[relatedDataName].emptyRow[fieldName],
                                [propName]: propValue
                            }
                        }
                    }
                }
            };

            break;
        case 'required':
        case 'readOnly':
            state = {...state, 
                relatedData: {
                    ...state.relatedData,
                    [relatedDataName]: {
                        ...state.relatedData[relatedDataName],
                        emptyRow: {
                            ...state.relatedData[relatedDataName].emptyRow, 
                            [fieldName]: {
                                ...state.relatedData[relatedDataName].emptyRow[fieldName],
                                [propName]: {
                                    ...state.relatedData[relatedDataName].emptyRow[fieldName][propName],
                                    FORM_CREATE_MODE: propValue.FORM_CREATE_MODE,
                                    FORM_EDIT_MODE: propValue.FORM_EDIT_MODE
                                }
                            }
                        }
                    }
                }
            };

            break;
        default:
            console.log('Not registered Field Object property name for mutation - ' + propName);
    }

    return state;
}

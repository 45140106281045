import React, { createRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Header, Form, Popup, Divider, Grid, List, Transition, Icon, Ref, Rail, Sticky, Segment } from 'semantic-ui-react';

import { getRouteObj } from '../../helpers/functions';
import { ROUTES } from '../../helpers/routes';
import {
    fetchScreenData, clearPage, updateFilterFieldValue, onLoadButtonClick, toggleExpandOfNodeElem, selectTreeRecord,
    setExpandNoAll, setExpandYesAll, updateScreenAjaxAction
} from '../../actions/treePageWithSidePane/actions';
import { EMPTY_VALUE } from '../../helpers/const';

class TreeNavigationWithStickySidePanePage extends React.Component {
    contextRef = createRef();
    state = { routeInfo: getRouteObj(this.props.match.path) };

    componentDidMount = () => {
        this.props.fetchScreenData(this.state.routeInfo.API_FETCH_URL);
    }

    componentWillUnmount = () => {
        this.props.clearPage();
    }

    onFilterFieldChange = (fieldName, value, onChangeAjaxEventUrl) => {
        this.props.updateFilterFieldValue(fieldName, value);
        
        if (! _.isUndefined(onChangeAjaxEventUrl)) {
            const postData = new FormData();
            postData.append(fieldName, value);

            this.props.updateScreenAjaxAction(onChangeAjaxEventUrl, postData);
        }
    }

    renderFieterFields = () => {
        const { filterFields } = this.props.data;

        if (filterFields == EMPTY_VALUE) {
            return null;
        }

        return _.map(filterFields, (fieldConfig, fieldName) => {
            const { width, label, value, readOnly, required, options, onChangeAjaxEventUrl } = fieldConfig;

            return (
                <Form.Select
                    width={width}
                    fluid
                    label={label}
                    search
                    selection
                    key={fieldName}
                    options={options}
                    required={required}
                    disabled={readOnly}
                    value={value}
                    onChange={(e, data) => { this.onFilterFieldChange(fieldName, data.value, onChangeAjaxEventUrl) }}
                    selectOnBlur={false}
                />
            );
        })
    }

    getFilterFieldsPostData = () => {
        const { filterFields } = this.props.data;

        const postData = new FormData();
        _.forEach(filterFields, (fieldConfig, fieldName) => {
            const { value } = fieldConfig;

            postData.append(fieldName, value);
        })

        return postData;
    }

    getFilterFieldValueByFieldName = (fieldName) => {
        const { filterFields } = this.props.data;

        return filterFields[fieldName].value;
    }

    renderLoadTreeButton = () => {
        const { filterActions, spinnerIsActive } = this.props.data;
        if (filterActions == EMPTY_VALUE) {
            return null;
        }

        const { text, visible, requiredFieldsForUnlock, lockedMessage, url } = filterActions.loadTreeAction;

        //1. If not visible - do not render
        if (!visible) {
            return null;
        }

        //2. Build Post data from all filter form fields 
        const postData = this.getFilterFieldsPostData();

        //3. Process if the button is locked - do all required fields have value?
        let buttonIsLocked = false;
        _.forEach(requiredFieldsForUnlock, fieldName => {
            let valueOfThisFieldName = this.getFilterFieldValueByFieldName(fieldName);

            if (valueOfThisFieldName == EMPTY_VALUE) {
                buttonIsLocked = true;
            }
        });

        //4.Render Button
        if (buttonIsLocked) {
            //4.1. Render Locked button
            return (
                <Popup
                    wide='very'
                    trigger={
                        <div>
                            <Form.Button color='green' disabled style={{marginTop: '1.7em'}}>
                                {text}
                            </Form.Button>
                        </div>
                    }
                    content={lockedMessage}
                    basic
                />
            );
        } else {
            //4.2. Render button ready for use
            return (
                <Form.Button
                    style={{marginTop: '1.7em'}}
                    color='green'
                    onClick={() => this.props.onLoadButtonClick(url, postData)}
                    loading={spinnerIsActive}
                    disabled={spinnerIsActive}
                >
                    {text}
                </Form.Button>
            );
        }
    }

    renderResetButton = () => {
        const { filterActions } = this.props.data;
        if (filterActions == EMPTY_VALUE) {
            return null;
        }

        const { text, visible, url } = filterActions.resetScreenAction;

        if (!visible) {
            return null;
        }

        const postParams = this.getFilterFieldsPostData();

        return (
            <Form.Button
                style={{marginTop: '1.7em'}}
                color='red'
                onClick={() => this.props.updateScreenAjaxAction(url, postParams)}
            >
                {text}
            </Form.Button>
        );
    }

    renderFilterArea = () => {
        return (
            <Form>
                <Form.Group>
                    {this.renderFieterFields()}

                    {this.renderLoadTreeButton()}

                    {this.renderResetButton()}
                </Form.Group>
            </Form>
        )
    }

    renderElementChildren = (expanded, hasChildren, elements) => {
        if (!hasChildren) {
            return null;
        }

        return (
            <List.List>
                {_.map(elements, elem => this.renderSingleElement(elem, expanded))}
            </List.List>
        );
    }

    renderSingleElement = (elem, parentIsExpand = true) => {
        const noChildrenIcon = false;
        const hasChildrenClosedIcon = 'plus square outline';
        const hasChildrenOpenIcon = 'minus square outline';

        const { treeData, sidePaneLoadedTreeElementId } = this.props.data;

        const { allNodesExpandState } = treeData;
        const { expandedYes, expandedNo } = allNodesExpandState;

        const { text, iconName, iconColor, treeElementId, children, sidePaneInfo } = elem;
        const hasChildren = children !== undefined && !_.isEmpty(children);

        let foldIcon = '';
        if (!hasChildren) {
            foldIcon = noChildrenIcon;
        } else if (hasChildren && expandedYes.includes(treeElementId)) {
            foldIcon = hasChildrenOpenIcon;
        } else if (hasChildren && expandedNo.includes(treeElementId)) {
            foldIcon = hasChildrenClosedIcon;
        }

        let expandIconElement = (
            <List.Icon
                name={foldIcon}
                onClick={() => this.props.toggleExpandOfNodeElem(treeElementId)}
            />
        );

        let elementIcon = null;
        if (iconName) {
            let color = 'black';
            if (!_.isUndefined(iconColor)) {
                color = iconColor;
            }

            elementIcon = (
                <List.Icon name={iconName} color={color} />
            );
        }

        let currentElementIsTheSelectedElement = sidePaneLoadedTreeElementId == treeElementId;
        let style = {};
        if (currentElementIsTheSelectedElement) {
            style = { background: '#f0f0f0', padding: '2px', borderRadius: '6%' };
        }

        return (
            <Transition key={treeElementId} visible={parentIsExpand} animation='slide down' duration={300} unmountOnHide>
                <List.Item key={treeElementId}>
                    {expandIconElement}
                    {elementIcon}
                    <List.Content>
                        <List.Header
                            style={style}
                            onClick={() => {
                                if (!_.isUndefined(sidePaneInfo)) {
                                    this.props.selectTreeRecord(treeElementId, sidePaneInfo);
                                }
                            }}
                        >
                            {text}
                        </List.Header>
                        {this.renderElementChildren(expandedYes.includes(treeElementId), hasChildren, children)}
                    </List.Content>
                </List.Item>
            </Transition>
        );
    }

    renderTree = () => {
        const { treeData } = this.props.data;

        if (treeData == EMPTY_VALUE) {
            return null;
        }

        const { elements } = treeData;
        if (_.isEmpty(elements)) {
            return null;
        }

        return (
            <React.Fragment>
                <Icon
                    name='minus square outline'
                    onClick={this.props.setExpandNoAll}
                />
                <Icon
                    name='plus square outline'
                    onClick={this.props.setExpandYesAll}
                />
                <List size='tiny'>
                    {_.map(elements, elem => this.renderSingleElement(elem, true))}
                </List>
            </React.Fragment>
        );
    }

    renderSidePane = () => {
        const { sidePaneInfo } = this.props.data;
        if (sidePaneInfo == EMPTY_VALUE) {
            return null;
        }

        return (
            <React.Fragment>
                {
                    _.map(sidePaneInfo, (groupConfig, groupName) => {
                        const { fields, links } = groupConfig;
                        return (
                            <React.Fragment key={groupName}>
                                <Header as='h6' style={{ marginBottom: '20px', color: 'blue' }}>
                                    <Header.Content>{groupConfig.label}</Header.Content>
                                </Header>

                                <Grid style={{ marginBottom: '25px' }}>
                                    {
                                        _.map(fields, (fieldConfig, fieldName) => {
                                            const { label, value, isLink } = fieldConfig;

                                            let text = null;
                                            if (isLink === true) {
                                                const { frontEndRouteName, frontEndRouteParams } = fieldConfig;
                                                let route = ROUTES[frontEndRouteName].URL;
                                                if (frontEndRouteParams !== EMPTY_VALUE) {
                                                    _.forEach(frontEndRouteParams, (value, name) => {
                                                        route = route.replace(`:${name}`, value);
                                                    })
                                                }

                                                text = (
                                                    <Grid.Column 
                                                        style={{ fontSize: '12px' }}
                                                        as={Link}
                                                        name={frontEndRouteName} 
                                                        to={route}
                                                    >
                                                        {value}
                                                    </Grid.Column>
                                                );
                                            } else {
                                                text = <Grid.Column style={{ fontSize: '12px' }}>{value}</Grid.Column>;
                                            }

                                            return (
                                                <Grid.Row style={{ padding: '0', margin: '0' }} columns={2} key={fieldName}>
                                                    <Grid.Column style={{ fontWeight: 'bold', fontSize: '12px' }}>{label}</Grid.Column>
                                                    {text}
                                                </Grid.Row>
                                            );
                                        })
                                    }
                                </Grid>
                            </React.Fragment>
                        );
                    })
                }
            </React.Fragment>
        );
    }

    renderMainArea = () => {
        const { treeData } = this.props.data;

        if (_.isEmpty(treeData.elements) || treeData.elements == EMPTY_VALUE) {
            return null;
        }

        return (
            <Grid columns={2} divided>
                <Grid.Column width={12}>
                    <Ref innerRef={this.contextRef}>
                        <Segment>
                            {this.renderTree()}

                            <Rail position='right'>
                                <Sticky context={this.contextRef} offset={20}>
                                    {this.renderSidePane()}
                                </Sticky>
                            </Rail>
                        </Segment>
                    </Ref>
                </Grid.Column>
            </Grid>
        );
    }

    render() {
        const { title, subTitle } = this.props.data;

        return (
            <React.Fragment>
                <Header as='h2' textAlign='center' style={{ marginBottom: '0px' }}>
                    <Header.Content>{title}</Header.Content>
                </Header>

                <Divider horizontal>{subTitle}</Divider>

                {this.renderFilterArea()}

                {this.renderMainArea()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return { translations: state.auth.translations, data: state.treePageWithSidePaneReducer };
}

const actions = {
    fetchScreenData, clearPage, updateFilterFieldValue, onLoadButtonClick,
    toggleExpandOfNodeElem, setExpandNoAll, setExpandYesAll, selectTreeRecord,
    updateScreenAjaxAction
};

export default connect(mapStateToProps, actions)(TreeNavigationWithStickySidePanePage);

import actionTypes from "../../actions/checkList/actionTypes";
import { 
    open, close, toggleStatus, toggleSpinner, updateError, setStatusCheckedAll, setStatusUncheckedAll, 
    toggleShowHiddenElements, updateState 
} from './stateMutators';

const INITIAL_STATE = {
    isOpen: false,
    showSpinner: false,
    showHiddenElementsLogic: true,
    showHiddenElements: false,
    validationMessages: [],
    title: '',
    confirmUrl: '',
    
    statusChecked: [],
    statusUnchecked: [],
    statusDisabled: [],
    
    elements: [],
    allValuesNotHidden: [],
    allValuesHidden: [],
    allValuesNotHiddenAndHidden: [],
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.TOGGLE_SHOW_HIDDEN_ELEMENTS:
            return toggleShowHiddenElements(state, action);
        case actionTypes.UPDATE_ERROR_LIST:
            return updateError(state, action);
        case actionTypes.TOGGLE_SPINNER_LIST:
            return toggleSpinner(state, action);
        case actionTypes.OPEN_LIST:
            return open(state, action);
        case actionTypes.CLOSE_LIST:
            return close(INITIAL_STATE);
        case actionTypes.TOGGLE_STATUS_LIST:
            return toggleStatus(state, action);
        case actionTypes.STATUS_CHECKED_ALL_LIST:
            return setStatusCheckedAll(state, action);
        case actionTypes.STATUS_UNCHECKED_ALL_LIST:
            return setStatusUncheckedAll(state, action);
        default: 
            return state;
    }
}

export default reducer;

import React from 'react';
import _ from 'lodash';
import { Icon, Step } from 'semantic-ui-react';
import { FlowStepProp } from '../../enum/apiResultProp/FlowStepProp';
import CustomLabel from '../widgets/CustomLabel';
import { ActionProp } from '../../enum/apiResultProp/ActionProp';
import { ActionType } from '../../enum/ActionType';

export default ({ step, uniqueKey, onClickMutateAction, reloadScreenWithPostDataOnButtonClick }) => {
    const stepName = step[FlowStepProp.Name];
    const title = step[FlowStepProp.Title];
    const titleColor = step[FlowStepProp.TitleColor];
    const description = step[FlowStepProp.Description];
    const isHighlighted = step[FlowStepProp.IsHighlighted];
    const stepNumberLabel = step[FlowStepProp.StepNumberLabel];
    const statusLabel = step[FlowStepProp.StatusLabel];
    const action = step[FlowStepProp.OnClickAction];

    let additionalProps = { };
    if (action) {
        const onClick = () => {
            const actionType = action[ActionProp.ActionType];
            if (actionType === ActionType.MutateScreenWithPostDataAction) {
                const backEndUrl = action[ActionProp.BackEndUrl];

                onClickMutateAction({ stepName, backEndUrl });
            } else if (actionType === ActionType.MutateScreenNoPostDataAction) {
                const backEndUrl = action[ActionProp.BackEndUrl];

                onClickMutateAction({ stepName, backEndUrl });
            } else if (actionType === ActionType.ReloadScreenWithPostDataAction) {
                const backEndUrl = action[ActionProp.BackEndUrl];
                const onCliclActionParams = { backEndUrl };

                reloadScreenWithPostDataOnButtonClick(onCliclActionParams);
            } 
        }

        additionalProps.onClick = onClick;
    }

    let stepNumberLabelComponent = null;
    if (stepNumberLabel) {
        stepNumberLabelComponent = <CustomLabel labelProps={stepNumberLabel} />;
    }

    let statusLabelComponent = null;
    if (statusLabel) {
        statusLabelComponent = <CustomLabel labelProps={statusLabel} />
    }

    return (
        <Step
            active={isHighlighted}
            key={uniqueKey}
            {...additionalProps}
        >
            <Icon style={{marginBottom: '0.5em'}}>
                {statusLabelComponent}  
            </Icon>
            <Icon style={{marginBottom: '0.5em'}}>
                {stepNumberLabelComponent}  
            </Icon>
            <Step.Content>
                <Step.Title style={{ color: titleColor }}>
                    {title}
                </Step.Title>
                {/* <Step.Description>{statusLabelComponent}</Step.Description> */}
                <Step.Description>{description}</Step.Description>
            </Step.Content>
        </Step>
    );
}

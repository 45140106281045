import React from 'react';
import _ from 'lodash';
import { Step, Icon, Divider, Label, Grid, Button, Popup, Header, Table, Tab, TableBody } from 'semantic-ui-react';

import ButtonWithConfirmation from '../customComponents/ButtonWithConfirmation';

import { getValue } from '../../helpers/functions';
import { STRINGS } from '../../helpers/strings';

class Steps extends React.Component {
    renderStep = (step, key) => {
        const { general, access, circleLabel, statusLabel, onCircleLabelHoverPopupInfo, onCardClick } = step;

        let phaseNumberLabel = null;
        if (onCircleLabelHoverPopupInfo.visible) {
            const { columnNames, gridData } = onCircleLabelHoverPopupInfo.info;
            let popupContent = (
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            {_.map(columnNames, (headerLabel, fieldName) => {
                                return <Table.HeaderCell key={fieldName}>{headerLabel}</Table.HeaderCell>;
                            })}
                        </Table.Row>
                    </Table.Header>

                    <TableBody>
                        {_.map(gridData, (fields, rowKey) => {
                            return (
                                <Table.Row key={rowKey}>
                                    {_.map(columnNames, (fieldText, fieldName) => {
                                        let fieldValue = fields[fieldName];

                                        return <Table.Cell key={rowKey + fieldName}>{fieldValue}</Table.Cell>
                                    })}
                                </Table.Row>
                            );
                        })}
                    </TableBody>
                </Table>
            );

            phaseNumberLabel = (
                <Popup
                    flowing
                    trigger={
                        <Label circular color={circleLabel.color} style={{ marginRight: '2em' }} size='big'>
                            {circleLabel.text}
                        </Label>
                    }
                    content={popupContent}
                />
            );
        } else {
            phaseNumberLabel = (
                <Label circular color={circleLabel.color} style={{ marginRight: '2em' }} size='big'>
                    {circleLabel.text}
                </Label>
            );
        }

        let labelForStatus = null;
        if (statusLabel.visible) {
            labelForStatus = <Label style={{ marginLeft: '1em' }} color={statusLabel.color}>{statusLabel.text}</Label>;
        }

        
        const stepElem = (
            <Step
                active={general.currentlySelected}
                key={key}
                link
                onClick={() => {
                    if (!onCardClick.disabled && access.hasAccess) {
                        this.props.onPhaseClick(onCardClick.url)
                    }
                }}
            >
                {phaseNumberLabel}
                <Step.Content>
                    <Step.Title style={{ color: circleLabel.color }}>
                        {general.title}
                        {labelForStatus}
                    </Step.Title>
                    <Step.Description>{general.description}</Step.Description>
                </Step.Content>
            </Step>
        );

        let result = null;
        if (access.hasAccess) {
            result = stepElem;
        } else {
            result = (
                <Popup
                    key={key}
                    flowing
                    trigger={stepElem}
                    content={
                        <React.Fragment>
                            <Icon
                                name='window close outline'
                                color='red'
                                size='big'
                            />
                            <Label basic color='red'>
                                {access.noAccessMessage}
                            </Label>
                        </React.Fragment>
                    }
                />
            );
        }

        return result;
    }

    renderRows = () => {
        const { rows } = this.props.stepsConfiguration;

        return (
            <React.Fragment>
                {
                    _.map(rows, ((row, idx) => {
                        const countOfSteps = _.keys(row).length;
                        return (
                            <Step.Group
                                key={idx}
                                widths={countOfSteps}
                                style={{ marginBottom: '0px' }}
                            >
                                {_.map(row, (step, stepIdx) => this.renderStep(step, stepIdx))}
                            </Step.Group>
                        );
                    }))
                }
            </React.Fragment>
        );
    }

    renderActions = () => {
        const { actions } = this.props.stepsConfiguration;
        if (actions == undefined) {
            return null;
        }

        const {
            actionBack, actionRollback, actionStartWork, actionSubmitWork, actionDeclineWork, actionEdit, actionCancelEdit, actionSaveEdit,
            actionStartCheck, startCheckActions, actionCheck, actionDeclineCheck, actionStartApprove, startApproveActions, actionApprove, actionDeclineApprove,
            actionActivate, actionDownloadPDF
        } = actions;

        //ROLLBACK ACTION
        let actionRollbackBtn = null;
        if (actionRollback.active) {
            actionRollbackBtn = (
                <ButtonWithConfirmation
                    size='large'
                    translations={this.props.translations}
                    rowId={1}
                    key={'delete_1'}
                    question={getValue(this.props.translations, STRINGS.BUTTON_CONFIRMATION_DEFAULT_QUESTION)}
                    buttonConfirmTitle={actionRollback.title}
                    actionConfirm={() => this.props.onDeclineClick(actionRollback.url)}
                />
            );
        }

        //START ACTIONS
        let actionStartWorkBtn = null;
        if (actionStartWork.active) {
            actionStartWorkBtn = (
                <Button
                    size='large'
                    color='blue'
                    onClick={() => this.props.onStartWorkClick(actionStartWork.url)}
                >
                    <Button.Content>
                        <Icon name='arrow right alternate circle' />
                        {actionStartWork.title}
                    </Button.Content>
                </Button>
            );
        }
        //This is logic for single check action, based on user permissions
        let actionStartCheckBtn = null;
        if (actionStartCheck.active) {
            actionStartCheckBtn = (
                <Button
                    size='large'
                    color='blue'
                    onClick={() => this.props.onStartWorkClick(actionStartCheck.url)}
                >
                    <Button.Content>
                        <Icon name='arrow right alternate circle' />
                        {actionStartCheck.title}
                    </Button.Content>
                </Button>
            );
        }
        //This is logic for many check action, based on apqp phase config
        let actionStartCheckButtons = null;
        if (!_.isUndefined(startCheckActions) && !_.isEmpty(startCheckActions)) {
            actionStartCheckButtons = (
                <>
                    {_.map(startCheckActions, (checkActionObject, roleName) => {
                        const { active, url, title } = checkActionObject;
                        if (!active) {
                            return null;
                        }

                        return (
                            <Button
                                size='large'
                                color='blue'
                                onClick={() => this.props.onStartWorkClick(url)}
                                key={roleName}
                            >
                                <Button.Content>
                                    <Icon name='arrow right alternate circle' />
                                    {title}
                                </Button.Content>
                            </Button>
                        )
                    })}
                </>
            );
        }
        
        //This is logic for many approve actions, based on apqp phase config
        let actionStartApproveButtons = null;
        if (!_.isUndefined(startApproveActions) && !_.isEmpty(startApproveActions)) {
            actionStartApproveButtons = (
                <>
                    {_.map(startApproveActions, (approveActionObject, roleName) => {
                        const { active, url, title } = approveActionObject;
                        if (!active) {
                            return null;
                        }

                        return (
                            <Button
                                size='large'
                                color='blue'
                                onClick={() => this.props.onStartWorkClick(url)}
                                key={roleName}
                            >
                                <Button.Content>
                                    <Icon name='arrow right alternate circle' />
                                    {title}
                                </Button.Content>
                            </Button>
                        )
                    })}
                </>
            );
        }
        let actionStartApproveBtn = null;
        if (actionStartApprove.active) {
            actionStartApproveBtn = (
                <Button
                    size='large'
                    color='blue'
                    onClick={() => this.props.onStartWorkClick(actionStartApprove.url)}
                >
                    <Button.Content>
                        <Icon name='arrow right alternate circle' />
                        {actionStartApprove.title}
                    </Button.Content>
                </Button>
            );
        }
        let actionEditBtn = null;
        if (actionEdit.active) {
            actionEditBtn = (
                <Button
                    size='large'
                    color='green'
                    onClick={() => this.props.onPhaseClick(actionEdit.url)}
                >
                    <Button.Content>
                        <Icon name='arrow right alternate circle' />
                        {actionEdit.title}
                    </Button.Content>
                </Button>
            );
        }

        //DECLINE ACTIONS
        let actionDeclineCheckBtn = null;
        if (actionDeclineCheck.active) {
            actionDeclineCheckBtn = (
                <ButtonWithConfirmation
                    size='large'
                    translations={this.props.translations}
                    rowId={1}
                    key={'delete_1'}
                    question={getValue(this.props.translations, STRINGS.BUTTON_CONFIRMATION_DEFAULT_QUESTION)}
                    buttonConfirmTitle={actionDeclineCheck.title}
                    actionConfirm={() => this.props.onDeclineClick(actionDeclineCheck.url)}
                />
            );
        }
        let actionDeclineApproveBtn = null;
        if (actionDeclineApprove.active) {
            actionDeclineApproveBtn = (
                <ButtonWithConfirmation
                    size='large'
                    translations={this.props.translations}
                    rowId={1}
                    key={'delete_1'}
                    question={getValue(this.props.translations, STRINGS.BUTTON_CONFIRMATION_DEFAULT_QUESTION)}
                    buttonConfirmTitle={actionDeclineApprove.title}
                    actionConfirm={() => this.props.onDeclineClick(actionDeclineApprove.url)}
                />
            );
        }
        let actionDeclineWorkBtn = null;
        if (actionDeclineWork.active) {
            actionDeclineWorkBtn = (
                <ButtonWithConfirmation
                    size='large'
                    translations={this.props.translations}
                    rowId={1}
                    key={'delete_1'}
                    question={getValue(this.props.translations, STRINGS.BUTTON_CONFIRMATION_DEFAULT_QUESTION)}
                    buttonConfirmTitle={actionDeclineWork.title}
                    actionConfirm={() => this.props.onDeclineClick(actionDeclineWork.url)}
                />
            );
        }
        let actionCancelEditBtn = null;
        if (actionCancelEdit.active) {
            actionCancelEditBtn = (
                <ButtonWithConfirmation
                    size='large'
                    translations={this.props.translations}
                    rowId={1}
                    key={'cancel_edit_1'}
                    question={getValue(this.props.translations, STRINGS.BUTTON_CONFIRMATION_DEFAULT_QUESTION)}
                    buttonConfirmTitle={actionCancelEdit.title}
                    actionConfirm={() => this.props.onPhaseClick(actionCancelEdit.url)}
                />
            );
        }

        //SUBIMIT ACTIONS
        let actionCheckBtn = null;
        if (actionCheck.active) {
            actionCheckBtn = (
                <Button
                    size='large'
                    color='green'
                    onClick={() => this.props.onSubmitClick(actionCheck.url)}
                >
                    <Button.Content>
                        <Icon name='arrow right alternate circle' />
                        {actionCheck.title}
                    </Button.Content>
                </Button>
            );
        }
        let actionApproveBtn = null;
        if (actionApprove.active) {
            actionApproveBtn = (
                <Button
                    size='large'
                    color='green'
                    onClick={() => this.props.onSubmitClick(actionApprove.url)}
                >
                    <Button.Content>
                        <Icon name='arrow right alternate circle' />
                        {actionApprove.title}
                    </Button.Content>
                </Button>
            );
        }
        let actionSubmitWorkBtn = null;
        if (actionSubmitWork.active) {
            actionSubmitWorkBtn = (
                <Button
                    size='large'
                    color='green'
                    onClick={() => this.props.onSubmitClick(actionSubmitWork.url)}
                >
                    <Button.Content>
                        <Icon name='arrow right alternate circle' />
                        {actionSubmitWork.title}
                    </Button.Content>
                </Button>
            );
        }
        let actionSaveEditBtn = null;
        if (actionSaveEdit.active) {
            actionSaveEditBtn = (
                <Button
                    size='large'
                    color='green'
                    onClick={() => this.props.onSubmitClick(actionSaveEdit.url)}
                >
                    <Button.Content>
                        <Icon name='arrow right alternate circle' />
                        {actionSaveEdit.title}
                    </Button.Content>
                </Button>
            );
        }

        //COMMON ACTIONS
        let actionBackBtn = (
            <Button
                size='large'
                color='teal'
                onClick={() => this.props.onBackClick()}
            >
                <Button.Content>
                    <Icon name='left arrow alternate circle' />
                    {actionBack.title}
                </Button.Content>
            </Button>
        );
        let actionActivateBtn = null;
        if (actionActivate.active) {
            actionActivateBtn = (
                <Button
                    size='large'
                    color='blue'
                    onClick={() => this.props.onActivateClick(actionActivate.url)}
                >
                    <Button.Content>
                        <Icon name='arrow right alternate circle' />
                        {actionActivate.title}
                    </Button.Content>
                </Button>
            );
        }
        let actionDownloadPDFBtn = null;
        if (actionDownloadPDF.active) {
            actionDownloadPDFBtn = (
                <Button
                    size='large'
                    color='blue'
                    onClick={() => this.props.download(actionDownloadPDF.url, actionDownloadPDF.docName)}
                >
                    <Button.Content>
                        <Icon name='download' />
                        {actionDownloadPDF.title}
                    </Button.Content>
                </Button>
            );
        }

        return (
            <React.Fragment>
                <Grid style={{ marginTop: '1em' }}>
                    <Grid.Column floated='left' width={10}>
                        {actionBackBtn}
                        {actionDownloadPDFBtn}
                        {actionActivateBtn}

                        {actionStartWorkBtn}
                        {actionStartCheckBtn}
                        {actionStartCheckButtons}
                        {actionStartApproveBtn}
                        {actionStartApproveButtons}

                        {actionSubmitWorkBtn}
                        {actionCheckBtn}
                        {actionApproveBtn}

                        {actionEditBtn}
                        {actionSaveEditBtn}
                    </Grid.Column>

                    <Grid.Column floated='right' width={5}>
                        {actionRollbackBtn}
                        {actionDeclineWorkBtn}
                        {actionDeclineCheckBtn}
                        {actionDeclineApproveBtn}
                        {actionCancelEditBtn}
                    </Grid.Column>
                </Grid>
            </React.Fragment>
        );
    }

    renderSubtitle = () => {
        return (
            <Header as='h4' textAlign='center'>
                <Header.Content>{this.props.stepsConfiguration.subTitle}</Header.Content>
            </Header>
        );
    }

    render = () => {
        return (
            <React.Fragment>

                {this.renderRows()}

                {this.renderSubtitle()}

                {this.renderActions()}

                <Divider hidden />

            </React.Fragment>
        );
    }
}

export default Steps;
